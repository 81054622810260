

import { IEletronicInvoiceService } from "../interfaces/services/IEletronicInvoiceService";
import { IEletronicInvoiceRepository } from "../interfaces/repositories/IEletronicInvoiceRepository";
import { EletronicInvoiceTicket } from "../agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";
import { EletronicInvoice, IEletronicInvoiceV2 } from "../agreggates/EletronicInvoice/EletronicInvoice";
import { EletronicInvoiceRepository } from "../../../Infrastructure/repositories/api/EletronicInvoiceRepository";

export const EletronicInvoiceService = (
): IEletronicInvoiceService => {

  const eletronicInvoiceRepository: IEletronicInvoiceRepository = EletronicInvoiceRepository();



  const sendAsync = async (
    eletronicInvoice: IEletronicInvoiceV2,   
  ): Promise<EletronicInvoiceTicket[]> => {
    console.log("send");
   
      const response = await eletronicInvoiceRepository.sendAsync(eletronicInvoice)
      if (response) {
        console.log('eletronicInvoiceRepository', response)
        return response
      }
    
    return []
  };

  return { sendAsync };
};
