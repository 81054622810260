import { useCatalog } from "application/contexts/catalog/CatalogContext";
import { Product } from "domains/catalog/aggregates/Product";
import React, { FC, useCallback, useEffect, useState } from "react";
import "./WeighingMachineModal.scss";
import WeighingMachineProduct from "./weighingMachineProduct/WeighingMachineProduct";
import balancaImg from "../img/weighing-machine.svg";
import balancaImg2 from "../img/retire-da-balanca.gif";
import balancaImg3 from "../img/Balanca-1.gif";
import { BotaoGrande } from "components/botaoGrande/BotaoGrande";
import { roundValue } from "utils/utils";
export interface IWeighingMachineModalProps {
  //propertys
  weight: number;
  onClose: () => void;
  onClickConfirmar: (productSelected: Product, weight?: number) => void;
  weighingMachineProducts: Product[]
}
const WeighingMachineModal: FC<IWeighingMachineModalProps> = ({
  weight,
  onClose,
  onClickConfirmar,
  weighingMachineProducts
}) => {
  const [selectedProduct, setselectedProduct] = useState<Product | null>();
  const [adicionadoAoCarrinho, setAdicionadoAoCarrinho] = useState(false);
  const [retirarDaBalanca, setRetirarDaBalanca] = useState(false);

  useEffect(() => {
    if (weighingMachineProducts.length === 1) {
      setselectedProduct(weighingMachineProducts[0]);
    }
  }, [weighingMachineProducts]);


  const onClickContinuar = useCallback(() => {
    if (selectedProduct) {
      onClickConfirmar(selectedProduct, weight);
      // setAdicionadoAoCarrinho(true);
    }
  }, [onClickConfirmar, selectedProduct, weight]);

  const onClickVoltar = useCallback(() => {
    if (selectedProduct || weighingMachineProducts.length === 1) {
      setselectedProduct(null);
      setAdicionadoAoCarrinho(false);
    } else {
      // setRetirarDaBalanca(true);
      onClose();
    }
  }, [onClose, selectedProduct, weighingMachineProducts.length]);

  return (
    <div id={"WeighingMachineModal"}>
      <div className={"container"}>
        {!!weight &&
          selectedProduct &&
          !adicionadoAoCarrinho &&
          !retirarDaBalanca && (
            <>
              <div className={"topImageWeighing"}>
                <img src={selectedProduct.thumbnailUrl}></img>
              </div>
              <div className={"topNameWeighing"}>
                <h2>{selectedProduct.name}</h2>
              </div>
              <div className="content">
                <div className={"imageWeighing"}>
                  <img src={balancaImg}></img>
                </div>
                <div className={"weight"}>
                  <h2>{((weight - (selectedProduct.tare ? selectedProduct.tare : 0)) / 1000).toFixed(3).replace(".", ",")}Kg</h2>
                </div>
                <div className={"weight"}>
                  <h2>tara:{selectedProduct.tare}g</h2>
                </div>

                <div className={"total"}>
                  <h1>
                    Total: R$
                    {roundValue((selectedProduct.price * (weight - (selectedProduct.tare ? selectedProduct.tare : 0))) / 1000)
                      .toFixed(2)
                      .replace(".", ",")}
                  </h1>
                </div>
                <div className={"price"}>
                  <h3>
                    Valor da Kg: R$
                    {selectedProduct?.price.toFixed(2).replace(".", ",")}
                  </h3>
                </div>

                <div className={"buttons"}>
                  {((selectedProduct.price * (weight - (selectedProduct.tare ? selectedProduct.tare : 0))) / 1000) > 0 ?
                    <div
                      onClick={onClickContinuar}
                      className={"botaoContained"}
                      style={{ marginBottom: 16 }}
                    >
                      Continuar
                    </div> :
                    <>
                      <h3>Produto não atingiu o peso minimo</h3>
                      <div
                        className={"botaoContained"}
                        style={{ marginBottom: 16, opacity: 0.1 }}
                      >Continuar
                      </div>
                    </>

                  }
                </div>
                <div onClick={onClickVoltar} className={"botaoOutlined"}>
                  Voltar
                </div>
              </div>
            </>
          )}

        {!!weight &&
          !adicionadoAoCarrinho &&
          !retirarDaBalanca && (
            !selectedProduct &&
            <div className="content">
              <h1> Escolha um Produto </h1>
              <div className={"productList"}>
                {weighingMachineProducts.map((product) => (
                  <WeighingMachineProduct
                    product={product}
                    // selected={product.id === selectedProduct?.id}
                    onClick={() => setselectedProduct(product)}
                  />
                ))}
              </div>
              <button onClick={onClickVoltar} className={"botaoOutlined"}>
                Voltar
              </button>
            </div>
          )}

        {adicionadoAoCarrinho && (
          <div className="content">
            <div className={"imageWeighing"}>
              <img src={balancaImg2}></img>
            </div>
            <h1>Produto Adicionado ao Carrinho</h1>
            <h2>Retire o produto da balança</h2>
          </div>
        )}

        {retirarDaBalanca && (
          <div className="content">
            <div className={"imageWeighing"}>
              <img src={balancaImg2}></img>
            </div>
            <h1>Retire o produto da balança</h1>
          </div>
        )}

        {!weight && (
          <div className="content">
            <div className={"imageWeighing"}>
              <img src={balancaImg3}></img>
            </div>
            <h1>Coloque o produto sobre a balança</h1>
            {/* <h2>Retire o produto da balança</h2> */}
          </div>
        )}
      </div>
    </div>
  );
};
export default WeighingMachineModal;
function GetWeighingProducts(catalog: import("../../../../domains/catalog/aggregates/Catalog").Catalog | null): React.SetStateAction<Product[]> {
  throw new Error("Function not implemented.");
}

