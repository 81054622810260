
import { Button, IconButton } from '@material-ui/core'
import { Close, Favorite, KeyboardArrowDown, KeyboardArrowUp, Star } from '@material-ui/icons'
import { Carousel } from 'components/carousel/Carousel'
import React, { FC, useState } from 'react'
import { CardMoreDescription } from '../../components/cardMoreDescription/CardMoreDescription'
import styles from './TourDetail.module.scss'
import UseTourDetail from './UseTourDetail'
import Medal from 'assets/icons/medal.svg'
import TourLayout from '../../components/layout/TourLayout'

export const dayOfWeekToString = (dayOfWeek: number) => {
    switch (dayOfWeek) {
        case 0:
            return "Domingo"
        case 1:
            return "Segunda-feira"
        case 2:
            return "Terça-feira"
        case 3:
            return "Quarta-feira"
        case 4:
            return "Quinta-feira"
        case 5:
            return "Sexta-feira"
        case 6:
            return "Sábado"
        default:
            return ""
    }
}
export interface ITourDetailProps {

}
const TourDetail = () => {
    const { tourDetail, onPressBack, tourId, onClickReserveHandle } = UseTourDetail();
    const [openDate, setOpenDate] = useState(false);
    const [openValue, setOpenValue] = useState(false);
    return (
        <TourLayout
            onPressBack={onPressBack}
            title={"Detalhes"}
            isTopLayout
        >
            {
                tourDetail ?
                    <div id={styles.TourDetail} >
                        {/* <img className={styles.image} alt={tourDetail?.name} src={tourDetail?.imageUrl} />
                 */}
                        {
                            !!tourDetail.showcaseImages?.length &&
                            <Carousel values={tourDetail.showcaseImages.map(item => item.url)} />
                        }
                        <div className={styles.tourDetails}>
                            <div className={styles.container}>
                                {
                                    tourId === '1debb996-0c1c-4942-851f-f990900dc2ae' ?
                                        <div className={styles.tourLocation}>
                                            <h3>
                                                {tourDetail?.name}
                                            </h3>
                                            <div className={styles.favorite}>
                                                <Star />
                                                <p>Destaque</p>
                                            </div>
                                        </div>
                                        :

                                        tourId === '8a17f4d2-3aef-4e11-8530-c057abe5f551' ?
                                            <div className={styles.tourLocation}>
                                                <h3>
                                                    {tourDetail?.name}
                                                </h3>
                                                <div className={styles.favorite}>
                                                    <Favorite />
                                                    <p>Mais procurado</p>
                                                </div>
                                            </div>
                                            :

                                            tourId === '72c36fe2-358e-4f73-9ce9-ea185d25ad1e' ?
                                                <div className={styles.tourLocation}>
                                                    <h3>
                                                        {tourDetail?.name}
                                                    </h3>
                                                    <div className={styles.favorite}>
                                                        <img src={Medal} alt="Medalha de mais vendido" />
                                                        <p>Mais vendido</p>
                                                    </div>
                                                </div>
                                                :
                                                <div className={styles.nameTour}>

                                                    <h3>
                                                        {tourDetail?.name}
                                                    </h3>
                                                </div>

                                }
                                <div>
                                    <div className={styles.description}>
                                        {/* <div className={openViewMore ? styles.showDescription : styles.hideDescription}> */}
                                        <div>
                                            {tourDetail?.description && tourDetail?.description.split('\n').map(str => <p>{str}</p>)}

                                        </div>
                                        {/* </div> */}
                                    </div>
                                    {/* <div className={styles.textViewMore} onClick={() => setOpenViewMore((prev) => !prev)}>
                                {
                                    !openViewMore ?
                                        <p>Ver mais</p>
                                        :
                                        <p>Ver menos</p>
                                }
                            </div> */}
                                    <div>
                                        <div>
                                            {
                                                tourDetail?.moreDescriptions?.map((item, index) => (
                                                    <CardMoreDescription value={item} key={index} />
                                                ))
                                            }
                                        </div>
                                        <div className={styles.containerDescription}>
                                            <div onClick={() => setOpenDate((prev) => !prev)}>
                                                <span>Horários do passeio</span>
                                                <IconButton >
                                                    {!openDate ? (
                                                        <KeyboardArrowDown />
                                                    ) : (
                                                        <KeyboardArrowUp />
                                                    )}
                                                </IconButton>
                                            </div>
                                            <div
                                                className={
                                                    openDate ? styles.showCard : styles.hideCard
                                                }
                                            >
                                                {
                                                    tourDetail?.availableDays?.sort((a, b) => a.dayOfWeek - b.dayOfWeek).map((item) => {

                                                        return (
                                                            <p>{dayOfWeekToString(item.dayOfWeek)}: <b>{(item.startHour.substr(0, 5))}h às {(item.endHour.substr(0, 5))}h </b></p>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className={styles.containerDescription}>
                                        <div onClick={() => setOpenValue((prev) => !prev)}>
                                            <span>Valores</span>
                                            <IconButton>
                                                {!openValue ? (
                                                    <KeyboardArrowDown />
                                                ) : (
                                                    <KeyboardArrowUp />
                                                )}
                                            </IconButton>
                                        </div>
                                        <div
                                            className={
                                                openValue ? styles.showCard : styles.hideCard
                                            }
                                        >
                                            <div className={styles.containerAvailableDays}>
                                                {
                                                    tourDetail?.prices?.map((item) => (
                                                        <p><span>{item.description}</span> - <b>{new Intl.NumberFormat("pt-BR", {
                                                            style: "currency",
                                                            currency: "BRL",
                                                        }).format(item.price)}</b></p>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className={styles.containerButton}>
                                <div>


                                    <Button fullWidth variant="contained" onClick={() => onClickReserveHandle(tourDetail.id)} >Conferir datas disponíveis</Button>
                                </div>

                            </div>


                        </div>
                    </div>
                    :
                    <div> </div>
            }
        </TourLayout>
    )
}
export default TourDetail



