import { ErrorCodeType } from "application/models/IError";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { IOrderRepository } from "domains/order/interfaces/repositories/IOrderRepository";
import { IOrder } from "modules/order/domain/models/IOrder";
import { IPedidoPosDTO } from "modules/order/domain/models/IPedidoPosDTO";
import { TransactionType } from "modules/payment/domain/models/TransactionType";
import { GetError } from "utils/GetError";

export interface ISyncOrderResult {
  message?: string;
  success?: boolean;
  pedidoPOS?: IPedidoPosDTO;
}

export const SyncOrderUseCase = async (
  orderRepository: IOrderRepository,
  order: IOrder,
  transactionType: TransactionType,
  kiosk: Kiosk
): Promise<ISyncOrderResult> => {
  let result: ISyncOrderResult = {};
  if (
    transactionType === TransactionType.pospaid ||
    transactionType === TransactionType.cashless ||
    order.totalValue === 0
  ) {
    try {
      const pedidoPOS = await orderRepository.sendNowOrdersAsync(
        order,
        kiosk.localId,
        transactionType,
        order.payment?.FinancialTransactionModel,
        kiosk.id
      );
      result = {
        message: `Pedido sincronizado tag: ${
          pedidoPOS.clienteEstabelecimentoObject?.tag ?? "-"
        }`,
        success: true,
        pedidoPOS,
      };
      return result;
    } catch (error) {
      GetError(
        error,
        "onSucessPayment",
        "SyncOrderUseCase",
        "Falha ao sincronizar pedido",
        ErrorCodeType.ORDER
      );
      result = {
        message: `${
          (error as any)?.response?.data?.message ?? "Falha ao sincronizar o pedido"
        }`,
        success: false,
      };
      return result;
    }
  } else {
    try {
      const pedidoPOS = await orderRepository.sendOrderAsync(
        order,
        order.payment?.paymentMethod ?? TransactionType.misto,
        order.payment?.FinancialTransactionModel
      );
      result = {
        message: `Pedido adicionado a fila de sincronização`,
        success: false,
        pedidoPOS,
      };

      return result;
    } catch (error) {
      GetError(
        error,
        "SyncOrderUseCase",
        "SyncOrderUseCase",
        "Falha ao sincronizar pedido",
        ErrorCodeType.GENERAL
      );

      result = {
        message: `Falha na sincronização`,
        success: false,
      };
      return result;
    }
  }
};
