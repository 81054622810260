import React, { useState, useRef, FC, useCallback, useEffect } from "react";
import { ProdutoLista } from "./components/produtoLista/ProdutoLista";
import { ProdutoListaSeccionado, ProdutoListaSeccionadoInterface } from "./components/produtoListaSeccionado/ProdutoListaSeccionado";
import { TabCategoriaCardapio } from "./components/tabCategoriaCardapio/TabCategoriaCardapio";
import { StoreProvider, StoreConsumer } from "./StoreContext";
import { ProdutoDetalhamento } from "./components/produtoDetalhamento/ProdutoDetalhamento";
import { ProdutoDetalhamentoSimple } from "./components/produtoDetalhamentoSimple/ProdutoDetalhamentoSimple";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { Dialog, Icon } from "@material-ui/core";
import './StorePage.css'
import { useHistory } from "react-router";
import { useCatalog } from "../../../../application/contexts/catalog/CatalogContext";
import { useWeighingMachine } from "application/contexts/weighingMachine/WeighingMachine";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { SeccionedProduct } from "domains/catalog/aggregates/SeccionedProduct";
import { UseBarCode } from "areas/barCode/hooks/UseBarCode";
import { ProdutoTipo } from "Infrastructure/repositories/api/PedidoMeep";

const StorePage: FC = () => {
  const [positionCategoria, setpositionCategoria] = useState(0)

  const { replace } = useHistory()
  const { catalog } = useCatalog();
  const { kiosk } = useKiosk()
  const { weighingMachineEnable, disableWeighModal } = useWeighingMachine()
  // const { hideListProduct } = useCart()
  const refProdutoLista = useRef<ProdutoListaSeccionadoInterface>(null);

  useEffect(() => {
    disableWeighModal()
    return () => {

    }
  }, [disableWeighModal])

  const onPressVoltarHandle = useCallback(
    () => {
      if (catalog && catalog.stores.length > 1) {
        replace(`/catalog`)
      } else {
        if (weighingMachineEnable) {
          replace('/weighing')
        } else {
          replace(`/`);
        }
      }
    },
    [catalog, replace, weighingMachineEnable],
  )
  const filterSeccionedProducts = useCallback((seccionedProducts: SeccionedProduct[]) => {
    if (!seccionedProducts) {
      return [];
    }
    
    const filteredSeccionedProducts = seccionedProducts.map(seccionedProduct => ({
      ...seccionedProduct,
      products: seccionedProduct.products.filter(product => product.hideCatalog !== true && product.type !== ProdutoTipo.Kilo),
    }))
      .filter(seccionedProduct => seccionedProduct.products.length > 0)

    return filteredSeccionedProducts;
  },[]);

  const {focus, refDiv} = UseBarCode();

  return (
    <StoreProvider>
      <StoreConsumer>
        {
          ({
            productSelected,
            refItem,
            closeDetalhamento,
            openModal,
            store,
            featureds
          }) =>
            <>

              <div className="StorePage">
                <Toolbar 
                  imageUrl={store?.imageUrl ?? ""} 
                  title={store?.name ?? ''} 
                  onPressVoltar={onPressVoltarHandle} 
                  isClosed={store?.isClosed}
                />
                {
                  <Dialog
                    open={openModal}
                    // anchor="bottom"
                    // refItem={refItem}
                    maxWidth={false}
                    onClose={closeDetalhamento}
                  // size={productSelected?.compositions?.length ? undefined : "small"}
                  >

                    {
                      productSelected && openModal && (productSelected.compositions?.length || productSelected.productGeneralObservation) ?
                        <div
                          style={{
                            width: '90vw',
                            height: '80vh',
                            overflow: 'hidden',
                            minHeight: '80vh',
                            position: 'relative'
                          }}
                          className={"ModalDetalhamento"}>
                          <ProdutoDetalhamento
                            onClose={closeDetalhamento}
                            produto={productSelected}
                          />
                        </div>
                        :
                        productSelected && openModal &&
                        <div
                          style={{
                            width: '90vw',
                            overflow: 'hidden',
                            minHeight: '50vh',
                            position: 'relative',
                          }}
                          className={"ModalDetalhamento"}>
                          <ProdutoDetalhamentoSimple
                            onClose={closeDetalhamento}
                            produto={productSelected}
                          />
                        </div>
                    }
                  </Dialog>
                }{
                  store &&
                  <div ref={refDiv} onClick={focus}>
                    <TabCategoriaCardapio
                      position={positionCategoria}
                      onClick={(index) => { refProdutoLista.current?.scrollToCategoria(index) }}
                      produtoSeccionado={filterSeccionedProducts(store?.seccionedProduct)}
                    />
                    {
                      weighingMachineEnable &&
                      <div 
                        className={'pesarOutroPratoButton'} 
                        onClick={() => { replace('/weighing') }}
                        style={{
                          pointerEvents: store?.isClosed ? "none" : undefined,
                          opacity: store?.isClosed ? 0.5 : undefined,
                        }}
                      > 
                        <Icon fontSize={"large"}>point_of_sale</Icon>   Pesar outro prato 
                      </div>
                    }
                    {featureds && (
                      <ProdutoLista 
                        hideZeroPrices={kiosk?.hideZeroPrice} 
                        destaque 
                        produtos={featureds}
                        isClosed={store?.isClosed}
                        />
                      )}
                    <ProdutoListaSeccionado
                      ref={refProdutoLista}
                      isClosed={store?.isClosed}
                      produtosSeccionado={filterSeccionedProducts(store?.seccionedProduct)}
                      onChangeCategory={
                        (position) => {
                          setpositionCategoria(position)
                        }
                      }
                    />
                  </div>
                }
              </div>

            </>
        }
      </StoreConsumer>
    </StoreProvider >
  );
}
export default StorePage