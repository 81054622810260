import React, { FC, memo } from 'react'
import './StepComposicao.css'
import { Icon } from '@material-ui/core'

export interface EtapaInteface {
    id: string
    text: string
    checked: boolean
    enable?: boolean
    number?: number
}
interface StepComposicaoProps {
    etapas: EtapaInteface[],
    etapaAtual: number
}
export const StepComposicao: FC<StepComposicaoProps> = memo(({ etapas, etapaAtual }) => {
    return (
        <div className={"StepComposicao"}>
            {etapas.map((etapa, _index) =>
                // <div className={"containerItemStepComposicao"}>
                <div className={"StepComposicaoItemContainer"} style={{ flex: 1/* _index < etapas.length - 1 ? 1 : 0*/ }} key={_index}>
                    <StepItemComposicao
                        id={etapa.id}
                        text={etapa.text}
                        checked={etapa.checked && _index < etapaAtual}
                        number={_index}
                        enable={(_index === etapaAtual)} />
                    {
                        // _index < etapas.length - 1 &&
                        // <StepDivisorComposicao />
                    }
                </div>
            )
            }
        </div >
    )
})


export const StepItemComposicao: FC<EtapaInteface> = ({ number = 0, id, text, checked, enable }) => {
    return (
        <div className={"StepItemComposicao"} style={{ fontWeight: enable ? 600 : 500 }}>
            <div className={"iconStepItemComposicao"} style={{ backgroundColor: checked ? "#1ca" : enable ? "#1ac" : "#666" }}>
                {checked ? <Icon>check</Icon> : <>{number + 1}</>}
            </div>
            <div className={"textStepItemComposicao"}>
                {text}
            </div>
        </div>
    )
}

export const StepDivisorComposicao = () => {
    return (
        <div className={"StepDivisorComposicao"} >
            <div className={"line"} />
        </div>

    )
}
