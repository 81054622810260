import React, { useCallback, useEffect, useState } from 'react'
import { IReserveLog } from 'modules/tour/domain/models/IReserveLog'
import styles from './ReserveLog.module.scss'
import { Dialog, Drawer, Icon, IconButton } from '@material-ui/core';
import { usePrinter } from 'application/contexts/printer/PrinterContext';
import GetLogTourUseCase from 'modules/tour/application/useCases/tour/GetLogTourUseCase';
import ReserveTourLogRepository from 'Infrastructure/repositories/localStorage/ReserveTourLogRepository';
import { CustomerReserveFormDTO } from 'modules/tour/domain/models/ReserveTour';

const ReserveLog = () => {

    const [reserveLog, setReserveLog] = useState<IReserveLog[]>([]);
    const [reserveSelected, setReserveSelected] = useState<IReserveLog>()
    const { printReserveTour } = usePrinter();

    useEffect(() => {
        GetLogTourUseCase(ReserveTourLogRepository()).then((response) => setReserveLog(response))
        return () => {

        }
    }, [])

    const onPressPrinter = useCallback((item: CustomerReserveFormDTO) => {
        printReserveTour([{
            id: item.id,
            tourName: reserveSelected?.reserve.tour.name ?? "",
            date: reserveSelected?.reserve.date ?? "",
            customerName: item.name,
            customerDocument: item.cpf,
            customerBirthday: item.birthday,
            price: item.price.price,
        }
        ])
    }, [printReserveTour, reserveSelected])

    return (
        <div>
            {
                reserveLog.map((item) =>
                    <div onClick={() => setReserveSelected(item)} className={styles.reserveItem}>
                        {item.transaction &&
                            <div className={styles.item}>
                                {new Date(item.transaction?.DataCriacao).toLocaleString('pt-BR')}
                            </div>
                        }
                        <div className={styles.item}>
                            {item.reserve.tour.name}
                        </div>
                        <div className={styles.item}>
                            R${item.reserve.totalPrice.toFixed(2)}
                        </div>
                        <div className={styles.item}>
                            {item.reserve.customers.length} Clientes
                        </div>
                        <div className={styles.item}>
                            para o dia {item.reserve.date}
                        </div>
                    </div>
                )
            }
            <Dialog open={!!reserveSelected} onClose={() => setReserveSelected(undefined)}>
                {reserveSelected && <div className={styles.drawerContainer}>
                    <h2>Detalhes da Reserva</h2>
                    <div className={styles.item}>
                        Reserva: {reserveSelected.reserve.tour.name}
                    </div>
                    {reserveSelected.transaction &&
                        <div className={styles.item}>
                            realizado em: {new Date(reserveSelected.transaction?.DataCriacao).toLocaleString('pt-BR')}
                        </div>
                    }
                    <div className={styles.item}>
                        Total: R${reserveSelected.reserve.totalPrice.toFixed(2)}
                    </div>
                    <div className={styles.item}>

                        Cartão: XXXX XXXX XXXX {reserveSelected.transaction?.FinalCartao}
                    </div>
                    <div className={styles.item}>
                        Numero de Reservas: {reserveSelected.reserve.customers.length}
                    </div>
                    <div className={styles.item}>
                        Dia da reserva {reserveSelected.reserve.date}
                    </div>
                    <h3>Clientes</h3>
                    <div className={styles.item}>
                        {reserveSelected.reserve.customers.map((customer) => (
                            <div className={styles.customer}>
                                <div className={styles.item}>
                                    Nome: {customer.name}
                                </div>
                                <div className={styles.item}>
                                    CPF: {customer.cpf}
                                </div>
                                <div className={styles.item}>
                                    Tipo: {customer.price.description}
                                </div>
                                <div className={styles.item}>
                                    Valor: R${customer.price.price}
                                </div>
                                <div className={styles.actions}>
                                    <IconButton onClick={() => onPressPrinter(customer)}><Icon>printer</Icon></IconButton>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>}

            </Dialog>
        </div>
    )
}

export default ReserveLog
