import React, { FC, useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import "./FullScreenToast.css"
import { Icon } from '@material-ui/core'
interface FullScreenToastProps {
    // open: boolean;

    config: IFullScreenToastConfig | null
}


export interface IFullScreenToastConfig {
    message?: string | JSX.Element,
    color?: string,
    type?: "error" | "success" | "warning",
    icon?: string,
    iconSize?: number,
    time?: number,
    onClickButton?: () => void,
    buttonText?: string,
    onClose?: () => void,
    background?: string,
}

export const FullscreenToast: FC<FullScreenToastProps> = ({ config }) => {
    const [color, setcolor] = useState("#1ac")

    useEffect(() => {
        if (config) {
            setcolor(config?.color ? config.color : config?.type === "success" ? "#1ba" : config?.type === "error" ? "#f55" : config?.type === "warning" ? "#F80" : "#1ac")
            if (!!config?.time) {
                const time = setTimeout(
                    () => {
                        console.log("toast fullScreen", config)
                        if (config?.onClose) {
                            config.onClose();
                        }
                    },
                    config?.time ?? 6000
                )
                return () => {
                    clearTimeout(time);
                }
            }
        }
    }, [config])

    return (
        <CSSTransition mountOnEnter unmountOnExit in={!!config} timeout={500} classNames="modal-sucess">
            {/* <Drawer anchor="bottom" open={!!config}> */}
            {
                < div
                    className={"FullScreenToast"}
                    style={{ background: color }
                    }>
                        {config?.background && (
                            <img
                            id="fullscreen-toast-papelDeParedePlayer"
                            src={config.background}
                            // src={bg}
                            alt="papel de Parede"
                            ></img>
                        )}
                    <div className={"message-FullScreenToast"}>

                        <div className={"icon-FullScreenToast"}>
                            <Icon style={{ fontSize: config?.iconSize ?? 60 }} fontSize="large">{config?.icon ? config.icon : config?.type === "success" ? "check" : config?.type === "error" ? "close" : config?.type === "warning" ? "alert" : ""}</Icon>
                        </div>
                        <div className={"text-FullScreenToast"}>
                            {config?.message ?? ""}
                        </div>
                        {config?.onClickButton &&
                            <div className={"button-FullScreenToast"} onClick={config.onClickButton}>{config.buttonText}</div>
                        }
                    </div>
                </div >
            }
            {/* // </Drawer> */}
        </CSSTransition >
    )
}
