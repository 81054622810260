export const hideCpf = (cpf: string) => {
    if (cpf.length !== 11) {
        return cpf;
    }
    const firstThree = cpf.slice(0, 3);
    const lastTwo = cpf.slice(-2);
    const middle = '.***.***-';
  
    return `${firstThree}${middle}${lastTwo}`;

}

export const hideCnpj = (cnpj: string) => {
  if (cnpj.length !== 14) {
      return cnpj;
  }
  const firstTwo = cnpj.slice(0, 2);
  const lastTwo = cnpj.slice(-2);
  const middle = '.***.***/****-';

  return `${firstTwo}${middle}${lastTwo}`;
}


export const toInputDateString = (date: Date | string) => {
    date = date instanceof Date ? date : new Date(date);
    return `${date.toLocaleDateString("pt-BR", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    })} - ${date.toLocaleTimeString([], {      
      hour12: false
    })}`;
  }

export const roundValue = (value: number) => {
  return Math.round((value + Number.EPSILON) * 100) / 100;
}