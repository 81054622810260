import { CefSharpPaymentRepository } from "Infrastructure/services/cefSharpService/local/CefSharpPaymentRepository";
import { ErrorCodeType } from "application/models/IError";
import { IPinpadService } from "modules/payment/application/interfaces/IPinpadService";
import { DoPinpadPaymentUseCase } from "modules/payment/application/useCases/DoPinpadPaymentUseCase";
import { TransactionType } from "modules/payment/domain/models/TransactionType";
import { GetError } from "utils/GetError";

export const UsePinpad = () => {

  const doPinpadPayment = async (value: number, type: TransactionType) => {
    const paymentService: IPinpadService = CefSharpPaymentRepository();
    try {
      const result = await DoPinpadPaymentUseCase(paymentService, value, type);
      return result
    } catch (error) {
      return Promise.reject(
        GetError(
          error,
          'doPinpadPayment',
          'UsePinpad',
          undefined,
          ErrorCodeType.PAYMENT
        )
      )
    }
  };

  return {
    doPinpadPayment,
  };
};
