import React, { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { PrinterView, PrinterViewComponent } from '../../../components/printerView/PrinterView'
import { Kiosk, SystemPrintType } from '../../../domains/kiosk/aggregates/Kiosk';
import { Order, RePrintOrderSyncObject } from '../../../domains/order/agreggates/order/Order';
import { Payment } from '../../../domains/order/agreggates/payment/payment';
import { PedidoPos, ProdutoPedidoPos, ProdutoTipo } from '../../../Infrastructure/repositories/api/PedidoMeep';
import { ResumoLayoutPrinter } from '../../../components/layoutsPrinter/resumoLayoutPrinter/ResumoLayoutPrinter'
import { TicketLayoutPrinter } from '../../../components/layoutsPrinter/ticketLayoutPrinter/TicketLayoutPrinter'
import { EletronicInvoiceTicket } from '../../../domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket';
import { useKiosk } from '../kiosk/KioskContext';
import { PrintType } from '../../../domains/kiosk/aggregates/PrintType';
import { InvoiceLayoutPrinter } from '../../../components/layoutsPrinter/invoiceLayoutPrinter/InvoiceLayoutPrinter';
import ReserveTourLayoutPrinter from 'components/layoutsPrinter/reserveTourLayoutPrinter/ReserveTourLayoutPrinter';
import { IReserveTourPrinterLayout } from 'components/layoutsPrinter/reserveTourLayoutPrinter/ReserveTourLayoutPrinter.type';
import CpfRegisterLayoutPrinter from 'components/layoutsPrinter/cpfRegisterLayoutPrinter/CpfRegisterLayoutPrinter';
import { ICpfRegisterPrinterLayout } from 'components/layoutsPrinter/cpfRegisterLayoutPrinter/CpfRegisterLayoutPrinter.type';
import { OrderRepositoryLocalStorage } from 'Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage';
import { Operator } from 'domains/admin/aggregates/operator/Operator';
import moment from 'moment';
import { v4 } from 'uuid';
import { IOfflineRechargePrint, OfflineRechargeLayout } from 'components/layoutsPrinter/offlineRechargeLayout/OfflineRechargeLayout';
import { RemotePrinter } from 'components/layoutsPrinter/remotePrinter/RemotePrinter';
import { IPrintSector } from 'Infrastructure/repositories/api/ConfigTotemMeep';
import { OrderItem } from 'domains/order/agreggates/order/OrderItem';
import { useAppInsights } from 'Infrastructure/repositories/appInsights/AppInsights';


interface IPriterContext {
    printOrder: (pedidoPOS: PedidoPos) => Promise<void>
    printInvoice: (invoice: EletronicInvoiceTicket[]) => Promise<void>
    printReserveTour: (reserveTour: IReserveTourPrinterLayout[]) => Promise<void>,
    printCpfRegister: (cpfRegisterData: ICpfRegisterPrinterLayout) => Promise<void>,
    rePrintTicket: (pedidoPOs: PedidoPos, operator: Operator, produtoPedidoPos?: ProdutoPedidoPos) => Promise<void>
    printOfflineRecharge: (offlineRecharge: IOfflineRechargePrint) => Promise<void>
    printRemote: (order: Order) => Promise<void>
}

export interface ITicket {
    pedidoPos: PedidoPos,
    produtoPedidoPos: ProdutoPedidoPos,
    index: number,
    isReprint?: boolean
}

//TODO: Remover/Refatorar contexto
const PrinterContext = createContext<IPriterContext>({} as IPriterContext)

export const PrinterProvider: FC = ({ children }) => {
    const refResumoPrinterView = useRef<PrinterViewComponent>(null);
    const refRemotePrinterView = useRef<PrinterViewComponent>(null);
    const refTicketPrinterView = useRef<PrinterViewComponent>(null);
    const refInvoicePrinterView = useRef<PrinterViewComponent>(null);
    const refReserveTourPrinterView = useRef<PrinterViewComponent>(null);
    const refCpfRegisterPrinterView = useRef<PrinterViewComponent>(null);
    const offlineRechargePrinterView = useRef<PrinterViewComponent>(null);

    const [pedidoPosToPrint, setpedidoPosToPrint] = useState<PedidoPos | null>(null);

    const [remoteToPrint, setRemoteToPrint] = useState<Order | null>(null);

    const [ticketToPrint, setTicketToPrint] = useState<ITicket | null>(null);

    const [invoiceToPrint, setInvoiceToPrint] = useState<(EletronicInvoiceTicket & { randon: string }) | null>(null);

    const [reserveTourToPrint, setReserveTourToPrint] = useState<IReserveTourPrinterLayout | null>(null);

    const [cpfRegisterToPrint, setCpfRegisterToPrint] = useState<ICpfRegisterPrinterLayout | null>(null);

    const [offlineRechargeToPrint, setOfflineRechargeToPrint] = useState<IOfflineRechargePrint | null>(null)

    const { kiosk } = useKiosk();

    const { addLog } = useAppInsights();

    const rePrintTicket = useCallback(
        async (pedidoPOs: PedidoPos, operator: Operator, produtoPedidoPos?: ProdutoPedidoPos) => {
            if (kiosk?.printType === PrintType.Ticket) {
                if (produtoPedidoPos) {

                    const index = pedidoPOs.produtosObject.findIndex(item => item.ticketId === produtoPedidoPos.ticketId)
                    setTicketToPrint({ pedidoPos: pedidoPOs, produtoPedidoPos, index: index, isReprint: true });
                    const orderRepositoryLocalStorage = OrderRepositoryLocalStorage();
                    const orderReprint: RePrintOrderSyncObject =
                    {
                        reimpressaoId: v4(),
                        dataRealizacao: moment().utc().toDate(),
                        ticketId: produtoPedidoPos.ticketId,
                        operadorId: operator.id,
                        localClienteId: kiosk?.localId ?? "",
                        equipamentoId: kiosk?.id ?? ""
                    }


                    orderRepositoryLocalStorage.updateRePrintTicket(produtoPedidoPos.ticketId, orderReprint);
                    orderRepositoryLocalStorage?.syncRePrint(orderReprint)
                }
            } else if (kiosk?.printType === PrintType.OrderResume) {
                setpedidoPosToPrint({ ...pedidoPOs });
                const orderRepositoryLocalStorage = OrderRepositoryLocalStorage();
                const orderReprint: RePrintOrderSyncObject =
                {
                    reimpressaoId: v4(),
                    dataRealizacao: moment().utc().toDate(),
                    ticketId: pedidoPOs.pedidoPOSId ?? '',
                    operadorId: operator.id,
                    localClienteId: kiosk?.localId ?? "",
                    equipamentoId: kiosk?.id ?? ""
                }
                pedidoPOs.pedidoPOSId && orderRepositoryLocalStorage.updateRePrintTicket(pedidoPOs.pedidoPOSId, orderReprint);
                orderRepositoryLocalStorage?.syncRePrint(orderReprint)

            }
        },
        [kiosk],
    )
    const printOrder = useCallback(
        async (pedidoPOs: PedidoPos) => {
            if (kiosk?.printType === PrintType.Ticket) {
                pedidoPOs?.produtosObject?.map((produto, index) =>
                    produto.tipoProduto !== ProdutoTipo.TaxaServico
                    &&
                    setTicketToPrint({ pedidoPos: pedidoPOs, produtoPedidoPos: produto, index: index }));
                // setpedidoPosToPrint(pedidoPOs);

            } else if (kiosk?.printType === PrintType.OrderResume) {
                setpedidoPosToPrint(pedidoPOs);
            }
        },
        [kiosk],
    )


    const groupItemsByProductIds = useCallback((order: Order, printingSectorsContainer: IPrintSector[], totemId: string): { printerName: string, items: OrderItem[] }[] => {
        const groupedItems: { [printerName: string]: OrderItem[] } = {};

        order.orderItems.forEach(item => {
            const productId = item.productId;
            const printingSector = printingSectorsContainer.find(sector =>
                sector.productsIds.includes(productId) && sector.totemsIds.includes(totemId)
            );

            const printerName = printingSector ? printingSector.printerName : (item.printerName ?? '');

            if (!groupedItems[printerName]) {
                groupedItems[printerName] = [];
            }

            groupedItems[printerName].push(item);
        });
        return Object.entries(groupedItems).map(([printerName, items]) => ({
            printerName,
            items
        }));
    }, []);

    const printRemote = useCallback(
        async (order: Order) => {
            const groupedItems = groupItemsByProductIds(order, kiosk?.printingSectors ?? [], kiosk?.id ?? '');

            for (const group of groupedItems) {
                const orderToPrint: Order = {
                    ...order,
                    orderItems: group.items
                };
                setRemoteToPrint(orderToPrint);
            }
        },
        [groupItemsByProductIds, kiosk]
    );

    const printInvoice = useCallback(
        async (invoices: EletronicInvoiceTicket[]) => {
            if (kiosk?.mustPrintEletronicInvoice) {

                // console.log("Invoices to Print", invoices);
                invoices.map(async (invoice) => {

                    // console.log("invoice map start delay")
                    function delay(ms: number) {
                        return new Promise(resolve => setTimeout(resolve, ms));
                    }
                    await delay(1000)
                    setInvoiceToPrint({ ...invoice, randon: v4() });
                    // console.log("invoice map", invoice)

                })
            }
        },
        [kiosk],
    )
    const printReserveTour = useCallback(
        async (reserveTour: IReserveTourPrinterLayout[]) => {
            reserveTour.map((item, index) => setReserveTourToPrint(item));
        },
        [])
    const printCpfRegister = useCallback(
        async (cpfRegisterData: ICpfRegisterPrinterLayout) => {
            setCpfRegisterToPrint(cpfRegisterData)
        },
        [])
    const printOfflineRecharge = useCallback(
        async (offlineRecharge: IOfflineRechargePrint) => {
            setOfflineRechargeToPrint(offlineRecharge)
        },
        [])

    useEffect(() => {
        if (pedidoPosToPrint) {
            refResumoPrinterView.current?.print();
        }
        return () => {

        }
    }, [pedidoPosToPrint])

    useEffect(() => {
        if (remoteToPrint && kiosk) {
            refRemotePrinterView.current?.remotePrint(remoteToPrint, kiosk);
        }
    }, [kiosk, remoteToPrint])

    useEffect(() => {
        if (ticketToPrint) {
            refTicketPrinterView.current?.print();
        }
        return () => {

        }
    }, [ticketToPrint])

    useEffect(() => {
        if (reserveTourToPrint) {
            // console.log(reserveTourToPrint)
            refReserveTourPrinterView.current?.print();
        }
        return () => {

        }
    }, [reserveTourToPrint])

    useEffect(() => {
        if (invoiceToPrint) {
            // console.log("print Ivoince effect", invoiceToPrint)
            refInvoicePrinterView.current?.print();
        }
        return () => {

        }
    }, [invoiceToPrint])

    useEffect(() => {
        if (cpfRegisterToPrint) {
            refCpfRegisterPrinterView.current?.print();
        }
        return () => {

        }
    }, [cpfRegisterToPrint])

    useEffect(() => {
        if (offlineRechargeToPrint) {
            offlineRechargePrinterView.current?.print();
        }
        return () => {

        }
    }, [offlineRechargeToPrint])

    const onPrintFinish = useCallback(
        () => {
            addLog("Print", {message: "End print", orderLog: {ticketToPrint, pedidoPosToPrint}});
        },
        [addLog, pedidoPosToPrint, ticketToPrint],
    )

    return (
        <PrinterContext.Provider value={{ printOrder, printInvoice, printReserveTour, printCpfRegister, rePrintTicket, printOfflineRecharge, printRemote }}>
            {children}
            {
                pedidoPosToPrint && kiosk &&
                <PrinterView id="resumo" ref={refResumoPrinterView} onPrintFinish={onPrintFinish}>
                    <ResumoLayoutPrinter kiosk={kiosk} pedidoPos={pedidoPosToPrint} />
                </PrinterView>
            }
            {
                remoteToPrint && kiosk &&
                <PrinterView id="remoteToPrinter" ref={refRemotePrinterView}>
                    <RemotePrinter kiosk={kiosk} remoteToPrint={remoteToPrint} />
                </PrinterView>
            }
            {
                ticketToPrint && kiosk &&
                <PrinterView id="ticket" ref={refTicketPrinterView} onPrintFinish={onPrintFinish}>
                    <TicketLayoutPrinter kiosk={kiosk} ticketToPrint={ticketToPrint}></TicketLayoutPrinter>
                </PrinterView>
            }
            {
                invoiceToPrint && kiosk &&
                <PrinterView id="invoice" ref={refInvoicePrinterView}>
                    <InvoiceLayoutPrinter kiosk={kiosk} invoice={invoiceToPrint}></InvoiceLayoutPrinter>
                </PrinterView>
            }
            {
                reserveTourToPrint && kiosk &&
                <PrinterView id="tour" ref={refReserveTourPrinterView}>
                    <ReserveTourLayoutPrinter reserveTour={reserveTourToPrint} ></ReserveTourLayoutPrinter>
                </PrinterView>
            }
            {
                cpfRegisterToPrint && kiosk &&
                <PrinterView id="cpfRegister" ref={refCpfRegisterPrinterView}>
                    <CpfRegisterLayoutPrinter registerPrinterData={cpfRegisterToPrint} ></CpfRegisterLayoutPrinter>
                </PrinterView>
            }
            {
                offlineRechargeToPrint && kiosk &&
                <PrinterView id="offLineRecharge" ref={offlineRechargePrinterView}>
                    <OfflineRechargeLayout kiosk={kiosk} offlineRechargePrint={offlineRechargeToPrint} />
                </PrinterView>
            }
            {/* <PrinterView test ref={refResumoPrinterView}>
                <ResumoLayoutPrinter
                    kiosk={kiosk ?? {} as Kiosk}
                    pedidoPos={{ ...pedidoPosExamble } as PedidoPos}
                />
            </PrinterView> */}
            {/* <PrinterView test ref={refResumoPrinterView}>
                <TicketLayoutPrinter kiosk={kiosk ?? {} as Kiosk} produtoPedidoPos={pedidoPosExamble.produtosObject[0]}></TicketLayoutPrinter>
            </PrinterView> */}
            {/* <PrinterView test id="invoice" ref={refInvoicePrinterView}>
                <InvoiceLayoutPrinter kiosk={kiosk ?? {} as Kiosk} invoice={invoiceExample}></InvoiceLayoutPrinter>
            </PrinterView> */}
        </PrinterContext.Provider >
    )
}


export const usePrinter = () => {
    const context = useContext(PrinterContext)
    return context
}