import React, { createContext, FC, useContext, useState, RefObject, memo, useCallback, useMemo } from 'react'
import { Store } from '../../../../domains/catalog/aggregates/Store'
import { useParams } from 'react-router-dom'
import { Product } from '../../../../domains/catalog/aggregates/Product'
import { useCatalog } from '../../../../application/contexts/catalog/CatalogContext'
import { useUi } from '../../../../application/contexts/ui/UIContext'

interface IStoreProvide {
    openModal: boolean;
    openDetalhamento: (product: Product, refItem: RefObject<HTMLDivElement>, width?: string, height?: string) => void,
    closeDetalhamento: () => void,
    refItem?: RefObject<HTMLDivElement>,
    productSelected?: Product,
    store?: Store;
    featureds?: Product[];
    isClosed?: boolean;
}

const storeContext = createContext<IStoreProvide>({} as IStoreProvide)

export const StoreProvider: FC = memo(({ children }) => {

    const [modalValues, setmodalValues] = useState<{ open: boolean, ref?: RefObject<HTMLDivElement>, product?: Product }>({ open: false })

    const { storeId } = useParams<{ storeId: string }>();

    const { catalog } = useCatalog();

    const { toast } = useUi()

    const { featureds, isClosed } = useMemo(() => {

        const store = catalog?.stores[parseInt(storeId)];

        const featureds = store?.seccionedProduct.flatMap((sectionedproduct) => sectionedproduct.products).filter((_produto => _produto.isFeatured));
        const isClosed = store?.isClosed;

        return {
            featureds,
            isClosed
        }
    }, [catalog, storeId])
    
    const openDetalhamento = useCallback((_product: Product, _refItem: RefObject<HTMLDivElement>) => {
        if (isClosed) {
            toast('Loja fechada', 'error')
        } else {
            setmodalValues({ open: true, product: _product, ref: _refItem })
        }
    }, [isClosed, toast])

    const closeDetalhamento = useCallback(() => {
        if (isClosed) {
            toast('Loja fechada', 'error')
        } else {
            setmodalValues(prev => ({ open: false, ref: prev.ref, product: prev.product }))
        }
    }, [isClosed, toast])

    return (
        <storeContext.Provider value={
            {
                openDetalhamento,
                closeDetalhamento,
                openModal: modalValues.open,
                refItem: modalValues.ref,
                productSelected: modalValues.product,
                store: catalog?.stores[parseInt(storeId)],
                featureds,
                isClosed
            }
        }
        >
            {children}
        </storeContext.Provider>
    )
})
export const StoreConsumer = storeContext.Consumer

export const useStore = () => {
    const context = useContext(storeContext)
    return context
}
