import { AddPlaceDemandScheduleRequestDTO } from "modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentRequest"
import { IAddReserveScheduleWithoutPaymentResponse } from "modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentResponse"
import { TourDetail } from "modules/tour/domain/models/CatalogTour"
import { IReserverTourFormProps } from "modules/tour/presentation/components/reserveTourForm/ReserverTourForm"
import { ReserveTourFormValue } from "modules/tour/presentation/components/reserveTourForm/UseReserverTourForm.type"
import { ICartTourItem } from "modules/tour/presentation/components/tourCart/ICartTourItem"
import { ICartTour } from "modules/tour/presentation/components/tourCart/ICartTour"
import { ITourRepository } from "../../interfaces/ITourRespository"

const PostScheduleCartReservetWithoutPaymentUseCase = async (tourRepository: () => ITourRepository, cartTour: ICartTour): Promise<IAddReserveScheduleWithoutPaymentResponse> => {

    const services = cartTour.cartTourItems.map(cartItem => {
        const [day, mounth, year] = cartItem.reserveTour.date.split("/")
        const [hour, minute, second] = cartItem.reserveTour.time.split(":")

        return cartItem.reserveTour.customers.map((customer) => {

            const [_day, _mounth, _year] = customer.birthday.split("/")

            return ({
                serviceScheduledId: cartItem.tour.scheduledId,
                start: (new Date(Number(year), Number(mounth) - 1, Number(day), Number(hour), Number(minute) + 2, Number(second), 0)).toISOString(),
                client: {
                    birthDate: `${_year}-${_mounth}-${_day}`,
                    document: customer.cpf + " / " + customer.rg,
                    name: customer.name,
                    phone: customer.phone,
                    note: customer.note
                }
            }
            )
        })
    }).flatMap(item => item)

    const resquest: AddPlaceDemandScheduleRequestDTO = {
        services: services
    }
    const response = await tourRepository().postAddResevePlaceSchedule(resquest);
    return response
}
export default PostScheduleCartReservetWithoutPaymentUseCase
