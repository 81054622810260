import React, { FC, useState, useEffect, useCallback, useRef } from "react";
import "./ProdutoDetalhamento.css";
import { Button, Icon, IconButton } from "@material-ui/core";
import { Product } from "../../../../../../domains/catalog/aggregates/Product";
import { OrderComposition } from "../../../../../../domains/order/agreggates/order/OrderComposition";
import { ComposicaoLista } from "../composicaoLista/ComposicaoLista";
import QRCode from 'qrcode.react'
import InputText from "components/inputText/InputText";
import { v4 } from "uuid";
import { OrderCompositionItem } from "domains/order/agreggates/order/OrderCompositionItem";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";

interface ProdutoDetalhamentoProps {
  produto: Product;
  onClose: () => void;
  enableQrCodeDescription?: boolean
}
export const ProdutoDetalhamento: FC<ProdutoDetalhamentoProps> = ({
  produto,
  onClose,
}) => {
  const [quantidade, setquantidade] = useState(1);
  // const cart = useCart();
  const [addProductCart] = useCartStore((state: ICartContext) => [
    state.addProductCart
  ]);
  const [orderCompositions, setOrderCompositions] = useState<OrderComposition[]>([]);
  const [observationField, setObservationField] = useState({ observation: '' })
  const [minimo, setMinimo] = useState<boolean>(true);
  const [disableButtomAdicionar, setdisableButtomAdicionar] = useState(false);
  const [addtionalCount, setAddtionalCount] = useState(0)
  const [selectedItemsFree, setSelectedItemsFree] = useState<OrderCompositionItem[]>([]);
  const [selectedItemsReplica, setSelectedItemsReplica] = useState<OrderCompositionItem[]>([]);
  const [descriptionObject, setdescriptionObject] = useState<{ description?: string, qrCode?: string }>();

  const quatitadeMaxFreeDesc = useRef(0)

  useEffect(() => {
    try {
      const objectDescription = JSON.parse(produto.description ?? '');
      setdescriptionObject(objectDescription);
    } catch (error) {
      if (produto.description && produto.description.length > 0) {
        setdescriptionObject({
          description: produto.description,
          qrCode: undefined
        });
      }
    }

    try {
      if (produto.compositions && produto.compositions.length > 0) {
        for (const composition of produto.compositions) {
          if (composition.compositionItems.length > 0) {
            for (const compositionItem of composition.compositionItems) {
              if (compositionItem.compositionId === null || compositionItem.compositionId === undefined || compositionItem.compositionId === "" || compositionItem.compositionId === "00000000-0000-0000-0000-000000000000") {
                compositionItem.compositionId = composition.id;
              }
            }
          }
        }
      }
    } catch (error) {
      // console.log("not object description", error)
    }
    console.log('Produto', produto)
  }, [produto])


  const adicionarUmTotal = () => {
    setAddtionalCount(prev => prev + 1)
  }
  const removerUmTotal = () => {
    setAddtionalCount(prev => prev - 1)
  }




  const getTotalItemsFreeDesc = () => {
    if (produto.maxQuantityFreeAdditional) {
      quatitadeMaxFreeDesc.current = produto.maxQuantityFreeAdditional
    }
    if (selectedItemsFree.length <= 0) {
      return quatitadeMaxFreeDesc.current;
    } else {
      for (let z = 0; z < selectedItemsFree.length; z++) {
        quatitadeMaxFreeDesc.current -= selectedItemsFree[z].quantity;
      }
    }

    return quatitadeMaxFreeDesc.current;
  }

  const getTotalItemsFree = () => {
    let tot = 0;
    if (selectedItemsFree.length <= 0) {
      return tot;
    } else {
      for (let z = 0; z < selectedItemsFree.length; z++) {
        tot += selectedItemsFree[z].quantity;
      }
    }

    return tot;
  }

  const getValorTotalItemsFree = () => {
    let tot = 0;
    if (selectedItemsFree.length <= 0) {
      return tot;
    } else {
      for (let z = 0; z < selectedItemsFree.length; z++) {
        tot += (selectedItemsFree[z].price * selectedItemsFree[z].quantity);
      }
    }

    return tot;
  }

  const onClickAdicionar = useCallback(() => {
    if (produto) {
      let finishedCompositionList = [...orderCompositions];
      /**
       * Se estiver o parametro do IF observacao entra aqui
       * START
       */
      const _observationField: OrderComposition = {
        id: v4(),
        description: 'Observação',
        compositionId: "00000000-0000-0000-0000-000000000000",
        totalPrice: 0,
        orderCompositionItems: [{
          id: v4(),
          description: observationField.observation,
          price: 0,
          quantity: 1,
          compositionId: "00000000-0000-0000-0000-000000000000",
          produtoId: '',
          compositionItemId: v4(),
          quantityFree: 0,
          quantityFreeSelected: 0,
          itemType: 0
        }]
      };

      finishedCompositionList.push(_observationField);

      for (let i = 0; i < finishedCompositionList.length; i++) {
        const finshedList = finishedCompositionList[i];        
        for (let j = 0; j < finshedList.orderCompositionItems.length; j++) {
          const orderCompositionItem = finshedList.orderCompositionItems[j];
          if (orderCompositionItem.quantity <= 0) {
            finshedList.orderCompositionItems.splice(j, 1);
            j--;
          }
        }
      }

      addProductCart(produto, quantidade, finishedCompositionList, produto.adultHood);


      onClose();
    }


  }, [addProductCart, observationField.observation, onClose, orderCompositions, produto, quantidade]);

  const onClickAdd = () => {
    setquantidade((prev) => prev + 1);
  };
  const onClickRemove = () => {
    if (quantidade > 1) {
      setquantidade((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (produto.compositions?.length && produto.compositions?.length > 0 && orderCompositions.length === 0 && !minimo) {
      setdisableButtomAdicionar(true);
    } else {
      setdisableButtomAdicionar(false);
    }
  }, [orderCompositions, produto, setdisableButtomAdicionar, minimo]);

  const onFinishCompostions = useCallback((orderCompositionList: OrderComposition[], _minimo: boolean) => {
    setOrderCompositions(orderCompositionList);
    setMinimo(_minimo);
  }, []);

  const limitChars = 200;

  const handleChangeObservation = useCallback((observation: string) => {
    if (observation.length <= limitChars) {
      setObservationField({ observation });
    }
  }, [])

  const getTotal = useCallback(() => {
    return addtionalCount;
  }, [addtionalCount]);

  const renderMaxQuantityDiv = () => {
    const maxQuantity = getTotalItemsFreeDesc();

    if (maxQuantity > 0) {
      return <div className={"container-quantity-free-max"}>
        <p className={"container-quantity-free-max-text"}>{'Gratuidade total restante: ' + maxQuantity}</p>
      </div>;
    }

    return null;
  }

  return (
    <>
      <div className={"produto-detalhamento"}>
        <div
          className={"image-detalhamento"}
        // style={{ backgroundImage: "url(" + produto.imageUrl + ")" }}
        >
          {/* <BotaoVoltar onClick={onClose}></BotaoVoltar> */}
          <IconButton 
            className={"close-Button"} 
            onClick={onClose}
            data-custom-id="detail-close-button-icon"
            data-custom-description="Fechar detalhamento"
            data-custom-info={JSON.stringify({
              productId: produto.id,
              productName: produto.name
            })}
          ><Icon>close</Icon></IconButton>
        </div>

        <div className={"conteudo-detalhamento"}>
          <div className={"titulo-detalhamento"}>{produto.name}</div>

          {!descriptionObject ?
            <div className={"descricao-detalhamento"}>{produto.description}</div>
            :
            <div className={"descricao-detalhamento-object"} >
              <div className={"descricao-detalhamento"}>{descriptionObject?.description}</div>
              <div className={"descricao-detalhamento"}>{descriptionObject?.qrCode && <QRCode value={descriptionObject.qrCode} />}</div>
            </div>
          }

          {produto.compositions && (

            <div className={"composicao-detalhamento"}>
              <ComposicaoLista
                addtionalCount={addtionalCount}
                compostionFreeMax={produto.maxQuantityFreeAdditional}
                onFinish={onFinishCompostions}
                composicoes={produto.compositions.filter(it => it.compositionItems.length > 0)}
                setAddtionalCount={setAddtionalCount}
                adicionarUmTotal={adicionarUmTotal}
                removeUmTotal={removerUmTotal}
                getTotal={getTotal}
                getTotalItemsFreeDesc={getTotalItemsFreeDesc}
                renderMaxQuantityDiv={renderMaxQuantityDiv}
                setSelectedItemsFree={setSelectedItemsFree}
                selectedItemsFree={selectedItemsFree}
                getTotalItemsFree={getTotalItemsFree}
                selectedItemsReplica={selectedItemsReplica}
                setSelectedItemsReplica={setSelectedItemsReplica}
                getValorTotalItemsFree={getValorTotalItemsFree}
              ></ComposicaoLista>
            </div>
          )}
        </div>
        <div className={"observation"}>
          {
            !!produto.productGeneralObservation &&
            <>
              <label className="observation-label">Observações</label>
              <InputText
                label="Observações"
                className="input"
                placeHolder="Digite sua observação"
                value={observationField.observation}
                type={'text'}
                multiline
                onChange={(value) => handleChangeObservation(value)}
              />
            </>
          }
        </div>
      </div>

      <div className={"rodape-detalhamento"}>
        <div className={"container-quantidade-detalhamento"}>
          <Button
            onClick={onClickRemove}
            className={"remove-quantidade-detalhamento"}
            data-custom-id="detail-remove-button-icon"
            data-custom-description="Remover produto"
            data-custom-info={JSON.stringify({
              productId: produto.id,
              productName: produto.name
            })}
          >
            <Icon>remove</Icon>
          </Button>
          <div className={"quantidade-detalhamento"}>{quantidade}</div>
          <Button
            onClick={onClickAdd}
            className={"add-quantidade-detalhamento"}
            data-custom-id="detail-add-button-icon"
            data-custom-description="Adicionar produto"
            data-custom-info={JSON.stringify({
              productId: produto.id,
              productName: produto.name
            })}
          >
            <Icon>add</Icon>
          </Button>
        </div>
        <Button
          fullWidth
          onClick={onClickAdicionar}
          disabled={disableButtomAdicionar}
          style={{ opacity: disableButtomAdicionar ? 0.2 : 1 }}
          className={"botao-continuar-detalhamento"}
          data-custom-id="detail-add-button"
            data-custom-description="Adicionar produto"
            data-custom-info={JSON.stringify({
              productId: produto.id,
              productName: produto.name
            })}
        >
          ADICIONAR
        </Button>
      </div>
    </>
  );
};
