import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { HttpRepository } from '../../../Infrastructure/repositories/api/_HttpRepository';
import { CefSharpConfigRepository } from '../../../Infrastructure/services/cefSharpService/local/CefSharpConfigRepository';

const configRepository = CefSharpConfigRepository();
const httpRepository = HttpRepository();

interface IAuthContext {
    isAuth: boolean
}


const AuthContext = createContext<IAuthContext>({} as IAuthContext)
export const AuthProvider: FC = ({ children }) => {

    const [isAuth, setisAuth] = useState(false)

    useEffect(() => {
        configRepository.getConfigAsync().then((config) => {
            httpRepository.configureInterceptors(config.token);
            setisAuth(true)
        })
    }, [])

    return (
        <AuthContext.Provider value={{ isAuth }} >
            {children}
        </AuthContext.Provider>
    )
}


export const useAuth = (): IAuthContext => {
    const context = useContext(AuthContext)
    return context
}