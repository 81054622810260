import { ICartItem } from '../../domain/models/ICart';

// export const RemoveProductCartUseCase = (): IRemoveCartItemUseCase => {
export const RemoveCartItemByIdUseCase = (prevCartItems: ICartItem[], cartItemId: string, quantity: number): ICartItem[] => {

    const removeCartItem = (_prevCartItems: ICartItem[], _cartItemId: string, _quantity: number) => {
        const newCartItemsRemovedQuantitys = _prevCartItems.map((prevCartItem) => {
            if (prevCartItem.id === _cartItemId) {
                return { ...prevCartItem, quantity: prevCartItem.quantity - _quantity };
            } else {
                return { ...prevCartItem };
            }
        });
        const newCartItems = newCartItemsRemovedQuantitys.filter((cartItem) => (cartItem?.quantity > 0));
        return newCartItems;
    };

    return removeCartItem(prevCartItems, cartItemId, quantity);
};

