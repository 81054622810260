import { addDays } from 'date-fns'
import { ITourRepository } from '../../interfaces/ITourRespository'
import { GetVancaciesAndDiponibilityResponseDto } from './../../../domain/dtos/GetVacanciesAndDisponibilityByServiceIdResponseDTO'

const GetDiponibilityAndVancanciesUseCase = async (tourRepository:()=> ITourRepository, scheduledId: string): Promise<GetVancaciesAndDiponibilityResponseDto[]> => {
    const response = await tourRepository().getVacanciesAndDisponibilityService(scheduledId, new Date().toISOString(), addDays(new Date(), 90).toISOString())
    return response
}

export default GetDiponibilityAndVancanciesUseCase
