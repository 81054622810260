import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { StoreTour } from '../../../domain/models/CatalogTour';
import { TransactionType } from '../../components/reserverTourResume/ReserveTourResume.type';
import { ICartTour } from '../../components/tourCart/ICartTour';
import { useCatalogTour } from '../../contexts/TourCatalogContext';
import UsePaymentTour from '../../hooks/UsePaymentTour';

export default function UseStoreTourPage() {

    const { getStoreTourById, catalogTour } = useCatalogTour();

    const [storeTour, setStoreTour] = useState<StoreTour>();
    const { replace } = useHistory();
    const { storeTourId } = useParams<{ storeTourId: string }>();
    const { paymentCartTour } = UsePaymentTour();
    

    useEffect(() => {
        const value = getStoreTourById(storeTourId);
        setStoreTour(value);
    }, [catalogTour, getStoreTourById, storeTourId])

    const onPressBack = useCallback(
        () => {
            replace('/tour')
        },
        [],
    )

    const onClickTourDetails = useCallback(
        (id: string) => {  
            
            replace(`/tour/storeTour/${storeTourId}/moreDetails/${id}`)
        },
        [storeTourId],
    )

    const onClickGoToPaymentHandle = useCallback(
        (cartTour: ICartTour,transactionType?: TransactionType) => {
            paymentCartTour(cartTour,transactionType)
        },
        [paymentCartTour]
    )

    return (
        {
            storeTour,
            onPressBack,
            onClickTourDetails,          
            onClickGoToPaymentHandle
        }
    )
}
