import { Icon, IconButton, Theme, Tooltip, withStyles } from '@material-ui/core'
import { Help, HelpOutline, KeyboardArrowLeft } from '@material-ui/icons'
import React, { FC } from 'react'
import styles from './HeaderLayout.module.scss'
export interface IHeaderLayoutProps {

    name?: string,
    onClickBack?: () => void,
    handleHelpModal?: () => void,


}


const HeaderLayout: FC<IHeaderLayoutProps> = ({
    name,
    onClickBack,
    handleHelpModal

}) => {
    return (
        <div id={styles.HeaderLayout} >
            <div className={styles.button} > {onClickBack && <IconButton onClick={onClickBack}><KeyboardArrowLeft /></IconButton>} </div>
            <div className={styles.container} >
                <h3>{name}</h3>
            </div>
            <div className={styles.buttonHelp} > {<IconButton onClick={handleHelpModal}><Help/></IconButton>} </div>
        </div>
    )
}
export default HeaderLayout