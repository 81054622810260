import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Dialog, DialogContent, DialogTitle, Icon, IconButton } from "@material-ui/core";
import './Cashless.css'
import { ICardCashless } from '../../domains/cashless/aggregates/ICardCashless';
import { useKiosk } from '../../application/contexts/kiosk/KioskContext';
import { IExtract } from '../../domains/cashless/aggregates/IExtract';
import { ExtractView } from './extractView/ExtractView'
import { BotaoVoltar } from '../../components/botaoVoltar/BotaoVoltar';
import { useHistory, useParams } from 'react-router-dom';
import { Order } from '../../domains/order/agreggates/order/Order';
import { v4 } from 'uuid';
import { ProdutoTipo } from '../../Infrastructure/repositories/api/PedidoMeep';
import { Payment } from '../../domains/order/agreggates/payment/payment';
import { useCatalog } from '../../application/contexts/catalog/CatalogContext';
import { useUi } from '../../application/contexts/ui/UIContext';
import { TecladoVirtual } from '../../components/tecladoVirtual/TecladoVirtual';
import { moneyMask, moneyToFloat } from '../../corss-cutting/masks/money'
import { SystemType } from '../../domains/kiosk/aggregates/Kiosk';
import { toast as toastfy } from 'react-toastify';
import { TransactionType } from '../../domains/order/agreggates/payment/transactionType';
import { useCardReader } from '../../application/contexts/cardReader/CardReaderContext';
import { CashlessRepository } from '../../Infrastructure/repositories/api/CashlessRepository';
import { MeepOrderRepository } from '../../Infrastructure/repositories/api/OrderRepository';
import { IModalNotaFiscalRef, ModalNotaFiscal } from 'areas/catalog/components/modalNotaFiscal/ModalNotaFiscal';
import localforage from 'localforage';
import IdleTime, { IIdleTimeHandler } from 'components/idleTime/IdleTime';
import { IOrderLog } from 'modules/order/domain/models/IOrderLog';
import { IPedidoPosDTO } from 'modules/order/domain/models/IPedidoPosDTO';
import { IEmitedNfce } from 'modules/order/domain/models/INfce';
import { GetError } from 'utils/GetError';
import { ErrorCodeType } from 'application/models/IError';
import { OrderRepositoryLocalStorage } from 'Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage';
import { IOrderProviderRef } from 'modules/order/presentation/OrderProvider';
import { useSession } from 'application/contexts/session/SessionContext';
import InputText from 'components/inputText/InputText';
import { roundValue } from 'utils/utils';
import { useAppInsights } from 'Infrastructure/repositories/appInsights/AppInsights';
import ActionModal, { IActionModalHandler } from 'components/actionModal/ActionModal';

//container 
const cashlessRepository = CashlessRepository()
const orderRepositoryDB = OrderRepositoryLocalStorage();

interface ICashlessProps {
    orderProvider: React.RefObject<IOrderProviderRef>
}

//TODO: Refatorar
export const Cashless: FC<ICashlessProps> = ({ orderProvider }) => {
    const { kiosk, } = useKiosk()
    const { type } = useParams<{ type: "cashless" | "orderPad" | "school" }>();
    const { replace, push } = useHistory();
    const { verifySessionIsOpen } = useSession();
    const { getConfigurationProduct, handleRestrictions, catalog, handleDiscounts } = useCatalog();
    const { showLoading, hideLoading, toastFullscreen, toast } = useUi();
    const { cardData, orderPad, enableSensor, getSchoolCardDataWithName, isSchoolData, groupDiscounts, isPaying, readedCard, setReadedCard } = useCardReader();
    const { addLog } = useAppInsights();
    const orderRepository = MeepOrderRepository(addLog);

    const [inProgress, setInProgress] = useState(false);
    const [nickname, setNickname] = useState<string>("");

    const idleTimeRef = useRef<IIdleTimeHandler>(null);

    const [extract, setExtract] = useState<IExtract | null>(null);
    const [serviceRate, setServiceRate] = useState<boolean>(true);
    const [rechargeValue, setRechargeValue] = useState("0.00")
    const [rechargeModal, setRechargeModal] = useState(false)

    const refNotaFiscalPrint = useRef<IModalNotaFiscalRef>(null);
    const revalidateTagRef = useRef<IActionModalHandler>(null);

    const resetIdleTime = useCallback(() => {
        idleTimeRef.current?.resetCounter();
    }, [idleTimeRef]);

    useEffect(() => {
        if (orderPad || cardData) {
            resetIdleTime();
        }
    }, [cardData, orderPad, resetIdleTime])


    const onTimerFinish = useCallback(() => {
        // closeModal();
        replace("/");
    },[replace],)

    const isSystemType = useCallback((systemType: SystemType) => {
        return kiosk?.configSymstem?.find((configSymstem) => (configSymstem.systemType === systemType)) ? true : false
    }, [kiosk])

    useEffect(() => {
        console.log("enableSensor");
        enableSensor();
    }, [enableSensor])

    const getExtract = useCallback(
        async (cardData: ICardCashless) => {
            if (kiosk) {
                var isMinimalAndPosPad = isSystemType(SystemType.pospayOrderPad) && kiosk.minimumConsumption;
                try {
                    showLoading();
                    const responseExtract = await cashlessRepository.getExtractData(cardData.associationId, kiosk.localId, cardData.id, isMinimalAndPosPad)
                    if (responseExtract) {
                        setExtract(responseExtract);
                        resetIdleTime()
                    } else {
                        setExtract(null);
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    hideLoading();
                }
            }
        },
        [hideLoading, isSystemType, kiosk, resetIdleTime, showLoading]
    )

    useEffect(() => {
        if (cardData) {
            console.log("GET EXTRACT", {cardData})
            if (cardData?.group?.extensionData !== "F6EC7A68-D413-4284-AE3A-43C7DEF285F1") {
                if (!isSchoolData.current) {
                    getExtract(cardData);
                }
            } else {
                const message = (
                    <div className="offline-recharce-success-message">
                        <p>Operação não é permitida no totem</p>
                        <p>
                            Procure um caixa móvel
                        </p>
                    </div>
                );
                toastFullscreen(message, "warning", {
                    icon: "error_outline",
                    iconSize: 100,
                    time: 3000,
                });
            }
        } else {
            console.log("SET EXTRACT NULL")
            setExtract(null);
        }
    }, [cardData, getExtract, isSchoolData, toastFullscreen])

    const onClickVoltar = useCallback(() => {
        replace("/")
    },[replace])

    const criarPedidoPagamentoComanda = useCallback(() => {
        if (extract) {
            if (getConfigurationProduct(ProdutoTipo.Recarga)) {
                const newOrderId = v4()
                const newOrder: Order = {
                    id: newOrderId,
                    cartDate: new Date().toISOString(),
                    orderItems: [{
                        id: v4(),
                        name: getConfigurationProduct(ProdutoTipo.Recarga)?.name ?? "",
                        category: "",
                        price: roundValue(Math.abs(extract?.Saldo) + extract.TaxaServico),
                        realPrice: roundValue(Math.abs(extract?.Saldo) + extract.TaxaServico),
                        totalPrice: roundValue(Math.abs(extract?.Saldo) + extract.TaxaServico),
                        productPrice: roundValue(Math.abs(extract?.Saldo) + extract.TaxaServico),
                        productId: getConfigurationProduct(ProdutoTipo.Recarga)?.id ?? "",
                        productType: ProdutoTipo.Recarga,
                        quantity: 1,
                        imageUrl: "",
                        hideCatalog: false,
                        description: null,
                        barcode: null,
                        orderComposition: null,
                        compositions: null,
                        printerName: null,
                    }],
                    totalValue: roundValue(Math.abs(extract?.Saldo) + extract.TaxaServico),
                    friendlyId: kiosk?.kioskName.replace(/\D/mg, "") + " " + newOrderId.substr(0, 4),
                    createdAt: new Date().toLocaleString(),
                    customer: {
                        name: extract.Nome,
                        associationId: cardData?.associationId,
                        orderId: newOrderId,
                        tag: orderPad?.id,
                        number: orderPad?.numero,
                        orderPad: orderPad?.numero
                    },
                }
                return newOrder;
            } else {
                toastFullscreen("Produtos de configuração não cadastrado", "error")
            }
        }

    }, [cardData, extract, getConfigurationProduct, kiosk, orderPad, toastFullscreen])



    const criarPedidoTaxaDeServico = useCallback(() => {
        if (getConfigurationProduct(ProdutoTipo.TaxaServico)) {
            if (extract && serviceRate && kiosk?.serviceRatePercent) {
                const newOrderId = v4()
                const newOrder: Order = {
                    id: newOrderId,
                    cartDate: new Date().toISOString(),
                    orderItems: [{
                        id: v4(),
                        name: getConfigurationProduct(ProdutoTipo.TaxaServico)?.name ?? "",
                        category: "",
                        price: extract.TaxaServico,
                        realPrice: extract.TaxaServico,
                        totalPrice: extract.TaxaServico,
                        description: null,
                        productPrice: extract.TaxaServico,
                        productId: getConfigurationProduct(ProdutoTipo.TaxaServico)?.id ?? "",
                        productType: ProdutoTipo.TaxaServico,
                        quantity: 1,
                        imageUrl: "",

                        hideCatalog: false,
                        barcode: null,
                        orderComposition: null,
                        compositions: null,
                        printerName: null,
                    }],
                    totalValue: extract.TaxaServico,
                    friendlyId: kiosk?.kioskName.replace(/\D/mg, "") + " " + newOrderId.substr(0, 4),
                    createdAt: new Date().toLocaleString(),
                    customer: { name: extract.Nome, associationId: cardData?.associationId, orderId: newOrderId, tag: orderPad?.id, number: orderPad?.numero, orderPad: orderPad?.numero },
                }
                return newOrder
            } else {
                toastFullscreen("Produtos de configuração não cadastrado", "error")
            }
        }
    }, [cardData, extract, getConfigurationProduct, kiosk, orderPad, serviceRate, toastFullscreen])

    const addOrderLog = useCallback(
        async (
          order: IOrderLog | null,
          message: string,
          pedidoPOS?: IPedidoPosDTO,
          payment?: Payment,
          nfce?: IEmitedNfce[]
        ) => {
          try {
            if (order) {
              let orderLog = { ...order, payment, pedidoPOS, nfce };
              console.log({ orderLog, message });
              addLog("cashless", {message, orderLog});
              await orderRepositoryDB.add(orderLog, message);
            }
          } catch (error) {
            GetError(
              error,
              "addOrderLog",
              "Cashless",
              "Falha ao adicionar log",
              ErrorCodeType.GENERAL
            );
          }
        },
        [addLog]
    );

    const onSucessPayment = useCallback(
        async (order: Order, transactionType: TransactionType, payment: Payment) => {
            if (kiosk) {
                showLoading();
                setRechargeModal(false);
                setRechargeValue("00.0")

                try {
                    const sincronizarServiceFee = async () => {
                        if (serviceRate && kiosk?.serviceRate && kiosk.serviceRatePercent > 0 && isSystemType(SystemType.pospayOrderPad)) {
                            const taxaDeServico = criarPedidoTaxaDeServico();
                            if (taxaDeServico) {
                                await orderRepository.sendNowOrdersAsync(taxaDeServico, kiosk.localId, TransactionType.misto);
                            }
                        }
                    }

                    const sincronizarServiceFeeAssincrono = async () => {
                        if (serviceRate && kiosk?.serviceRate && kiosk.serviceRatePercent > 0 && isSystemType(SystemType.pospayOrderPad)) {
                            const taxaDeServico = criarPedidoTaxaDeServico();
                            if (taxaDeServico) {
                                await orderRepository.sendOrderAsync(taxaDeServico, TransactionType.misto);
                            }
                        }
                    }

                    // await sincronizarServiceFeeAssincrono();

                    const sincronizarPagamento = async () => {
                        await orderRepository.sendNowOrdersAsync(order, kiosk.localId, payment.paymentMethod, payment.FinancialTransactionModel, kiosk.id);
                    }

                    const sincronizarPagamentoAsincrono = async () => {
                        await orderRepository.sendOrderAsync(order, payment.paymentMethod, payment?.FinancialTransactionModel);
                    }

                    try {
                        await sincronizarPagamento();
                    } catch (error) {
                        console.log(error);
                        try {
                            await sincronizarPagamento();
                        } catch (error) {
                            console.log(error);
                            await sincronizarPagamentoAsincrono();
                            await sincronizarServiceFeeAssincrono();
                            throw (error)
                        }
                    }

                    
                    // //taxa de servico
                    try {
                        await sincronizarServiceFee();
                    } catch (error) {
                        console.log(error);
                        try {
                            await sincronizarServiceFee();
                        } catch (error) {
                            console.log(error);
                            await sincronizarServiceFeeAssincrono()
                            throw (error)
                        }
                    }

                    console.log("PAGAMENTO FINALIZADO");
                    toastFullscreen("Pagamento realizado com sucesso", "success", {onClose: () => { replace("/")} });
                    hideLoading();


                } catch (error) {
                    toastFullscreen("Pagamento aprovado, estamos tentando enviar o pedido para o servidor.", "warning", { onClose: () => { replace("/") } });
                    localforage.setItem(`@log-pos-errorScreen`, error)
                    hideLoading();
                } finally {
                    isPaying.current = false;
                }
            }
        },
        [kiosk, showLoading, toastFullscreen, serviceRate, isSystemType, criarPedidoTaxaDeServico, orderRepository, replace, hideLoading, isPaying],
    )

    const onClickPagarComanda = useCallback(async () => {
        if (extract) {
            const newOrder = criarPedidoPagamentoComanda();
            if (newOrder) {
                addOrderLog(newOrder, `Criar ordem de pagamento TAG: ${cardData?.number}`);
                try {
                    setInProgress(true);
                    const result = await orderProvider.current?.startPayment(newOrder, 'Payment');
                    if (result) {
                        onSucessPayment(result?.order, result?.paymentType, result?.payment)
                    } else {
                        toast("Falha no pagamento", "error");
                        GetError(
                            new Error("Falha no pagamento"),
                            "onClickPagarComanda",
                            "Cashless",
                            undefined,
                            ErrorCodeType.PAYMENT
                        )
                    }
                } catch (error) {
                    GetError(
                        error,
                        "onClickPagarComanda",
                        "Cashless",
                        undefined,
                        ErrorCodeType.PAYMENT
                    )
                } finally {
                    setInProgress(false)
                }
            }
        }
    }, [addOrderLog, cardData, criarPedidoPagamentoComanda, extract, onSucessPayment, orderProvider, toast])

    const criarOrderRecarga = useCallback((value: number, cardData: ICardCashless) => {
        if (getConfigurationProduct(ProdutoTipo.Recarga)) {
            const newOrderId = v4()
            const newOrder: Order = {
                id: newOrderId,
                cartDate: new Date().toISOString(),
                orderItems: [{
                    id: v4(),
                    name: getConfigurationProduct(ProdutoTipo.Recarga)?.name ?? "",
                    category: "",
                    price: value,
                    realPrice: value,
                    totalPrice: value,
                    description: null,
                    productPrice: value,
                    productId: getConfigurationProduct(ProdutoTipo.Recarga)?.id ?? "",
                    productType: ProdutoTipo.Recarga,
                    quantity: 1,
                    imageUrl: "",
                    barcode: null,
                    hideCatalog: false,
                    orderComposition: null,
                    compositions: null,
                    printerName: null,
                }],
                totalValue: value,
                friendlyId: kiosk?.kioskName.replace(/\D/mg, "") + " " + newOrderId.substr(0, 4),
                createdAt: new Date().toLocaleString(),
                customer: {
                    name: cardData?.holderName,
                    associationId: cardData?.associationId,
                    orderId: newOrderId,
                    tag: cardData?.tag,
                    number: cardData?.tag,
                    orderPad: cardData?.tag
                },
            }
            return newOrder;
        } else {
            toastFullscreen("Produtos de configuração não cadastrado", "error")
        }


    }, [getConfigurationProduct, kiosk, toastFullscreen])


    const onClickAdicionarCredito = useCallback(async () => {
        if (moneyToFloat(rechargeValue) >= 1 && !isPaying.current) {
            console.log("EXTRAC TO ADD", extract, rechargeValue);
            setServiceRate(false);
            isPaying.current = true;
            if (cardData && cardData.associationId) {
                console.log("EXTRAC TO ADD 1")
                const newOrder = criarOrderRecarga(moneyToFloat(rechargeValue), cardData);
                if (newOrder) {
                    await addOrderLog(newOrder,`Adicionar crédito tag: ${cardData.tag}`, undefined);
                    console.log("EXTRAC TO ADD 2")
                    try {
                        setInProgress(true);
                        const result = await orderProvider.current?.startPayment(newOrder, 'Recharge');
                        if (result) {
                            onSucessPayment(result?.order, result?.paymentType, result?.payment)
                        } else {
                            toast("Falha no pagamento", "error");
                            GetError(
                                new Error("Falha no pagamento"),
                                "onClickPagarComanda",
                                "Cashless",
                                undefined,
                                ErrorCodeType.PAYMENT
                            )
                        }
                    } catch (error) {
                        GetError(
                            error,
                            "onClickPagarComanda",
                            "Cashless",
                            undefined,
                            ErrorCodeType.PAYMENT
                        )
                    } finally {
                        setInProgress(false);
                    }
                }
            } else {
                toastfy.warn("Tente novamente")
            }
        }
    }, [addOrderLog, cardData, criarOrderRecarga, extract, isPaying, onSucessPayment, orderProvider, rechargeValue, toast])



    const onpressback = useCallback(() => {
        setRechargeValue("00.0")
    }, [])
    const onpressKeyboard = useCallback((key: string) => {
        setRechargeValue(prev => (prev + key))
    }, [])
    const onPressClear = useCallback(() => {

    }, [])

    const onClickCloseRechargeDialog = useCallback(() => {
        setRechargeModal(false);
        setRechargeValue("00.0")
    }, [])

    const getCardDataWithName = useCallback(
      () => {
        if (nickname.length >= 3) {
            getSchoolCardDataWithName(nickname);
        } else {
            toast("Apelido inválido", 'error');
        }
      },
      [getSchoolCardDataWithName, nickname, toast],
    )
    

    useEffect(() => {
        const verifyRestrictions = async () => {
            //Validação de restrições de catalogo para Meep Escola
            if (type === 'school' && cardData) {
                if (cardData.restrictions.length) {
                    handleRestrictions(cardData.restrictions);
                }
                if (cardData.group && groupDiscounts?.length) {
                    handleDiscounts(groupDiscounts)
                }
                const hasOpenSession = await verifySessionIsOpen();
                if (catalog?.stores && hasOpenSession) {
                    if (catalog.stores.length === 1) {
                        push("/catalog/store/" + 0);
                    } else {
                        push("/catalog/");
                    }
                }
            }
        }
        verifyRestrictions();
    }, [cardData, catalog, groupDiscounts, handleDiscounts, handleRestrictions, push, type, verifySessionIsOpen])

    const payRecharge = useCallback(
      async () => {
        if (moneyToFloat(rechargeValue) < 1) {
            toast("Recarga mínima R$1,00", "error");
        } else if (kiosk && kiosk?.doubleValidationOnlineRecharge) {
            setReadedCard(null);
            isPaying.current = false;
            try {
                await revalidateTagRef.current?.open();
            } catch (error) {
                console.log({error})   
            }
        } else {
            !isPaying.current && onClickAdicionarCredito();
        }
      },
      [isPaying, kiosk, onClickAdicionarCredito, rechargeValue, setReadedCard, toast],
    );
    
    const { handleDoubleConfirmation, modalMessage, autoClose, confirmTitle, modalTitle } = useMemo(() => {
        let modalMessage = ''
        let confirmTitle = ''
        let modalTitle = 'ATENÇÃO'
        let autoClose = 0;
        let handleDoubleConfirmation;
        if (!readedCard) {
            modalMessage = 'Aproxime seu cartão/pulseira para iniciar o pagamento'
            confirmTitle = 'Cancelar'
        } else {
            if (cardData?.tag !== readedCard) {
                autoClose = 3;
                modalMessage = 'Cartão/pulseira incorreto, refaça a operação'
                setTimeout(() => {
                    push('/');
                }, 3000);
            } else {
                modalTitle = "CONFIRMADO"
                modalMessage = 'Voce será direcionado ao pagamento'
                autoClose = 3;
            }
        }

        return {
            modalMessage,
            handleDoubleConfirmation,
            autoClose,
            confirmTitle,
            modalTitle
        }
    }, [cardData, push, readedCard])

    return (
        <div id="cardpayment">
            <Dialog open={rechargeModal}>
                <DialogTitle>  <div className="titulo-recharge">Recarregar cartão  {extract?.Nome ? "de " + extract.Nome.split(' ')[0] : ""}</div></DialogTitle>
                <DialogContent>
                    <div className="instrucao-recharge">Insira o valor da recarga</div>
                    <div className="input-recharge">
                        <div className="value-recharge">
                            {moneyMask(rechargeValue)}
                        </div>
                        <div className="back-recharge" onClick={onpressback}>
                            <Icon className="icon-recharge" fontSize="large">backspace</Icon>
                        </div>
                    </div>
                    <div className="erro-recharge" onClick={onpressback}>
                    </div>
                    <TecladoVirtual
                        type="numeric"
                        onPressBack={onpressback}
                        onKeyPress={onpressKeyboard}
                        onPressClear={onPressClear}
                    />
                    <IconButton onClick={onClickCloseRechargeDialog} style={{ position: "absolute", right: 8, top: 8 }}>
                        <Icon fontSize="large">close</Icon>
                    </IconButton>
                    <div className="container-button">
                        <div onClick={payRecharge} className="button" style={{ background: "#1ba" }}>Adicionar Credito</div>
                    </div>
                </DialogContent>
            </Dialog>
            <BotaoVoltar onClick={onClickVoltar} />
            <div className="content">
                <img className="logo" src={require("../../assets/images/logo-meep.png")} alt="logo" />
                {!extract && (
                    <>
                        <img className="gif" src={require("../../assets/images/RFID.gif")} alt="RFID" />
                        <div className="instruction-label">
                            Aproxime o cartão/pulseira do leitor
                        </div>

                        {kiosk?.paymentNominalCashless &&  ( //kiosk?.searchMeepCardByName &&
                            <div className='nickname-container'>
                                <p><b>OU</b></p>
                                <label>Insira seu apelido:</label>
                                <InputText
                                    label="Insira seu apelido"
                                    className="nickname-input"
                                    valueClassName='nickname-value-input'
                                    placeHolder="Apelido"
                                    value={nickname}
                                    type={'text'}
                                    onChange={(value: string) => setNickname(value)}
                                    onPressEnter={getCardDataWithName}
                                />
                            </div>
                        )}
                    </>
                )}

                {
                    extract &&
                    <ExtractView
                        onClickAdd={() => {
                            setRechargeModal(true);
                        }}
                        onClickPayment={onClickPagarComanda}
                        extract={extract}
                        type={type}
                        serviceRate={serviceRate && kiosk?.serviceRate ? kiosk?.serviceRatePercent : null}
                    />
                }

                <IdleTime ref={idleTimeRef} useDefaultTime isActive={!inProgress} onFinish={onTimerFinish} />

                <ModalNotaFiscal ref={refNotaFiscalPrint}></ModalNotaFiscal>

                <ActionModal
                    ref={revalidateTagRef}
                    confirmTitle={confirmTitle}
                    message={modalMessage}
                    title={modalTitle}
                    onClickConfirm={handleDoubleConfirmation}
                    hideCloseButton
                    oldStyle
                    autoCloseDelay={autoClose}
                    confirmationDelay={autoClose}
                    onAutoClose={onClickAdicionarCredito}
                />

            </div>
        </div >
    )
}

