import React, {
  useContext,
  createContext,
  FC,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import {
  TransactionType,
  TransactionTypeSefaz,
} from "../../../domains/order/agreggates/payment/transactionType";
import { PaymentModal } from "../../../areas/payment/PaymentModal";
import { Payment, PixApprovedTransaction } from "../../../domains/order/agreggates/payment/payment";
import { ICustomer } from "../../../domains/order/agreggates/payment/ICustomer";
import { Dialog, Modal } from "@material-ui/core";
import { EletronicInvoice, IEletronicInvoiceV2 } from "../../../domains/order/agreggates/EletronicInvoice/EletronicInvoice";
import { ProductItem } from "../../../domains/order/agreggates/EletronicInvoice/ProductItem";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import { Order, OrderLog } from "../../../domains/order/agreggates/order/Order";
import { useKiosk } from "../kiosk/KioskContext";
import { useHistory } from "react-router-dom";
import { useUi } from "../ui/UIContext";
import { EletronicInvoiceTicket } from "../../../domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";
import { ICardCashless } from "../../../domains/cashless/aggregates/ICardCashless";
import KeyboardPimpad from "../../../areas/payment/keyboardPimpad/KeyboardPimpad"
import { OrderRepositoryLocalStorage } from "../../../Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage";
import { PaymentService } from "../../../domains/order/services/paymentService";
import { PrintService } from "../../../domains/order/services/printService";
import { MeepOrderRepository, orderToPedidoPOS } from "../../../Infrastructure/repositories/api/OrderRepository";
import { EletronicInvoiceService } from "../../../domains/order/services/EletronicInvoiceService";
import { PedidoPos } from "Infrastructure/repositories/api/PedidoMeep";
import DefferedPix, { IDefferedPixHandler } from "areas/payment/defferedPix/DefferedPix";

interface IPaymentContext {
  openPaymentModal: boolean;
  openPayment: (order: Order, onSucessPayment: (order: Order, transactionType: TransactionType, payment: Payment) => void, recharge?: boolean) => void;
  order: Order | null;
  callPaymentAsync(transactionType: TransactionType, customer?: ICustomer): void;
  callPaymentCashlessAsync(cardData: ICardCashless, transactionType: TransactionType, customer?: ICustomer): void;
  callPaymentCashlessOfflineAsync: (order: Order, transactionType: TransactionType, onSuccessPayment: ((order: Order, transactionType: TransactionType, payment?: Payment) => void)) => Promise<void>
  message: string;
  setMessage: (value: string) => void;
  inProgress: boolean;
  //////////////////////////////////////
  addToOrderLog: (order: Order | null,message: string, pedidoPOS?: PedidoPos, payment?: Payment, nfce?: EletronicInvoiceTicket[]) => Promise<void>,
  closeModal: () => void
  generateEletronicInvoice: (pedidoId: string, documento?: string, ehManual?: boolean) => Promise<EletronicInvoiceTicket[]>
  setinProgress: (value: boolean) => void
  setValuesWithoutOrder: (params: { instalment: number, totalValue: number }) => void
  callSucessWithFreeOrderAsync: (customer: ICustomer) => void;
  callSucessPix: (transaction: PixApprovedTransaction, customer?: ICustomer) => Promise<void>
}

const paymentContext = createContext<IPaymentContext>({} as IPaymentContext);

// let onSucessPayment: ((payment?: Payment) => void) | undefined;

//container
const orderRepositoryDB = OrderRepositoryLocalStorage()
const paymentService = PaymentService()
const printService = PrintService()
const orderRepository = MeepOrderRepository()
const eletronicInvoiceService = EletronicInvoiceService()


export const PaymentProvider: FC = ({ children }) => {
  const defferdPixRef = useRef<IDefferedPixHandler>(null);

  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [rechargePayment, setrechargePayment] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [inProgress, setinProgress] = useState(false);
  const { toastFullscreen, toast } = useUi()

  const [keyboardValues, setKeyboardValues] = useState<{ min: number, max: number, message: string } | null>(null);

  // const [sucessModalOpen, setsucessModalOpen] = useState(false);

  const { kiosk } = useKiosk();

  const [order, setOrder] = useState<Order | null>(null);
  const [onSuccessPayment, setonSuccessPayment] = useState<((order: Order, transactionType: TransactionType, payment?: Payment) => void) | null>(null);

  const installment = useRef(1);
  const [totalValue, setTotalValue] = useState<number>();
  const messageRef = useRef<string>();
  const paying = useRef(false);


  const history = useHistory();


  const setValuesWithoutOrder = useCallback((params: { instalment: number, totalValue: number }) => {
    const genericOrder = {} as Order
    setOrder({ ...genericOrder, totalValue: params.totalValue });
    installment.current = params.instalment;
    setTotalValue(params.totalValue);
  }, [])

  const openPayment = useCallback((order: Order, _onSucessPayment: (order: Order, transactionType: TransactionType, payment: Payment) => void, rechargePayment?: boolean) => {
    setrechargePayment(rechargePayment ?? false);
    setonSuccessPayment(() => _onSucessPayment);
    setOpenPaymentModal(true);
    setOrder(order);
  },[]);

  const closeModal = useCallback(() => {
    setOpenPaymentModal(false);
    setonSuccessPayment(null)
    setOrder(null);
    setMessage("");
    setKeyboardValues(null)
  }, []);


  const closeKeyboardModal = useCallback(() => {

    setKeyboardValues(null)
  }, []);

  const paymentToEletronicInvoice = useCallback(
    (pedidoId: string, documento?: string, ehManual?: boolean): IEletronicInvoiceV2 => { 
      const eletronicInvoice: IEletronicInvoiceV2 = {
        pedidoId: pedidoId,
        documento: documento ?? '',
        ehManual: ehManual ?? false 
      };
      console.log("NFE", eletronicInvoice);
      return eletronicInvoice;
    },
    []
  );

  const generateEletronicInvoice = useCallback(async (
    pedidoId: string,
    documento?: string,
    ehManual?: boolean
  ) => {
    if (kiosk?.generateEletronicInvoice) {
      const response = await eletronicInvoiceService.sendAsync(paymentToEletronicInvoice(pedidoId, documento, ehManual))

      console.log("RESPONSE GENERATE INVOICE", response)

      return response
    } else {
      return []
    }
  },
    [kiosk, paymentToEletronicInvoice]
  );

  const addToOrderLog = useCallback(
    async (order: OrderLog | null, message: string, pedidoPOS?: PedidoPos, payment?: Payment, nfce?: EletronicInvoiceTicket[]) => {
      try {
        if (order) {
          let orderLog = { ...order, payment, pedidoPOS, nfce };
          await orderRepositoryDB.add(orderLog, message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    []
  );


  const onSucessDefault = useCallback(async (orderWithCustomer: Order, transactionType: TransactionType, payment: Payment) => {
    const pedidoPos = await orderRepository.sendOrderAsync(
      orderWithCustomer,
      payment.paymentMethod,
      payment.FinancialTransactionModel
    )
    if (kiosk) {
      try {
        orderRepository.syncOrdersAsync(kiosk.localId);
      } catch (error) {
        console.error("error syncOrder on default sucess", error)
      }
      printService.printOrders(payment, pedidoPos, orderWithCustomer, kiosk);
    }
    toastFullscreen("Pagamento realizado com sucesso", "success", {onClose: () => { history.replace("/"); }});
    await addToOrderLog(orderWithCustomer, "Pagamento realizado", pedidoPos, payment);
    generateEletronicInvoice(orderWithCustomer.id, orderWithCustomer?.customer?.document, false);

  },
    [addToOrderLog, generateEletronicInvoice, history, kiosk, toastFullscreen],
  )



  const callPaymentCashlessAsync = useCallback(async (cardData: ICardCashless, transactionType: TransactionType, customer?: ICustomer) => {


    if (order && kiosk) {
      const orderWithCustomer: Order = {
        ...order,
        
        customer: {
          ...order.customer, 
          name: customer?.name ?? cardData?.holderName,
          clientIdentificator: customer?.clientIdentificator,
          phone: customer?.phone,
          document: customer?.document ?? cardData?.holderDocument,
          email: customer?.email,
          associationId: cardData.associationId,
          number: cardData?.number,
          tag: cardData?.tag,
          prism: customer?.prism
        }
      }

      closeModal();
      setinProgress(false);
      orderRepository.syncOrdersAsync(kiosk?.localId ?? "");

      const orderLog: OrderLog = {
        ...orderWithCustomer,
        paymentApproved: true,
      }

      await addToOrderLog(orderLog, "Pagamento realizado", orderToPedidoPOS(orderWithCustomer, transactionType));

      if (onSuccessPayment) {
        onSuccessPayment(orderWithCustomer, transactionType);
      }
    }

  }, [addToOrderLog, closeModal, kiosk, onSuccessPayment, order])

  
  const callSucessPix = useCallback(async (transaction: PixApprovedTransaction, customer?: ICustomer) => {
    setinProgress(true);

    if (order) {
      const payment = {
        amount: transaction.value,
        success: true,
        paymentMethod: TransactionType.pix,
        FinancialTransactionModel: {
          TipoAdquirente: 10, // meep
        }
      } as Payment;

      const orderWithCustomer: Order = {
        ...order,
        customer: {
          ...order.customer, 
          clientIdentificator: customer?.clientIdentificator,
          name: customer?.name ?? kiosk?.kioskName,
          phone: customer?.phone,
          document: customer?.document,
          email: customer?.email,
          prism: customer?.prism
        },
        payment,
        paymentPix: transaction,
      }

      const orderLog: OrderLog = {
        ...orderWithCustomer,
        paymentApproved: true,
      }

      await addToOrderLog(orderLog, "Pagamento realizado", orderToPedidoPOS(orderWithCustomer, payment.paymentMethod, payment.FinancialTransactionModel), payment);

      closeModal();
      setinProgress(false);
      orderRepository.syncOrdersAsync(kiosk?.localId ?? "");
      if (onSuccessPayment) {
        onSuccessPayment(orderWithCustomer, TransactionType.pix, payment);
      }
    }
  }, [addToOrderLog, closeModal, kiosk, onSuccessPayment, order])

  const callPaymentCashlessOfflineAsync = useCallback(
    async (
      _order: Order, 
      transactionType: TransactionType, 
      onSuccessPayment: ((order: Order, transactionType: TransactionType, payment?: Payment) => void)
    ) => {
      if(transactionType === TransactionType.pix) {
        try {
          const pixTransaction = await defferdPixRef.current?.open(
            _order
          );

          if (pixTransaction) {
            const payment = {
              amount: pixTransaction.value,
              success: true,
              paymentMethod: TransactionType.pix,
              FinancialTransactionModel: {
                TipoAdquirente: 10, // meep
              }
            } as Payment;
      
            const pixOrder: Order = {
              ..._order,
              payment,
              paymentPix: pixTransaction,
            }
  
            onSuccessPayment(pixOrder, transactionType, payment);
            orderRepository.syncOrdersAsync(kiosk?.localId ?? "");
          } else {
            await addToOrderLog(_order, 'Falha ao pagar com PIX');
          }

        } catch (error) {
          await addToOrderLog(_order, 'Falha ao pagar com PIX');
        }
      } else {
        setinProgress(true);
        installment.current = 1;
        
        try {
          const payment = await paymentService.payAsync(_order?.totalValue, transactionType);       
          if (payment.success) {
            onSuccessPayment(_order, transactionType, { ...payment, FinancialTransactionModel: { ...payment.FinancialTransactionModel, Parcelas: installment.current } });
          } else {
            await addToOrderLog(_order, payment?.errorMessage ?? "Falha ao efetuar pagamento");
            toastFullscreen(payment?.errorMessage ?? "Falha ao efetuar pagamento", 'warning')
          }
          closeModal();
        } catch (error) {
          await addToOrderLog(_order, (error as any)?.errorMessage ?? "Falha ao efetuar o pagamento");
          toastFullscreen((error as any)?.errorMessage ?? "Falha ao efetuar o pagamento", 'warning')
          setMessage("Operacão cancelada");
        } finally {
          closeModal();
          setinProgress(false);
        }
      }

  },
    [addToOrderLog, closeModal, kiosk, toastFullscreen]
  );

  const callSucessWithFreeOrderAsync = useCallback(async (customer?: ICustomer) => {
    setinProgress(true);

    if (order) {
      const orderWithCustomer: Order = {
        ...order,
        customer: {
          ...order.customer, 
          name: customer?.name ?? kiosk?.kioskName,
          clientIdentificator: customer?.clientIdentificator,
          phone: customer?.phone,
          document: customer?.document,
          email: customer?.email,          
          prism: customer?.prism
        }
      }

      closeModal();
      setinProgress(false);
      orderRepository.syncOrdersAsync(kiosk?.localId ?? "");

      const orderLog: OrderLog = {
        ...orderWithCustomer,
        paymentApproved: true,
      }

      await addToOrderLog(orderLog, "Pagamento realizado", orderToPedidoPOS(orderWithCustomer));

      if (onSuccessPayment) {
        onSuccessPayment(orderWithCustomer, TransactionType.money);
      }
    }
  }, [addToOrderLog, closeModal, kiosk, onSuccessPayment, order])

  const callPaymentAsync = useCallback(async (transactionType: TransactionType, customer?: ICustomer) => {
    setinProgress(true);
    installment.current = 1;
    if (order && !paying.current) {
      paying.current = true;
      const orderWithCustomer: Order = {
        ...order,
        customer: {
          ...order.customer, 
          name: customer?.name ?? kiosk?.kioskName,
          clientIdentificator: customer?.clientIdentificator,
          phone: customer?.phone,
          document: customer?.document,
          email: customer?.email,
          prism: customer?.prism
          
        }
      }

      try {
        const payment = await paymentService.payAsync(orderWithCustomer?.totalValue, transactionType);       
        console.log("Payment Response", payment)
        console.log("PARCELA AO PAGAR", installment.current)
        if (payment) {
          const orderLog: OrderLog = {
            ...orderWithCustomer,
            paymentApproved: true,
          }
          await addToOrderLog(orderLog, "Pagamento realizado", orderToPedidoPOS(orderWithCustomer, transactionType, payment.FinancialTransactionModel), payment);
          if (onSuccessPayment) {
            onSuccessPayment(orderWithCustomer, transactionType, { ...payment, FinancialTransactionModel: { ...payment.FinancialTransactionModel, Parcelas: installment.current } });
          } else {
            onSucessDefault(orderWithCustomer, transactionType, { ...payment, FinancialTransactionModel: { ...payment.FinancialTransactionModel, Parcelas: installment.current } });
          }
        }
        closeModal();
      } catch (error) {
        addToOrderLog(orderWithCustomer, ((error as any).message ?? `Falha no pagamento`) + ' ' + messageRef.current);
        toast((error as any).message ?? 'Falha no pagamento', "error");
        setMessage("Operacão cancelada");

      } finally {
        closeModal();
        setinProgress(false);
        paying.current = false;
        try {
          if (kiosk) {
            orderRepository.syncOrdersAsync(kiosk.localId);
          }
        } catch (error) {
          console.error("error syncOrder on PaymentContext", error)
        }
      }

    }
  },
    [order, kiosk, closeModal, addToOrderLog, onSuccessPayment, onSucessDefault, toast]
  )

  window.showMessageFromClient = (
    message: string,
    comando: number,
    severit: string
  ) => {
    console.log(comando + severit, message);
    setMessage(message.replace("SOLICITE A SENHA", "Digite sua senha").replace("Transacao Aprov.","Aguarde, em processamento..."));
    if (messageRef?.current) {
      messageRef.current = message;
    }
  };

  useEffect(() => {
    console.log("PAYMENT CONTEXT");
  }, []);


  window.showKeyboard = (min: number, max: number, message: string) => {
    console.log("min: ", min);
    console.log("max: ", max);
    console.log("message: ", message);
    setKeyboardValues({ min, max, message });
  };

  return (
    <paymentContext.Provider
      value={{
        openPaymentModal,
        openPayment,
        order,
        callPaymentAsync,
        message,
        setMessage,
        inProgress,
        setinProgress,
        addToOrderLog,
        generateEletronicInvoice,
        callPaymentCashlessAsync,
        closeModal,
        setValuesWithoutOrder,
        callSucessWithFreeOrderAsync,
        callSucessPix,
        callPaymentCashlessOfflineAsync
      }}
    >
      {children}
      <Modal open={openPaymentModal}>
        <PaymentModal onPressCloseModal={closeModal} rechagePayment={rechargePayment} />
      </Modal>

      <DefferedPix ref={defferdPixRef} addToOrderLog={addToOrderLog} />

      {
        <Modal open={!!keyboardValues}>
          <KeyboardPimpad
            total={order?.totalValue ?? totalValue}
            enableInstallment={kiosk?.enableInstallment}
            keyboardValues={keyboardValues}
            onSendInstallment={parcela => { installment.current = parcela; console.log("UPDATE PARCELA", installment) }}
            onSend={(values) => { setKeyboardValues(null); }}
            onCancel={closeKeyboardModal}
          />
        </Modal>
      }

      <Dialog open={inProgress && message !== ""}>
        <div style={{
          fontSize: 32,
          padding: 32,
        }}>{message}</div>
      </Dialog>

      {/* <SucessModal open={sucessModalOpen} onClose={() => { setsucessModalOpen(false) }} /> */}
    </paymentContext.Provider>
  );
};

export const PaymentConsumer = paymentContext.Consumer;

/**
 * @deprecated
 */
export const usePayment = () => {
  return useContext(paymentContext);
};
