import { IGetMainServicesCategoryByLocalIdResponse, IGetServicesCategoryByLocalIdResponse } from 'modules/tour/domain/dtos/GetServicesByLocalIdResponseDTO'
import React from 'react'
import { v4 } from 'uuid'
import { CatalogTour, CategoryTours, StoreTour } from '../../../domain/models/CatalogTour'
import { ITourRepository } from '../../interfaces/ITourRespository'

const GetCatalogToursStoresByLocalIdUseCase = async (localId: string, tourRepository: ITourRepository): Promise<StoreTour[]> => {



    // const responseCategory: IGetServicesCategoryByLocalIdResponse = await tourRepository.getServiceCategoryByLocalId(localId)
    const responseMain: IGetMainServicesCategoryByLocalIdResponse = await tourRepository.getMainServiceCategoryByLocalId(localId)


    const responsesStoreToursWithCategoriesPromises = responseMain.items.sort((a, b) => {
        if (a.index && b.index) {
            if (a.index > b.index) {
                return 1;
            }
            if (a.index < b.index) {
                return -1;
            }
        }
        return 0;
    }).map(async item => {
        const response = await tourRepository.getServiceCategoryByCategoryId(item.id)
        const storeTour: StoreTour = {
            id: item.id,
            name: item.name,
            imageUrl: item.imageUrl,
            categoryTours: response.items.map<CategoryTours>((categoryItem) => ({
                id: categoryItem.id,
                name: categoryItem.name,
                tours: categoryItem.services.map((serviceItem) => ({
                    localId: localId,
                    id: serviceItem.id,
                    name: serviceItem.name,
                    description: serviceItem.description,
                    imageUrl: serviceItem.imageUrl,
                    duration: serviceItem.duration,
                    prices: serviceItem.prices,
                    moreDescriptions: serviceItem.moreDescriptions
                })),
            }))
        }
        return storeTour;
    }
    )

    const responsesStoreToursWithCategories = await Promise.all(responsesStoreToursWithCategoriesPromises);


    return responsesStoreToursWithCategories.filter(item => item.categoryTours.length)
}



export default GetCatalogToursStoresByLocalIdUseCase
