import React, {
  ForwardRefRenderFunction,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useKiosk } from "../../application/contexts/kiosk/KioskContext";
import styles from "./IdleTime.module.scss";

const DELAY = 1000;
const NO_TIMER = 9999;
const EXPIRATION_DEFAULT_TIME = 30;

function useInterval(callback: () => void, delay: number) {
  const savedCallback = useRef<() => void>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current && savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export interface IIdleTimeHandler {
  resetCounter(): void;
}

interface IIdleTimeProps {
  onFinish: () => void;
  useDefaultTime?: boolean;
  isActive: boolean;
}

const IdleTime: ForwardRefRenderFunction<IIdleTimeHandler, IIdleTimeProps> = (
  { onFinish, useDefaultTime, isActive },
  ref
) => {
  const { kiosk } = useKiosk();

  const expirationTime =
    kiosk?.expirationTime ??
    (useDefaultTime ? EXPIRATION_DEFAULT_TIME : NO_TIMER);

  const [counter, setCounter] = useState<number>(expirationTime);

  const intervalCallback = useCallback(() => {
    isActive && setCounter((prev) => prev - 1);
  }, [isActive]);

  useInterval(intervalCallback, DELAY);

  const resetCounter = useCallback(() => {
    console.log("resetTimer");
    setCounter(expirationTime);
  }, [expirationTime]);

  useImperativeHandle(ref, () => ({ resetCounter }));

  useEffect(() => {
    isActive && resetCounter();
  }, [isActive, resetCounter]);

  useEffect(() => {
    if (counter <= 0) {
      if (isActive) {
        setCounter(expirationTime);
        onFinish();
      }
    }
  }, [counter, expirationTime, isActive, onFinish]);

  useEffect(() => {
    window.addEventListener("click", resetCounter);
    return () => {
      window.removeEventListener("click", resetCounter);
    };
  }, [resetCounter]);

  const renderCounter = useMemo(
    () => (isActive && counter >= 0 && counter <= 10 ? counter : ""),
    [counter, isActive]
  );

  return <div className={styles.IdleTimeContainer}>{renderCounter}</div>;
};

export default memo(forwardRef(IdleTime));
