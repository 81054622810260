import React, { FC } from 'react'
import "./BotaoVoltar.css"

import { Icon, Button } from "@material-ui/core";
export const BotaoVoltar: FC<{ onClick: () => void, style?: React.CSSProperties }> = ({ onClick, style }) => {
    return (
        <Button 
            style={style} 
            onClick={onClick} 
            className={"botao-voltar-component"} 
            data-custom-id="back-button"
            data-custom-description="Voltar"
        >
            <Icon fontSize="large" className={"icon-voltar-component"} >arrow_back</Icon> Voltar
        </Button>
    )
}
