import React, { FC, memo } from 'react'
import { ButtonBase, Icon } from '@material-ui/core'
import styles from './Tecla.module.scss'


interface Tecla {
    onClick: (key: string) => void
    children: string
}

export const Tecla: FC<Tecla> = memo((props) => {
    return (
        <div onClick={() => props.onClick(props.children)}
            className={styles.teclaContainer} style={{
                visibility: props.children ? "visible" : "hidden",
                backgroundColor: props.children === "check" ? "#1fb" : props.children === "back-space" ? "#fa0" : props.children === "clear" ? "#f66" : "#fff",
                width: "100%",
                fontSize: props.children.length > 1 ? 22 : 30,
                color: props.children === "check" || props.children === "back-space" ? "#fff" : "unset",
            }}>
            {
                (props.children === "back-space") ? <>
                    {/* <Icon>keyboard_backspace</Icon> */}
                    <div><Icon fontSize="large">backspace</Icon></div>
                </> :
                    (props.children === "clear") ? <>
                        {/* <Icon>keyboard_backspace</Icon> */}
                        <div>LIMPAR</div>
                    </>
                        :
                        (props.children === "check") ? <>
                            {/* <Icon>check</Icon> */}
                            <Icon fontSize={'large'}>check</Icon>
                        </> :
                            (props.children === " ") ?
                                <Icon>space_bar</Icon> :
                                props.children
            }
        </div>
    )
})
