import localforage from "localforage";


const KEY = "@meep/RDSummitDocuments";

export interface IDocumentsRepository {
    add(document: string): Promise<boolean>;
}

export const DocumentsRepository = (): IDocumentsRepository => {

    const add = async (document: string): Promise<boolean> => {
        try {
            let documents = await localforage.getItem<string[]>(KEY) ?? [];
            console.log('document', document);
            const index = documents.findIndex(it => it === document);

            if (index >= 0) {
                return false;
            }

            documents.push(document);

            await localforage.setItem(KEY, documents);
            return true;


        } catch (error) {
            console.log(error);
            return false;
        }
    }

    return {
        add,
    }
}