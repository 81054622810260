import { Modal } from "@material-ui/core";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useUi } from "application/contexts/ui/UIContext";
import ActionModal, {
  IActionModalHandler,
} from "components/actionModal/ActionModal";
import { PaymentService } from "domains/order/services/paymentService";
import { IOrder } from "modules/order/domain/models/IOrder";
import {
  MeepPIXEnum,
  PixApprovedTransaction,
  PixPaymentRequest,
  PixPaymentResponse,
} from "modules/payment/domain/models/PixApprovedTransaction";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { createDefferedPromise } from "utils/DefferedPromise";
import styles from "./Pix.module.scss";
import { GetError } from "utils/GetError";
import { ErrorCodeType } from "application/models/IError";
import { IOrderLog } from "modules/order/domain/models/IOrderLog";
import { OrderRepositoryLocalStorage } from "Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";

const REQUEST_TIMER_DELAY = 5;
const PIX_EXPIRATION_TIMER = 360;

let requestDelayTimeout: NodeJS.Timeout;
let expirationTimeout: NodeJS.Timeout;

const paymentService = PaymentService();
const locaStorageOrderRepository = OrderRepositoryLocalStorage();

export interface IPixHandler {
  open(order: IOrder): Promise<PixApprovedTransaction>;
}

interface IPixProps {}

const Pix: React.ForwardRefRenderFunction<IPixHandler, IPixProps> = (
  {},
  ref
) => {
  const deffered = useRef(createDefferedPromise<PixApprovedTransaction>());
  const cancelModalRef = useRef<IActionModalHandler>(null);
  const actionModalExpirationTimeRef = useRef<IActionModalHandler>(null);

  // const { toastFullscreen } = useUi();
  const { kiosk } = useKiosk();
  const { addLog } = useAppInsights();

  const [isActiveRequestTimer, setIsActiveRequestTimer] = useState(false);
  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [expirationCounter, setExpirationCounter] =
    useState(PIX_EXPIRATION_TIMER);
  const [isActiveExpirationTimer, setIsActiveExpirationTimer] = useState(true);
  const [pixStatus, setPixStatus] = useState<PixPaymentResponse | null>(null);
  const [requestTimerDelayCounter, setRequestTimerDelayCounter] =
    useState<number>(REQUEST_TIMER_DELAY);

  const paymentId = useRef<string | null>(null);
  const pixPaymentStatus = useRef<MeepPIXEnum | null>(null);
  const requestCounter = useRef(0);

  const startRequestTimer = useCallback(() => {
    setIsActiveExpirationTimer(true);
    setExpirationCounter(PIX_EXPIRATION_TIMER);
    setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
    setIsActiveRequestTimer(true);
  }, []);

  const resetExpirationTimer = useCallback(() => {
    setPixStatus(null);
    clearTimeout(expirationTimeout);
    setIsActiveExpirationTimer(false);
  }, []);

  const resetRequestTimer = useCallback(() => {
    clearTimeout(requestDelayTimeout);
    setIsActiveRequestTimer(false);
    setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
  }, []);

  useEffect(() => {
    if (isActiveRequestTimer && requestTimerDelayCounter > 0 && visible) {
      requestDelayTimeout = setTimeout(() => {
        setRequestTimerDelayCounter((prev) => prev - 1);
      }, 1000);
    } else if (isActiveRequestTimer && requestTimerDelayCounter === 0) {
      setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
    }

    return () => {
      clearTimeout(requestDelayTimeout);
    };
  }, [requestTimerDelayCounter, isActiveRequestTimer, visible]);

  const addOrderLog = useCallback(
    async (
      order: IOrderLog,
      message: string,
      pix: PixPaymentResponse,
      requestCount: number,
      action: string,
      paymentId: string,
    ) => {
      try {
        const pixLog = {
          ...pix,
          requestCount,
          action,
          currentPaymentId: paymentId,
          qrCodeBase64: ''
        }
        addLog("pix", {message, order, pixLog});
        await locaStorageOrderRepository.add(order, message, JSON.stringify(pixLog));
      } catch (error) {
        GetError(
          error,
          "addOrderLog",
          "Pix",
          "Falha ao adicionar log",
          ErrorCodeType.GENERAL
        );
      }
    },
    [addLog]
  );

  const successPix = useCallback(
    (transaction: PixApprovedTransaction) => {
      setVisible(false);
      resetExpirationTimer();
      setPixStatus(null);
      pixPaymentStatus.current = null;
      paymentId.current = null;
      deffered.current.resolver(transaction);
    },
    [resetExpirationTimer]
  );

  const cancelPixPayment = useCallback(
    async (message?: string) => {
      await addOrderLog(
        {id: paymentId.current} as IOrder,
        "Cancel pix",
        pixStatus ?? {} as PixPaymentResponse,
        requestCounter.current,
        'CANCELED - Close pix modal',
        paymentId?.current ?? ''
      );
      requestCounter.current = 0;
      paymentId.current = null;
      pixPaymentStatus.current = null;
      setVisible(false);
      resetExpirationTimer();
      const cancelledPix = {
        ...pixStatus,
        message: message ?? "Pix - Fechado pelo usuário ou tempo esgotado"
      }
      setPixStatus(null);
      deffered.current?.rejecter(
        GetError(
          message ?? "Pix - Fechado pelo usuário ou tempo esgotado",
          "cancelPixPayment",
          "Pix",
          JSON.stringify(cancelledPix),
          ErrorCodeType.PAYMENT
        )
      );
    },
    [pixStatus, resetExpirationTimer, addOrderLog]
  );

  const getPixStatus = useCallback(async (_paymentId: string) => {
    try {
      const result = await paymentService.pixPaymentStatus(_paymentId);
      const parseResponse: PixPaymentResponse = {
        ownerId: result.ownerId ?? result.OwnerId,
        paymentId: result.paymentId ?? result.Id,
        qrCodeBase64: result.qrCodeBase64 ?? result.QrCodeBase64,
        qrCodeLink: result.qrCodeLink ?? result.QrCodeLink,
        status: result.status ?? result.Status,
        value: result.value ?? result.Value,
        approvedAt: result.approvedAt ?? result.ApprovedAt,
        acquirerType: result.acquirerType ?? result.AcquirerType,
      };

      if (parseResponse.status === "pending") {
        setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
      } else if (parseResponse.status === "cancelled") {
        resetRequestTimer();
        addOrderLog(
          order,
          "Pix cancelled",
          parseResponse ?? {} as PixPaymentResponse,
          requestCounter.current,
          'Cancelled',
          paymentId?.current ?? ''
        );
        // toastFullscreen("Pagamento cancelado", "error");
        cancelPixPayment("QRCode cancelado");
      } else if(parseResponse.status === "approved" && paymentId.current === parseResponse.paymentId) {
        resetRequestTimer();
        const transactionPix: PixApprovedTransaction = {
          ownerId: parseResponse.ownerId,
          paymentId: parseResponse.paymentId,
          status: parseResponse.status,
          value: parseResponse.value,
          acquirerType: parseResponse.acquirerType,
          approvedAt: parseResponse.approvedAt,
          createdAt: parseResponse.createdAt,
          qrCodeLink: parseResponse.qrCodeLink,
        };
        addOrderLog(
          order,
          "Pix Approved",
          parseResponse,
          requestCounter.current,
          'Approved',
          paymentId?.current ?? ''
        );
        successPix(transactionPix);
      }


      if(parseResponse.paymentId === paymentId.current) {
        pixPaymentStatus.current = parseResponse.status;
        setPixStatus(parseResponse);
        await addOrderLog(
          { id: _paymentId } as IOrder,
          "Pooling",
          parseResponse,
          requestCounter.current,
          'getPixStatus',
          paymentId?.current ?? ''
        );
      } else {
        await addOrderLog(
          { id: _paymentId } as IOrder,
          "Pooling",
          parseResponse,
          requestCounter.current,
          'getPixStatus error: different pix id',
          paymentId?.current ?? ''
        );
      }
    } catch (error) {
      pixPaymentStatus.current = "pending";
      GetError(
        error,
        'getPixStatus',
        'Pix',
        undefined,
        ErrorCodeType.PAYMENT
      )
      await addOrderLog(
        { id: _paymentId } as IOrder,
        "Pooling",
        {} as PixPaymentResponse,
        requestCounter.current,
        `getPixStatus error: ${JSON.stringify(error)}`,
        paymentId?.current ?? ''
      );
    } finally {
      requestCounter.current += 1;
    }
  }, [addOrderLog, cancelPixPayment, order, resetRequestTimer, successPix]);

  const checkExpiration = useCallback(async () => {
    try {
      setExpirationCounter(PIX_EXPIRATION_TIMER);
      paymentId.current && getPixStatus(paymentId.current);
      await addOrderLog(
        {id: paymentId.current} as IOrder,
        "Expiration time",
        {} as PixPaymentResponse,
        requestCounter.current,
        'open expiration modal',
        paymentId?.current ?? ''
      );
      await actionModalExpirationTimeRef.current?.open();
      await addOrderLog(
        {id: paymentId.current} as IOrder,
        "Expiration time rebooted",
        {} as PixPaymentResponse,
        requestCounter.current,
        'Keep waiting pix response',
        paymentId?.current ?? ''
      );
    } catch (error) {
      await addOrderLog(
        {id: paymentId.current} as IOrder,
        "Expiration time over",
        {} as PixPaymentResponse,
        requestCounter.current,
        'CANCELED - Close pix modal',
        paymentId?.current ?? ''
      );
      GetError(
        error,
        'checkExpiration',
        'Pix',
        undefined,
        ErrorCodeType.PAYMENT
      )
      paymentId.current && (await getPixStatus(paymentId.current));
      resetExpirationTimer();
    }
  }, [addOrderLog, getPixStatus, resetExpirationTimer]);

  useEffect(() => {
    if (isActiveExpirationTimer && expirationCounter > 0 && visible) {
      expirationTimeout = setTimeout(() => {
        setExpirationCounter((prev) => prev - 1);
      }, 1000);
    } else if (isActiveExpirationTimer && expirationCounter === 0) {
      checkExpiration();
    }

    return () => {
      clearTimeout(expirationTimeout);
    };
  }, [
    checkExpiration,
    expirationCounter,
    isActiveExpirationTimer,
    resetExpirationTimer,
    visible,
  ]);

  const onClickCancel = useCallback(async () => {
    try {
      paymentId.current && getPixStatus(paymentId.current);
      await addOrderLog(
        {id: paymentId.current} as IOrder,
        "Click Cancel Pix",
        {} as PixPaymentResponse,
        requestCounter.current,
        'open cancel modal',
        paymentId?.current ?? ''
      );
      await cancelModalRef.current?.open();
    } catch (error) {
      GetError(
        error,
        'onClickCancel',
        'Pix',
        undefined,
        ErrorCodeType.PAYMENT
      )
      await addOrderLog(
        {id: paymentId.current} as IOrder,
        "Continue waiting pix",
        {} as PixPaymentResponse,
        requestCounter.current,
        'Keep waiting pix response',
        paymentId?.current ?? ''
      );
    }
  }, [addOrderLog, getPixStatus]);


  useEffect(() => {
    if (!isActiveExpirationTimer && visible) {
      // toastFullscreen("Tempo para pagamento do pix expirado.", "warning");
      cancelPixPayment("Tempo de pagamento pix esgotado");
    }
  }, [
    cancelPixPayment,
    isActiveExpirationTimer,
    // toastFullscreen,
    visible,
  ]);

  useEffect(() => {
    if (
      isActiveRequestTimer &&
      requestTimerDelayCounter === 0 &&
      paymentId.current &&
      visible
    ) {
      getPixStatus(paymentId.current);
    }
  }, [
    requestTimerDelayCounter,
    isActiveRequestTimer,
    pixStatus,
    visible,
    getPixStatus,
  ]);

  const getPixPayment = useCallback(
    async (localClientId: string, _order: IOrder) => {
      try {
        setPixStatus(null);
        pixPaymentStatus.current = null;
        const payload: PixPaymentRequest = {
          Value: _order.totalValue,
          LocalClientId: localClientId,
          PixPaymentOrderId: _order.id,
        };
        let result: PixPaymentResponse;
        result = await paymentService.pixPayment(payload);
        if (!result) {
          await addOrderLog(
            _order,
            "Create Pix",
            result,
            requestCounter.current,
            'getPixPayment falha ao obter pix status',
            paymentId?.current ?? ''
          );
          // toastFullscreen("Falha ao pagar com pix", "error");
          cancelPixPayment("Falha ao obter os dados do pix");
        }
        pixPaymentStatus.current = result.Status ?? result.status;
        const parseResponse: PixPaymentResponse = {
          ownerId: result.ownerId ?? result.OwnerId,
          paymentId: result.paymentId ?? result.Id,
          qrCodeBase64: result.qrCodeBase64 ?? result.QrCodeBase64,
          qrCodeLink: result.qrCodeLink ?? result.QrCodeLink,
          status: result.status ?? result.Status,
          value: result.value ?? result.Value,
          approvedAt: result.approvedAt ?? result.ApprovedAt,
          acquirerType: result.acquirerType ?? result.AcquirerType,
        };
        await addOrderLog(
          _order,
          "Create Pix",
          parseResponse,
          requestCounter.current,
          'getPixPayment',
          paymentId?.current ?? ''
        );
        setPixStatus(parseResponse);
      } catch (error) {
        await addOrderLog(
          _order,
          "Create Pix",
          {} as PixPaymentResponse,
          requestCounter.current,
          `getPixPayment error: ${(error as any)?.response?.data?.Message ?? "Falha ao obter os dados do pix"}`,
          paymentId?.current ?? ''
        );
        GetError(
          error,
          'getPixPayment',
          'Pix',
          undefined,
          ErrorCodeType.PAYMENT
        )
        // toastFullscreen(
        //   (error as any)?.response?.data?.message ?? "Falha ao obter os dados do pix",
        //   "error"
        // );
        cancelPixPayment(
          (error as any)?.response?.data?.message ?? "Falha ao obter os dados do pix"
        );
      } finally {
        requestCounter.current += 1;
      }
    },
    [addOrderLog, cancelPixPayment]
  );

  const open = useCallback(
    (_order: IOrder) => {
      paymentId.current = _order.id;
      requestCounter.current = 0;
      pixPaymentStatus.current = null;
      setOrder(_order);
      setPixStatus(null);
      kiosk?.localId && getPixPayment(kiosk.localId, _order);
      startRequestTimer();
      
      setVisible(true);

      addOrderLog(
        _order,
        "Open Pix",
        {} as PixPaymentResponse,
        requestCounter.current,
        'open',
        paymentId?.current ?? ''
      );
      
      deffered.current = createDefferedPromise<PixApprovedTransaction>();
      return deffered.current.promise;
    },
    [addOrderLog, getPixPayment, kiosk, startRequestTimer]
  );

  useImperativeHandle(ref, () => ({ open }));

  const [minuteLeft, minuteRight] = useMemo(
    () =>
      String(Math.floor(expirationCounter / 60))
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );
  const [secondLeft, secondRight] = useMemo(
    () =>
      String(expirationCounter % 60)
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );

  return (
    <Modal id={styles.Pix} open={visible} data-custom-name="modalPix">
      <div className={styles.container} data-custom-name="pixContainer">
        <div className={styles.pixBody}>
          {!pixStatus && (
            <>
              <p>Gerando QR Code...</p>
              <p>
                Enquanto isso, abra o app
                <br />
                do seu banco no celular
              </p>
            </>
          )}

          {pixStatus?.qrCodeBase64 && (
            <div className={styles.pixQrCodeContainer}>
              <p>Leia o QR Code:</p>
              <div className={styles.pixQrCode}>
                <img
                  src={`data:image/jpeg;base64,${pixStatus.qrCodeBase64}`}
                  alt="qrCode"
                />
              </div>
              <div className={styles.countdownContainer}>
                <div>
                  <span>{minuteLeft}</span>
                  <span>{minuteRight}</span>
                </div>
                <span>:</span>
                <div>
                  <span>{secondLeft}</span>
                  <span>{secondRight}</span>
                </div>
              </div>
              <p>
                Após isso, o QR Code irá expirar e você
                <br /> voltará para o carrinho.
              </p>
            </div>
          )}
        </div>

        {order.totalValue && (
          <div className={styles.totalValue}>
            <p>Total:</p>
            <p>
              <b>R$ {order.totalValue.toFixed(2).replace(".", ",")}</b>
            </p>
          </div>
        )}

        <div className={styles.cancelBotaoGrande} onClick={onClickCancel}>
          CANCELAR
        </div>
        <ActionModal
          ref={cancelModalRef}
          confirmTitle="Cancelar"
          message='Esta ação irá cancelar este pagamento, não efetue o pagamento do pix se você clicar em "Cancelar"!'
          title="ATENÇÃO!"
          onClickConfirm={cancelPixPayment}
          closeButtonTile="Voltar"
          confirmationDelay={5}
          hideCloseButton
        />
        <ActionModal
          ref={actionModalExpirationTimeRef}
          confirmTitle="Prorrogar"
          message="Seu tempo esgotou, deseja prorrogar o tempo?"
          title="ATENÇÃO!"
          onClickConfirm={() => {}}
          closeButtonTile="Cancelar"
          hideCloseButton
        />
      </div>
    </Modal>
  );
};
export default forwardRef(Pix);
