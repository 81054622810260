import React, { FC, forwardRef, PropsWithRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { Drawer, Icon } from '@material-ui/core'
import ButtonPickerSchedule from '../buttonPicker/ButtonPickerSchedule'
import { IServiceCategory } from '../../../../domains/schedule/agreggates/IServiceCategory'
import styles from './Styles.module.scss'


export interface IPickerCategoryServiceProps {
    //propertys
    categoryServices: IServiceCategory[];
    value: IServiceCategory | null;
    onChange: (CategoryService: IServiceCategory) => void;
    open?: boolean
}
export interface IPIckerCategoryServiceRef {
    openDrawer: () => void
}
const PickerCategoryService = forwardRef<IPIckerCategoryServiceRef, IPickerCategoryServiceProps>(({
    categoryServices: CategoryServices,
    value,
    onChange,
    open,
}, ref) => {
    const [openList, setopenList] = useState(open ?? true)

    const openDrawer = useCallback(() => {
        setopenList(true);
    }, [])

    useImperativeHandle(
        ref,
        () => ({
            openDrawer
        }),
        [openDrawer],
    )


    const closeListHandle = useCallback(() => {
        setopenList(false)
    }, [])
    const openListHandle = useCallback(() => {
        setopenList(true)
    }, [])
    const onClickItem = useCallback((CategoryService: IServiceCategory) => {
        onChange(CategoryService);
        closeListHandle();
    }, [closeListHandle])

    return (
        <div id={styles.PickerCategoryService} >
            <div className={styles.botao} >
                <ButtonPickerSchedule onClick={openListHandle} selected={!!value}>
                    {value ? value.name :
                        "Selecionar uma categoria de serviço"}
                    <Icon fontSize="large" className={styles.editIcon}>edit</Icon>
                </ButtonPickerSchedule>
            </div>
            <Drawer anchor={"bottom"} open={openList} onClose={closeListHandle}>
                <div id={styles.drawerCategoryService}>
                    <h1>Selecione uma categoria</h1>
                    <div className={styles.lista}>
                        {
                            CategoryServices.map((CategoryService, index) => (
                                <div onClick={() => onClickItem(CategoryService)} className={styles.item}>{CategoryService.name}</div>
                            ))
                        }
                    </div>
                </div>
            </Drawer>
        </div>
    )
})
export default PickerCategoryService