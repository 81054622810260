import React, { FC, useState, useEffect, useCallback } from 'react'

import { useCatalog } from "../../application/contexts/catalog/CatalogContext";
import { useKiosk } from '../../application/contexts/kiosk/KioskContext';
import { MeepOrderRepository } from '../../Infrastructure/repositories/api/OrderRepository';

//container
const orderRepository = MeepOrderRepository();

export const SyncTimer: FC = () => {



    const [minuteTime, setminuteTime] = useState(30)
    const [minuteTick, setMinuteTick] = useState(0)

    const { sync } = useCatalog();
    const { kiosk } = useKiosk();

    const onInterval = useCallback(
        () => {
            if (minuteTick >= minuteTime) {
                sync()
                if (kiosk) {
                    orderRepository.syncOrdersAsync(kiosk.localId)
                }
                setMinuteTick(0)
            }
        },
        [kiosk, minuteTick, minuteTime, orderRepository, sync],
    )

    useEffect(() => {
        const updateTime = setInterval(() => {
            setMinuteTick(prev => prev + 1);
            onInterval()
        }, 60000)
        return () => {
            clearInterval(updateTime)
        }
    }, [onInterval])

    return (
        <>
        </>
    )
}
