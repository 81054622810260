import { Drawer, Icon } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { IService } from '../../../../domains/schedule/agreggates/IServices'
import styles from './PickerService.module.scss'
import ButtonPickerSchedule from '../buttonPicker/ButtonPickerSchedule'

export interface IPickerServiceProps {
    //propertys
    services: IService[];
    value: IService | null;
    onChange: (service: IService) => void;
}
const PickerService: FC<IPickerServiceProps> = ({
    services,
    value,
    onChange
}) => {
    const [openList, setopenList] = useState(false)

    useEffect(() => {

        if (!!services.length) {
            setopenList(true)
        }

        return () => {

        }
    }, [services])

    const closeListHandle = () => {
        setopenList(false)
    }

    const openListHandle = () => {
        setopenList(true)
    }

    const onClickItem = (service: IService) => {
        onChange(service);
        closeListHandle();
    }

    return (
        <div id={styles.PickerService} >
            <div className={styles.botao} >
                <ButtonPickerSchedule onClick={openListHandle} selected={!!value}>
                    {value ? value.name :
                        "Selecionar Serviço"}
                    <Icon fontSize="large" className={styles.editIcon}>edit</Icon>
                </ButtonPickerSchedule>
            </div>
            <Drawer anchor={"bottom"} open={openList} onClose={closeListHandle}>
                <div id={styles.drawerService}>
                    <h1>Selecione um serviço</h1>
                    <div className={styles.lista}>
                        {
                            services.map((service, index) => (
                                <div onClick={() => onClickItem(service)} className={styles.item}>{service.name} - R${service.value.toFixed(2).replace(".",",")}</div>
                            ))
                        }
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
export default PickerService