import { INfceRepository } from "domains/order/interfaces/repositories/INfceRepository";
import {
  INFCeResult,
  INfce,
  INfceDTO,
  INfceRequest,
} from "modules/order/domain/models/INfce";
import { ProductItem } from "../../../domains/order/agreggates/EletronicInvoiceTicket/ProductItem";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { HttpRepository } from "./_HttpRepository";

const httpRepository: IHttpRepository = HttpRepository();

export const NfceRepository = (): INfceRepository => {
  const emitNfce = async (nfce: INfce): Promise<INfceDTO[] | null> => {
    const request = parseNfceRequest(nfce);
    const response = await httpRepository.postAsync<INfceRequest, INFCeResult[]>(
      "/v2/NFCe/Emitir",
      request
    );
    return parseNfceResult(response);
  };

  // const emitSplitedNfce = async (nfce: INfce): Promise<Array<INfceDTO>> => {
  //   const request = parseNfceRequest(nfce);
  //   const response: INFCeResult[] = await httpRepository.postAsync(
  //     "/NFCe/EmitirSplit",
  //     request
  //   );
  //   return response
  //     .map((item: INFCeResult) => parseNfceResult(item))
  //     .filter((item) => item);
  // };

  const parseNfceRequest = (nfce: INfce): INfceRequest => {
    return {
      pedidoId: nfce.orderId,
      documento: nfce.document ?? '',
      ehManual: false,
    };
  };



  const parseNfceResult = (results: INFCeResult[]): INfceDTO[] | null => {

    const filteredResults = results.filter(result => result.sefaz && result.sefaz.chave);

    if (filteredResults.length === 0) {
      return null;
    }

    const tickets: INfceDTO[] = [];

    filteredResults.forEach((result: INFCeResult) => {
      const ticket: INfceDTO = {
        companyName: result.cliente,
        companyDocument: result.cnpj,
        status: result.status,
        clientName: result.cliente,
        totalValue: parseFloat(result["valor-total"]),
        numbering: result.numero,
        emissionDate: result.sefaz.dataEmissao,
        danfe: result.sefaz["url-danfe"] ?? "",
        key: result.sefaz.chave,
        protocol: result.sefaz.protocolo,
        itens: result.itens.map((item: any) => {
          return {
            id: item.Produto,
            name: item.ProdutoNome,
            quantity: item.Quantidade,
            value: item.ValorUnitario,
          } as ProductItem;
        }),
        mustPrintCompleteDANFE: true,
      };

      tickets.push(ticket);
    });

    return tickets;
  };

  return { emitNfce };
};
