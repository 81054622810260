import { Kiosk, SystemType } from "domains/kiosk/aggregates/Kiosk";
import { IPrintService } from "domains/order/interfaces/services/iprintService";
import { IOrder } from "modules/order/domain/models/IOrder";
import { IPedidoPosDTO } from "modules/order/domain/models/IPedidoPosDTO";

export const PrintOrderUseCase = async (
  printService: IPrintService,
  order: IOrder,
  pedidoPOS: IPedidoPosDTO,
  printOrder: (pedidoPOS: IPedidoPosDTO) => Promise<void>,
  printRemote: (order: IOrder) => Promise<void>,
  kiosk: Kiosk
) => {
  if (!kiosk.enableBarCode) {
    if (
      kiosk.oldPrinter ||
      kiosk.localId === "52d97f4e-5ad8-485f-9a18-85e622837359"
    ) {
      printService.printOrders(
        order.payment ?? null,
        pedidoPOS,
        order,
        kiosk,
        kiosk.configSymstem.find(
          (sistem) => sistem.systemType === SystemType.prepayTicket
        )?.printType
      );
    } else {
      printOrder(pedidoPOS);
      // printService.remotePrint(order, kiosk);
      printRemote({...order, passwordPanelCode: pedidoPOS.codigoPainelSenha});
    }
  }
};
