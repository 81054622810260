import { Cart } from "application/contexts/cart/Cart"
import { AxiosError } from "axios"
import { Order } from "domains/order/agreggates/order/Order"
import { OrderItem } from "domains/order/agreggates/order/OrderItem"
import { HttpRepository } from "./_HttpRepository"
import { ProdutoTipo } from "./PedidoMeep"

const InventoryRepository = () => {
    const api = HttpRepository()


    const getRemainInventoryFromErrorResponse = (erroResponse?: string) => {
        if (erroResponse) {
            const start = erroResponse.indexOf("Quantidade em estoque: ");
            const end = erroResponse.indexOf(".", start);
            return Number(erroResponse.substring(start + 23, end));
        }
        return null
    }

    const orderItemVerify = async (orderItem: OrderItem, orderId: string, localId: string, orderDate: string): Promise<IStoqueReturn> => {
        try {
            const requestBody: IInventoryRequest = {
                id: orderId,
                ownerID: localId,
                orderDate: orderDate,
                details: [{
                    productId: orderItem.productId,
                    quantity: orderItem.quantity
                }]
            }
            await api.postAsync("/vendas/BeginOrderValidation", requestBody);
            return {
                productId: orderItem.productId,
                availability: true,
            }
        } catch (e) {

            const error = e as AxiosError;

            return {
                productId: orderItem.productId,
                availability: false,
                quantityRemain: getRemainInventoryFromErrorResponse(error.response?.data.message) ?? 0,
                message: error.response?.data.message
            }
        }
    }

    const veryInventory = async (order: Order, localId: string) => {
        const orderItemPromise = order.orderItems.filter(it => it.productType !== ProdutoTipo.TaxaServico).map(orderItem => orderItemVerify(orderItem, order.id, localId, order.cartDate))
        return await Promise.all(orderItemPromise)
    }

    return {
        veryInventory
    }

}
export default InventoryRepository






interface IInventoryRequest {
    id: string;
    ownerID: string;
    orderDate: string;
    details: {
        productId: string;
        quantity: number;
        value?: number;
        totalValue?: number;
    }[]
}


interface IStoqueReturn {
    productId: string,
    availability: boolean,
    quantityRemain?: number,
    message?: string
}

//8fefd288-e3d3-47ad-8869-64595d4d18cf", categoryId: "31fd20f5-eb3a-473f-a107-4dcee938dd16