import { Dialog } from '@material-ui/core';
import React,{FC, useState} from 'react'
import { TransactionType } from '../reserverTourResume/ReserveTourResume.type';
import styles from './DialogSelectPaymentType.module.scss'
export interface IDialogSelectPaymentTypeProps{
    //propertys
    onPressIrParaPagamento: (transactionType:TransactionType)=> void,
    open: boolean,
    onClose: ()=> void,
}
const DialogSelectPaymentType:FC<IDialogSelectPaymentTypeProps> = ({onPressIrParaPagamento, open, onClose}) => {
    return (
        <Dialog open={open} onClose={onClose}>
                <div className={styles.selectTransactionContainer}>
                    Selecione a forma de pagamento
                    <div className={styles.buttonsCard}>
                        <div className={styles.button} onClick={() => {  onPressIrParaPagamento(TransactionType.credit);onClose()}} >
                            Crédito
                        </div>
                        <div className={styles.button} onClick={() => { onPressIrParaPagamento(TransactionType.debit);onClose()}} >
                            Débito
                        </div>
                    </div>
                </div>
            </Dialog>
)}
export default DialogSelectPaymentType