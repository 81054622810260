import { Drawer, Icon } from '@material-ui/core'
import React, { FC, useCallback, useEffect, useState } from 'react'
import './PickerDate.scss'


import ButtonPicker from '../buttonPicker/ButtonPickerSchedule'
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { format, getDay } from 'date-fns'
import { DateInfo } from './PickerDate.type';
// import 'react-calendar/dist/Calendar.css'
export interface IPickerDateProps {
    //propertys
    disabledClick?: boolean
    // availableDays?: any;
    dateInfo: DateInfo[];
    value: Date | null;
    onChange: (Date: Date) => void;
}
const PickerDate: FC<IPickerDateProps> = ({
    // availableDays,
    disabledClick,
    value,
    onChange,
    dateInfo
}) => {
    const [openList, setopenList] = useState(true)


    const closeListHandle = () => {
        setopenList(false)
    }

    const openListHandle = useCallback(() => {
        !disabledClick && setopenList(true)
    }, [disabledClick])

    const onClickItem = (Date: Date) => {
        onChange(Date);
        closeListHandle();
    }

    // const disabledDayOfWeek = useCallback(
    //     ((availableDays: AvailableDay[], date: Date) => {
    //         return !availableDays.find((availableDay) => availableDay.dayOfWeek === getDay(date))
    //     }),
    //     [],
    // )

    useEffect(() => {
        if (!disabledClick) {
            setopenList(true)
        }
        return () => {

        }
    }, [disabledClick])

    const isDisabledTile = useCallback(
        ({ activeStartDate, date, view }: CalendarTileProperties): boolean => {
            if (view === "month") {
                if (dateInfo.find(item => item.day === format(date, "dd/MM/yyyy"))) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        [dateInfo],
    )

    const tileContent = useCallback(
        ({ view, activeStartDate, date }: CalendarTileProperties) => {
            if (view === "month") {

                if (dateInfo.find(item => item.day === format(date, "dd/MM/yyyy"))) {
                    return (<div style={{ color: "#1a0" }}><Icon fontSize="large">check</Icon></div>)
                } else {
                    return (<div style={{ color: "#c00" }}><Icon fontSize="large"> close</Icon></div>)
                }
            } else {
                return null
            }
        },
        [dateInfo],
    )

    const setClassNameTile = useCallback(
        ({ activeStartDate, date, view }: CalendarTileProperties): string => {
            if (view === "month") {
                if (dateInfo.find(item => item.day === format(date, "dd/MM/yyyy"))) {
                    return ("availabeDay")
                } else {
                    return ("busyDay")
                }
            } else {
                return ""
            }
        },
        [dateInfo],
    )

    return (
        <div id={"PickerDate"} >
            {
                <div className={"botao"} style={{ opacity: disabledClick ? 0.4 : 1 }}>
                    <ButtonPicker onClick={openListHandle} selected={!!value}>
                        <Icon fontSize={"large"}>event</Icon>  {value ? value.toLocaleDateString('pt-BR') :
                            "Selecionar Data"}
                    </ButtonPicker>
                </div>
            }
            {

                <Drawer anchor={"bottom"} open={!disabledClick && openList} onClose={closeListHandle}>
                    <div id={"drawerDate"}>
                        <h1>Selecione uma Data</h1>
                        <div className={"calendar"}>
                            {
                                <Calendar
                                    minDate={new Date()}
                                    locale="pt-BR"
                                    onChange={(date: Date) => onClickItem(date as Date)}
                                    tileDisabled={isDisabledTile}
                                    tileContent={tileContent}
                                    tileClassName={setClassNameTile}
                                />
                            }
                        </div>
                    </div>
                </Drawer>
            }
        </div>
    )
}
export default PickerDate