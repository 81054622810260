import React, { FC, useCallback, useState, memo, useMemo, useEffect, forwardRef, useImperativeHandle } from "react";
import "./ComposicaoItem.css";
import { Fab, Icon, IconButton } from "@material-ui/core";
import { OrderCompositionItem } from "domains/order/agreggates/order/OrderCompositionItem";
import { roundValue } from "utils/utils";

interface ComposicaoItemProps {
  id: string
  imageUrl: string;
  description: string;
  value: number;
  onQuantityChanged: (quantity: number, quantityFree?: number) => void;
  disabled?: boolean;
  min: number
  max: number
  quantityfree: number
  onAdd?: () => void,
  onRemove?: () => void,
  disabledAdd?: boolean,
  quantidadeInicial?: number,
  type: "radio" | "checkbox" | "quantity",
  selectedId?: string
  onSelect?: (id: string) => void
  addtionalCount: number;
  setAddtionalCount: React.Dispatch<React.SetStateAction<number>>
  adicionarUmTotal: () => void;
  removeUmTotal: () => void;
  getTotal: () => number;
  getTotalItemsFree: () => number;
  selectedItemsFree: OrderCompositionItem[];
  selectedItemsReplica: OrderCompositionItem[];
  itemType: number;
  compostionFreeMax: number | undefined
  getTotalItemsFreeDesc: () => number
}
export interface IComposicaoItem {
  unCheck: () => void
  check: () => void
}

export enum EnumTypes {
  "Fixed" = 1,
  "Variable" = 2
}

export const ComposicaoItem = forwardRef<IComposicaoItem, ComposicaoItemProps>(({ id, getTotalItemsFreeDesc, compostionFreeMax, selectedItemsReplica, selectedItemsFree, addtionalCount, adicionarUmTotal, removeUmTotal, getTotal, setAddtionalCount, onQuantityChanged, onSelect, quantityfree, max, min, selectedId, type, imageUrl, description, value, onAdd, onRemove, disabledAdd, quantidadeInicial, itemType }, ref) => {

  const [quantity, setQuantity] = useState(quantidadeInicial ?? 0);

  useEffect(() => {
    let replicas = [...selectedItemsReplica];
    let innerItem = replicas.find(replica => {
      return replica.compositionItemId === id;
    });

    console.log("INNER REPLICA ITEM :", innerItem);

    if (quantity === 0 && innerItem !== undefined) {
      setQuantity(innerItem.quantity);
    }
  }, [id, quantity, selectedItemsReplica]);

  const adicionarUm = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
    adicionarUmTotal();
    onQuantityChanged(quantity + 1, quantityfree);
  }
  const removerUm = () => {
    setQuantity((prevQuantity) => prevQuantity - 1);
    removeUmTotal();
    onQuantityChanged(quantity - 1, quantityfree);
  }

  const add = () => {
    if (quantity < max) {
      adicionarUm();
    }
  };

  const remove = () => {
    if (quantity > 0) {
      removerUm();
    }
  };

  const toggle = () => {

    if (quantity > 0) {
      setQuantity(0);
      onQuantityChanged(0, 0)
      // onRemove()

    } else {

      setQuantity(1);
      // onAdd()
      onQuantityChanged(1, 1)
      if (onSelect) {
        onSelect(id)
      }
    }
  };

  useImperativeHandle(ref, () => ({
    unCheck: () => {
      setQuantity(0);
      console.log('ImprerativeUnCheck', quantity)
    },
    check: () => {
      setQuantity(1);
      console.log('ImprerativeCheck', quantity)
    }
  }));

  const ifDisableAdd = () => {
    if (disabledAdd) {
      return true
    } else {
      if (max) {
        if (quantity >= max) {
          return true
        } else {
          return false
        }
      }
      return false
    }
  }

  const getFreeQuantityByItem = (id: string, listaGratis: OrderCompositionItem[]) => {

    if (listaGratis.length <= 0) {
      return 0;
    } else {
      const item = listaGratis.find(lg => {
        return lg.compositionItemId === id;
      });
      if (item === undefined) {
        return 0;
      } else {
        return item.quantity;
      }
    }
  }

  let diferenca = (quantity - getFreeQuantityByItem(id, selectedItemsFree));


  let totalGratisDoItemNaListaGratis = 0;
  let itemObj = selectedItemsFree.find(sif => {
    return sif.description === description;
  });

  if (itemObj !== undefined) {
    totalGratisDoItemNaListaGratis = itemObj.quantity;
  }

  let restaX = quantityfree - totalGratisDoItemNaListaGratis;

  if (getTotalItemsFreeDesc() === null || getTotalItemsFreeDesc() === undefined) {
    restaX = 0;
  }

  return (
    <div
      data-custom-id="composition-toogle-button-icon"
      data-custom-description="Escolher"
      data-custom-info={JSON.stringify({
        id,
        description,
        value,
        quantityfree,
        disabledAdd,
        quantidadeInicial,
        type,
        selectedId,
        addtionalCount,
        selectedItemsFree,
        selectedItemsReplica,
        itemType,
        compostionFreeMax,
        min,
        max,
      })}
      onClick={type !== "quantity" ? toggle : undefined}
      className={`${quantity === 0
        ? "composicao-item"
        : "composicao-item composicao-item-selected"} ${type.toString()}`

      }

      style={ifDisableAdd() && type === "quantity" && max <= 1 && quantity === 0 ? {
        opacity: 0.5
      } : {}}
    >
      < div
        className={"composicao-item-image"}
        // onClick={add}
        style={{ backgroundImage: "url(" + imageUrl + ")" }}
      />

      <div className="composicao-item-info">
        {/* <div className="composicao-item-nome">{name}</div> */}
        <div className="composicao-item-descricao">{description}</div>
        <div className="composicao-item-valor">
          {value > 0 &&
            <>
              R${value.toFixed(2).replace(".", ",")}
            </>}
        </div>
      </div>
      <div className="container-composicao-item">

        {
          diferenca > 0 && (
            <div className="composicao-item-valor-total">
              {(diferenca * value) > 0 &&
                <>
                  +R${roundValue(diferenca * value).toFixed(2).replace(".", ",")}
                </>}
            </div>
          )
        }
        {
          type === "quantity" &&
          <div className="composicao-item-quantidadeContainer">
            {
              restaX > 0 && (getTotalItemsFreeDesc() !== null && getTotalItemsFreeDesc() !== undefined && getTotalItemsFreeDesc() > 0) ?
                <div className={"container-quantity-free-max"}>
                  <p className={"container-quantity-free-max-text"}>{'Gratuidade restante: ' + restaX}</p>
                </div>
                :
                ""
            }
            <Fab
              style={{
                display: itemType === EnumTypes.Fixed ? 'none' : 'flex',
              }}
              disabled={quantity <= 0}
              onClick={remove}
              data-custom-id="composition-remove-button-icon"
              data-custom-description="Remover"
              data-custom-info={JSON.stringify({
                id,
                description,
                value,
                quantityfree,
                disabledAdd,
                quantidadeInicial,
                type,
                selectedId,
                addtionalCount,
                selectedItemsFree,
                selectedItemsReplica,
                itemType,
                compostionFreeMax,
                min,
                max,
              })}
              className={quantity <= 0 ? "composicao-item-remove-botao composicao-item-botao-disabled" : " composicao-item-remove-botao"}>
              {/* {(quantidade === 1) ? <Icon>close</Icon> : */}
              <Icon>remove</Icon>
              {/* } */}
            </Fab>
            <div className="composicao-item-quantidade">{quantity}</div>
            <Fab
              style={{
                display: itemType === EnumTypes.Fixed ? 'none' : 'flex',
              }}
              disabled={ifDisableAdd()} 
              onClick={add} 
              data-custom-id="composition-add-button-icon"
              data-custom-description="Adicionar"
              data-custom-info={JSON.stringify({
                id,
                description,
                value,
                quantityfree,
                disabledAdd,
                quantidadeInicial,
                type,
                selectedId,
                addtionalCount,
                selectedItemsFree,
                selectedItemsReplica,
                itemType,
                compostionFreeMax,
                min,
                max,
              })}
              className={ifDisableAdd() ? "composicao-item-add-botao  composicao-item-botao-disabled" : "composicao-item-add-botao"}>
              <Icon>add</Icon>
            </Fab>
          </div>
        }
        {
          Boolean(type === "radio" || type === "checkbox") &&
          <IconButton style={{ height: 50, width: 50, borderWidth: 2, borderStyle: "solid", borderColor: quantity > 0 ? "#4b8" : "#ddd" }}>
            {quantity > 0 ? <Icon fontSize="large" style={{ color: "#4b8" }}>check</Icon> : null}
          </IconButton>
        }

      </div>
      {/* {
        type === "radio" &&
        <Fab
          disabled={quantity <= 0}
          onClick={remove}
          className={quantity <= 0 ? "composicao-item-remove-botao composicao-item-botao-disabled" : " composicao-item-remove-botao"}>
         
          <Icon>remove</Icon>
    
        </Fab>
      } */}
    </div >
  );
});
