import React, { FC, useEffect } from "react";
import "./Catalog.css";
import { useWeighingMachine } from "application/contexts/weighingMachine/WeighingMachine";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";
import { useCartStore } from "modules/cart/infra/store/CartStore";

export const Catalog: FC = ({ children }) => {

  const [setConfigCart] = useCartStore((state: ICartContext) => [
    state.setConfigCart
]);
  const { weighingMachineEnable} = useWeighingMachine()

  useEffect(() => {
    setConfigCart({
      fullscreen: false,
      disableCloseButton: false,
      openOnCreate: false,
      label : weighingMachineEnable ? 'Finalizar': undefined
    });
  }, [setConfigCart, weighingMachineEnable]);

  return (
    <div className="cardapio">
      {children}
    </div>
  );
};
