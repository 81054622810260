import { Favorite, KeyboardArrowRight, Star } from '@material-ui/icons'
import React, { FC } from 'react'
import { moneyMask } from '../../../../../corss-cutting/masks/money'
import { Tour } from '../tourCategoryList/TourCategoryList.type'
import Medal from 'assets/icons/medal.svg'

import styles from './TourItem.module.scss'

export interface ITourItemProps {
    //propertys
    tourItem: Tour,
    onClick?: () => void
}
const TourItem: FC<ITourItemProps> = ({ tourItem, onClick }) => {
    return (
        <div onClick={onClick} id={styles.TourItem} >
            <div className={styles.container}>

                <div className={styles.image} >
                    <img className={styles.img} src={tourItem.imageUrl} alt="Imagem do serviço oferecido" />
                </div>
                <div className={styles.content}>


                    <div className={styles.containerCard}>
                        <div className={styles.headerCard}>
                            <div className={styles.name} >
                                <h3>{tourItem.name}</h3>
                            </div>

                            <div className={styles.price} >
                                <span>a partir de</span>

                                <h4>{new Intl.NumberFormat("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                }).format(!!tourItem.prices.length ? Math.max(...tourItem.prices.map((item) => item.price)) : 0)}</h4>
                            </div>
                        </div>
                        <div className={styles.description}>
                            <p>{tourItem.description}</p>
                        </div>
                        {
                            tourItem.id === "72c36fe2-358e-4f73-9ce9-ea185d25ad1e" ?
                                <div className={styles.footerCard}>
                                    <div className={styles.favorite}>
                                        <img src={Medal} alt="Medalha de mais vendido" />
                                        <p>Mais vendido</p>
                                    </div>
                                    <div className={styles.viewMore} >
                                        <p>Ver mais</p>
                                        <KeyboardArrowRight />
                                    </div>
                                </div>
                                :
                                tourItem.id === '8a17f4d2-3aef-4e11-8530-c057abe5f551' ?
                                    <div className={styles.footerCard}>
                                        <div className={styles.favorite}>
                                            <Favorite />
                                            <p>Mais procurado</p>
                                        </div>
                                        <div className={styles.viewMore} >
                                            <p>Ver mais</p>
                                            <KeyboardArrowRight />
                                        </div>
                                    </div>
                                    :
                                    tourItem.id === '1debb996-0c1c-4942-851f-f990900dc2ae' ?
                                        <div className={styles.footerCard}>
                                            <div className={styles.favorite}>
                                                <Star />
                                                <p>Destaque</p>
                                            </div>
                                            <div className={styles.viewMore} >
                                                <p>Ver mais</p>
                                                <KeyboardArrowRight />
                                            </div>
                                        </div>
                                        :
                                        <div className={styles.viewMoreOnly} >
                                            <p>Ver mais</p>
                                            <KeyboardArrowRight />
                                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default TourItem