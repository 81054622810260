
import { IHttpRepository } from "../../interfaces/IHttpRepository";

import { IUserDataRepository } from "../../../domains/user/interfaces/repositories/IUserDataRepository";
import { IUserData } from "../../../domains/user/interfaces/IUserData";
import { HttpRepository } from "./_HttpRepository";

export interface UsuarioMeep {
  DataNascimento?: string;
  Email?: string;
  Id?: string;
  Nome?: string;
  Sexo?: number;
  Telefone?: string;
  NomeMae?: string;
}

export const UserDataRepositoryMeep = (
): IUserDataRepository => {
  const httpRepository: IHttpRepository = HttpRepository();
 

  const getAsyncUserData = async (cpf: string, localId: string) => {
    const usuarioMeep = await httpRepository.getAsync<UsuarioMeep>("/dispositivo/dadoscliente/" + localId + "/" + cpf);

    const userData: IUserData = {
      birth: usuarioMeep.DataNascimento,
      email: usuarioMeep.Email,
      id: usuarioMeep.Id,
      name: usuarioMeep.Nome,
      genre: usuarioMeep.Sexo,
      tel: usuarioMeep.Telefone,
      motherName: usuarioMeep.NomeMae,
    };


    return userData;

  };

  return {
    getAsyncUserData,
  };
};
