import { Dialog } from "@material-ui/core";
import { Product } from "domains/catalog/aggregates/Product";
import GetWeighingProducts from "domains/weighingMachine/services/GetWeighingProducts";
import "./_WeighingMachine.scss";

import React, { createContext, FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import WeighingMachineModal from "./_weighingMachineModal/WeighingMachineModal";
import { CefSharpWeighingMachine } from "Infrastructure/services/cefSharpService/local/CefSharpWeighingMachine";
import { useCatalog } from "../catalog/CatalogContext";
import { useHistory } from "react-router";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";
import { roundValue } from "utils/utils";

interface IWeighingMachine {
  weighingMachineEnable: boolean;
  clearWeight: () => void;
  disableWeighModal: () => void
  enableWeighModal: () => void
  onPressBack: () => void
}

const WeighingMachineContext = createContext<IWeighingMachine>({} as IWeighingMachine);

const cefSharpWeighingMachine = CefSharpWeighingMachine();
const WeighingMachineProvider: FC = ({ children }) => {
  const [weight, setWeight] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [disabledWeighModal, setDisabledWeighModal] = useState(false)
  // const { addProductCart } = useCart();
  const [addProductCart] = useCartStore((state: ICartContext) => [
    state.addProductCart,
  ]);
  
  const [serialPort, setserialPort] = useState("");
  const [weighingMachineEnable, setWeighingMachineEnable] = useState(false);
  const [weighingMachineProducts, setWeighingMachineProducts] = useState<Product[]>([]);

  const { catalog } = useCatalog();
  const { replace, push } = useHistory();

  const [count, setCount] = useState(0);

  const timeRef = useRef<NodeJS.Timeout>()

  useEffect(() => {

    const PORTA = localStorage.getItem("@weighning-port");
    setserialPort(PORTA ?? "COM0");
    setWeight(0);

  }, [catalog]);

  useEffect(() => {
    if (catalog) {
      setWeighingMachineProducts(GetWeighingProducts(catalog))
    }
  }, [catalog]);

  useEffect(() => {
    if (catalog) {
      if (!!GetWeighingProducts(catalog).length) {
        setWeighingMachineEnable(true)
      }
      else {
        setWeighingMachineEnable(false)
      }
    }
  }, [catalog]);


  useEffect(() => {
    // console.log("weight", weight);
    if (weight) {
      setOpenModal(true);
    }
  }, [weight]);

  const clearWeight = useCallback(() => {
    setWeight(0);
    replace('/weighing')
  }, [replace]);


  // const onWeighing = debounce(1000, useCallback((_weight: number) => {
  const onWeighing = useCallback((_weight: number) => {
    setWeight(_weight)
    // setOpenModal(true);
    setCount(0);
    // console.log("weight debounce", _weight)
  }, [])
  // }, []))

  const closeWeighingMachine = useCallback(() => {
    // console.log("RETURNOUT")
    if (timeRef.current) {
      // console.log("LIMPAR TIMER")
      clearTimeout(timeRef.current);
    }
    window.setWeight = () => {
      // console.log("--");
    }
  }, [])

  const initconection = useCallback(() => {
    if (!disabledWeighModal) {
      const PORTA = localStorage.getItem("@weighning-port");
      setserialPort(PORTA ?? "COM0");
      console.log(PORTA)
      cefSharpWeighingMachine.getValueFromWighingScale(PORTA ?? "COM0");
    }
  }, [disabledWeighModal])

  const initWeighingMachine = useCallback(
    () => {
      initconection();
      console.log('timerIniciado')
      timeRef.current = setInterval(() => {
        setCount(prev => prev + 1)
      }, 1000);
      // setTimeout(() => {
      //   setWeight(866.999);
      // }, 3000);
      window.setWeight = onWeighing;
      return () => { closeWeighingMachine() }
    },
    [closeWeighingMachine, initconection, onWeighing],
  )

  const disableWeighModal = useCallback(() => {
    console.log("disabled - WeighModal")
    setDisabledWeighModal(true);
    closeWeighingMachine();

  },[closeWeighingMachine])

  const onAddProduct = useCallback(
    (product: Product) => {
      const finalWeight = Number(((weight + (product?.tare ? (-product.tare) : 0)) / 1000).toFixed(3));
      const price = roundValue(product.price * finalWeight);
      addProductCart({ ...product, isWeight: true, price: price, totalPrice: price }, finalWeight, []); //TODO: Avaliar a necessidade de informar q eh um produto novo
      setOpenModal(false);
      disableWeighModal();
      if (catalog?.stores) {
        if (catalog.stores.length === 1) {
          
          push("/catalog/store/" + 0);
        } else {
          push("/catalog/");
        }
      }
    },
    [addProductCart, catalog, disableWeighModal, push, weight]
  );

  const enableWeighModal = useCallback(() => {
    console.log("enable - WeighModal")
    setDisabledWeighModal(false)
    initWeighingMachine();
  },
    [initWeighingMachine])

  const onCloseModal = useCallback(() => {
    // setWeight(0);
    setOpenModal(false);
  }, []);

  useEffect(() => {
    // console.log(count);
    if (count > 20) {
      setCount(0);
      console.log("effect close modal count > 5 ")
      setWeight(0);
      if (openModal) {
        onCloseModal();
      }
       initconection();
    }
    return () => { };
  }, [count, initconection, onCloseModal, openModal]);

  const onPressBack = useCallback(
    () => {
      closeWeighingMachine();
      setWeight(0)
      replace("/")
    },
    [closeWeighingMachine, replace],
  )

  return (
    <WeighingMachineContext.Provider value={{
      weighingMachineEnable,
      clearWeight,
      onPressBack,
      disableWeighModal,
      enableWeighModal
    }}>
      <>{children}</>
      {
        <Dialog fullWidth maxWidth="sm" classes={{ paper: "paperWeighingMachine" }} open={openModal && !disabledWeighModal} onClose={onCloseModal}>
          <WeighingMachineModal
            weighingMachineProducts={weighingMachineProducts}
            weight={weight}
            onClose={onCloseModal}
            onClickConfirmar={onAddProduct}
          />
        </Dialog>
      }
      {/* <button
        onClick={() => {
          cefSharpWeighingMachine.getValueFromWighingScale(serialPort);
        }}
        style={{ position: "fixed", bottom: 0, left: 0 }}
      >
        TESTE 2
      </button> */}
    </WeighingMachineContext.Provider>
  );
};

export default WeighingMachineProvider;

export const useWeighingMachine = () => {
  const context = useContext(WeighingMachineContext);
  return context;
};
