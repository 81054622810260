import React, { FC } from 'react'
import { IReserveTourPrinterLayout } from './ReserveTourLayoutPrinter.type'
import QRCode from 'qrcode.react'
import styles from './ReserveTourLayoutPrinter.module.scss'
interface ReserveTourLayoutPrinter {
    reserveTour: IReserveTourPrinterLayout
}

const ReserveTourLayoutPrinter: FC<ReserveTourLayoutPrinter> = ({ reserveTour }) => (
    <div id={styles.ReserveTourLayoutPrinter}>
        <div className={styles.container}>
            <div className="logo-ResumoLayoutPrinter">
                <img width={150} src={require('./../../../assets/images/logo-meep.png')}></img>
            </div>
            <div className={styles.tour}>
                <h2>{reserveTour.tourName}</h2>
            </div>
            <div className={styles.customer}>
                <div className={styles.item}>
                    <h2>{reserveTour.customerName}</h2>
                </div>
                <div className={styles.item}>
                    <h2>{reserveTour.customerDocument}</h2>
                </div>
                {/* <div className={styles.item}>
                    <h2>{reserveTour.customerBirthday}</h2>
                </div> */}
                <div className={styles.item}>
                    <h2>Data do passeio: {reserveTour.date}</h2>
                </div>
                {/* <div className={styles.item}>
                    <h2>{reserveTour.time}</h2>
                </div> */}
                <div className={styles.item}>
                    <span>O horário exato da sua reserva será avisado na véspera até as 18 horas por mensagem de Whatsapp. Em caso de dúvida 54-992046916</span>
                </div>
            </div>
            <div className={styles.price}>
                <h1>{new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL", }).format(reserveTour.price)}</h1>
            </div>
            <div className={styles.qrCode}>
                <QRCode
                    className="qrCode" size={200} value={reserveTour.id} />
            </div>
        </div>
    </div>
)

export default ReserveTourLayoutPrinter
