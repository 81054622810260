import React, { createContext, FC, useContext, useEffect } from 'react';
import { useKiosk } from '../kiosk/KioskContext';

interface IThemeContext { }

const updateCssVar = (cssVar: string, value: string) => {
    document.documentElement.style.setProperty(`--${cssVar}`, value);
};


const ThemeContext = createContext<IThemeContext>({} as IThemeContext);

export const ThemeProvider: FC = ({ children }) => {

    const { kiosk } = useKiosk();
    useEffect(() => {
        if (
            kiosk?.localId === "a0adfafd-02bc-aab8-9cda-4a48024c06b4" ||
            kiosk?.localId === "cb58946a-5d5f-3fda-5223-5f28bef679cb"
        ) {
            updateCssVar("primary", "#000");
        }
        if (
            kiosk?.localId === `99c26ea0-db30-f01c-e5ee-23d70e17fc88` || // american burguer
            kiosk?.localId === `eed18fa3-42ed-e13c-289c-35b2a5f51c37` || // american burguer
            kiosk?.localId === `2c2643cb-8fae-f170-21e2-3c07d9a4b19f` || // american burguer
            kiosk?.localId === `74525d53-07ec-25e9-5d10-3ec08d3fd029` || // american burguer
            kiosk?.localId === `303fae40-752f-3c30-faba-48ffb0181748` || // american burguer
            kiosk?.localId === `0a199b75-e726-ca02-4107-86b221ed059a` || // american burguer
            kiosk?.localId === `fdf00d77-9f18-844d-f489-870dad89e37a` || // american burguer
            kiosk?.localId === `e968073c-9397-97c7-49de-afb610b6648c` || // american burguer
            kiosk?.localId === `b7eb2ff2-f94d-b026-6fc8-c6c00d016f1b` || // american burguer
            kiosk?.localId === `e99a807d-b520-08df-80d3-d76b8fdb1cd0` || // american burguer
            kiosk?.localId === `50042ee0-8fa9-55e7-7b67-fc2a902e6959`    // american burguer // 
        ) {
            updateCssVar("primary", "#53a6cb");
        }
    }, [kiosk])



    return (
        <ThemeContext.Provider value={{}}>
            <>{children}</>
        </ThemeContext.Provider>
    );
};


export const useTheme = () => {
    const context = useContext(ThemeContext);
    return context;
};