import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { IService } from '../../domains/schedule/agreggates/IServices'
import ScheduleRepository from '../../Infrastructure/repositories/api/ScheduleRepository'
import { useKiosk } from '../../application/contexts/kiosk/KioskContext'
import { useUi } from '../../application/contexts/ui/UIContext'
import { IDemmandScheduleRequest } from '../../domains/schedule/agreggates/IDemmandSchedule'
import { ICollaborator } from '../../domains/schedule/agreggates/ICollaborator'
import { IDemandedService } from '../../domains/schedule/agreggates/IDemandedService'
import { ICollaboratorSchedule } from '../../domains/schedule/agreggates/ICollaboratorSchedule'
import ScheduleUseCase from './ScheduleUseCase'
import { useScheduleCart } from './components/scheduleCart/ScheduleCart'
import { v4 } from 'uuid'
import { IServiceCategory } from '../../domains/schedule/agreggates/IServiceCategory'
import { IScheduleItemCart } from './interfaces/IScheduleItemCart'
import { useHistory } from 'react-router'

const useSchedulePage = () => {

    const { kiosk } = useKiosk();
    const [autoSelectSchedule, setAutoSElectSchedule] = useState(kiosk?.onlyNextTime);

    const { replace } = useHistory();

    const { add, cancelSchedules, clear } = useScheduleCart()
    const { showLoading, hideLoading, toast } = useUi();
    const [services, setServices] = useState<IService[]>([])

    const [selectedService, setSelectedService] = useState<IService | null>(null)

    const [colaborators, setColaborators] = useState<ICollaborator[]>([])
    const [selectedColaborator, setSelectedColaborator] = useState<ICollaborator | null>(null)


    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    const [selectedTime, setSelectedTime] = useState<Date | null>(null)

    const [categoryServices, setCategoryServices] = useState<IServiceCategory[]>([]);
    const [selectedCategoryService, setSelectedCategoryService] = useState<IServiceCategory | null>(null);


    const [demandedServices, setDemandedServices] = useState<IDemandedService[]>()
    const [collaboratorSchedule, setCollaboratorSchedule] = useState<ICollaboratorSchedule>()

    const scheduleUseCase = useMemo(ScheduleUseCase, [])
    const now = useMemo(() => new Date(), [])

    const getNextAvailableScheduleOfAllColaborators = useCallback(scheduleUseCase.getNextAvailableScheduleOfAllColaborators, [])
    const getNextAvailableScheduleColaborator = useCallback(scheduleUseCase.getNextAvailableScheduleColaborator, [])

    useEffect(() => {
        setAutoSElectSchedule(kiosk?.onlyNextTime)
        return () => {

        }
    }, [kiosk?.onlyNextTime])


    useEffect(() => {
        setColaborators([])
        setSelectedColaborator(null)
        setSelectedDate(null)
        setSelectedTime(null)
        setDemandedServices(undefined)
        setCollaboratorSchedule(undefined)
        return () => {

        }
    }, [selectedService])

    useEffect(() => {
        setSelectedDate(null)
        setSelectedTime(null)
        setDemandedServices(undefined)
        setCollaboratorSchedule(undefined)
        return () => {

        }
    }, [selectedColaborator])

    useEffect(() => {
        if (!autoSelectSchedule) {
            setSelectedTime(null)
        }
        return () => {

        }
    }, [selectedDate])


    useEffect(() => {
        if (kiosk) {
            const scheduleRepository = ScheduleRepository();
            showLoading()
            scheduleRepository.getCategoryServices(kiosk.localId).then((response) => {
                setCategoryServices(response.items);
                // console.error(response)
            }).catch(erro => console.error("erro CATEGORY")).finally(() => { hideLoading() })

            return () => { }
        }
    }, [kiosk])


    useEffect(() => {
        if (kiosk && selectedCategoryService) {
            const scheduleRepository = ScheduleRepository();
            showLoading()
            scheduleRepository.getServicesByCategory(selectedCategoryService?.id).then((response) => {
                if (response) {
                    console.log(response)
                    setServices(response.items);
                }
            }).catch(erro => console.error(erro)).finally(() => { hideLoading() })
            // scheduleRepository.getServices(kiosk.localId).then((response) => {

            return () => {

            }
        }
    }, [kiosk, selectedCategoryService])



    const selectNextAbailableCollaborator = useCallback(() => {
        if (selectedService) {
            showLoading()
            getNextAvailableScheduleOfAllColaborators(selectedService.id, new Date()).then((response) => {
                if (response) {
                    setSelectedColaborator(response.colaborator)
                    setSelectedDate(response.time.time)
                    setSelectedTime(response.time.time)
                } else {
                    toast("Não há horarios dispóniveis para hoje", "warning")
                }
            }).finally(() => {
                hideLoading();
            })
        }
    }, [selectedService])

    useEffect(() => {
        if (selectedService) {
            const scheduleRepository = ScheduleRepository();
            showLoading();
            scheduleRepository.getCollaboratorsByServiceId(selectedService.id).then((response) => {
                if (response) {
                    setColaborators(response);
                }
            }).finally(() => {
                hideLoading()
            })
        }
        return () => {

        }
    }, [selectedService]);


    useEffect(() => {
        if (selectedColaborator) {
            const getCollaboratorScheduleData = async () => {
                showLoading()
                const scheduleUseCase = ScheduleUseCase();
                try {
                    const now = new Date();
                    const { collaboratorSchedule, demandedServices } = await scheduleUseCase.getColaboratorScheduleAndDemandedServices(selectedColaborator, now);
                    setCollaboratorSchedule(collaboratorSchedule);
                    setDemandedServices(demandedServices)


                    if (autoSelectSchedule) {
                        const colaboratorFirtTime = await getNextAvailableScheduleColaborator(selectedColaborator, now)
                        if (colaboratorFirtTime) {
                            setSelectedDate(colaboratorFirtTime.time.time)
                            setSelectedTime(colaboratorFirtTime.time.time)
                        } else {
                            toast("Não há horarios dispóniveis para hoje para " + selectedColaborator.name, "warning")
                        }
                    }

                } finally {
                    hideLoading()
                }
            }

            getCollaboratorScheduleData();

        }
        return () => {

        }
    }, [selectedColaborator, autoSelectSchedule]);


    const clearAll = useCallback(() => {
        setSelectedCategoryService(null)
        setSelectedService(null)
        setSelectedColaborator(null)
        setCollaboratorSchedule(undefined)
        setSelectedDate(null)
        setSelectedTime(null)
        setColaborators([])
    }, [])

    const onClickConfirmarAgendamento = useCallback(() => {

        console.log("CLICK CART", selectedService, selectedColaborator, collaboratorSchedule, selectedTime)
        const scheduleUseCase = ScheduleUseCase();
        if (selectedService
            && selectedColaborator
            && collaboratorSchedule
            && selectedTime
        ) {
            const scheduleRepository = ScheduleRepository();

            const id = v4();
            const demmandRquest: IDemmandScheduleRequest = {
                // userId: "",
                id: id,
                services: [{
                    serviceScheduledId: collaboratorSchedule.serviceScheduledId,
                    start: selectedTime,
                    duration: collaboratorSchedule.serviceDuration
                }]
            }
            showLoading()
            // const { product, orderCompositions } = scheduleUseCase.serviceToProduct(selectedService, selectedColaborator, collaboratorSchedule, selectedTime)
            // addProductCart(product, 1, orderCompositions);



            scheduleRepository.demmandSchedule(demmandRquest).then((response) => {
                const scheduleCartItem: IScheduleItemCart = {
                    id: id,
                    service: selectedService,
                    colaborator: selectedColaborator,
                    colaboratorSchedule: collaboratorSchedule,
                    dateTime: selectedTime
                }

                clearAll();
                add(scheduleCartItem)
            }).catch(() => {
                toast("Não foi possivei adicionar ao carrinho", "warning")
            }).finally(() => {
                hideLoading();
            })
        }


    },
        [selectedService, selectedColaborator, collaboratorSchedule, selectedTime]
    )


    const onClickBack = useCallback(
        () => {
            cancelSchedules();
            clear();
            replace("/")
        },
        [cancelSchedules, replace, clear],
    )

    return {
        services,
        selectedService,
        colaborators,
        selectedColaborator,
        selectedDate,
        selectedTime,
        demandedServices,
        collaboratorSchedule,
        now,
        setSelectedService,
        setSelectedColaborator,
        setSelectedDate,
        setSelectedTime,
        autoSelectSchedule,
        selectNextAbailableCollaborator,
        onClickConfirmarAgendamento,
        categoryServices,
        selectedCategoryService,
        setSelectedCategoryService,
        onClickBack
    }
}

export default useSchedulePage