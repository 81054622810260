
import { IPrintService, IReturnPrint } from "../interfaces/services/iprintService";
import { IPrintRepository } from "../interfaces/repositories/iprintRespository";
import { Ticket } from "../agreggates/ticket/ticket";
import { EletronicInvoiceTicket } from "../agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";
import { Payment } from "../agreggates/payment/payment";
import { Order } from "../agreggates/order/Order";
import { TransactionTypeLabel } from "../agreggates/payment/transactionType";
import { PrintType } from "../../kiosk/aggregates/PrintType";
import { OrderResume } from "../agreggates/order/OrderResume";
import { Kiosk } from "../../kiosk/aggregates/Kiosk";
import moment from "moment";
import { OrderDetailResume } from "../agreggates/order/OrderDetailResume";
import { PedidoPos } from "../../../Infrastructure/repositories/api/PedidoMeep";
import { LocalPrintRepository } from '../../../Infrastructure/services/cefSharpService/local/_LocalPrintRepository'
import { v4 } from "uuid";
import { IPrintSector } from "Infrastructure/repositories/api/ConfigTotemMeep";



export const PrintService = (
): IPrintService => {
  const printRepository: IPrintRepository = LocalPrintRepository();

  const printOrders = async (payment: Payment, pedidoPos: PedidoPos, order: Order, kiosk: Kiosk, printType?: PrintType): Promise<IReturnPrint[]> => {
    remotePrint(order, kiosk);
    const _printType = printType ?? kiosk.printType

    if (_printType !== PrintType.DontPrint) {
      if (_printType === PrintType.Ticket) {
        const printTicketStatus = await printTicketPedidoPos(payment, pedidoPos, kiosk);
        return printTicketStatus
      } else {
        return printResume(payment, order, kiosk);
      }
    } else {
      return []
    }
  };


  const printEletronicInvoice = async (
    eletronicInvoiceTicket: EletronicInvoiceTicket[]
  ): Promise<IReturnPrint[]> => {

    return await Promise.all(eletronicInvoiceTicket.map(async (ticket) => {
      const status = await printRepository.printEletronicInvoiceAsync(ticket)
      if (status) {
        return { status: true, message: `NFCe ${ticket.key} impressa.` };
      } else {
        return { status: false, message: `NFCe ${ticket.key} não impressa.` };
      }
    }))
  };


  const printTicketPedidoPos = async (
    payment: Payment | null,
    order: PedidoPos,
    kiosk: Kiosk
  ): Promise<{ status: boolean, message: string }[]> => {

    const tickets = getPedidoPosTicketsToPrint(payment, order, kiosk);

    return tickets.map((ticket) => {
      const printOk = printRepository.printOrderAsync(ticket);
      if (!printOk) {
        console.log("Não imprimiu");
        return { status: false, message: `Ticket ${ticket.id} não impresso.` }
      } else {
        return { status: true, message: `Ticket ${ticket.id} impresso.` };
      }
    })
  };

  const printResume = async (
    payment: Payment,
    order: Order,
    kiosk: Kiosk | null
  ): Promise<IReturnPrint[]> => {
    const orderResume = getOrderResume(kiosk, order);
    const status = await printRepository.printResumeAsync(orderResume)
    if (!status) {
      return [{ status: true, message: `Resmuo ${orderResume.id} impresso.` }];
    } else {
      return [{ status: false, message: `Resmuo ${orderResume.id} não impresso.` }];
    }

  };


  function getTicketsToPrint(payment: Payment, order: Order): Ticket[] {
    let tickets: Ticket[] = []

    let totalDeFichas = 0
    order.orderItems.map((orderItem) => {
      totalDeFichas = totalDeFichas + orderItem.quantity
    })
    let count = 0

    order?.orderItems.map((item, index) => {

      for (let i = 0; i < item.quantity; i++) {
        const ticket: Ticket = {
          card: payment.cardNumber,
          categoria: item.category,
          codigoBarras: item.id,
          dataVenda: new Date(),
          documentName: item.id,
          fichaAtual: count + 1,
          id: item.id,
          paymentType: TransactionTypeLabel.get(payment.paymentMethod) ?? "",
          produto: item.name,
          totalFichas: totalDeFichas,
          valor: item.price,
          terminal: "",
          notes: item.orderComposition?.map((orderComposition) => ({
            description: orderComposition.description,
            options: orderComposition.orderCompositionItems.map(
              (orderCompositionItem) => (
                orderCompositionItem.description
              )
            ),
          })),
          orderFriendlyId: order.friendlyId,
        };
        tickets.push(ticket)
      }
    });
    return tickets ?? [];
  }


  function getPedidoPosTicketsToPrint(payment: Payment | null, order: PedidoPos, kiosk: Kiosk): Ticket[] {
    let tickets: Ticket[] = []
    let totalDeFichas = order.produtosObject?.length ?? 0
    order?.produtosObject?.map((item, index) => {
      const ticket: Ticket = {
        card: payment?.cardNumber ?? "",
        categoria: "",
        codigoBarras: item.ticketId,
        dataVenda: new Date(),
        documentName: item.nome,
        fichaAtual: index + 1,
        id: item.ticketId,
        paymentType: payment?.paymentMethod ? TransactionTypeLabel.get(payment.paymentMethod) ?? "" : "",
        produto: item.nome,
        totalFichas: totalDeFichas,
        valor: item.valor ?? 0,
        terminal: kiosk.kioskName,
        notes: item.opcoesDoConsumidorObject?.map((orderComposition) => ({
          description: orderComposition.descricao,
          options: orderComposition.opcoes.map(
            (orderCompositionItem) => (
              orderCompositionItem.descricao
            )
          ),
        })),
        orderFriendlyId: order.codigoPedido,
      };
      tickets.push(ticket);
    });
    return tickets;
  }

  const findPrinterNameByProductId = (printingSectorsContainer: IPrintSector[], productId: string, totemId: string) => {
    let printerName;
    printingSectorsContainer.forEach(it => {
      if (it.productsIds.includes(productId) && it.totemsIds.includes(totemId)) {
        printerName = it.printerName;
      }
    })
    return printerName;
  };

  const getOrderResume = (
    kiosk: Kiosk | null,
    order: Order,
    imageBase64?: string
  ): OrderResume => {
    console.log({ order });
    return {
      id: order.friendlyId,
      businessName: kiosk?.localName ?? '',
      customer: (order.customer?.name ?? "--") + (order.observation ?? ""),
      tag: order.customer?.tag ?? '',
      responsable: "Totem",
      pointOfSale: kiosk?.kioskName ?? "TOTEM",
      date: moment.utc().toDate(),
      isCanceled: false,
      canceledBy: null,
      totalValue: order.totalValue,
      details: order.orderItems.map<OrderDetailResume>((item) => ({
        quantity: item.quantity,
        productName: item.name,
        productCategory: item.category,
        value: item.price,
        totalValue: item.price * item.quantity,
        notes: item.orderComposition?.map((orderComposition) => ({
          description: orderComposition.description,
          options: orderComposition.orderCompositionItems.map(
            (orderCompositionItem) => (
              orderCompositionItem.quantity + "x -" + orderCompositionItem.description
            )),
        })),
        orderNumber: item.id,
        printerName: findPrinterNameByProductId(kiosk?.printingSectors ?? [], item.productId, kiosk?.id ?? '') ?? item.printerName ?? "",
        attendantName: null,
        operatorName: null,
      })),
      orderFriendlyId: order.friendlyId + " -" + (order.observation ? "(" + order.observation + ")" : "") + (order.customer?.prism ? "(mesa:" + order.customer.prism + ")" : ""),
      prismNumber: order.customer?.prism ?? '',
      table: order.customer?.prism ?? '',
      note: order.observation ?? '',
      imageBase64: imageBase64 ?? '',
      duplicatePrint: kiosk?.duplicateRemotePrint,
    };
  };

  const remotePrint = async (
    order: Order,
    kioskCOnfig: Kiosk | null,
  ): Promise<void> => {
    const orderResume = getOrderResume(kioskCOnfig, order);

    if (kioskCOnfig?.configSymstem.flatMap(item => item.enableRemotePrint).find(item => item)) {
      if (kioskCOnfig?.addressLocalServer) {
        const print = await printRepository.remotePrintAsync(
          kioskCOnfig.addressLocalServer,
          orderResume
        );
        console.log("remote print: ", { orderResume, print });
      }
    }
  };

  const printString = async (text: string) => {
    try {
      function delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      // await delay(3000)
      await printRepository.printStringAsync(text)
    } catch (error) {
      console.log("cefsharp error", error)
    }
    return true
  }

  const printImage = async (image: string) => {
    try {
      await printRepository.printImage(image)
    } catch (error) {
      console.log("cefsharp error", error)
    }
    return true
  }

  const printRemote = async (
    order: Order,
    kioskCOnfig: Kiosk | null,
    imageBase64: string, 
  ): Promise<void> => {

    const orderResume = getOrderResume(kioskCOnfig, order, imageBase64);

    if (kioskCOnfig?.configSymstem.flatMap(item => item.enableRemotePrint).find(item => item)) {
      if (kioskCOnfig?.addressLocalServer) {
        const print = await printRepository.remotePrintAsync(
          kioskCOnfig.addressLocalServer,
          orderResume
        );
        console.log("print remote v2: ", { orderResume, print });
      }
    }
  };


  return {
    printOrders,
    printEletronicInvoice,
    printString,
    printImage,
    remotePrint,
    printRemote
  };
};
