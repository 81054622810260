import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { Order } from "domains/order/agreggates/order/Order";
import { Payment } from "domains/order/agreggates/payment/payment";
import { ICardCashless } from "./ICardCashless";
import { IExtract } from "./IExtract";
import { IOrderPad } from "./IOrderPad";

export interface PayAccountRequest {
    accountId: string;
    amount: number;
    discount: number;
    installments: number;
    paymentType: number;
    transactionResponse: string; //json com todos os dados retornados pela adquirente
    operatorId: string;
    success: Boolean;
    localId: string;
    orderId: string;
    paymentId: string;
    discountReason: string;
    discountOperator: string;
    serviceFeeEnabled: Boolean;
    equipmentName: string;
    equipamentId: string;
    operatorName: number;
}


export const paymentExtractToPayAccountRequest = (params: { kiosk: Kiosk, localId: string, orderId: string, paymentId: string, serviceFeeEnabled: boolean, cardData: ICardCashless, extract: IExtract, orderPad: IOrderPad, payment: Payment }) => {

    const request = {
        accountId: params.cardData.associationId,
        amount: Math.abs(params.extract.Saldo) + Math.abs(params.serviceFeeEnabled ? params.extract.TaxaServico : 0),
        discount: 0,
        installments: 1,
        paymentType: params.payment.paymentMethod,
        transactionResponse: JSON.stringify(params.payment.FinancialTransactionModel), //json com todos os dados retornados pela adquirente
        success: true,
        localId: params.localId,
        orderId: params.orderId,
        paymentId: params.paymentId,
        discountReason: "",
        discountOperator: "",
        serviceFeeEnabled: params.serviceFeeEnabled,
        equipmentName: params.kiosk.kioskName,
        equipamentId: params.kiosk.id,
        operatorId: "cf7d7062-ee3b-4dfa-8bce-bfc5dcaaf45e",
        operatorName: "Totem",
    }

    return request

}