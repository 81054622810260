import { Button } from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import { TecladoVirtual } from "../../../components/tecladoVirtual/TecladoVirtual";
import "./InputCpfCnpj.css";

interface InputCpfCnpjProps {
  onPressContinuar: (cpfOrCnpj?: string) => void;
  value?: string;
}

export const InputCpfCnpj: FC<InputCpfCnpjProps> = ({
  onPressContinuar,
  value,
}) => {
  const [cpfOrCnpj, setCpfOrCnpj] = useState(value ?? "");
  const [disableContinuar, setDisableContinuar] = useState(true);
  const [enableTeclado, setEnableTeclado] = useState(false);
  const [erro, setErro] = useState("");

  useEffect(() => {
    if (cpfOrCnpj.length === 11) {
      if (testaCPF(cpfOrCnpj)) {
        setErro("");
        setDisableContinuar(false);
      } else {
        setErro("CPF inválido");
        setDisableContinuar(true);
      }
    } else if (cpfOrCnpj.length === 14) {
      if (testaCNPJ(cpfOrCnpj)) {
        setErro("");
        setDisableContinuar(false);
      } else {
        setErro("CNPJ inválido");
        setDisableContinuar(true);
      }
    } else {
      setDisableContinuar(true);
    }
  }, [cpfOrCnpj]);

  const onPressKeyboard = (key: string) => {
    if (cpfOrCnpj.length < 14) {
      setCpfOrCnpj((prev) => prev + key);
    }
  };

  const onPressBack = () => {
    setCpfOrCnpj((prev) => prev.slice(0, -1));
  };

  const onPressClear = () => {
    setCpfOrCnpj("");
  };

  const onClickNaoInformar = () => {
    onPressContinuar("");
  };

  const onClickContinuar = () => {
    onPressContinuar(cpfOrCnpj);
  };

  const onClickInformar = () => {
    setEnableTeclado(true);
  };

  const cpfMask = (cpf: string) => {
    return cpf
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  };

  const cnpjMask = (cnpj: string) => {
    return cnpj
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})$/, "$1-$2");
  };

  const testaCPF = (strCPF: string) => {
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;
    if (strCPF === "11111111111") return false;
    if (strCPF === "22222222222") return false;
    if (strCPF === "33333333333") return false;
    if (strCPF === "44444444444") return false;
    if (strCPF === "55555555555") return false;
    if (strCPF === "66666666666") return false;
    if (strCPF === "77777777777") return false;
    if (strCPF === "88888888888") return false;
    if (strCPF === "99999999999") return false;

    for (let i = 1; i <= 9; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++)
      Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
  };

  const testaCNPJ = (cnpj: string) => {
    cnpj = cnpj.replace(/[^\d]+/g, "");

    if (cnpj.length !== 14) return false;

    if (
      cnpj === "00000000000000" ||
      cnpj === "11111111111111" ||
      cnpj === "22222222222222" ||
      cnpj === "33333333333333" ||
      cnpj === "44444444444444" ||
      cnpj === "55555555555555" ||
      cnpj === "66666666666666" ||
      cnpj === "77777777777777" ||
      cnpj === "88888888888888" ||
      cnpj === "99999999999999"
    )
      return false;

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
  };

  return (
    <div className="inputCpf">
      <div className="description-inputCpf">Deseja CPF ou CNPJ na nota?</div>
      {enableTeclado && (
        <>
          <div className="value-inputCpf">
            {cpfOrCnpj.length > 11 ? cnpjMask(cpfOrCnpj) : cpfMask(cpfOrCnpj)}
          </div>
          <div style={{ color: "#f55", fontSize: "small" }}>{erro}</div>

          <TecladoVirtual
            type="clear"
            onPressBack={onPressBack}
            onKeyPress={onPressKeyboard}
            onPressClear={onPressClear}
          />
        </>
      )}
      <div className="buttons-inputCpf">
        <Button
          onClick={onClickNaoInformar}
          className="button-inputCpf nao-informar-inputCpf"
        >
          {enableTeclado ? "NÃO INFORMAR" : "NÃO"}
        </Button>
        {enableTeclado ? (
          <Button
            onClick={onClickContinuar}
            disabled={disableContinuar}
            className={
              disableContinuar
                ? "button-inputCpf continuar-inputCpf disabled-continuar-inputCpf"
                : "button-inputCpf continuar-inputCpf enable-continuar-inputCpf"
            }
          >
            CONTINUAR PAGAMENTO
          </Button>
        ) : (
          <Button
            onClick={onClickInformar}
            className={"button-inputCpf continuar-inputCpf"}
          >
            SIM
          </Button>
        )}
      </div>
    </div>
  );
};
