import { Button, Icon } from '@material-ui/core'
import React, { FC } from 'react'
import { BotaoGrande } from '../../../../../components/botaoGrande/BotaoGrande'
import { IScheduleItemCart } from '../../../interfaces/IScheduleItemCart'
import styles from './ListScheduleCart.module.scss'
export interface IListScheduleCartProps {
    //propertys
    scheduleCartItems: IScheduleItemCart[];
    onClickContinuar: () => void;
    onClickRemove: (scheduleItemCart: IScheduleItemCart) => void;
    onClickClose: () => void;
    onClickSelecionarMais: () => void;
}
const ListScheduleCart: FC<IListScheduleCartProps> = ({ scheduleCartItems, onClickContinuar, onClickRemove, onClickClose, onClickSelecionarMais }) => {
    return (
        <div id={styles.ListScheduleCart} >
            {/* <h1>Carrinho</h1> */}
            <div className={styles.container} >
                {scheduleCartItems.map((item) =>
                (
                    <div className={styles.item}>
                        <div className={styles.column}>
                            <div className={styles.row}>
                                <div className={styles.colaborator}>
                                    {item.colaborator.name}
                                </div>
                                <div className={styles.horario}>
                                    {item.dateTime.toLocaleString('pt-BR')}
                                </div>
                            </div>
                            <div className={styles.row}>
                                <div className={styles.service}>
                                    {item.service.name}
                                </div>
                                <div className={styles.value}>
                                    R${item.colaboratorSchedule.serviceValue.toFixed(2).replace(".", ",")}
                                </div>
                            </div>
                        </div>
                        <div onClick={() => onClickRemove(item)} className={styles.removeButton}>
                            <Icon>close</Icon>
                        </div>
                    </div>
                )
                )}
                <div>
                    <BotaoGrande onClick={onClickSelecionarMais}>Escolher mais serviços</BotaoGrande>
                    <BotaoGrande onClick={onClickContinuar}>Ir para Pagamento</BotaoGrande>
                </div>
            </div>
        </div>
    )
}
export default ListScheduleCart