import { Order } from "domains/order/agreggates/order/Order"
import { FinancialTransactionModel } from "domains/order/agreggates/payment/FinancialTransactionModel"
import { IAddReserveScheduleWithoutPaymentResponse } from "modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentResponse"
import { ReserveTour } from "modules/tour/domain/models/ReserveTour"
import { IReserveTourLogRepository } from "../../interfaces/IReserveTourLogRepository"

const AddLogTourUseCase = async (reserveTourLogRepository: IReserveTourLogRepository,
    reserve: ReserveTour,
    productSync: IAddReserveScheduleWithoutPaymentResponse,
    transaction?: FinancialTransactionModel) => {
  
    console.log("ADD TOUR LOCALSTORAGE 1")

    const response = await reserveTourLogRepository.add({
        reserve,
        productSync,
        transaction
    });
  
    return response
}

export default AddLogTourUseCase
