
export enum TransactionType {
  credit = 1,
  debit = 2,
  money = 3,
  cashless = 4,
  pospaid = 5,
  misto = 6,
  voucher = 14,
  pix = 22
}
