import { Drawer, Icon } from '@material-ui/core'
import React, { FC, useEffect, useState } from 'react'
import { ICollaborator } from '../../../../domains/schedule/agreggates/ICollaborator'
import styles from './PickerColaborator.module.scss'
import ButtonPickerSchedule from '../buttonPicker/ButtonPickerSchedule'
import { ITimeItem } from '../../interfaces/ITimeItem'
import ScheduleUseCase from '../../ScheduleUseCase'
import { differenceInMinutes } from 'date-fns/esm'
import { TimeStatus } from '../../interfaces/TimeStatus'
export interface IPickerColaboratorProps {
    //propertys
    autoSelectSchedule?: boolean;
    colaborators: ICollaborator[];
    value: ICollaborator | null;
    onChange: (colaborator: ICollaborator) => void;
    onClickSelectNext?: () => void
}
const PickerColaborator: FC<IPickerColaboratorProps> = ({
    colaborators,
    value,
    onClickSelectNext,
    onChange,
    autoSelectSchedule
}) => {
    const [openList, setopenList] = useState(false)


    useEffect(() => {
        if (!!colaborators.length) {
            setopenList(true)
        }
    }, [colaborators])

    const closeListHandle = () => {
        setopenList(false)
    }

    const openListHandle = () => {
        setopenList(true)
    }

    const onClickItem = (colaborator: ICollaborator) => {
        onChange(colaborator);
        closeListHandle();
    }

    const onClickNext = () => {
        onClickSelectNext && onClickSelectNext();
        closeListHandle()
    }



    return (
        <div id={styles.PickerColaborator} >
            <div className={styles.botao} >
                <ButtonPickerSchedule onClick={openListHandle} selected={!!value}>
                    {value ? value.name :
                        "Selecionar Profissional"}
                    <Icon fontSize="large" className={styles.editIcon}>edit</Icon>

                </ButtonPickerSchedule>
            </div>
            <Drawer anchor={"bottom"} open={openList} onClose={closeListHandle}>
                <div id={styles.drawerColaborator}>
                    <h1>Selecione um profissional</h1>
                    <div className={styles.lista}>
                        {
                            onClickSelectNext && autoSelectSchedule &&
                            <div style={{ animationDelay: `${20}ms` }} onClick={onClickNext} className={styles.item}>Próximo Disponivel</div>
                        }
                        {
                            colaborators.map((colaborator, index) => (
                                <ColaboratorItem colaborator={colaborator} index={index} onClickItem={onClickItem} />
                            ))
                        }
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
export default PickerColaborator



export const ColaboratorItem: FC<{ colaborator: ICollaborator, index: number, onClickItem(colaborator: ICollaborator): void }> = ({ colaborator, index, onClickItem }) => {

    const [tempoRestante, setTempoRestante] = useState<Number>()

    useEffect(() => {
        const { getNextAvailableScheduleColaborator } = ScheduleUseCase();

        getNextAvailableScheduleColaborator(colaborator, new Date()).then((response) => {
            if (response) {
                console.log(response.time.status);
                if (response.time.status === TimeStatus.available) {
                    setTempoRestante(differenceInMinutes(response.time.time, new Date()))
                }
            } else {
                setTempoRestante(-1)
            }

        })
    }, [])

    return (
        <div
            style={{ animationDelay: `${20 * index}ms` }}
            onClick={() => onClickItem(colaborator)}
            className={styles.item}>
            <div>{colaborator.name}</div>
            { tempoRestante && <div style={{ fontSize: 16 }}> {tempoRestante !== -1 ? (tempoRestante < 10 ? "Disponivel" : `Disponivel em ${tempoRestante} minutos`) : `Não possui horários hoje`} </div>}
        </div>
    )
}
