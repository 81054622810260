import React, { FC } from 'react'
import { ICpfRegisterPrinterLayout } from './CpfRegisterLayoutPrinter.type'
import QRCode from 'qrcode.react'
import styles from './CpfRegisterLayoutPrinter.module.scss'
import { hideCpf } from 'utils/utils'
interface CpfRegisterLayoutPrinter {
    registerPrinterData: ICpfRegisterPrinterLayout
}

const CpfRegisterLayoutPrinter: FC<CpfRegisterLayoutPrinter> = ({ registerPrinterData: registerPrinterData }) => (
    <div id={styles.CpfRegisterLayoutPrinter}>
        <div className={styles.container}>
            <div className="logo-ResumoLayoutPrinter">
                {/* <img width={150} src={require('./../../../assets/images/logo-meep.png')}></img> */}
            </div>
            <div className={styles.user}>
                <h1>Bem-vindo</h1>
                <div className={styles.item}>
                    <h2>{registerPrinterData.name}</h2>
                </div>
                <div className={styles.item}>
                    <h2>{hideCpf(registerPrinterData.cpf)}</h2>
                </div>
                <div className={styles.item}>
                    <h2>{registerPrinterData.message}</h2>
                </div>
            </div>
            {/* <div className={styles.qrCode}>
                <QRCode
                    className="qrCode" size={200} value={registerPrinterData.id} />
            </div> */}
        </div>
    </div>
)

export default CpfRegisterLayoutPrinter
