import React, { FC, useState, useEffect, memo } from 'react'
import { Tabs, Tab } from '@material-ui/core';
import styles from './TabCategoria.module.scss'
interface TabCategoriaProps {
    list: {
        imageUrl?: string
        title: string
    }[],
    onClick: (index: number) => void
    position?: number
    hideImage?: boolean
}

export const TabCategoria: FC<TabCategoriaProps> = memo(({ position, list, onClick, hideImage }) => {
    const [categoriaIndex, setcategoriaIndex] = useState(position);
    const [disabledImage, setDisabledImage] = useState(false)
    useEffect(() => {
        setcategoriaIndex(position)
    }, [position])

    const onImgError = (ev: React.ChangeEvent<HTMLImageElement>) => {

        console.log("imageError")
        ev.target.hidden = true
        setDisabledImage(true)

    }

    return (
        <Tabs
            className={styles.Tabs}
            value={categoriaIndex}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, newValue) => { onClick(newValue); setcategoriaIndex(newValue) }}
            aria-label="simple tabs example"
            variant="scrollable"
            scrollButtons="off"
        >
            {list.map((produtoSeccionado, index) =>
                <Tab
                    key={index}
                    id={`simple-tab-${index}`}
                    aria-controls={`simple-tabpanel-${index}`}
                    value={index}
                    label={
                        <div className={styles.Tab} >
                            {
                                !disabledImage && !hideImage &&
                                <img
                                    onErrorCapture={onImgError}
                                    className={styles.TabImage}
                                    src={produtoSeccionado.imageUrl}
                                />
                            }
                            <div>{produtoSeccionado.title}</div>
                        </div>
                    }
                // classes={{ root: "TabRoot", }}
                >
                </Tab>
            )}
        </Tabs>
    )
})