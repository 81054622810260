import React, { FC, useState } from 'react'
import TourLayout from '../components/layout/TourLayout'
import styles from './TourPage.module.scss'
import UseTourPage from './UseTourPage'
import CategoryCardItem from '../components/categoryCard/CategoryCardItem'
import { Button } from '@material-ui/core'
import TourCart from '../components/tourCart/TourCart'
import DialogSelectPaymentType from '../components/dialogSelectPaymentType/DialogSelectPaymentType'
import { StoreTour } from 'modules/tour/domain/models/CatalogTour'
import BannerGramadoAboutProject from 'assets/images/projeto.png'
export interface ITourPageProps {
    //propertys
}

const aboutProject: StoreTour = {
    id: '',
    imageUrl: BannerGramadoAboutProject,
    name: 'Conheça o projeto',
    categoryTours: []
}

const TourPage: FC<ITourPageProps> = () => {
    const { catalogTour, onPressBack, onClickItem, onClickGoToPaymentHandle, onClickAboutProject } = UseTourPage();
    return (
        <div id={styles.TourPage} >
            <TourLayout onPressBack={onPressBack} title={catalogTour?.name} backgroundUrl={catalogTour?.imageUrl}>
                <div className={styles.categoryList} >
                    {catalogTour?.storeTours.map((item, index) => {
                        const _onClick = () => onClickItem(item.id);
                        return (
                            !item.name.includes("**") &&
                            <div key={index} className={styles.item} >
                                <CategoryCardItem
                                    imageUrl={item.imageUrl}
                                    title={item.name}
                                    onPress={_onClick}
                                />
                            </div>
                        )
                    })}
                    <div className={styles.item}>
                        <CategoryCardItem onPress={onClickAboutProject} imageUrl={aboutProject.imageUrl} title={aboutProject.name} />
                    </div>
                </div>
                <TourCart
                    onClickPayment={onClickGoToPaymentHandle}
                />
            </TourLayout>
        </div>
    )
}
export default TourPage