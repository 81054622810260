import { TransactionType } from 'domains/order/agreggates/payment/transactionType';
import { CefSharpPaymentRepository } from 'Infrastructure/services/cefSharpService/local/CefSharpPaymentRepository';


const paymentService = CefSharpPaymentRepository();

const PaymentTourReserveWithPinpad = async (value: number, transactionType: TransactionType = TransactionType.credit) => {
    const response = await paymentService.payAsync(value, transactionType);
    return (response)
}

export default PaymentTourReserveWithPinpad
