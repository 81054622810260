export interface Enviroment {
  apiUrl: string;
  isMock: boolean;
  appVersion: string;
  appInsightsConString: string;
}
const enviroment = {
  apiUrl: process.env.REACT_APP_API_URL,
  isMock: process.env.REACT_APP_IS_MOCK as unknown as boolean,
  appVersion: process.env.REACT_APP_VERSION,
  appInsightsConString: process.env.REACT_APP_CON_STRING
} as Enviroment;
export default enviroment;

//https://create-react-app.dev/docs/adding-custom-environment-variables/
