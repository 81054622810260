import { Kiosk, SystemType } from "domains/kiosk/aggregates/Kiosk";
import { ICartItem } from "modules/cart/domain/models/ICart";
import { ProdutoTipo } from "modules/order/domain/models/IOrder";
import { roundValue } from "utils/utils";

export const CalcServiceFeeUseCase = (
  cartItems: ICartItem[],
  kiosk: Kiosk | null,
  totals: {
    totalQuantity: number;
    totalPrice: number;
}
) => {
  if (
    kiosk &&
    kiosk.serviceRate &&
    kiosk.configSymstem.find((it) => it.systemType === SystemType.prepayTicket)
  ) {
    const totalPriceProduct = cartItems
      .map((item) => {
        return (
          item.isWeight
            ? item.totalPrice
            : item.productType !== ProdutoTipo.TaxaServico && !item.noServiceFee
            ? item.totalPrice * item.quantity
            : 0

        )
      }
      )
      .reduce((prev, current) => prev + current, 0);

      return roundValue(kiosk.serviceRatePercent * totalPriceProduct);
  } else {
    return 0;
  }
};
