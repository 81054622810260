import React, { FC, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import "./ToastProduct.css";
import { Product } from "../../../../../domains/catalog/aggregates/Product";
interface ToastProdutoProps {
  product: Product | null;
  onClose: () => void;
  open: boolean;
}

export const ToastProduto: FC<ToastProdutoProps> = ({
  product,
  onClose,
  open,
}) => {
  useEffect(() => {
    let time: NodeJS.Timer;
    if (product) {
      time = setTimeout(() => {
        onClose();
      }, 3000);
    }
    return () => {
      clearTimeout(time);
    };
  }, [product, onClose]);


  const onImgError = (ev: React.ChangeEvent<HTMLImageElement>) => {

    console.log("imageError")
    ev.target.hidden = true
  }
  return (
    <>
      <CSSTransition
        in={open}
        timeout={500}
        classNames={"anim-toastProduto"}
        unmountOnExit
        mountOnEnter
      >
        <div className={"toastProduto"}>
          <div className={"container-toastProduto"}>
            {product && (
              <>
                {/* <img
                  onErrorCapture={onImgError}
                  className={"image-container-toastProduto"}
                  src={product.thumbnailUrl}
                /> */}
                <div className={"info-toastProduto"}>
                  <div className={"nome-toastProduto"}>{product.name}</div>
                  <div className={"text-toastProduto"}>
                    Foi adicionado ao seu carrinho
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
