import { ErrorCodeType, IError, IErrorType } from "application/models/IError";

export const GetError = (
  error: any,
  caller: string,
  file: string,
  defaultMessage?: string,
  defaultCode?: ErrorCodeType,
): IError[] => {
  console.log(`[GET_ERROR][${caller} - ${file}]`, { error });

  const errorList: IError[] = [];
  let message = error?.message ?? defaultMessage ?? 'General Error';
  let code = defaultCode ?? ErrorCodeType.GENERAL;
  let stack: string | undefined;

  if ((error as IErrorType)[0]?.message !== undefined) {
    message = (error as IErrorType)[0].message;
  } else if (Array.isArray(error)) {
    errorList.concat(error);
  } else if (error instanceof Error) {
    message = error.message;
    stack = error.stack;
  } else if ((error as IError).code !== undefined) {
    const parsed = error as IError;
    message = parsed.message;
    code = parsed.code;
  }

  errorList.unshift({
    code,
    message,
    file,
    caller,
    stack,
    name: message,
  });

  return errorList;
};
