import React, { useCallback, useEffect, useState } from 'react'
import { CustomerReserveForm, ReserveTourFormValue } from './UseReserverTourForm.type';
import { useParams } from 'react-router-dom';
import { useCatalogTour } from '../../contexts/TourCatalogContext';
import { TourReserveForm } from './ReserveTour.type'
import { v4 } from 'uuid';
// import { toast } from 'react-toastify';
import { useUi } from 'application/contexts/ui/UIContext';
import GetUserDataByCpfUseCase from 'modules/tour/application/useCases/tour/GetUserDataByCpfUseCase';
import { ICartTourItem } from '../tourCart/ICartTourItem';

const UseReserverTourForm = (tour: TourReserveForm, onPressContinuar: (values: ReserveTourFormValue) => void, cartTourItems: ICartTourItem[]) => {
    const [date, setDate] = useState<Date | null>(null);
    const [time, setTime] = useState<{ start: string, end: string, vacancies: number }>();
    const [total, setTotal] = useState<number>(0);
    const { toast } = useUi()
    const [totalValue, setTotalValue] = useState(0)

    const [localVacancies, setLocalVacancies] = useState(time?.vacancies ?? 0)

    const [customers, setCustomers] = useState<CustomerReserveForm[]>([{
        id: v4(),
        name: "",
        birthday: "",
        cpf: "",
        phone: "",
        rg: "",
        note: "",
        price: { description: "", price: 0, minAge: 0, maxAge: 0 }
    }]);


    useEffect(() => {
        const total = customers.map(item => item.price?.price ?? 0).reduce((prev, current) => prev + current, 0)
        setTotalValue(total)
    }, [customers])

    useEffect(() => {
        if (time) {
            const cartItem = cartTourItems.find(item => item.id === tour.id)
            const vacancie = time.vacancies - (cartItem?.reserveTour.customers.length ?? 0)
            setLocalVacancies(vacancie);
        }

        return () => {

        }
    }, [cartTourItems, time, tour.id])


    const validate = useCallback(
        () => {
            if (!date) {
                toast("Selecione uma data", "error");
                return false;
            }
            if (!customers.length) {
                return false;
            }
            const containError = customers.map((item, index) => {
                if (!item.cpf) {
                    toast("Insira o CPF", "error");
                    return true
                }
                if (!TestaCPF(item.cpf)) {
                    toast("Insira um CPF válido", "error");
                    return true
                }
                if (!item.name) {
                    toast("Insira o nome", "error");

                    return true
                }
                if (!item.birthday) {
                    toast("Insira a data de nascimento", "error");

                    return true
                }
                if (!item.phone) {
                    toast("Insira um telefone para contato", "error");
                    return true
                }
                if (!item.note) {
                    toast("Insira o hotel que você está hospedado", "error");
                    return true
                }
                if (!item.rg) {
                    toast("Insira um RG ou Certidão de nascimento", "error");
                    return true
                }
                if (item.price?.description === "Selecionar" || item.price?.description === "" || !item.price?.description) {
                    toast("Selecione um valor", "error");
                    return true
                }
                return false
            }).find(item => item)

            if (containError) {
                return false
            }
            return true;

        },
        [customers, date, toast],
    )

    const changePriceHandle = useCallback((index: number, value: string) => {
        setCustomers(prev => (prev.map((_item, _index) => {
            if (index === _index) {
                return {
                    ..._item, price: tour.prices.find(price => price.description === value) ?? { description: "", price: 0, minAge: 0, maxAge: 0 }
                }
            } else {
                return {
                    ..._item
                }
            }
        })))
    }, [tour]);

    const changeDateHandle = useCallback((value: Date) => {
        setDate(value);
    }, []);



    const onChangeCustomer = useCallback((newValue: string, inputName: string, index: number) => {
        console.log(newValue)
        setCustomers(prev =>
            prev.map((item, _index) => {
                if (_index === index) {
                    switch (inputName) {
                        case "name":
                            return { ...item, name: newValue }
                        case "cpf":
                            autoCompleteCPF(newValue, index)
                            return { ...item, cpf: newValue }
                        case "birthday":
                            return {
                                ...item,
                                birthday: newValue,
                                price: { description: "", price: 0, minAge: 0, maxAge: 0 }
                            }
                        case "phone":
                            return { ...item, phone: newValue }
                        case "note":
                            return { ...item, note: newValue }
                        case "rg":
                            return { ...item, rg: newValue }
                        default:
                            return { ...item }

                    }
                } else {
                    return item;
                }
            })
        )
    }, []);


    const autoCompleteCPF = useCallback(
        (cpf: string, index: number) => {
            // if(cpf === "000"){
            //     setCustomers(prev => (
            //         prev.map((item, _index) => (index === _index ? {
            //             ...item,
            //             name: "TESTE",
            //             birthday: "29/05/1992",
            //             note: "hotel de teste",
            //             cpf: "11136397680",
            //             phone:"3199999999",
            //             rg:"13467998",
            //             price: { description: "", price: 0, minAge: 0, maxAge: 0 }
            //         } : item))
            //     )) 
            // }
            if (cpf.length === 14) {


                GetUserDataByCpfUseCase(cpf, tour.localId).then((response) => {
                    setCustomers(prev => (
                        prev.map((item, _index) => (index === _index ? {
                            ...item,
                            name: response.name,
                            birthday: response.birthday,
                            price: { description: "", price: 0, minAge: 0, maxAge: 0 }
                        } : item))
                    ))
                })
            }
        },
        [])

    const onChangeKeyboard = useCallback(
        (index: number, name: string, newValue: string) => {
            onChangeCustomer(newValue, name, index)
        },
        [onChangeCustomer],
    )

    const onPressNewCustomer = useCallback(() => {
        if (time) {
            if (customers.length + 1 <= time.vacancies) {
                setCustomers(prev => ([...prev, {
                    id: v4(),
                    name: "",
                    birthday: "",
                    cpf: "",
                    phone: "",
                    rg: "",
                    note: !!prev.length ? prev[0].note : "",
                    price: { description: "", price: 0, minAge: 0, maxAge: 0 }
                }]))
            } else {
                toast(`Não é possivel adicionar mais clientes pois o evento possui apenas ${time.vacancies} vaga restante`, "error")
            }
        }
    }, [customers.length, time, toast])

    const onPressDeleteCustomerByIndex = useCallback((index: number) => {
        setCustomers(prev => prev.filter((item, _index) => index !== _index))
    }, [])

    const onPressContinuarHandle = useCallback(() => {
        if (validate()) {
            if (customers && date && time) {
                onPressContinuar({
                    totalPrice: total,
                    customers: customers,
                    date: date.toLocaleDateString('pt-BR'),
                    time: time.start
                })
            }
        }
    }, [validate, customers, date, onPressContinuar, total, time]);


    useEffect(() => {
        const total = customers.map(item => item.price?.price ?? 0).reduce((prev, current) => prev + current, 0)
        setTotal(total);
    }, [customers])


    return (
        {
            date,
            customers,
            changeDateHandle,
            onChangeCustomer,
            onChangeKeyboard,
            onPressNewCustomer,
            onPressDeleteCustomerByIndex,
            changePriceHandle,
            onPressContinuarHandle,
            validate,
            time,
            setTime,
            totalValue,
            localVacancies
        }
    )
}

export default UseReserverTourForm


export const cpfMaks = (cpf: string) => {

    let newValue = cpf.substr(0, 3) + (cpf.length > 3 ? "." + cpf.substr(3, 3) : "") + (cpf.length > 6 ? "." + cpf.substr(6, 3) : "") + (cpf.length > 8 ? "-" + cpf.substr(9, 2) : "")
    // 111.333.444-80
    return newValue
}


const TestaCPF = (strCPF: string) => {
    strCPF = strCPF.replace(".", "").replace(".", "").replace(".", "").replace("-", "")
    var Soma;
    var Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;
    if (strCPF === "11111111111") return false;
    if (strCPF === "22222222222") return false;
    if (strCPF === "33333333333") return false;
    if (strCPF === "44444444444") return false;
    if (strCPF === "55555555555") return false;
    if (strCPF === "66666666666") return false;
    if (strCPF === "77777777777") return false;
    if (strCPF === "88888888888") return false;
    if (strCPF === "99999999999") return false;

    for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(9, 10))) return false;

    Soma = 0;
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
    Resto = (Soma * 10) % 11;

    if ((Resto === 10) || (Resto === 11)) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11))) return false;
    return true;
}