import React, { FC, useCallback, useRef, useState } from "react";
import { Icon } from "@material-ui/core";
import "./SelectCardType.css";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { TransactionType } from "domains/order/agreggates/payment/transactionType";
import ActionModal, { IActionModalHandler } from "components/actionModal/ActionModal";

const attention = require('../../../../../assets/images/attention.gif');

interface SelectCardTypeProps {
  kiosk: Kiosk;
  onSelect: (value: TransactionType) => void;
  rechargePayment?: boolean
}
export const SelectCardType: FC<SelectCardTypeProps> = ({
  onSelect,
  kiosk,
  rechargePayment
}) => {
  const actionModalRef = useRef<IActionModalHandler>(null);

  const onSelectType = useCallback(
    async (type: TransactionType) => {
      try {
        await actionModalRef.current?.open();
        onSelect(type);
      } catch (error) {
        console.log(error);
      }
    },
    [onSelect],
  )
  
  

  return (
    <div className="select-card-type">
      <div className="buttonsContainer-select-card-type">
        {
          kiosk?.paymentCredit &&
          <div
            onClick={() => onSelectType(TransactionType.credit)}
            className="button-select-card-type"
          >
            <Icon>payment</Icon>
            <p> Crédito</p>
          </div>
        }
        {
          kiosk?.paymentDebit &&
          <div
            onClick={() => onSelectType(TransactionType.debit)}
            className="button-select-card-type"
          >
            <Icon>payment</Icon>
            <p> Débito</p>
          </div>
        }
        {
          kiosk?.paymentVoucher &&
          <div
            onClick={() => onSelectType(TransactionType.voucher)}
            className="button-select-card-type"
          >
            <Icon>payment</Icon>
            <p> Voucher</p>
          </div>
        }
        {
          kiosk?.paymentPix &&
          <div
            onClick={() => onSelectType(TransactionType.pix)}
            className="button-select-card-type"
          >
            <Icon>pix</Icon>
            <p>Pix</p>
          </div>
        }
        {
          (kiosk?.paymentCashless /* && !isPreOrPos()*/) && !rechargePayment &&
          <div
            onClick={() => onSelectType(TransactionType.cashless)}
            className="button-select-card-type"
          >
            <Icon>payment</Icon>
            <p> Cartão/Pulseira Cashless</p>
          </div>
        }
      </div>
      <ActionModal 
        ref={actionModalRef}
        confirmTitle="Continuar"
        image={attention}
        message="Após o pagamento será necessário gravar o novo saldo em seu cartão/pulseira."
        title="ATENÇÃO!"
        hideCloseButton
      />
    </div >
  );
};
