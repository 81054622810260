import { Drawer, Icon } from '@material-ui/core';
import ButtonPicker from 'components/buttonPicker/ButtonPickerSchedule';
import React, { FC, useState, memo, useEffect } from 'react'
import styles from "./TimeSelect.module.scss"

interface TimeSelectProps {
    times: { start: string, end: string, vacancies: number }[]
    value?: { start: string, end: string, vacancies: number },
    onChange?: (values: { start: string, end: string, vacancies: number }
    ) => void,
    localVacancies: number
}

const TimeSelect: FC<TimeSelectProps> = memo(({ times, value, onChange, localVacancies }) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (times.length === 1) {
            onChange && onChange(times[0])
        }
    }, [times, onChange])
    return (
        <div id={styles.TimeSelect}>
            <ButtonPicker selected={!!value} onClick={() => setOpen(true)} /*className={styles.container}*/>
                {
                    value ? <div>
                        <div>{`A partir de ${value.start.substr(0, 5)} até as ${value.end.substr(0, 5)}`}</div>
                        {/* <div>{ `vagas: ${value.vacancies}`}</div> */}
                        <div>{localVacancies === 1 ?
                            <span style={{ color: "#a00d", display: 'flex', alignItems: "center" }}><Icon fontSize={"large"}>error_outline</Icon>Última vaga</span>
                            :
                            <span style={{ color: "#a00d", display: 'flex', alignItems: "center" }}><Icon fontSize={"large"}>error_outline</Icon>Últimas vagas restantes</span>
                        }</div>
                    </div>
                        :
                        "Selecione um horário"
                }
            </ButtonPicker>
            <Drawer anchor="bottom" open={open && times.length > 1} onClose={() => setOpen(false)}>
                <div className={styles.drawer}>
                    <h2> Selecione um horário </h2>
                    {times.map((item, index) => (
                        <div key={index} onClick={() => { onChange && onChange(item); setOpen(false) }} className={styles.timeItem}>{item.start}</div>
                    ))}
                </div>
            </Drawer>
        </div>
    )
})

export default TimeSelect
