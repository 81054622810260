import { AddPlaceDemandScheduleRequestDTO } from "modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentRequest"
import { IAddReserveScheduleWithoutPaymentResponse } from "modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentResponse"
import { TourDetail } from "modules/tour/domain/models/CatalogTour"
import { IReserverTourFormProps } from "modules/tour/presentation/components/reserveTourForm/ReserverTourForm"
import { ReserveTourFormValue } from "modules/tour/presentation/components/reserveTourForm/UseReserverTourForm.type"
import { ITourRepository } from "../../interfaces/ITourRespository"

const PostScheduleReservetWithoutPaymentUseCase = async (tourRepository: () => ITourRepository, reserve: ReserveTourFormValue, tourDetail: TourDetail):Promise<IAddReserveScheduleWithoutPaymentResponse> => {
    const [day, mounth, year] = reserve.date.split("/")
    const [hour, minute, second] = reserve.time.split(":")



    const resquest: AddPlaceDemandScheduleRequestDTO = {
        services: reserve.customers.map((customer) => {
            

            const [_day, _mounth, _year] = customer.birthday.split("/")

            return({
            serviceScheduledId: tourDetail.scheduledId,
            start: (new Date(Number(year), Number(mounth )-1, Number(day), Number(hour), Number(minute) + 2, Number(second), 0)).toISOString(),
            client: {
                birthDate: `${_year}-${_mounth}-${_day}`,
                document: customer.cpf + " / " + customer.rg,
                name: customer.name,
                phone: customer.phone,
                note: customer.note
            }
        })})
    }
    const response = await tourRepository().postAddResevePlaceSchedule(resquest);
    return response
}

export default PostScheduleReservetWithoutPaymentUseCase
