import React, { FC, useCallback, useState } from 'react'
import { OrderItem } from '../../../../../domains/order/agreggates/order/OrderItem'
import { Grid, Container, IconButton, Icon, Paper, Modal, Dialog, Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import "./ProdutoPedidoPosPreview.css"
import { ProdutoPedidoPos } from 'Infrastructure/repositories/api/PedidoMeep';
import { ProdutoPedidoPosOrderLog } from 'domains/order/agreggates/order/Order';
import { Operator } from 'domains/admin/aggregates/operator/Operator';
interface ProdutoPedidoPosPreviewProps {
    produtoPedidoPosPreview?: ProdutoPedidoPosOrderLog;
    indice?: boolean
    operators?: Operator[],
    currentOperator?: Operator | null,
    onClickPrint?: () => void,
    permissionPrint?: boolean
    isTicketPrinter?: boolean
    isPaid?: boolean
}
export const ProdutoPedidoPosPreview: FC<ProdutoPedidoPosPreviewProps> = ({ isPaid, produtoPedidoPosPreview, operators, currentOperator, indice, onClickPrint, permissionPrint, isTicketPrinter }) => {

    const [countReprint, setcountReprint] = useState(0)
    const [openAlert, setOpenAlert] = useState(false)

    const onClickReprintHandle = useCallback(() => {
        setcountReprint(prev => prev + 1);
        setOpenAlert(false);
        onClickPrint?.()
    }, [onClickPrint])

    return (
        <>
            <Grid container lg={12} item className="content-ProdutoPedidoPosPreview">
                <Grid sm={4} item className="nome-ProdutoPedidoPosPreview">
                    {
                        indice ?
                            "Nome"
                            : produtoPedidoPosPreview?.nome

                    }
                </Grid>
                <Grid sm={4} item className="total-ProdutoPedidoPosPreview">
                    {
                        indice ?
                            "Preço"
                            : "R$" + produtoPedidoPosPreview?.valor.toFixed(2).replace(".", ",")

                    }
                </Grid>

                <Grid sm={1} item className="actions-ProdutoPedidoPosPreview">
                    {
                        indice ?
                            "Ações"
                            :
                            isPaid && permissionPrint && isTicketPrinter && produtoPedidoPosPreview?.nome !== "Pagamento" && <> < IconButton onClick={() => setOpenAlert(true)}><Icon>print</Icon> {(produtoPedidoPosPreview?.reprinted?.length ?? 0) + countReprint}</IconButton>
                            </>
                    }
                </Grid>
            </Grid >
            {/* <div>{produtoPedidoPosPreview?.ticketId}</div> */}
            <Dialog open={openAlert} >
                <DialogTitle id="alert-dialog-title">
                    Atenção!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <b>Tem certeza que deseja reimprimir esta ficha?</b>

                        <div><b>operador: {currentOperator?.name}</b></div>
                        <div>produto: {produtoPedidoPosPreview?.nomeProduto}</div>
                        <div>valor: {produtoPedidoPosPreview?.valor}</div>
                        {!!produtoPedidoPosPreview?.reprinted?.length &&
                            <div>
                                <b>
                                    Esta ficha já foi reimpressa {produtoPedidoPosPreview?.reprinted?.length} vezes por:
                                </b>
                                <div>
                                    {produtoPedidoPosPreview?.reprinted?.map(reprintedLog => (
                                        <div>
                                            {operators?.find(_operator => _operator.id === reprintedLog.operadorId)?.name} - {reprintedLog.dataRealizacao.toLocaleString()}
                                        </div>))
                                    }
                                </div>
                            </div>
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={"outlined"}  color={"secondary"} onClick={() => setOpenAlert(false)}>
                        Cancelar
                    </Button>
                    <Button variant={"contained"} color={"secondary"} onClick={onClickReprintHandle}>Reimprimir</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
