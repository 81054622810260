import { Modal } from "@material-ui/core";
import { PedidoPos } from "Infrastructure/repositories/api/PedidoMeep";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useUi } from "application/contexts/ui/UIContext";
import { Order } from "domains/order/agreggates/order/Order";
import {
  Payment,
  PixApprovedTransaction,
  PixPaymentRequest,
  PixPaymentResponse,
} from "domains/order/agreggates/payment/payment";
import { PaymentService } from "domains/order/services/paymentService";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { createDefferedPromise } from "utils/DefferedPromise";
import styles from "./DefferedPix.module.scss";
import ActionModal, {
  IActionModalHandler,
} from "components/actionModal/ActionModal";

const REQUEST_TIMER_DELAY = 5;
const PIX_EXPIRATION_TIMER = 180;

let requestDelayTimeout: NodeJS.Timeout;
let expirationTimeout: NodeJS.Timeout;

const paymentService = PaymentService();

export interface IDefferedPixHandler {
  open(order: Order): Promise<PixApprovedTransaction>;
}

interface IDefferedPixProps {
  addToOrderLog: (
    order: Order | null,
    message: string,
    pedidoPOS?: PedidoPos | undefined,
    payment?: Payment | undefined
  ) => Promise<void>;
}

const DefferedPix: React.ForwardRefRenderFunction<
  IDefferedPixHandler,
  IDefferedPixProps
> = ({ addToOrderLog }, ref) => {
  const deffered = useRef(createDefferedPromise<PixApprovedTransaction>());
  const actionModalRef = useRef<IActionModalHandler>(null);
  const actionModalExpirationTimeRef = useRef<IActionModalHandler>(null);

  const { toastFullscreen } = useUi();
  const { kiosk } = useKiosk();

  const [isActiveRequestTimer, setIsActiveRequestTimer] = useState(false);
  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState<Order>({} as Order);
  const [expirationCounter, setExpirationCounter] =
    useState(PIX_EXPIRATION_TIMER);
  const [isActiveExpirationTimer, setIsActiveExpirationTimer] = useState(true);
  const [pixStatus, setPixStatus] = useState<PixPaymentResponse | null>(null);
  const [requestTimerDelayCounter, setRequestTimerDelayCounter] =
    useState<number>(REQUEST_TIMER_DELAY);

  const startRequestTimer = useCallback(() => {
    setIsActiveExpirationTimer(true);
    setExpirationCounter(PIX_EXPIRATION_TIMER);
    setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
    setIsActiveRequestTimer(true);
  }, []);

  const resetExpirationTimer = useCallback(() => {
    setPixStatus(null);
    clearTimeout(expirationTimeout);
    setIsActiveExpirationTimer(false);
  }, []);

  const resetRequestTimer = useCallback(() => {
    clearTimeout(requestDelayTimeout);
    setIsActiveRequestTimer(false);
    setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
  }, []);

  useEffect(() => {
    if (isActiveRequestTimer && requestTimerDelayCounter > 0 && visible) {
      requestDelayTimeout = setTimeout(() => {
        setRequestTimerDelayCounter((prev) => prev - 1);
      }, 1000);
    } else if (isActiveRequestTimer && requestTimerDelayCounter === 0) {
      setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
    }

    return () => {
      clearTimeout(requestDelayTimeout);
    };
  }, [requestTimerDelayCounter, isActiveRequestTimer, visible]);

  const getPixStatus = useCallback(async (paymentId: string) => {
    try {
      const result = await paymentService.pixPaymentStatus(paymentId);
      const parseResponse: PixPaymentResponse = {
        ownerId: result.ownerId ?? result.OwnerId,
        paymentId: result.paymentId ?? result.Id,
        qrCodeBase64: result.qrCodeBase64 ?? result.QrCodeBase64,
        qrCodeLink: result.qrCodeLink ?? result.QrCodeLink,
        status: result.status ?? result.Status,
        value: result.value ?? result.Value,
        approvedAt: result.approvedAt ?? result.ApprovedAt,
        acquirerType: result.acquirerType ?? result.AcquirerType,
      };
      setPixStatus(parseResponse);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const checkExpiration = useCallback(async () => {
    try {
      setExpirationCounter(PIX_EXPIRATION_TIMER);
      pixStatus?.paymentId && getPixStatus(pixStatus.paymentId);
      await actionModalExpirationTimeRef.current?.open();
      console.log("prorrogar pagamento");
    } catch (error) {
      // console.log(error)
      console.log("cancel payment");
      pixStatus?.paymentId && (await getPixStatus(pixStatus.paymentId));
      resetExpirationTimer();
    }
  }, [getPixStatus, pixStatus, resetExpirationTimer]);

  useEffect(() => {
    if (isActiveExpirationTimer && expirationCounter > 0 && visible) {
      expirationTimeout = setTimeout(() => {
        setExpirationCounter((prev) => prev - 1);
      }, 1000);
    } else if (isActiveExpirationTimer && expirationCounter === 0) {
      checkExpiration();
    }

    return () => {
      clearTimeout(expirationTimeout);
    };
  }, [
    checkExpiration,
    expirationCounter,
    isActiveExpirationTimer,
    resetExpirationTimer,
    visible,
  ]);

  const cancelPixPayment = useCallback(() => {
    setVisible(false);
    resetExpirationTimer();
    deffered.current?.rejecter(new Error("Fechado pelo usuário ou tempo esgotado"));
  }, [resetExpirationTimer]);

  const onClickCancel = useCallback(async () => {
    try {
      pixStatus?.paymentId && getPixStatus(pixStatus.paymentId);
      await actionModalRef.current?.open();
    } catch (error) {
      // console.log(error)
    }
  }, [getPixStatus, pixStatus]);

  const successPix = useCallback(
    async (transaction: PixApprovedTransaction) => {
      try {
        deffered.current.resolver(transaction);
      } catch (error) {
        deffered.current?.rejecter(new Error("Falha ao pagar com pix"));
      }
    },
    []
  );

  useEffect(() => {
    if (isActiveRequestTimer && requestTimerDelayCounter === 0 && visible) {
      if (pixStatus && pixStatus.status === "pending") {
        setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
      } else {
        resetRequestTimer();
        // resetExpirationTimer();
        if (pixStatus?.status === "approved") {
          const transactionPix: PixApprovedTransaction = {
            ownerId: pixStatus.ownerId,
            paymentId: pixStatus.paymentId,
            status: pixStatus.status,
            value: pixStatus.value,
            acquirerType: pixStatus.acquirerType,
            approvedAt: pixStatus.approvedAt,
            createdAt: pixStatus.createdAt,
            qrCodeLink: pixStatus.qrCodeLink,
          };
          successPix(transactionPix);
          setVisible(false);
        }
        if (pixStatus?.status === "cancelled") {
          toastFullscreen("Pagamento cancelado", "error");
          cancelPixPayment();
        }
      }
    }
  }, [
    cancelPixPayment,
    requestTimerDelayCounter,
    isActiveRequestTimer,
    pixStatus,
    resetRequestTimer,
    successPix,
    expirationCounter,
    toastFullscreen,
    visible,
  ]);

  useEffect(() => {
    if (!isActiveExpirationTimer && visible) {
      addToOrderLog(order, "Tempo de pagamento pix esgotado");
      toastFullscreen("Tempo para pagamento do pix expirado.", "warning");
      cancelPixPayment();
    }
  }, [
    addToOrderLog,
    cancelPixPayment,
    isActiveExpirationTimer,
    order,
    toastFullscreen,
    visible,
  ]);

  useEffect(() => {
    if (
      isActiveRequestTimer &&
      requestTimerDelayCounter === 0 &&
      pixStatus &&
      pixStatus.paymentId &&
      visible
    ) {
      getPixStatus(pixStatus.paymentId);
    }
  }, [
    requestTimerDelayCounter,
    isActiveRequestTimer,
    pixStatus,
    visible,
    getPixStatus,
  ]);

  const getPixPayment = useCallback(
    async (localClientId: string, _order: Order) => {
      try {
        const payload: PixPaymentRequest = {
          Value: _order.totalValue,
          LocalClientId: localClientId,
          PixPaymentOrderId: _order.id,
        };
        let result: PixPaymentResponse;
        result = await paymentService.pixPayment(payload);
        if (!result) {
          await addToOrderLog(_order, "Falha ao pagar com pix");
          toastFullscreen("Falha ao pagar com pix", "error");
          cancelPixPayment();
        }
        const parseResponse: PixPaymentResponse = {
          ownerId: result.ownerId ?? result.OwnerId,
          paymentId: result.paymentId ?? result.Id,
          qrCodeBase64: result.qrCodeBase64 ?? result.QrCodeBase64,
          qrCodeLink: result.qrCodeLink ?? result.QrCodeLink,
          status: result.status ?? result.Status,
          value: result.value ?? result.Value,
          approvedAt: result.approvedAt ?? result.ApprovedAt,
          acquirerType: result.acquirerType ?? result.AcquirerType,
        };
        setPixStatus(parseResponse);
      } catch (error) {
        await addToOrderLog(
          _order,
          (error as any)?.response?.data?.message ?? "Falha ao pagar com pix"
        );
        toastFullscreen(
          (error as any)?.response?.data?.message ?? "Falha ao pagar com pix",
          "error"
        );
        cancelPixPayment();
      }
    },
    [addToOrderLog, cancelPixPayment, toastFullscreen]
  );

  const open = useCallback(
    (_order: Order) => {
      kiosk?.localId && getPixPayment(kiosk.localId, _order);
      startRequestTimer();
      setOrder(_order);

      setVisible(true);

      deffered.current = createDefferedPromise<PixApprovedTransaction>();
      return deffered.current.promise;
    },
    [getPixPayment, kiosk, startRequestTimer]
  );

  useImperativeHandle(ref, () => ({ open }));

  const [minuteLeft, minuteRight] = useMemo(
    () =>
      String(Math.floor(expirationCounter / 60))
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );
  const [secondLeft, secondRight] = useMemo(
    () =>
      String(expirationCounter % 60)
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );

  return (
    <Modal id={styles.DefferedPix} open={visible}>
      <div className={styles.container}>
        <div className={styles.pixBody}>
          {!pixStatus && (
            <>
              <p>Gerando QR Code...</p>
              <p>
                Enquanto isso, abra o app
                <br />
                do seu banco no celular
              </p>
            </>
          )}

          {pixStatus?.qrCodeBase64 && (
            <div className={styles.pixQrCodeContainer}>
              <p>Leia o QR Code:</p>
              <div className={styles.pixQrCode}>
                <img
                  src={`data:image/jpeg;base64,${pixStatus.qrCodeBase64}`}
                  alt="qrCode"
                />
              </div>
              <div className={styles.countdownContainer}>
                <div>
                  <span>{minuteLeft}</span>
                  <span>{minuteRight}</span>
                </div>
                <span>:</span>
                <div>
                  <span>{secondLeft}</span>
                  <span>{secondRight}</span>
                </div>
              </div>
              <p>
                Após isso, o QR Code irá expirar e você
                <br /> voltará para as formas de pagamento.
              </p>
            </div>
          )}
        </div>

        {order.totalValue && (
          <div className={styles.totalValue}>
            <p>Total:</p>
            <p>
              <b>R$ {order.totalValue.toFixed(2).replace(".", ",")}</b>
            </p>
          </div>
        )}

        {/* <BotaoGrande onClick={changePaymentType}>Alterar forma de pagamento</BotaoGrande> */}
        <div className={styles.cancelBotaoGrande} onClick={onClickCancel}>
          CANCELAR
        </div>
        <ActionModal
          ref={actionModalRef}
          confirmTitle="Cancelar"
          message='Esta ação irá cancelar este pagamento, não efetue o pagamento do pix se você clicar em "Cancelar"!'
          title="ATENÇÃO!"
          onClickConfirm={cancelPixPayment}
          closeButtonTile="Voltar"
          confirmationDelay={5}
          hideCloseButton
        />
        <ActionModal
          ref={actionModalExpirationTimeRef}
          confirmTitle="Prorrogar"
          message="Seu tempo esgotou, deseja prorrogar o tempo?"
          title="ATENÇÃO!"
          onClickConfirm={() => {}}
          closeButtonTile="Cancelar"
          hideCloseButton
        />
      </div>
    </Modal>
  );
};
export default forwardRef(DefferedPix);
