import React, { FC, useState, useEffect, useCallback, useMemo, memo } from 'react'
import "./TecladoVirtual.css"
import { Tecla } from './tecla/Tecla'
import { Icon, IconButton } from '@material-ui/core'

let objectTecladoNumerico: (string[])[] =
    [
        ["1", "2", "3"],
        ["4", "5", "6"],
        ["7", "8", "9"],
        ["back-space", "0", "check"],
    ]
let objectTecladoClear: (string[])[] =
    [
        ["1", "2", "3"],
        ["4", "5", "6"],
        ["7", "8", "9"],
        ["clear", "0", "back-space"],
    ]




let objectTeclado: (string[])[] = [
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-"],
    ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "back-space"],
    ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ç", "check"],
    ["z", "x", "c", "v", "b", "n", "m", ",", "."],
    [" "]
]
let uperCasseTeclado: (string[])[] = [
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "back-space"],
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Ç"],
    ["Z", "X", "C", "V", "B", "N", "M", ",", "."],
    [" "]
]
let uperCasseTecladoCheck: (string[])[] = [
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "back-space"],
    ["Q", "W", "E", "R", "T", "Y", "U", "I", "O", "P"],
    ["A", "S", "D", "F", "G", "H", "J", "K", "L", "Ç"],
    ["Z", "X", "C", "V", "B", "N", "M", ",", ".", "check"],
    [" "]
]
let emailobjectTeclado: (string[])[] = [
    ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0", "-", "_"],
    ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "back-space"],
    ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ç",],
    ["z", "x", "c", "v", "b", "n", "m", "@", ".", ".com", ".br"],
]


interface TecladoVirtualProps {
    onKeyPress?: (keyPressed: string) => void
    onPressEnter?: () => void
    onPressBack?: () => void
    onPressClear?: () => void
    type?: "numeric" | "text" | "clear" | "email" | 'upperCaseCheck' | "strongPassword"
    mapKey?: (string[])[]
    label?: string,
    onChange?: (inputValue: string) => void
    value?: string,
    onClose?: () => void
}
export const TecladoVirtual: FC<TecladoVirtualProps> = memo(({
    onKeyPress,
    onPressEnter,
    onPressBack,
    onPressClear,
    type,
    mapKey,
    onChange,
    value,
    label,
    onClose
}) => {
    const [teclas, setTeclas] = useState(mapKey ? mapKey : type === "numeric" ? objectTecladoNumerico : type === "clear" ? objectTecladoClear : type === "email" ? emailobjectTeclado : type === "upperCaseCheck" ? uperCasseTecladoCheck : uperCasseTeclado)



    useEffect(() => {
        setTeclas(mapKey ? mapKey : type === "numeric" ? objectTecladoNumerico : type === "clear" ? objectTecladoClear : type === "email" ? emailobjectTeclado : type === "upperCaseCheck" ? uperCasseTecladoCheck : uperCasseTeclado)
        return () => {
        }
    }, [mapKey, type]);




    const onPressKey = useCallback((key: string) => {

        if (key === "check") {
            if (onPressEnter) {
                onPressEnter();
            }
        } else if (key === "back-space") {
            onPressBack && onPressBack();
            if (value) {
                onChange && onChange(value.substr(0, value.length - 1));
            }
        } else if (key === "clear") {
            onPressClear && onPressClear();
            onChange && onChange("");
        } else {
            onKeyPress && onKeyPress(key)
            onChange && onChange(value + key);
        }

    }, [onKeyPress, onPressEnter, onPressBack, onChange, value, onPressClear])



    const keyboardRender = useMemo(() => () => (

        teclas.map((linha, _key) =>
        (
            <div key={_key} className={"lineTecladoVirtual"}  >
                {
                    linha.map(
                        (tecla, key) =>
                            <div key={key} style={{ flex: (tecla === "check" || tecla === "back-space") && (type !== "numeric" && type !== "clear" && type !== 'strongPassword') ? 2 : 1 }} className={"teclaContainerTecladoVirtual"}>
                                <Tecla onClick={onPressKey}>{tecla}</Tecla>
                            </div>
                    )
                }
            </div>
        )
        )


    ), [onPressKey, teclas, type])



    return (
        <div className="tecladoContainer" >
            {/* asdfasdf */}
            {
                onClose &&
                <div className="tecladoOnClose">
                    <IconButton onClick={onClose}><Icon fontSize={'large'}>close</Icon></IconButton>
                </div>}
            {
                label &&
                <div className="tecladoLabel">
                    {label}
                </div>}
            {
                value !== undefined &&
                <div className="tecladoValue">
                    {value}
                </div>
            }
            <div style={{ maxHeight: teclas.length * 85 + 80 }} className="tecladoKeys">
                {keyboardRender()}
            </div >
        </div >
    )
})
