import { Checkbox, Dialog } from '@material-ui/core'
import { TransactionType } from 'domains/order/agreggates/payment/transactionType'
import React, { FC, useEffect, useState } from 'react'
import styles from './ReserveTourResume.module.scss'
import { ReserveTour, Tour } from './ReserveTourResume.type'
export interface IReserveTourResumeProps {
    tour: Tour,
    reserve: ReserveTour,
    onPressIrParaPagamento?: (type: TransactionType) => void
    onPressAdicionar?: () => void
    //propertys
}
const ReserveTourResume: FC<IReserveTourResumeProps> = ({
    tour,
    reserve,
    onPressIrParaPagamento,
    onPressAdicionar
}) => {

    const [termsChecked, setTermsChecked] = useState(false)
    const [openTerms, setopenTerms] = useState(false)
    const [openTransactionSelection, setOpenTransactionSelection] = useState(false)
    useEffect(() => {
        console.log(tour,
            reserve)
    }, [tour, reserve])


    // const convertDate = (reserve: ReserveTour)=>{
    //     const [day, mounth, year] = reserve.date.split("/")
    //     const [hour, minute, second] = reserve.time.split(":")

    //     return `${day} - ${mounth} - ${year} - ${hour} - ${minute} - ${second} - `
    //     return (new Date(Number(year), Number(mounth), Number(day), Number(hour), Number(minute), Number(second), 0)).toISOString()

    // }

    return (
        <div id={styles.ReserveTourResume} >
            <div className={styles.container} >
                <div className={styles.containerHeader} >
                    <img src={tour.imageUrl} />
                    <h1>{tour.name}</h1>
                    <h2>{reserve.date}</h2>
                    {/* <h2>{reserve.time}</h2> */}
                    <div className={styles.aviso}>
                        <h2> ATENÇÃO</h2>
                        <h3>
                            O horário exato da sua reserva será avisado na véspera até as 18 horas por mensagem de Whatsapp. Em caso de dúvida 54-992046916
                        </h3>
                    </div>
                    {/* <div>{convertDate(reserve)}</div> */}
                </div>
                <div className={styles.customerList}>
                    {reserve.customers.map((customer) => (
                        <div className={styles.customer}>
                            <div className={styles.item}>
                                <span>Nome</span><span>{customer.name}</span>
                            </div>
                            <div className={styles.item}>
                                <span>Data de nascimento</span><span>{customer.birthday}</span>
                            </div>
                            <div className={styles.item}>
                                <span>Documento</span><span>{customer.cpf}</span>
                            </div>
                            <div className={styles.item}>
                                <span>Telefone</span><span>{customer.phone}</span>
                            </div>
                            <div className={styles.item}>
                                <span>Hotel</span><span>{customer.note}</span>
                            </div>
                            <div className={styles.item}>
                                <span>Preço</span><span>{customer.price.description} - R${customer.price.price.toFixed(2)}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={styles.total}>
                    <span>Total</span><span> {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL", }).format(reserve.totalPrice)}</span>
                </div>
                <div className={styles.footer}>
                    <div className={styles.checkbox}>
                        <Checkbox style={{ scale: 2 }} onChange={(_, value) => setTermsChecked(value)} checked={termsChecked} /> <span onClick={() => setopenTerms(true)}>Aceito os <b>termos de reserva</b></span>
                    </div>
                    {
                        onPressIrParaPagamento &&
                        <div className={styles.botao} style={{ opacity: termsChecked ? 1 : 0.2 }} onClick={() => termsChecked && setOpenTransactionSelection(true)}>
                            Ir para pagamento
                        </div>
                    }{
                        onPressAdicionar &&
                        <div className={styles.botao} style={{ opacity: termsChecked ? 1 : 0.2 }} onClick={() => termsChecked && onPressAdicionar && onPressAdicionar()}>
                            Adicionar ao Carrinho
                        </div>
                    }
                </div>
            </div>
            <Dialog open={openTerms} onClose={() => setopenTerms(false)}>
                <div className={styles.dialogTerms}>
                    {`LEIA COM ATENÇÃO:\n
                    - Plantão 08h30 às 24h
                    Operações - (54) 99204-6916\n
                    - Os dias de realização dos passeios serão agendados de acordo com a programação interna da agência.\n
                    - Os horários dos passeios serão informados pela agência sempre no dia que "antecede" o passeio e através de mensagens de WhatsApp.\n
                    - Traslados de chegada pode haver a necessidade de espera de saída do transfer em cerca de 1:20 horas, de acordo com os horários dos voos.\n
                    - O traslado de saída do hotel é marcado cerca de até 5 horas antes do horário de saída do vôo, de acordo com a programação interna da agência.\n
                    - Não nos responsabilizamos por objetos deixados nos veículos utilizados nos passeios e transfers.\n
                    - No caso de desistência dos serviços, serão cobradas taxas administrativas de 50% do valor total. A devolução dos 50% do valor, será de acordo a
                    forma de pagamento. Caso a desistência ocorra há 48 horas antes da saída do passeio/translado/serviço, não haverá devolução.\n
                    - Observe o horário do início dos passeios/transfers no seu diário de viagem, no recibo de compras ou pela mensagem recebida via WhatsApp no dia
                    anterior ao serviço. Pois, não haverá tolerância no horário marcado para saída dos passeios/transfers.\n
                    - Por favor, aguarde na recepção do hotel, conforme o horário programado. Caso contrário, entenderemos que não irá ao passeio/transfer, configurando NO SHOW. Em consideração aos demais clientes, daremos continuidade ao passeio/transfer. Guias e Motoristas não são autorizados a circular pelas dependências dos Hotéis/Pousadas\n
                    - Os passeios opcionais poderão ser cancelados ou remarcados devido a condições climáticas, formação mínima de pessoas, cancelamento do
                    fornecedor, entre outros. No caso de cancelamento desta natureza, será restituído ao passageiro o valor pago integral.`}
                </div>
            </Dialog>
            <Dialog open={openTransactionSelection} onClose={() => setOpenTransactionSelection(false)}>
                <div className={styles.selectTransactionContainer}>
                    Selecione a forma de pagamento
                    <div className={styles.buttonsCard}>
                        <div className={styles.button} onClick={() => { onPressIrParaPagamento && onPressIrParaPagamento(TransactionType.credit); setOpenTransactionSelection(false) }} >
                            Crédito
                        </div>
                        <div className={styles.button} onClick={() => { onPressIrParaPagamento && onPressIrParaPagamento(TransactionType.debit); setOpenTransactionSelection(false) }} >
                            Débito
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
export default ReserveTourResume
