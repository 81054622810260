import { roundValue } from 'utils/utils';
import {ICartItem} from '../../domain/models/ICart';

export const CalcTotalsCartUseCase = (prevCartItems: ICartItem[]) => {
  const calcTotals = (_prevCartItems: ICartItem[]) => {
    const totalPrice = roundValue(_prevCartItems
      .map(item => {
        return item.isWeight ? 
          item.quantity * item.realPrice :
          item.quantity * item.totalPrice 
          
      })
      .reduce((acumulado, atual) => atual + acumulado, 0));
    const totalQuantity = _prevCartItems.map(item => item.quantity).reduce((acumulado, atual) => atual + acumulado, 0);
    console.log('[TOTAL:]', totalPrice);
    
    return {totalQuantity: totalQuantity, totalPrice: totalPrice};
  };

  return calcTotals(prevCartItems);
};
