

import { NfceRepository } from "Infrastructure/repositories/api/NFceRepository";
import { INfceService } from "modules/order/application/interfaces/INfceService";
import { INfce, INfceDTO } from "modules/order/domain/models/INfce";
import { INfceRepository } from "../interfaces/repositories/INfceRepository";

export const NfceService = (
): INfceService => {

  const nfceRepository: INfceRepository = NfceRepository();

  const emitNfce = async (nfce: INfce): Promise<INfceDTO[]> => {
    console.log("send");
    const response = await nfceRepository.emitNfce(nfce)
    if (response) {
      return response
    }
    return []
  };
  return { emitNfce };
};
