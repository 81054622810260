import React, { FC } from "react";
import "./ListaCategorias.css";
import { ItemCategoria } from "./itemCategoria/ItemCategoria";
import { BotaoGrande } from "../../../../components/botaoGrande/BotaoGrande";
import { Catalog } from "../../../../domains/catalog/aggregates/Catalog";

interface ListaCategoriasProps {
  onClickCategoria: (categoryId: string) => void;
  onClickTodos: () => void
  catalog: Catalog
}

export const ListaCategorias: FC<ListaCategoriasProps> = (props) => {

  return (
    <div className="listaCatgorias">
      <div className="listaCatgorias-titulo">
        <div>Bem-Vindo</div>
        <div>Você já sabe o que quer comer?</div>
      </div>
      <div className="listaCatgorias-categorias">
        <ItemCategoria
          id={""}
          nome={"TODOS"}
          imagemUrl={""}
          onClick={props.onClickTodos}
        />

        {props.catalog?.tags?.map((category, index) => (
          <ItemCategoria
            key={index}
            id={category.id}
            nome={category.name}
            imagemUrl={category.imageUrl}
            onClick={() => {
              props.onClickCategoria(category.id);
            }}
          />
        ))}
      </div>
      <div className="listaCatgorias-containerBotao">
        <BotaoGrande onClick={props.onClickTodos ?? undefined}> VER LISTA DE RESTAURANTE</BotaoGrande>
      </div>
    </div>
  );
};
