import React, { FC, memo, useCallback, useEffect, useMemo } from 'react'
import { Kiosk } from '../../../domains/kiosk/aggregates/Kiosk'
import "./TicketLayoutPrinter.css"
import { OpcaoDoConsumidor, OpcoesDoConsumidor, ProdutoPedidoPos, ProdutoTipo } from '../../../Infrastructure/repositories/api/PedidoMeep'
import QRCode from 'qrcode.react'
import { ITicket } from 'application/contexts/printer/PrinterContext'
import { hideCnpj, hideCpf } from 'utils/utils'
interface TicketLayoutPrinterProps {
    ticketToPrint: ITicket,
    kiosk: Kiosk
}



export const TicketLayoutPrinter: FC<TicketLayoutPrinterProps> = memo(({ kiosk, ticketToPrint: { produtoPedidoPos, pedidoPos, index, isReprint } }) => {


    const calcOptionsValue = useCallback((produtoPedidoPos: ProdutoPedidoPos) => {
        return produtoPedidoPos
            .opcoesDoConsumidorObject?.flatMap(item => item.opcoes)
            .map(item => {
                let valorTotalItemCobrado = (item.quantidade * item.produtoValor);
                let valorTotalItemGratis = (item.quantidadeGratuitoSelecionado * item.produtoValor);

                if (valorTotalItemGratis <= 0) {
                    valorTotalItemGratis = 0;
                }

                let diffTotal = (valorTotalItemCobrado - valorTotalItemGratis);

                return Math.max(diffTotal, 0);
            })
            .reduce((prev, current) => prev + current, 0);
    }, [])

    const { adicionais, observacoes } = useMemo(() => {
        let _adicionais: OpcoesDoConsumidor[] = [];
        let _observacoes: OpcoesDoConsumidor[] = [];

        produtoPedidoPos.opcoesDoConsumidorObject.forEach(it => {
            if (it.opcoes.filter(f => f.descricao.length > 0).some(op => !op.produtoId)) {
                _observacoes.push(it);
            } else {
                if (it.opcoes && it.opcoes.length) {
                    let nOpcoes: OpcaoDoConsumidor[] = [];
                    for (const ito of it.opcoes) {
                        if (ito.descricao !== "Observação" && !!ito?.produtoId) {
                            nOpcoes.push(ito);
                        }
                    }
                    it.opcoes = nOpcoes;
                }
                _adicionais.push(it);
            }
        });

        return {
            adicionais: _adicionais,
            observacoes: _observacoes,
        }
    }, [produtoPedidoPos.opcoesDoConsumidorObject])

    const totalTickets = useMemo(() => pedidoPos?.produtosObject.filter(it => it.tipoProduto !== ProdutoTipo.TaxaServico).length, [pedidoPos]);

    const password = useMemo(() => pedidoPos?.codigoPainelSenha, [pedidoPos]);

    const  removerZerosEsquerda = (password: string | undefined) => {
        return password?.replace(/^0+/, '');
    }


    const getTotalFromItem = useCallback((ito: OpcaoDoConsumidor) => {
        let valorTotalItemCobrado = (ito.quantidade * ito.produtoValor);
        let valorTotalItemGratis = (ito.quantidadeGratuitoSelecionado * ito.produtoValor);

        if (valorTotalItemGratis <= 0) {
            valorTotalItemGratis = 0;
        }

        let diffTotal = Math.max((valorTotalItemCobrado - valorTotalItemGratis), 0);
        return diffTotal
    }, [])

    const localName = useMemo(() => {
        const name = produtoPedidoPos?.storeName ? produtoPedidoPos.storeName === kiosk.localName ? kiosk.localName + " | " + produtoPedidoPos.storeName : kiosk.localName : kiosk.localName;
        return isReprint ? name + "- FR" : name;
    }, [isReprint, kiosk.localName, produtoPedidoPos])

    return (
        <div id="ticketprodutoPedidoPos">
            <div className="container">
                <div className="logo">
                    <img width={150} src={require('./../../../assets/images/logo-meep.png')} alt="logo" />
                </div>
                <div className="localName">
                    { localName }
                </div>


                <div className="friendlyId">
                    <span className="textLabelPedido">Pedido:</span> <span className="textPedido">{pedidoPos?.codigoPedido}</span>
                </div>

                {
                    kiosk.mustEnableManagementPassword &&
                    <div className="productPassWordOrder">
                        <span className="textLabelSenha">{password === null || password === undefined|| password === '' ? 'SEM SENHA' : 'SENHA'}</span> <span className="textSenha"> {password === null || password === undefined|| password === ''  ? 'Dirija-se ao balcão' : removerZerosEsquerda(password)}</span>
                    </div>
                }


                <div>
                    <QRCode width={500} value={produtoPedidoPos.ticketId.replace("-", "").replace("-", "").replace("-", "").replace("-", "").replace("-", "")} />
                </div>

                <div className="ordersItems-ResumoLayoutPrinter">
                    {kiosk.printCategoryInProductName && produtoPedidoPos?.category && (
                        <div className="productCategory">
                            {produtoPedidoPos.category}
                        </div>
                    )}
                    <div className="text-ordersItem-ResumoLayoutPrinter">
                        <div className="name-ordersItem-ResumoLayoutPrinter">
                            {produtoPedidoPos.nomeProduto}
                        </div>
                        <div className="price-ordersItem-ResumoLayoutPrinter">
                            R${(((produtoPedidoPos.valor + calcOptionsValue(produtoPedidoPos)) * (produtoPedidoPos.quantidade ?? 1))).toFixed(2).replace(".", ",")}
                        </div>
                    </div>


                    {((adicionais.length > 0) || (observacoes.length > 0)) &&
                        <div className="consumerOptions">
                            {adicionais.length > 0 && (
                                <>
                                    {adicionais.map((opcaoDoConsumidor) => (
                                        <>
                                            {opcaoDoConsumidor.opcoes.length > 0 && (
                                                <div>
                                                    <div className="descricao">{opcaoDoConsumidor.descricao}:</div>
                                                    <div>
                                                        {
                                                            opcaoDoConsumidor.opcoes.map((opcao) => {
                                                                const valorFormatado = (getTotalFromItem(opcao)).toFixed(2).replace(".", ",");
                                                                return (
                                                                    <div className="option">
                                                                        <div>{opcao.quantidade}x{opcao.descricao}</div>
                                                                        <div>R${valorFormatado}</div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ))}
                                </>
                            )}
                            {observacoes.length > 0 && (
                                <>
                                    {observacoes.map((op) => (
                                        <div>
                                            <div className="descricao">{op.descricao}:</div>
                                            <div>
                                                {
                                                    op.opcoes.map((opcao) => (
                                                        <div className="option">
                                                            <div>{opcao.descricao}</div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                    }

                    <div>
                        {pedidoPos?.observacaoPedido}
                    </div>
                    {/* <div>
                        {produtoPedidoPos.tipoProduto}
                    </div> */}
                </div>

                <div className="kioskName">
                    {(index ?? 0) + 1}/{totalTickets}
                </div>

                <div className='resume-layout-dotted-separator' />
                {pedidoPos?.clienteEstabelecimentoObject.nome &&
                    <div className="kioskName">
                        {pedidoPos?.clienteEstabelecimentoObject.nome === kiosk.kioskName ? '' : 'Nome: ' + pedidoPos?.clienteEstabelecimentoObject.nome}
                    </div>}

                {!!pedidoPos?.clienteEstabelecimentoObject.cpf && pedidoPos.clienteEstabelecimentoObject.cpf.length === 11 &&
                    <div className="kioskName">
                        CPF:{hideCpf(pedidoPos?.clienteEstabelecimentoObject.cpf)}
                    </div>}
                {!!pedidoPos?.clienteEstabelecimentoObject.cpf && pedidoPos.clienteEstabelecimentoObject.cpf.length === 14 &&
                    <div className="kioskName">
                        CPF:{hideCnpj(pedidoPos?.clienteEstabelecimentoObject.cpf)}
                    </div>}
                {pedidoPos?.clienteEstabelecimentoObject.mesa &&
                    <div className="kioskName">
                        Mesa: {pedidoPos?.clienteEstabelecimentoObject.mesa}
                    </div>}


                <div className="date-ResumoLayoutPrinter">
                    {pedidoPos.dataPedido?.toLocaleString("pt-BR")}
                </div>
                <div className="date-ResumoLayoutPrinter">
                    {kiosk.kioskName}
                </div>
            </div>
        </div>
    )
})
