import { Icon } from "@material-ui/core";
import { IOrderItem } from "modules/cart/domain/models/IOrderItem";
import { ProdutoTipo } from "modules/cart/domain/models/IProductType";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import React, { FC, memo, useCallback, useEffect } from "react";
import "./CartItem.css";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";
import { roundValue } from "utils/utils";

interface ICartItemProps {
  orderItem: IOrderItem;
  hideZeroPrice?: boolean;
}

export const CartItem: FC<ICartItemProps> = memo((props) => {
  // const { addQuantityProductIdCart, removeQuantityProductIdCart } = useCart();

  const [addCartItem, removeCartItem] = useCartStore((state: ICartContext) => [
    state.addCartItem,
    state.removeCartItem,
  ]);

  const onPressRemoveHandle = useCallback(() => {
    removeCartItem(props.orderItem.id, 1);
  }, [props.orderItem.id, removeCartItem]);
  const onPressAddHandle = useCallback(() => {
    addCartItem(props.orderItem.id, 1);
  }, [addCartItem, props.orderItem.id]);

  const onPressRemoveWeightHandle = useCallback(() => {
    removeCartItem(props.orderItem.id, props.orderItem.quantity)
  },[props.orderItem.id, props.orderItem.quantity, removeCartItem])


  return (
    <div className="carrinho-item">
      <div
        className={"image"}
        // onClick={onClickAdd}
        style={{ backgroundImage: "url(" + props.orderItem.imageUrl + ")" }}
      />
      <div className="info">
        {!(props.orderItem.productType === ProdutoTipo.Kilo) ? (
          <div className="nome">{props.orderItem.name} </div>
        ) : (
          <div className="nome">
            {`${props.orderItem.name}  (R$${
              props.orderItem.realPrice && props.orderItem.realPrice.toFixed(2)
            }/Kg)`}{" "}
          </div>
        )}
        <div className="additional-carrinhoItem">
          {props.orderItem.orderComposition
            ?.filter(
              (items) =>
                !(
                  items.description === "Observação" &&
                  items.orderCompositionItems[0].description === ""
                )
            )
            .map((composition) =>
              composition.orderCompositionItems.filter(
                (item) =>
                  item.description !== "" ||
                  item.description !== undefined ||
                  item.description !== null
              ) ? (
                <div>
                  <b>{composition.description}</b>
                  <div>
                    {composition.orderCompositionItems.map((item) => (
                      <div>
                        <span>
                          {item.quantity} x {item.description}{" "}
                          {item.price === 0
                            ? ""
                            : item.price.toFixed(2).replace(".", ",")}
                        </span>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                ""
              )
            )}
        </div>
        {/* <div className="descricao">{props.orderItem.description}</div> */}
        {/* <div className="valor">R${props.orderItem.value.toFixed(2).replace(".", ",")}</div> */}
      </div>
      {props.orderItem.productType !== ProdutoTipo.Kilo &&
        !props.orderItem?.isWeight &&
        props.orderItem.productType !== ProdutoTipo.TaxaServico && (
          <div className="quantidadeContainer">
            <div
              onClick={onPressRemoveHandle}
              className={"cart-item-botao remove-botao"}
              data-custom-id="cart-item-remove-button-icon"
              data-custom-description="Remover produto"
              data-custom-info={JSON.stringify({
                orderItem: props.orderItem,
              })}
            >
              {/* {(props.orderItem.quantidade === 1) ? <Icon>close</Icon> : */}
              <Icon>remove</Icon>
              {/* } */}
            </div>
            <div className="quantidade">{props.orderItem.quantity}</div>
            <div
              onClick={onPressAddHandle}
              className={"cart-item-botao add-botao"}
              data-custom-id="cart-item-add-button-icon"
              data-custom-description="Adicionar produto"
              data-custom-info={JSON.stringify({
                orderItem: props.orderItem,
              })}
            >
              <Icon>add</Icon>
            </div>
          </div>
        )}
      {(props.orderItem.productType === ProdutoTipo.Kilo || props.orderItem.isWeight) && (
        <div className="quantidadeGranelContainer">
          <div className="quantidadeGranel">
            {`${props.orderItem.quantity.toFixed(3)}Kg`}{" "}
          </div>
        </div>
      )}
      <div className="valor">
        {!(props.hideZeroPrice && props.orderItem.price === 0) && (
          <>R$ {roundValue(props.orderItem.isWeight ? props.orderItem.realPrice * props.orderItem.quantity : props.orderItem.totalPrice * props.orderItem.quantity).toFixed(2).replace(".", ",")}</>
        )}
      </div>
      {props.orderItem.isWeight && (
        <div className="quantidadeContainer">
          <div 
            className={"remove-weight"} 
            onClick={onPressRemoveWeightHandle}
            data-custom-id="cart-item-trash-button-icon"
            data-custom-description="Deletar produto"
            data-custom-info={JSON.stringify({
              orderItem: props.orderItem,
            })}
          >
            <Icon>delete</Icon>
          </div>
        </div>
      )}
    </div>
  );
});
