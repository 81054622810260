import { Icon } from "@material-ui/core";
import { Keyboard } from "components/keyboard/Keyboard";
import { moneyMask } from "corss-cutting/masks/money";
import React, { FC, useCallback, useMemo, useRef } from "react";
import { hideCpf } from "utils/utils";
import "./OfflineRecharge.css";
import { OfflineRechargeSteps } from "./OfflineRecharge.types";
import { Reading } from "./components/reading/Reading";
import { SelectCardType } from "./components/selectCardType/SelectCardType";
import useOfflineRecharge from "./useOfflineRecharge";
import { BotaoVoltar } from "components/botaoVoltar/BotaoVoltar";
import IdleTime, { IIdleTimeHandler } from "components/idleTime/IdleTime";
import { IOrderProviderRef } from "modules/order/presentation/OrderProvider";

const logo = require("../../../assets/images/logo-meep-novo.png");
const totemGif = require("../../../assets/images/totem.gif");

interface IOfflineRechargeProps {
  orderProvider: React.RefObject<IOrderProviderRef>
}

export const OfflineRecharge: FC<IOfflineRechargeProps> = ({ orderProvider }) => {
  const idleTimeRef = useRef<IIdleTimeHandler>(null)

  const {
    onpressback,
    onpressKeyboard,
    onPressClear,
    paymentCashlessOffline,
    step,
    offlineCardData,
    rechargeValue,
    kiosk,
    activeLoad,
    onClickBack,
    blockReturn,
    onTimerFinish,

  } = useOfflineRecharge(orderProvider, idleTimeRef);

  const cpf = useMemo(() => {
    let cpf;

    if (offlineCardData?.Document?.length === 11) {
      cpf = offlineCardData?.Document;
    }

    return cpf;
  }, [offlineCardData]);

  const renderStep = useCallback(() => {
    switch (step) {
      case OfflineRechargeSteps.READ:
        return (
          <div className="offline-recharge-steps">
            <div className="offline-recharge-steps-selected">1</div>
            <div>2</div>
            <div>3</div>
          </div>
        );
      case OfflineRechargeSteps.PAY_RECHARGE:
        return (
          <div className="offline-recharge-steps">
            <div>1</div>
            <div className="offline-recharge-steps-selected">2</div>
            <div>3</div>
          </div>
        );
      case OfflineRechargeSteps.RECORD:
        return (
          <div className="offline-recharge-steps">
            <div>1</div>
            <div>2</div>
            <div className="offline-recharge-steps-selected">3</div>
          </div>
        );
    }
  }, [step]);

  return (
    <div
      id="offline-recharge"
      style={{
        backgroundColor: step === OfflineRechargeSteps.READ ? "#4D13B7" : "#fff",
      }}
    >
      <div className="offline-recharge-header">
        <img
          width={250}
          src={logo}
          alt="logo"
          style={{
            filter:
              step === OfflineRechargeSteps.READ
                ? "invert(100%) sepia(40%) saturate(0) hue-rotate(222deg) brightness(119%) contrast(115%)"
                : "",
          }}
        />
        {renderStep()}
      </div>
      {step === OfflineRechargeSteps.PAY_RECHARGE && offlineCardData && (
        <>
          {cpf && <p className="offline-recharge-cpf">CPF: {hideCpf(cpf)}</p>}
          <div className="offline-recharge-balance">
            <p>Saldo</p>
            <p>{moneyMask(offlineCardData.Balance.toString())}</p>
          </div>

          <div className="offline-recharge-value">
            <p>Insira o valor da recarga</p>
            <div className="offline-recharge-value-container">
              <div className="offline-recharge-input-value">{moneyMask(rechargeValue)}</div>
              <div className="offline-recharge-back-button" onClick={onPressClear}>
                <Icon className="offline-recharge-icon-back">backspace</Icon>
              </div>
            </div>
            <Keyboard
              type="numeric"
              onPressBack={onpressback}
              onKeyPress={onpressKeyboard}
              onPressClear={onPressClear}
            />
          </div>

          <div className="offline-recharge-footer">
            <p>Forma de pagamento</p>
            <div>{kiosk && <SelectCardType kiosk={kiosk} onSelect={paymentCashlessOffline} rechargePayment />}</div>
          </div>
        </>
      )}
      {step === OfflineRechargeSteps.READ && (
        <>
          <div>
            {/* <img src={nfc} alt="nfc" className="offline-recharge-nfc-image" /> */}
            <div className="offline-recharge-totem-message-read">
              <p>
                <b>Para começar,</b> aproxime
              </p>
              <p>
                seu <b>cartão/pulseira</b>
              </p>
            </div>
            <div>
              <img className="offline-recharge-totem" src={totemGif} alt="totem" />
            </div>
            <div className="offline-recharge-totem-message-read-b fa-blink">
              <p>
                <b>Não retire,</b>
              </p>
              <p>
                até a <b>conclusão!</b>
              </p>
            </div>
          </div>
          <div className="offline-recharge-empty" />
        </>
      )}
      {step === OfflineRechargeSteps.RECORD && (
        <>
          <div>
            <div className="offline-recharge-totem-message">
              <p>
                <b>Estamos quase lá!</b>
              </p>
            </div>
            <div>
              <img className="offline-recharge-totem" src={totemGif} alt="totem" />
            </div>
            <div className="offline-recharge-totem-message-footer">
              <p>
                <b>Aproxime</b> seu cartão/pulseira
              </p>
              <p>
                para <b>gravar o saldo</b>
              </p>
            </div>
          </div>
          <div className="offline-recharge-empty" />
        </>
      )}
      <IdleTime ref={idleTimeRef} isActive={!blockReturn} onFinish={onTimerFinish} />
      <Reading active={activeLoad} />
      {(step === OfflineRechargeSteps.READ || step === OfflineRechargeSteps.PAY_RECHARGE ) && (
        <BotaoVoltar onClick={onClickBack} />
      )}
    </div>
  );
};
