import { ProdutoTipo } from '../../../Infrastructure/repositories/api/PedidoMeep';
import { Catalog } from '../../catalog/aggregates/Catalog'
import { Product } from '../../catalog/aggregates/Product'

const GetWeighingProducts = (catalog: Catalog) => {

    const getWeighingProducts = (_catalog: Catalog) => {
        const allProducts: Product[] = _catalog.stores.flatMap(item => item.seccionedProduct).flatMap(item => item.products);
        return allProducts.filter(item => item.type === ProdutoTipo.Kilo)
    }

    return getWeighingProducts(catalog);
}
export default GetWeighingProducts