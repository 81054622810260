import { ITourRepository } from '../../interfaces/ITourRespository'

const AddOrderOnDemandUseCase = async (tourRepository: () => ITourRepository, orderId: string, demandScheduleId: string): Promise<unknown> => {

    return await tourRepository().addOrderInDemmandSchedule({
        demandScheduleId,
        orderId,
    })
}

export default AddOrderOnDemandUseCase
