import { create } from "zustand";
import { ICartContext } from "../../domain/interfaces/IUseCaseCart";
import { IOrderComposition } from "../../domain/models/IOrderComposition";
import {
  addCartItem,
  addProductCart,
  clearCart,
  clearLastProduct,
  clearTempMajorityProduct,
  removeCartItem,
  removeCartItemByProductId,
  setAdultHood,
  setConfigCart,
  setNewCart,
  setShowCart,
  setShowVerifyMajorityModal,
} from "./CartActions";
import { IProductCart } from "../../domain/models/IProductCart";
import { IConfigCart } from "modules/cart/domain/models/IConfigCart";

export const useCartStore = create<ICartContext>((set) => ({
  cart: {
    id: "",
    date: new Date().toISOString(),
  },
  cartItems: [],
  totals: {
    totalQuantity: 0,
    totalPrice: 0,
  },
  lastProductAdded: null,
  openLastProductToast: false,
  addProductCart: (
    product: IProductCart,
    quantity: number,
    orderCompositions: IOrderComposition[],
    showVerifyMajorityModal?: boolean,
    notes?: string
  ) => {
    set((state: ICartContext) =>
      addProductCart(
        state.cartItems,
        product,
        quantity,
        orderCompositions,
        showVerifyMajorityModal && !state.adultHood,
        notes
      )
    );
  },
  removeCartItemByProductId: (productId: string, quantity: number) => {
    set((state: ICartContext) =>
      removeCartItemByProductId(state.cartItems, productId, quantity)
    );
  },
  removeCartItem: (cartItemId: string, quantity: number) => {
    set((state: ICartContext) =>
      removeCartItem(state.cartItems, cartItemId, quantity)
    );
  },
  addCartItem: (cartItemId: string, quantity: number) => {
    set((state: ICartContext) =>
      addCartItem(state.cartItems, cartItemId, quantity)
    );
  },
  clearCart: () => {
    set(() => clearCart());
  },
  clearLastProduct: () => {
    set(() => clearLastProduct());
  },
  configCart: undefined,
  setConfigCart: (configCart: IConfigCart) => {
    set(() => setConfigCart(configCart));
  },
  setNewCart: (id: string) => {
    set(() => setNewCart(id));
  },
  showVerifyMajorityModal: false,
  tempMajorityProduct: null,
  adultHood: false,
  setAdultHood: (adultHood: boolean) => {
    set(() => setAdultHood(adultHood));
  },
  setShowVerifyMajorityModal: (showVerifyMajorityModal: boolean) => {
    set(() => setShowVerifyMajorityModal(showVerifyMajorityModal));
  },
  clearTempMajorityProduct: () => {
    set(() => clearTempMajorityProduct());
  },
  showCart: false,
  setShowCart: (showCart: boolean) => {
    set(() => setShowCart(showCart));
  },
}));
