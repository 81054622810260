import React from 'react'
import Slider, { Settings } from "react-slick";

import styles from './Carousel.module.scss'
interface ICarouselProps {
    values: string[];
}

var settings:Settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,  
    speed: 1000,
    arrows: false,
    autoplay: true,
    lazyLoad: "ondemand" 
    
   
};

export const Carousel: React.FC<ICarouselProps> = ({ values }) => {
    return (
        <div id={styles.Carousel}>

            <Slider {...settings}>

                {
                    values.map(item => (

                        <img src={item} style={{borderRadius:16, objectFit: "cover"}} alt="Imagens slider" />

                    ))
                }
            </Slider>
        </div>
    )
}
