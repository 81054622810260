import React, { FC, useState, useEffect, useCallback, useMemo } from 'react'
import "./PassKeyboard.css"
import { TecladoVirtual } from 'components/tecladoVirtual/TecladoVirtual';

interface PassKeyboard {
    password: string;
    onValidate: (validate: boolean) => void
}



const keys = [
    [["1 - 2", "3 - 4"], ["5 - 6", "7 - 8"], ["back-space", "9 - 0", "check"]],
    [["8 - 7", "1 - 6"], ["2 - 5", "3 - 4"], ["back-space", "9 - 0", "check"]],
    [["8 - 4", "9 - 1"], ["6 - 5", "7 - 3"], ["back-space", "0 - 2", "check"]],
    [["8 - 0", "1 - 6"], ["4 - 9", "3 - 5"], ["back-space", "2 - 7", "check"]],
    [["1 - 3", "2 - 6"], ["7 - 4", "5 - 9"], ["back-space", "0 - 8", "check"]],
    [["7 - 8", "0 - 5"], ["3 - 1", "2 - 4"], ["back-space", "6 - 9", "check"]],
    [["5 - 1", "4 - 3"], ["2 - 9", "6 - 8"], ["back-space", "7 - 0", "check"]],
    [["4 - 5", "1 - 8"], ["3 - 9", "2 - 6"], ["back-space", "0 - 7", "check"]],
    [["3 - 9", "0 - 7"], ["4 - 6", "2 - 5"], ["back-space", "1 - 8", "check"]],
    [["5 - 8", "9 - 3"], ["0 - 1", "4 - 7"], ["back-space", "2 - 6", "check"]],
]




export const PassKeyboard: FC<PassKeyboard> = ({ password, onValidate }) => {


    const [keyboardValue, setkeyboardValue] = useState<string[]>([])

    const onpressback = () => {
        setkeyboardValue(prev => prev.slice(0, prev.length - 1))

    }
    const onPressKey = useCallback((pressedKey: string) => {
        if (keyboardValue.length < 16) {
            setkeyboardValue(prev => [...prev, pressedKey])
        }
    },[keyboardValue])


    const randomInt = useMemo(() => {
        return 0 + Math.floor((keys.length - 1) * Math.random());
    }, [])

    const onConfirm = useCallback(
      () => {
        if (keyboardValue.length === password.length) {
            const validation = keyboardValue.map((value, index) => {
                return value.includes(password[index])
            }).every((value) => value)
            console.log(validation)
            onValidate(validation);
        } else {
            onValidate(false);
        }
      },
      [keyboardValue, onValidate, password],
    )
    


    return (
        <div>
            <div className="input-passKeyboard">
                <div className="value-passKeyboard">
                    {keyboardValue.map(_value => "*")}
                </div>
            </div>
            <TecladoVirtual 
                mapKey={keys[randomInt]} 
                onKeyPress={onPressKey} 
                onPressEnter={onConfirm}
                onPressBack={onpressback}
                type='strongPassword'
            />
        </div>
    )
}
