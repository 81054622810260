import React, { memo, FC, useMemo, useState, useEffect } from "react";
import "./Toolbar.css";
import { ReactComponent as Clock } from "assets/icons/clock.svg";
import { BotaoVoltar } from "../../../../components/botaoVoltar/BotaoVoltar";
import { useHistory } from "react-router-dom";
import fitfood from "assets/images/fitfood.png";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useCardReader } from "application/contexts/cardReader/CardReaderContext";
import { Icon } from "@material-ui/core";

interface ToolbarProps {
  imageUrl?: string;
  onPressVoltar?: () => void;
  title?: string;
  isClosed?: boolean;
}
export const Toolbar: FC<ToolbarProps> = memo(
  ({ imageUrl, onPressVoltar, title, isClosed }) => {
    const history = useHistory();
    const { kiosk } = useKiosk();
    // TODO: Buscar do msm local do pagamento
    const { cardData, isSchoolData } = useCardReader();
    const goBack = () => {
      if(history.location.pathname !== '/') {
        history.goBack();
      }
    };

    const [isFullHD, setIsFullHD] = useState(window.innerHeight >= 1920);
    const [showBalance, setShowBalance] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsFullHD(window.innerHeight >= 1920);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const backgroundImage =
      kiosk?.localId === "2a051162-7bda-d82c-2d94-bb74a7bd912e"
        ? fitfood
        : imageUrl;

    const showNominalData = useMemo(
      () => kiosk?.paymentNominalCashless && cardData && isSchoolData.current,
      [cardData, isSchoolData, kiosk]
    );

    return (
      <div
        className="toolbar"
        style={{
          backgroundImage: `url(${backgroundImage})`,
          paddingTop: 16,
          backgroundBlendMode: isClosed ? "overlay" : undefined,
          backgroundColor: isClosed ? 'rgba(255, 255, 255, 0.5)' : undefined,
        }}
      >
        <BotaoVoltar
          onClick={onPressVoltar ?? goBack}
          style={{ marginTop: showNominalData ? 60 : 0 }}
        />
        {isClosed && (
          <div className="closed">
            <Clock style={{width: 25, marginRight: 8}}/>
            Loja fechada
          </div>
        )}
        {showNominalData && (
          <div
            className="student-toolbar"
            onClick={() => setShowBalance((prev) => !prev)}
          >
            <div style={{fontSize: isFullHD ? 24 : 18}}>
              <b>{cardData?.holderName}</b>
            </div>
            <div style={{fontSize: isFullHD ? 24 : 18}}>
              Cartão:<b>&nbsp;{`${cardData?.tag}`}</b>
            </div>
            <div style={{fontSize: isFullHD ? 24 : 18}}>
              Limite diário:
              {!cardData?.dailyLimit ? (
                <b>&nbsp;Indefinido</b>
              ) : showBalance ? (
                <b>
                  &nbsp;R$ {cardData.dailyLimit.toFixed(2).replace(".", ",")}
                </b>
              ) : (
                <b>
                  &nbsp;R${" "}
                  <b style={{ fontFamily: "monospace", fontSize: isFullHD ? 22 : 16 }}>
                    {cardData?.dailyLimit
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\d/g, "•")}
                  </b>
                </b>
              )}
            </div>
            <div style={{fontSize: isFullHD ? 24 : 18}}>
              Saldo:
              {showBalance ? (
                <b>&nbsp;R$ {cardData?.balance.toFixed(2).replace(".", ",")}</b>
              ) : (
                <b>
                  &nbsp;R${" "}
                  <b style={{ fontFamily: "monospace", fontSize: isFullHD ? 22 : 16 }}>
                    {cardData?.balance
                      .toFixed(2)
                      .replace(".", ",")
                      .replace(/\d/g, "•")}
                  </b>
                </b>
              )}
              {showBalance ? (
                <Icon fontSize={isFullHD ? "medium" : "small"}>visibility_off_outlined</Icon>
              ) : (
                <Icon fontSize={isFullHD ? "medium" : "small"}>visibility_outlined</Icon>
              )}
            </div>
          </div>
        )}
        <div className="name-toolbar">{title}</div>
      </div>
    );
  }
);
