import Axios, { AxiosRequestConfig } from "axios";

import { IHttpRepository } from "../../interfaces/IHttpRepository";
import LogService from "../localLog/LogService"

export const baseApiUrl: string = process.env.REACT_APP_API_URL ?? "";

const axiosInstance = Axios.create({
  baseURL: baseApiUrl,
  timeout: 60000,
});

export const HttpRepository = (
): IHttpRepository => {

  const setBaseUrl = (url: string): void => {
  };


  const getAsync = async <TResponse extends unknown>(
    endPoint: string,
    config?: { params?: any, timeout?: number }
  ): Promise<TResponse> => {
    const response = await axiosInstance.get<TResponse>(endPoint, config)
    return response.data
  };

  const postAsync = async <TRequest extends unknown, TResponse extends unknown>(
    endPoint: string,
    postObject: TRequest
  ): Promise<TResponse> => {
    const response = await axiosInstance.post<TResponse>(endPoint, postObject)
    return response.data
  };




  const configureInterceptors = (
    token?: string,
    headers?: Array<{ key: string; value: string }>
  ) => {
    if (token || headers) {
      axiosInstance.interceptors.request.use((request) => {
        LogService().add({ url: request.url, message: "Api request", tag: "REQUEST_API", object: request.data });
        return addReaders(request, token, headers)
      });
    }

    axiosInstance.interceptors.response.use(
      (response) => {
        LogService().add({ url: response.config.url, message: "Api response", tag: "RESPONSE_API", object: response.data });
        return response
      }, (error) => {
        LogService().add({ message: error?.message ?? "Erro response", tag: "RESPONSE_ERRO", object: error });
        throw (error)
      }
    )


  };

  const addReaders = (
    request: AxiosRequestConfig,
    token?: string,
    headers?: Array<{ key: string; value: string }>
  ): AxiosRequestConfig => {
    headers?.forEach((header) => {
      request.headers[header.key] = header.value;
    });
    request.headers["Authorization"] = "Bearer " + token;
    request.headers["contentType"] = "application/json; charset=utf-8";

    return request;
  };


  const setTimeout = (time: number) => {
    axiosInstance.defaults.timeout = time;
    axiosInstance.defaults.timeoutErrorMessage =
      "TIME OUT VERIFIQUE SE ESTE EQUIPAMENTO POSSUI PERMISSÃO";
  };

  return {
    getAsync,
    postAsync,
    configureInterceptors,
    setBaseUrl,
    setTimeout,
  };
};
