import React, { FC, useEffect, useMemo, useState } from "react";
import "./Reading.css";
import { CSSTransition } from "react-transition-group";

const progress = require("../../../../../assets/images/progress.gif");

let expirationTimeout: NodeJS.Timeout;
const LOAD_TIMER = 3;

interface IReadingProps {
  active: "reading" | "writing" | null;
}

export const Reading: FC<IReadingProps> = ({ active }) => {
  const [expirationCounter, setExpirationCounter] = useState(LOAD_TIMER);

  useEffect(() => {
      expirationTimeout = setTimeout(() => {
        setExpirationCounter((prev) => prev - 1);
      }, 1000);

    return () => {
      clearTimeout(expirationTimeout);
    };
  }, [expirationCounter]);  

  useEffect(() => {
    if (!!active) {
      setExpirationCounter(LOAD_TIMER)
    }
  }, [active])
  

  const [secondLeft, secondRight] = useMemo(
    () =>
      String(expirationCounter % 60)
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );

  return (
    <CSSTransition mountOnEnter unmountOnExit in={!!active} timeout={500} classNames="modal-sucess">
      <div className={"reading-toast"}>
        <div className={"message-reading-toast"}>
          <div className="message-reading-timer">
            <div>
              <span>0</span>
              <span>0</span>
            </div>
            <span>:</span>
            <div>
              <span>{secondLeft}</span>
              <span>{secondRight}</span>
            </div>
          </div>
          <div className="message-reading-warning">
            <h1>NÃO REMOVA</h1>
            <h1>SEU CARTÃO/PULSEIRA</h1>
          </div>
          <img className="reading-toast-totem" src={progress} alt="totem" />
          <div className={"text-reading-toast"}>
            {active === "reading" && (
              <p>
                Verificando informações...
              </p>
            )}
            {active === "writing" && (
              <p>
                Gravando recarga...
              </p>
            )}
            <p>
              <b>Aguarde </b>até a conclusão!
            </p>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
