import { Button, Dialog } from '@material-ui/core'
import { useKiosk } from 'application/contexts/kiosk/KioskContext'
import { usePrinter } from 'application/contexts/printer/PrinterContext'
import { useUi } from 'application/contexts/ui/UIContext'
import { BotaoGrande } from 'components/botaoGrande/BotaoGrande'
import InputText from 'components/inputText/InputText'
import { PrintService } from 'domains/order/services/printService'
import { HttpRepository } from 'Infrastructure/repositories/api/_HttpRepository'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styles from './CpfRegisterPage.module.scss'
import UserRegisterService from './service/UserRegisterService'
export interface ICpfRegisterPageProps {
    //propertys
    onClose?: () => void
}
const CpfRegisterPage: FC<ICpfRegisterPageProps> = ({ onClose }) => {

    const [values, setValues] = useState({ cpf: "" });
    const [userName, setUserName] = useState("")
    const { replace } = useHistory();
    const { showLoading, hideLoading, toast } = useUi();
    const { kiosk } = useKiosk();
    const { printCpfRegister } = usePrinter();
    const timer = useRef<NodeJS.Timer>();


    const onConfirm = useCallback(() => {
        setUserName("");
        if (timer.current) {
            console.log("clear finishTimer")
            clearTimeout(timer.current)
        }
        if (onClose) {
            onClose();
        }
        else {
            replace("/");
        }
    }, [onClose, replace])

    const timeoutstart = useCallback(() => {

        console.log("SET Timer")
        timer.current = setTimeout(() => {
            onConfirm();
            console.log("finish imer")

        }, 5000);

    }, [onConfirm])

    const onSubmit = useCallback(() => {
        const httpRepository = HttpRepository();
        const userRegisterService = UserRegisterService(httpRepository)
        if (kiosk) {

            showLoading();
            userRegisterService.getUserData(kiosk?.localId, values.cpf,).then(response => {
                setUserName(response.Nome);
                printCpfRegister({
                    name: response.Nome,
                    cpf: response.CPF,
                    message: 'Obrigado pela presença e boa sorte!',
                })
                timeoutstart()


            }).catch(() => {
                toast("FALHA AO REALIZAR CADASTRO VERIFIQUE SEU CPF", "error")
            }).finally(() => {
                hideLoading()
            })
        }


    }, [values, onConfirm, timeoutstart])

    const onChangeCPFHandle = useCallback((cpf: string) => {
        setValues({ cpf });
    }, [])





    return (
        <div id={styles.CpfRegisterPage} >
            <div className={styles.content} >
                <h2> CADASTRO </h2>
                <div className={styles.inputCpfContainer} >
                    <InputText
                        label="Digite seu CPF"
                        className={styles.input}
                        placeHolder={"Digite seu CPF"}
                        value={values.cpf}
                        autoFocus
                        type={'numeric'}
                        onChange={onChangeCPFHandle}
                        onPressEnter={onSubmit}
                    />
                </div>
                <div>
                    <div onClick={onSubmit} className={styles.buttonContinuar} > Continuar</div>
                </div>
                <Dialog open={!!userName}>
                    <div className={styles.dialogConfirm} >
                        <h2>Bem-vindo</h2>
                        {userName} seu cadastro foi realizado com sucesso!
                        <div
                            onClick={onConfirm}
                            className={styles.buttonContinuar}
                        > Confirmar</div>
                    </div>
                </Dialog>
            </div>
        </div>
    )
}
export default CpfRegisterPage