import { ISessionResponse } from 'Infrastructure/repositories/api/PasswordPainel';
import { IHttpRepository } from '../../../Infrastructure/interfaces/IHttpRepository'
import { HttpRepository } from '../../../Infrastructure/repositories/api/_HttpRepository';
import { ISessionService } from '../interfaces/services/ISessionService'

export const SessionService = (
): ISessionService => {
    const httpRepository: IHttpRepository = HttpRepository();

    const closeSession = async (id: string) => {
        const responseClose = await httpRepository.postAsync<any, any>("/SessaoPOS/FecharSessaoTotem", { id });
    }
    const openSession = async (id: string) => {
        const responseClose = await httpRepository.postAsync<any, any>("/SessaoPOS/AbrirNovaSessaoTotem", { id });
    }

    const getSession = async (localId: string): Promise<ISessionResponse> => {
        const responseGet = await httpRepository.getAsync<ISessionResponse>("/SessaoPOS/PossuiSessaoAberta?localId=" + localId);
        return {
            possuiSessaoAberta: responseGet?.possuiSessaoAberta ?? true,
            sessaoPOSId: responseGet.sessaoPOSId
        }
    }

    return {
        closeSession,
        openSession,
        getSession
    }
}