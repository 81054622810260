import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { TransactionType } from '../components/reserverTourResume/ReserveTourResume.type';
import { ICartTour } from '../components/tourCart/ICartTour';
import { useTourCart } from '../components/tourCart/TourCartContext';
import { useCatalogTour } from '../contexts/TourCatalogContext'
import UsePaymentTour from '../hooks/UsePaymentTour';

export const UseTourPage = () => {


    const { catalogTour } = useCatalogTour();
    const { paymentCartTour } = UsePaymentTour();
    const { replace, push } = useHistory();

    const onPressBack = useCallback(
        () => {
            replace('/')
        },
        [],
    )

    const onClickGoToPaymentHandle = useCallback(
        (cartTour: ICartTour,transactionType?: TransactionType) => {
            paymentCartTour(cartTour,transactionType)
        },
        [paymentCartTour]
    )

    const onClickItem = useCallback(
        (id: string) => {
            console.log("CLICKED ON ", id)
            replace('/tour/storeTour/' + id)
        },
        [replace],
    )
    const onClickAboutProject = useCallback(
        () => {
            push('/tour/aboutProject')
        },
        [push],
    )

    return (
        {
            catalogTour,
            onPressBack,
            onClickItem,
            onClickGoToPaymentHandle,
            onClickAboutProject
        }
    )
}

export default UseTourPage
