import { ILocalStorageRepository } from "../../interfaces/ILocalStorageRepository";
import { ICatalogLocalStorageRepository } from "../../interfaces/ICatalogLocalStorageRepository";
import { Catalog } from "../../../domains/catalog/aggregates/Catalog";
import { LocalStorageRepository } from "./_LocalStorageRepository";

const key = "CATALOG";
export const CatalogLocalStorageRepository = (

): ICatalogLocalStorageRepository => {
  const localStorageRepository: ILocalStorageRepository = LocalStorageRepository()

  const post = async (data: Catalog): Promise<void> => {
    await localStorageRepository.post(key, data);
  };

  const get = async (): Promise<Catalog | null> => {
    const value = await localStorageRepository.get<Catalog>(key);
    if (value) {
      return value;
    }
    return null;
  };

  const remove = async (): Promise<void> => {
    await localStorageRepository.remove(key);
  };

  return { post, get, remove };
};
