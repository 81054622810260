import React, { FC, useState, useCallback, useEffect, useMemo } from "react";
import { Composicao } from "../composicao/Composicao";
import "./ComposicaoLista.css";
import {
  EtapaInteface,
  StepComposicao,
} from "../stepComposicao/StepComposicao";
import { Button, Icon } from "@material-ui/core";
import { CSSTransition } from "react-transition-group";
import { Composition } from "../../../../../../domains/catalog/aggregates/Composition";
import { OrderComposition } from "../../../../../../domains/order/agreggates/order/OrderComposition";
import { OrderCompositionItem } from "domains/order/agreggates/order/OrderCompositionItem";

interface ComposicaoListaProps {
  composicoes: Composition[];
  compostionFreeMax: number | undefined;
  onFinish: (orderCompositions: OrderComposition[], minimos: boolean) => void;
  addtionalCount: number
  setAddtionalCount: React.Dispatch<React.SetStateAction<number>>;
  adicionarUmTotal: () => void;
  removeUmTotal: () => void;
  getTotal: () => number;
  setSelectedItemsFree: React.Dispatch<React.SetStateAction<OrderCompositionItem[]>>;
  selectedItemsFree: OrderCompositionItem[];
  getTotalItemsFree: () => number;
  selectedItemsReplica: OrderCompositionItem[];
  setSelectedItemsReplica: React.Dispatch<React.SetStateAction<OrderCompositionItem[]>>;
  getValorTotalItemsFree: () => number;
  renderMaxQuantityDiv: () => JSX.Element | null
  getTotalItemsFreeDesc: () => number
}

export const ComposicaoLista: FC<ComposicaoListaProps> = ({
  composicoes,
  onFinish,
  compostionFreeMax,
  addtionalCount,
  setAddtionalCount,
  adicionarUmTotal,
  removeUmTotal,
  getTotal,
  setSelectedItemsFree,
  selectedItemsFree,
  getTotalItemsFree,
  selectedItemsReplica,
  setSelectedItemsReplica,
  getValorTotalItemsFree,
  renderMaxQuantityDiv,
  getTotalItemsFreeDesc
}) => {
  const [etapas, setetapas] = useState<EtapaInteface[]>(
    composicoes.sort((a, b) => {
      if (a.description > b.description) {
        return 1;
      }
      if (a.description < b.description) {
        return -1;
      }
      return 0;
    }).map((item, index) => ({
      id: item.id,
      text: item.description,
      checked: item.min === 0,
      enable: false,
    }))
  );

  const [etapaAtual, setetapaAtual] = useState<number>(0);
  const [orderCompositionList, setOrderCompositionList] = useState<OrderComposition[]>([]);

  const [enableNextButton, setenableNextButton] = useState(false);

  const onClickAvancar = useCallback(() => {
    if (composicoes[etapaAtual].min === 0) {
      setetapas((prev) => {
        let newValue = prev;
        newValue[etapaAtual].checked = true;
        return newValue;
      });
    }
    if (etapaAtual < etapas.length - 1) {
      setetapaAtual((prev) => prev + 1);
    }
  }, [setetapas, etapaAtual, composicoes, etapas]);

  const onClickVotar = useCallback(() => {
    if (etapaAtual > 0) {
      setetapas((prev) => {
        let newValue = prev;
        newValue[etapaAtual].checked =
          composicoes[etapaAtual].min === 0 ? true : false;
        return newValue;
      });
      setetapaAtual((prev) => prev - 1);
    }
  }, [etapaAtual, composicoes]);

  const onChangeComposition = useCallback((newOrderComposition: OrderComposition, minimo: boolean, maximo: boolean) => {
    setetapas((prev) => {
      let newValue = prev.map((item, _index) =>
        item.id === newOrderComposition.compositionId
          ? { ...item, checked: minimo }
          : item
      );
      return newValue;
    });

    setenableNextButton(minimo);

    if (orderCompositionList.length <= 0) {
      let _orderCompositionList = [...orderCompositionList, newOrderComposition];
      setOrderCompositionList(_orderCompositionList);

      console.log("COMPOSICAO ZERADO");
    } else {
      let _orderCompositionList = [...orderCompositionList];
      let index = _orderCompositionList.findIndex(_orderCompositionListItem => {
        return _orderCompositionListItem.compositionId === newOrderComposition.compositionId
      });
      if (index === -1) {
        _orderCompositionList.push(newOrderComposition);
        setOrderCompositionList(_orderCompositionList);
        console.log("COMPOSICAO COM VALOR ADD +1");
      } else {
        _orderCompositionList[index] = newOrderComposition;
        setOrderCompositionList(_orderCompositionList);
        console.log("COMPOSICAO COM VALOR SUBSTITUI");
      }
    }

    console.log("orderCompositionList : ", orderCompositionList);
  }, [orderCompositionList]);

  useEffect(() => {
    if (etapas.every((item) => item.checked) && etapaAtual === etapas.length - 1) {
      onFinish(orderCompositionList, true);
    } else {
      onFinish([], false);
    }
  }, [etapas, onFinish, etapaAtual, orderCompositionList]);

  return (
    <div className="composicao-lista">
      <div>
      
        {composicoes && (
          <>
            {composicoes.length > 1 && (
              <StepComposicao
                etapas={etapas}
                etapaAtual={etapaAtual}
              ></StepComposicao>
            )}
            <div className={"composicao-buttons-container"}>
              {
                <div
                  className={"composicao-lista-button-container"}
                  style={{
                    maxWidth: 200,
                    left: 10,
                    visibility: etapaAtual !== 0 ? "visible" : "hidden",
                  }}
                >
                  <Button
                    className={"composicao-lista-button"}
                    disabled={!(etapaAtual > 0)}
                    style={{
                      backgroundColor: "#fff",
                      color: "#f66",
                      border: "2px solid #f66",
                    }}
                    fullWidth
                    onClick={onClickVotar}
                    data-custom-id="composition-lista-back-button"
                    data-custom-description="Anterior"
                    data-custom-info={JSON.stringify({
                      step: etapaAtual,
                    })}
                  >
                    <Icon>arrow_back</Icon> ANTERIOR
                  </Button>
                </div>
              }
              <div className={"composicao-buttons-descricao"}>
                {etapas[etapaAtual]?.text}
              </div>
              {
                <div
                  className={"composicao-lista-button-container"}
                  style={{
                    right: 10,
                    visibility:
                      enableNextButton && etapaAtual < etapas.length - 1
                        ? "visible"
                        : "hidden",
                  }}
                >
                  <Button
                    className={"composicao-lista-button"}
                    disabled={!(etapaAtual < etapas.length - 1)}
                    style={{ backgroundColor: "#1ac", color: "#FFF" }}
                    color={"secondary"}
                    variant="contained"
                    fullWidth
                    onClick={onClickAvancar}
                    data-custom-id="composition-lista-forward-button"
                    data-custom-description="Próximo"
                    data-custom-info={JSON.stringify({
                      step: etapaAtual,
                    })}
                  >
                    PRÓXIMO <Icon>arrow_forward</Icon>
                  </Button>
                </div>
              }
            </div>
          </>
        )}
          {
          renderMaxQuantityDiv()
        }
      </div>
      <div className="transitionComposicao-container">
        {composicoes.map((composicao, index) => {
          return (
            <CSSTransition
              in={index === etapaAtual}
              key={index}
              timeout={200}
              classNames="transitionComposicao"
              unmountOnExit
              mountOnEnter
            >
              <Composicao
                key={index}
                getTotalItemsFreeDesc={getTotalItemsFreeDesc}
                compostionFreeMax={compostionFreeMax}
                composicao={composicao}
                hideDescription
                addtionalCount={addtionalCount}
                onChange={onChangeComposition}
                setAddtionalCount={setAddtionalCount}
                adicionarUmTotal={adicionarUmTotal}
                removeUmTotal={removeUmTotal}
                getTotal={getTotal}
                initalValues={orderCompositionList.find((_ordercomposition) => _ordercomposition.compositionId === composicao.id)}
                setSelectedItemsFree={setSelectedItemsFree}
                selectedItemsFree={selectedItemsFree}
                getTotalItemsFree={getTotalItemsFree}
                selectedItemsReplica={selectedItemsReplica}
                setSelectedItemsReplica={setSelectedItemsReplica}
                getValorTotalItemsFree={getValorTotalItemsFree}
              />
            </CSSTransition>
          );
        })}
      </div>
    </div>
  );
};
