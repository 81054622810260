import React, { FC } from "react";
import "./ItemStore.css";
import { ReactComponent as Clock } from "assets/icons/clock.svg";

interface ItemListaCategoriasProps {
  nome: string;
  imagemUrl: string;
  id: string;
  onClick?: () => void;
  isClosed?: boolean;
}

export const ItemStore: FC<ItemListaCategoriasProps> = (props) => {
  return (
    <div onClick={props.onClick} className="itemListaStore">
      <div className="imagem" style={{ backgroundImage: "url(" + props.imagemUrl + ")", opacity: props.isClosed ? 0.5 : undefined }}>
      </div>
        {props.isClosed && (
          <div className="closed">
            <Clock style={{width: 25, marginRight: 8}}/>
            Loja fechada
          </div>
        )}
      <div className="nome">{props.nome}</div>
    </div>
  );
};
