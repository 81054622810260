import React, { FC, forwardRef, memo, useCallback, useImperativeHandle, useState } from 'react'
import html2canvas from 'html2canvas';
import { PrintService } from '../../domains/order/services/printService';
import { v4 } from 'uuid';
import { Order } from 'domains/order/agreggates/order/Order';
import { PedidoPos } from 'Infrastructure/repositories/api/PedidoMeep';
import { Kiosk } from 'domains/kiosk/aggregates/Kiosk';

export interface PrinterViewComponent {
    print: () => void;
    remotePrint: (order: Order, kiosk: Kiosk ) => void;
}

export interface PrinterViewProps {
    test?: boolean
    children: React.ReactNode;
    id?: string;
    onPrintFinish?: () => void
}
//container
const printService = PrintService();

export const PrinterView = memo(forwardRef<PrinterViewComponent, PrinterViewProps>(({ children, test, id, onPrintFinish }, ref) => {
    const print = useCallback(async () => {
        // window.print()
        let htmlElement = document.getElementById(id ?? "printerView")
        const key = "print - " + v4()
        console.log("print")
        console.time(key)
        if (htmlElement) {
            console.log("print - criar imagem")
            console.timeLog(key)
            let canvas: HTMLCanvasElement = await html2canvas(htmlElement, { scale: 1, scrollY: 0})
            console.log("print - enviar para cefSharp")
            console.timeLog(key)
            let base64image: string = canvas.toDataURL("image/png", 1)           
            // console.log("print - imagem gerada", base64image)
            await printService.printImage(base64image);
            console.log("print - resposta cefSharp")
            console.timeLog(key)
            onPrintFinish && onPrintFinish()
        }
        console.timeEnd(key)

    }, [id, onPrintFinish])

    const remotePrint = useCallback(async (order: Order, kiosk: Kiosk) => {
        // window.print()
        let htmlElement = document.getElementById(id ?? "printerView")
        const key = "print - " + v4()
        console.log("print")
        console.time(key)
        if (htmlElement) {
            console.log("print - criar imagem")
            console.timeLog(key)
            let canvas: HTMLCanvasElement = await html2canvas(htmlElement, { scale: 1, scrollY: 0})
            console.log("print - enviar para cefSharp")
            console.timeLog(key)
            let base64image: string = canvas.toDataURL("image/png", 1)           
            // console.log("print - imagem gerada", base64image)
            await printService.printRemote(order, kiosk, base64image);
            console.log("print - resposta cefSharp")
            console.timeLog(key)
            onPrintFinish && onPrintFinish()
        }
        console.timeEnd(key)

    }, [id, onPrintFinish])

    useImperativeHandle(ref, () => ({
        print,        
        remotePrint
    }));

    return (
        <div style={
            {
                bottom: test ? 0 : -1000,
                left: test ? 0 : -50000,
                zIndex: test ? 50000 : -5000000, position: 'fixed', overflow: "visible", minWidth: 300,
            }
        } onClick={() => { print() }} id={id ?? "printerView"} >
            {
                children
            }
        </div >
    )
}))
