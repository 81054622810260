import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./PixStatus.css";
import { Order } from "domains/order/agreggates/order/Order";
import { BotaoGrande } from "components/botaoGrande/BotaoGrande";
import { PaymentService } from "domains/order/services/paymentService";
import {
  Payment,
  PixApprovedTransaction,
  PixPaymentRequest,
  PixPaymentResponse,
} from "domains/order/agreggates/payment/payment";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useUi } from "application/contexts/ui/UIContext";
import { PedidoPos } from "Infrastructure/repositories/api/PedidoMeep";
import ActionModal, { IActionModalHandler } from "components/actionModal/ActionModal";

const REQUEST_TIMER_DELAY = 5;
const PIX_EXPIRATION_TIMER = 180;

interface IPixStatusProps {
  order: Order;
  onPressCancel: () => void;
  changePaymentType: () => void;
  onSuccessPayment: (transactionPix: PixApprovedTransaction) => void;
  addToOrderLog: (order: Order | null, message: string, pedidoPOS?: PedidoPos | undefined, payment?: Payment | undefined) => Promise<void>
}

const paymentService = PaymentService();
let requestDelayTimeout: NodeJS.Timeout;
let expirationTimeout: NodeJS.Timeout;

export const PixStatus: FC<IPixStatusProps> = ({ order, onPressCancel, changePaymentType, onSuccessPayment, addToOrderLog }) => {
  const { kiosk } = useKiosk();
  const [pixStatus, setPixStatus] = useState<PixPaymentResponse | null>(null);
  const { toastFullscreen } = useUi();
  const actionModalRef = useRef<IActionModalHandler>(null);
  const actionModalExpirationTimeRef = useRef<IActionModalHandler>(null);

  const [isActiveRequestTimer, setIsActiveRequestTimer] = useState(false);
  const [isActiveExpirationTimer, setIsActiveExpirationTimer] = useState(true);
  const [requestTimerDelayCounter, setRequestTimerDelayCounter] = useState<number>(REQUEST_TIMER_DELAY);
  const [expirationCounter, setExpirationCounter] = useState(PIX_EXPIRATION_TIMER);

  const startRequestTimer = useCallback(() => {
    setIsActiveRequestTimer(true);
  }, []);

  const resetRequestTimer = useCallback(() => {
    clearTimeout(requestDelayTimeout);
    setIsActiveRequestTimer(false);
    setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
  }, []);

  useEffect(() => {
    if (isActiveRequestTimer && requestTimerDelayCounter > 0) {
      requestDelayTimeout = setTimeout(() => {
        setRequestTimerDelayCounter((prev) => prev - 1);
      }, 1000);
    } else if (isActiveRequestTimer && requestTimerDelayCounter === 0) {
      setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
    }

    return () => {
      clearTimeout(requestDelayTimeout);
    };
  }, [requestTimerDelayCounter, isActiveRequestTimer]);

  
  const getPixStatus = useCallback(async (paymentId: string) => {
    try {
      console.log('getPixStatus');
      const result = await paymentService.pixPaymentStatus(paymentId);
      const parseResponse: PixPaymentResponse = {
        ownerId: result.ownerId ?? result.OwnerId,
        paymentId: result.paymentId ?? result.Id,
        qrCodeBase64: result.qrCodeBase64 ?? result.QrCodeBase64,
        qrCodeLink: result.qrCodeLink ?? result.QrCodeLink,
        status: result.status ?? result.Status,
        value: result.value ?? result.Value,
        approvedAt: result.approvedAt ?? result.ApprovedAt,
        acquirerType: result.acquirerType ?? result.AcquirerType,
      }
      setPixStatus(parseResponse);
    } catch (error) {
      console.log(error);
    }
  },[]);

  const cancelPixPayment = useCallback(() => {
    setPixStatus(null);
    onPressCancel();
  }, [onPressCancel]);

  const onClickCancel = useCallback(async () => {
    try {
      pixStatus?.paymentId && getPixStatus(pixStatus.paymentId);
      await actionModalRef.current?.open();
    } catch (error) {
      // console.log(error)
    }
  }, [getPixStatus, pixStatus]);

  const onConfirmCancel = useCallback(async () => {
    await addToOrderLog(order, "Encerrado pelo usuário");
    cancelPixPayment();
  }, [addToOrderLog, cancelPixPayment, order]);

  const resetExpirationTimer = useCallback(() => {
    setPixStatus(null);
    clearTimeout(expirationTimeout);
    setIsActiveExpirationTimer(false);
  }, []);

  const checkExpiration = useCallback(
    async () => {
        try {
          setExpirationCounter(PIX_EXPIRATION_TIMER)
          pixStatus?.paymentId && getPixStatus(pixStatus.paymentId);
          await actionModalExpirationTimeRef.current?.open();
          console.log("prorrogar pagamento")
        } catch (error) {
          console.log('cancel payment')
          pixStatus?.paymentId && await getPixStatus(pixStatus.paymentId);
          resetExpirationTimer();    
        }
    },
    [getPixStatus, pixStatus, resetExpirationTimer],
  )
  

  useEffect(() => {
    if (isActiveRequestTimer && requestTimerDelayCounter === 0) {
      if (pixStatus && pixStatus.status === "pending") {
        setRequestTimerDelayCounter(REQUEST_TIMER_DELAY);
      } else {
        resetRequestTimer();
        resetExpirationTimer();
        if (pixStatus?.status === "approved") {
          const transactionPix: PixApprovedTransaction = {
            ownerId: pixStatus.ownerId,
            paymentId: pixStatus.paymentId,
            status: pixStatus.status,
            value: pixStatus.value,
            acquirerType: pixStatus.acquirerType,
            approvedAt: pixStatus.approvedAt,
            createdAt: pixStatus.createdAt,
            qrCodeLink: pixStatus.qrCodeLink
          };
          onSuccessPayment(transactionPix);
        }
        if (pixStatus?.status === "cancelled") {
          toastFullscreen("Pagamento cancelado", "error");
          cancelPixPayment();
        }
      }
    }
  }, [
    cancelPixPayment,
    requestTimerDelayCounter,
    isActiveRequestTimer,
    onSuccessPayment,
    pixStatus,
    resetExpirationTimer,
    resetRequestTimer,
    toastFullscreen,
  ]);

  useEffect(() => {
    if (isActiveExpirationTimer && expirationCounter > 0) {
      expirationTimeout = setTimeout(() => {
        setExpirationCounter((prev) => prev - 1);
      }, 1000);
    } else if (isActiveExpirationTimer && expirationCounter === 0) {
      checkExpiration();
    }

    return () => {
      clearTimeout(expirationTimeout);
    };
  }, [checkExpiration, expirationCounter, isActiveExpirationTimer]);

  useEffect(() => {
    if (!isActiveExpirationTimer) {
      addToOrderLog(order, "Tempo de pagamento pix esgotado");
      toastFullscreen("Tempo para pagamento do pix expirado.", "warning");
      cancelPixPayment();
    }
  }, [addToOrderLog, cancelPixPayment, isActiveExpirationTimer, order, toastFullscreen]);

  useEffect(() => {
    if (isActiveRequestTimer && requestTimerDelayCounter === 0 && pixStatus && pixStatus.paymentId) {
      getPixStatus(pixStatus.paymentId);
    }
  }, [requestTimerDelayCounter, isActiveRequestTimer, pixStatus, getPixStatus]);

  useEffect(() => {
    const getPixPayment = async (localClientId: string) => {
      try {
        const payload: PixPaymentRequest = {
          Value: order.totalValue,
          LocalClientId: localClientId,
          PixPaymentOrderId: order.id,
        };
        let result: PixPaymentResponse;
        result = await paymentService.pixPayment(payload);
        if (!result) {
          await addToOrderLog(order, "Falha ao pagar com pix");
          toastFullscreen("Falha ao pagar com pix", "error");
          cancelPixPayment();
        }
        const parseResponse: PixPaymentResponse = {
          ownerId: result.ownerId ?? result.OwnerId,
          paymentId: result.paymentId ?? result.Id,
          qrCodeBase64: result.qrCodeBase64 ?? result.QrCodeBase64,
          qrCodeLink: result.qrCodeLink ?? result.QrCodeLink,
          status: result.status ?? result.Status,
          value: result.value ?? result.Value,
          approvedAt: result.approvedAt ?? result.ApprovedAt,
          acquirerType: result.acquirerType ?? result.AcquirerType,
        }
        setPixStatus(parseResponse);
      } catch (error) {
        await addToOrderLog(order, (error as any)?.response?.data?.message ?? "Falha ao pagar com pix");
        toastFullscreen((error as any)?.response?.data?.message ?? "Falha ao pagar com pix", "error");
        cancelPixPayment();
      }
    };
    if (kiosk?.localId) {
      getPixPayment(kiosk.localId);
      startRequestTimer();
    }
  }, [addToOrderLog, cancelPixPayment, kiosk, order, order.id, order.totalValue, startRequestTimer, toastFullscreen]);

  const [minuteLeft, minuteRight] = useMemo(
    () =>
      String(Math.floor(expirationCounter / 60))
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );
  const [secondLeft, secondRight] = useMemo(
    () =>
      String(expirationCounter % 60)
        .padStart(2, "0")
        .split(""),
    [expirationCounter]
  );

  return (
    <div id="pixStatus">
      <div className="pixBody">
        {!pixStatus && (
          <>
            <p>Gerando QR Code...</p>
            <p>
              Enquanto isso, abra o app
              <br />
              do seu banco no celular
            </p>
          </>
        )}

        {pixStatus?.qrCodeBase64 && (
          <div className="pixQrCodeContainer">
            <p>Leia o QR Code:</p>
            <div className="pixQrCode">
              <img src={`data:image/jpeg;base64,${pixStatus.qrCodeBase64}`} alt="qrCode" />
            </div>
            <div className="countdownContainer">
              <div>
                <span>{minuteLeft}</span>
                <span>{minuteRight}</span>
              </div>
              <span>:</span>
              <div>
                <span>{secondLeft}</span>
                <span>{secondRight}</span>
              </div>
            </div>
            <p>
              Após isso, o QR Code irá expirar e você
              <br /> voltará para as formas de pagamento.
            </p>
          </div>
        )}
      </div>

      <div className="totalValue">
        <p>Total:</p>
        <p>R$ {order.totalValue.toFixed(2).replace(".", ",")}</p>
      </div>

      <BotaoGrande onClick={changePaymentType}>Alterar forma de pagamento</BotaoGrande>
      <div className="cancelBotaoGrande">
        <BotaoGrande onClick={onClickCancel}>Cancelar pedido</BotaoGrande>
      </div>
      <ActionModal 
        ref={actionModalRef}
        confirmTitle="Cancelar"
        message='Esta ação irá cancelar este pagamento, não efetue o pagamento do pix se você clicar em "Cancelar"!'
        title="ATENÇÃO!"
        onClickConfirm={onConfirmCancel}
        closeButtonTile="Voltar"
        oldStyle
        confirmationDelay={5}
        hideCloseButton
      />
      <ActionModal 
        ref={actionModalExpirationTimeRef}
        confirmTitle="Prorrogar"
        message='Seu tempo esgotou, deseja prorrogar o tempo?'
        title="ATENÇÃO!"
        onClickConfirm={() => {}}
        closeButtonTile="Cancelar"
        oldStyle
        hideCloseButton
      />
    </div>
  );
};
