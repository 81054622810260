import { IBarCodeRepository } from "../../interfaces/IBarCodeRepository";
import { ICatalogLocalStorageRepository } from "../../interfaces/ICatalogLocalStorageRepository";
import { CatalogLocalStorageRepository } from "./CatalogLocalStorageRepository";

export const BarCodeRepository = (
): IBarCodeRepository => {
  const catalogLocalStorage: ICatalogLocalStorageRepository = CatalogLocalStorageRepository()
  const getProduct = async (barCode: string) => {
    console.log("barcode:", barCode);
    // console.log(catalog?.stores.length);

    // let productSelected = catalog?.stores.map((store) => {
    //   let _sectionProduct = store.seccionedProduct.map((secction) => {
    //     let _product = secction.products.find(
    //       (product) => product.barcode === barCode
    //     );
    //     return _product;
    //   }).find((__product) => __product && __product.barcode === barCode);
    //   return _sectionProduct;
    // }).find((__product) => __product && __product.barcode === barCode);

    // if (productSelected) {
    //   return productSelected;
    // } else {
    //   return null;
    // }

    try {

      const catalog = await catalogLocalStorage.get();
      const products = catalog?.stores.flatMap((item) => item.seccionedProduct).flatMap(item => item.products)

      let productSelected_ = products?.find((item) => {
        // console.log(item.name);
        // console.log(item.barcode);
        const barCodeComplete = item.barCode?.padEnd(6, '0')

        return (barCodeComplete === barCode)
      })
      // console.log('product list length', products?.length)
      // console.log('product name', productSelected_?.name)
      // console.log('barCode product', productSelected_?.barcode)
      // console.log('barCode readed', barCode)

      return productSelected_ ?? null
    } catch (error) {
      console.error(error)
      return null
    }

  };

  return { getProduct };
};
