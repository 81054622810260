import { ICartItem } from "../../domain/models/ICart";
import { RemoveCartItemByIdUseCase } from "./RemoveCartItemByIdUseCase";

export const RemoveCartItemByProductIdUseCase = (prevCartItems: ICartItem[], productId: string, quantity: number): ICartItem[] => {

    const removeCartLastItem = (_prevCartItems: ICartItem[], productId: string, _quantity: number) => {

        const lastCartItem = _prevCartItems.find(item => productId === item.productId);

        if (lastCartItem) {
            return RemoveCartItemByIdUseCase(prevCartItems, lastCartItem.id, quantity);
        }
        else {
            return prevCartItems;
        }

    };

    return removeCartLastItem(prevCartItems, productId, quantity);
};

