import { ICefSharp } from "./Interfaces/_ICefSharp";
import { ICefSharpOrderService } from "./Interfaces/ICefSharpOrderService";

declare const CefSharp: ICefSharp;
declare const orderService: ICefSharpOrderService;

export const CefSharpLocalOrderRepository = (): ICefSharpOrderService => {
  const saveInFileAsync = async (
    id: string,
    request: string,
    response: string
  ): Promise<boolean> => {
    await CefSharp.BindObjectAsync("orderService");
    return await orderService.saveInFileAsync(id, request, response);
  };

  return { saveInFileAsync };
};
