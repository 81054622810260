import { IOrderRepository } from 'domains/order/interfaces/repositories/IOrderRepository'
import { Order } from 'domains/order/agreggates/order/Order'
import { PedidoPos, ProdutoTipo } from 'Infrastructure/repositories/api/PedidoMeep'
import { ReserveTourFormRequestDTO } from 'modules/tour/domain/dtos/ReserveFormRequest'
import { TourDetail } from 'modules/tour/domain/models/CatalogTour'
import { v4 } from 'uuid'
import { TransactionType } from 'domains/order/agreggates/payment/transactionType'
import { FinancialTransactionModel } from 'domains/order/agreggates/payment/FinancialTransactionModel'
import { ReserveTour } from 'modules/tour/presentation/components/reserverTourResume/ReserveTourResume.type'
import { IAddReserveScheduleWithoutPaymentResponse } from 'modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentResponse'
import { ICartTourItem } from 'modules/tour/presentation/components/tourCart/ICartTourItem'
import { ICartTour } from 'modules/tour/presentation/components/tourCart/ICartTour'

const SyncReserveCartOrderUseCase = (
    orderRepository: () => IOrderRepository,
    cartTour: ICartTour,
    productSync: IAddReserveScheduleWithoutPaymentResponse,
    localId: string,
    transactionType?: TransactionType,
    transaction?: FinancialTransactionModel
): Promise<PedidoPos> => {
    const response = orderRepository().sendNowOrdersAsync(TourProductToOrder(cartTour, productSync), localId, transactionType, transaction)
    return response;
}

const TourProductToOrder = (cartTour: ICartTour, productSync: IAddReserveScheduleWithoutPaymentResponse) => {

    const newOrder: Order = {
        id: v4(),
        cartDate: new Date().toISOString(),
        orderItems: productSync.productsDemanded.map((item, index) => {

            return ({
                id: v4(),
                name: item.productName,
                category: "",
                price: cartTour.cartTourItems.flatMap(item => item.reserveTour.customers)[index].price.price,
                realPrice: cartTour.cartTourItems.flatMap(item => item.reserveTour.customers)[index].price.price,
                totalPrice: cartTour.cartTourItems.flatMap(item => item.reserveTour.customers)[index].price.price,
                description: item.client.name + " " + item.client.document,
                productPrice: item.productValue,
                productId: item.productId,
                productType: ProdutoTipo.Agendamento,
                quantity: 1,
                imageUrl: "",
                hideCatalog: false,
                barcode: "",
                orderComposition: [],
                compositions: [],
                printerName: "",
            })
        }),
        totalValue: cartTour.totalPrice,
        friendlyId: "",
        createdAt: new Date().toISOString(),

    }

    return newOrder

}


export default SyncReserveCartOrderUseCase





