import React, { FC, useState, useEffect, useCallback, useMemo } from 'react'
import { TecladoVirtual } from '../../../components/tecladoVirtual/TecladoVirtual'
import { Icon } from '@material-ui/core'
import "./AuthKeyboard.css"

interface AuthKeyboardProps {
    password: string;
    onValidate: (validate: boolean) => void
}



const keys = [
    [["1 - 2"], ["3 - 4"], ["5 - 6"], ["7 - 8"], ["9 - 0"]],
    [["8 - 7"], ["1 - 6"], ["2 - 5"], ["3 - 4"], ["9 - 0"]],
    [["8 - 4"], ["9 - 1"], ["6 - 5"], ["7 - 3"], ["0 - 2"]],
    [["8 - 0"], ["1 - 6"], ["4 - 9"], ["3 - 5"], ["2 - 7"]],
    [["1 - 3"], ["2 - 6"], ["7 - 4"], ["5 - 9"], ["0 - 8"]],
    [["7 - 8"], ["0 - 5"], ["3 - 1"], ["2 - 4"], ["6 - 9"]],
    [["5 - 1"], ["4 - 3"], ["2 - 9"], ["6 - 8"], ["7 - 0"]],
    [["4 - 5"], ["1 - 8"], ["3 - 9"], ["2 - 6"], ["0 - 7"]],
    [["3 - 9"], ["0 - 7"], ["4 - 6"], ["2 - 5"], ["1 - 8"]],
    [["5 - 8"], ["9 - 3"], ["0 - 1"], ["4 - 7"], ["2 - 6"]],
    // [["-"], ["-"], ["-"], ["-"], ["-"]],
]




export const AuthKeyboard: FC<AuthKeyboardProps> = ({ password, onValidate }) => {


    const [keyboardValue, setkeyboardValue] = useState<string[]>([])

    const onpressback = () => {
        setkeyboardValue(prev => prev.slice(0, prev.length - 1))

    }
    const onPressKey = useCallback((pressedKey: string) => {
        if (keyboardValue.length < 16) {
            setkeyboardValue(prev => [...prev, pressedKey])
        }
    },[keyboardValue])


    const randomInt = useMemo(() => {
        return 0 + Math.floor((keys.length - 1) * Math.random());
    }, [])

    useEffect(() => {
        if (keyboardValue.length === password.length) {
            const validation = keyboardValue.map((value, index) => {
                return value.includes(password[index])
            }).every((value) => value)
            console.log(validation)
            onValidate(validation);
        } else {
            onValidate(false);
        }
    }, [keyboardValue, password, onValidate])


    return (
        <div>
            <div className="input-authKeyboard">
                <div className="value-authKeyboard">
                    {keyboardValue.map(value => (<Icon>fiber_manual_record</Icon>))}
                </div>
                <div className="back-authKeyboard" onClick={onpressback}>
                    <Icon className="icon-authKeyboard" fontSize="large">backspace</Icon>
                </div>
            </div>
            <TecladoVirtual mapKey={keys[randomInt]} onKeyPress={onPressKey}></TecladoVirtual>
        </div>
    )
}
