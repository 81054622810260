import React, { FC, useCallback, useState, useEffect, useRef, MutableRefObject } from "react";
import "./Composicao.css";
import { ComposicaoItem, EnumTypes, IComposicaoItem } from "./composicaoItem/ComposicaoItem";
import { v4 } from "uuid";
import {
  Composition,
  CompositionItem,
} from "../../../../../../domains/catalog/aggregates/Composition";
import { OrderComposition } from "../../../../../../domains/order/agreggates/order/OrderComposition";
import { OrderCompositionItem } from "../../../../../../domains/order/agreggates/order/OrderCompositionItem";

interface ComposicaoProps {
  composicao: Composition;
  onChange: (
    orderComposition: OrderComposition,
    minimo: boolean,
    maximo: boolean
  ) => void;
  initalValues?: OrderComposition;
  hideDescription?: boolean;
  onMax?: () => void;
  compostionFreeMax: number | undefined;
  addtionalCount: number;
  setAddtionalCount: React.Dispatch<React.SetStateAction<number>>
  adicionarUmTotal: () => void;
  removeUmTotal: () => void;
  getTotal: () => number;
  setSelectedItemsFree: React.Dispatch<React.SetStateAction<OrderCompositionItem[]>>
  selectedItemsFree: OrderCompositionItem[]
  getTotalItemsFree: () => number;
  selectedItemsReplica: OrderCompositionItem[];
  setSelectedItemsReplica: React.Dispatch<React.SetStateAction<OrderCompositionItem[]>>;
  getValorTotalItemsFree: () => number;
  getTotalItemsFreeDesc: () => number
}

export const Composicao: FC<ComposicaoProps> = ({ composicao, setAddtionalCount, onChange, initalValues, hideDescription, addtionalCount, onMax, compostionFreeMax,
  setSelectedItemsFree,
  selectedItemsFree,
  adicionarUmTotal,
  removeUmTotal,
  getTotal,
  getTotalItemsFreeDesc,
  getTotalItemsFree,
  selectedItemsReplica,
  setSelectedItemsReplica,
  getValorTotalItemsFree
}) => {
  const [quantidade, setquantidade] = useState(0);
  const [disableAdd, setDisableAdd] = useState(true);
  const [minimo, setMinimo] = useState(false);
  //const [selectedItems, setSelectedItems] = useState<OrderCompositionItem[]>(initalValues?.orderCompositionItems ?? []);
  const [selectedItems, setSelectedItems] = useState<OrderCompositionItem[]>([]); //Referente a lista de itens da categoria
  //const corectInitialValues = useRef<OrderCompositionItem[]>([]);
  //const [corectInitialValues, setCorrentInitialValues] = useState(0);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [refComposicaoItems, setrefComposicaoItems] = useState<MutableRefObject<IComposicaoItem | null>[]>(composicao.compositionItems.map(item => useRef<IComposicaoItem | null>(null)))

  const totalFree = useRef(0);

  const setQuantity = (items: OrderCompositionItem[], tquant: number) => {
    for (let i = 0; i < items.length; i++) {
      tquant = tquant + items[i].quantity;
    }

    setquantidade(tquant);
  }

  const bindSelectedOrderCompositionItem = useCallback((list: OrderCompositionItem[], replicas: OrderCompositionItem[]) => {
    if (replicas.length > 0) {
      for (const replica of replicas) {
        if (replica.compositionId === composicao.id) {
          list.push(replica);
        }
      }
    }
  },[composicao.id])

  //Carregar os itens selecionados
  useEffect(() => {
    let totalQuantity = 0;
    const selectedOrderCompositionItem: OrderCompositionItem[] = [];
    let replicaItems = [...selectedItemsReplica];

    //bindSelectedOrderCompositionItem(selectedOrderCompositionItem, replicaItems);

    if(composicao.compositionItems.length > 0){
      for(const composicaoItem of composicao.compositionItems){
        if(composicaoItem.type === EnumTypes.Fixed && composicaoItem.max === 0 && composicaoItem.min === 0){
          let indexCompositionItemFixed = replicaItems.findIndex(soc => {
            return soc.compositionItemId === composicaoItem.id;
          });

          if(indexCompositionItemFixed === -1){
            const fixedOrderCompositionItem: OrderCompositionItem = {
              id: v4(),
              compositionId: composicaoItem.compositionId,
              produtoId: composicaoItem.produtoId,
              productId: composicaoItem.productId,
              description: composicaoItem.description,
              price: composicaoItem.price,
              quantityFree: composicaoItem.quantityFree,
              compositionItemId: composicaoItem.id,
              quantity: composicaoItem.quantity, //lembrar de colocar a quantidade fixa que vier do back-end
              quantityFreeSelected: 0,
              itemType: composicaoItem.type
            };
            
            replicaItems.push(fixedOrderCompositionItem);
            setSelectedItemsReplica(replicaItems);

            // console.log("LISTA DE PRE SELECAO: ", selectedOrderCompositionItem);
          }
        }
      }
    }

    bindSelectedOrderCompositionItem(selectedOrderCompositionItem, replicaItems);

    setSelectedItems(selectedOrderCompositionItem);
    setQuantity(selectedOrderCompositionItem, totalQuantity);

  }, [composicao, selectedItemsReplica, setSelectedItemsReplica, bindSelectedOrderCompositionItem]);

  const onChangeValues = useCallback((orderCompositionItens: OrderCompositionItem[]) => {
      let _minimo: boolean = false;
      let _maximo: boolean = false;
      let _quantidadeItensSelecionados = 0;

      if(orderCompositionItens.length > 0){
        for(const _orderCompositionItem of orderCompositionItens){
          _quantidadeItensSelecionados += _orderCompositionItem.quantity;
        }
      }

      if (composicao.min && _quantidadeItensSelecionados < composicao.min) {
        _minimo = false;
        setMinimo(_minimo);
      } else {
        _minimo = true;
        setMinimo(_minimo);
      }

      if (composicao.max && _quantidadeItensSelecionados === composicao.max) {
        _maximo = true;
      } else {
        _maximo = false;
      }

      let valorTotalSelectedItems = 0; // valor total replica representa o valor total geral ainda sem tirar o gratuito

      const listaGratisSelecionado = [...selectedItemsFree]; //aqui eu acesso o ultimo estado da variavel

      if(orderCompositionItens.length > 0) {
        for(const _selectedItem of orderCompositionItens){
          let _valorTotalItensSelecionados = (_selectedItem.price * _selectedItem.quantity);
          valorTotalSelectedItems = valorTotalSelectedItems + _valorTotalItensSelecionados;

          if(listaGratisSelecionado && listaGratisSelecionado.length > 0){
            for(const lgs of listaGratisSelecionado){
              if(_selectedItem.id === lgs.id){
                _selectedItem.quantityFreeSelected = lgs.quantity;
              }
            }
          }
        }
      }

      



      let orderCompositionToFinish: OrderComposition = {
        compositionId: composicao.id,
        id: v4(),
        description: composicao.description,
        imageUrl: composicao.imageUrl,
        orderCompositionItems: orderCompositionItens,
        totalPrice: valorTotalSelectedItems
      };

      // console.log(`VALOR TOTAL DE TUDO PAGANDO - COMP: ${orderCompositionToFinish.description} :`, orderCompositionToFinish.totalPrice);

      let valorTotalSelectedItemsFree = 0;
      let listaGratis = [...selectedItemsFree];
      if(listaGratis.length > 0){
        for(const lg of listaGratis){
          if(lg.compositionId === composicao.id){
            let totalInnerCompositionItems = (lg.price * lg.quantity);
            valorTotalSelectedItemsFree = valorTotalSelectedItemsFree + totalInnerCompositionItems;
          }
        }
      }

      // console.log(`VALOR TOTAL DE TUDO GRATIS - COMP: ${orderCompositionToFinish.description} :` , valorTotalSelectedItemsFree);

      orderCompositionToFinish.totalPrice = orderCompositionToFinish.totalPrice - valorTotalSelectedItemsFree;


      // console.log(`VALOR TOTAL DE TUDO PAGANDO MENOS GRATIS: - COMP: ${orderCompositionToFinish.description}`, orderCompositionToFinish.totalPrice);

      onChange(orderCompositionToFinish,_minimo,_maximo);
    },
    [composicao, onChange, selectedItemsFree]
  );

  useEffect(() => {
    if (composicao.max && quantidade >= composicao.max) {
      setDisableAdd(true);
    } else {
      setDisableAdd(false);
    }

    if (composicao.min && quantidade < composicao.min) {
      setMinimo(false);
    } else {
      setMinimo(true);
    }

    if (composicao.max && quantidade >= composicao.max && onMax) {
      onMax();
    }
  }, [quantidade, initalValues, setDisableAdd, composicao, onMax]);

  useEffect(() => {
    let _selectedItens = [...selectedItems];
    onChangeValues(_selectedItens);
  }, [selectedItems]);


  const detectType = useCallback(
    () => {
      return composicao.min === 1 && composicao.max === 1 ? "radio" : composicao.max === 1 ? "checkbox" : "quantity"
    },
    [composicao.max, composicao.min],
  )

  const unselectOtherItems = useCallback(
    (id: string) => {
      composicao.compositionItems.map((item, index) => {
        // console.log("uncheck" + id + " != " + item.id)
        if (item.id !== id) {
          refComposicaoItems[index].current?.unCheck()
        }
      })
    },
    [composicao.compositionItems, refComposicaoItems],
  );

  const isFree = (quantityFree: number, totalInnerSelectedFree: number, quantidadeTotalItemAtual: number) => {
    /**
     * Verifica se o limite maximo grtatuito ainda é maior que o total adicionado gratuito para permitir inclusão de um novo item gratis.
     * Se verdadeiro, significa que 'posso' adicionar um item gratis, mas não significa que devo adicionar
     */
    if (compostionFreeMax && compostionFreeMax > totalInnerSelectedFree) { // Verifica limite externo
      /**
       * Verifica se eu devo adicionar um item gratis ou não
       */
      return quantityFree >= quantidadeTotalItemAtual; //verifica limite interno
    } else {
      return false;
    }
  };

  //Pega o total da lista que estou passando
  const getTotalFreeFromList = (list: OrderCompositionItem[]) => {
    let tot = 0;
    if (list.length <= 0) {
      return tot;
    } else {
      for (let z = 0; z < list.length; z++) {
        tot += list[z].quantity;
      }
    }

    return tot;
  }

  useEffect(() => {
    totalFree.current = getTotalItemsFree();
  }, [getTotalItemsFree]);

  //Devolve uma lista gratuita
  const bindFreeList = (listaReplica: OrderCompositionItem[]) => {
    let listaGratis: OrderCompositionItem[] = [];

    for (let x = 0; x < listaReplica.length; x++) {
      if (listaReplica[x].quantity > 0) {
        for (let z = 0; z < listaReplica[x].quantity; z++) {
          let quantify = (listaReplica[x].quantity - z);
          let canBeFree = listaReplica[x].itemType !== EnumTypes.Fixed && isFree(listaReplica[x].quantityFree, getTotalFreeFromList(listaGratis), quantify);
          if (canBeFree) {
            let existedCompositionItem = listaGratis.findIndex(lg => {
              return lg.compositionItemId === listaReplica[x].compositionItemId;
            });
            if (existedCompositionItem !== -1) {
              listaGratis[existedCompositionItem].quantity++;
              listaGratis[existedCompositionItem].quantityFreeSelected++;
            } else {
              const newFreeOrderCompositionItem: OrderCompositionItem = {
                id: listaReplica[x].id,
                compositionId: listaReplica[x].compositionId,
                produtoId: listaReplica[x].produtoId,
                productId: listaReplica[x].productId,
                description: listaReplica[x].description,
                price: listaReplica[x].price,
                quantityFree: listaReplica[x].quantityFree,
                compositionItemId: listaReplica[x].compositionItemId,
                quantity: 1,
                quantityFreeSelected: 1,
                itemType: listaReplica[x].itemType
              };
              listaGratis.push(newFreeOrderCompositionItem);
            }
          } else {
            let existedCompositionItem = listaGratis.findIndex(lg => {
              return lg.compositionItemId === listaReplica[x].compositionItemId;
            });
            if (existedCompositionItem !== -1) {
              //listaGratis[existedCompositionItem].quantity = 0;
              //listaGratis[existedCompositionItem].quantityFreeSelected = 0;
              if(listaReplica[x].quantityFree <= 0){
                listaGratis[existedCompositionItem].quantity = 0;
                listaGratis[existedCompositionItem].quantityFreeSelected = 0;
              } else {
                if(z <= 0 && !canBeFree){
                  listaGratis[existedCompositionItem].quantity = 0;
                  listaGratis[existedCompositionItem].quantityFreeSelected = 0;
                } else {
                  continue;
                }
              }
            } else {
              const newFreeOrderCompositionItem: OrderCompositionItem = {
                id: listaReplica[x].id,
                compositionId: listaReplica[x].compositionId,
                produtoId: listaReplica[x].produtoId,
                productId: listaReplica[x].productId,
                description: listaReplica[x].description,
                price: listaReplica[x].price,
                quantityFree: listaReplica[x].quantityFree,
                compositionItemId: listaReplica[x].compositionItemId,
                quantity: 0,
                quantityFreeSelected: 0,
                itemType: listaReplica[x].itemType
              };
              listaGratis.push(newFreeOrderCompositionItem);
            }
          }
        }
      } else {
        let existedCompositionItem = listaGratis.findIndex(lg => {
          return lg.compositionItemId === listaReplica[x].compositionItemId;
        });
        if (existedCompositionItem !== -1) {
          listaGratis[existedCompositionItem].quantity = 0;
          listaGratis[existedCompositionItem].quantityFreeSelected = 0;
        } else {
          const newFreeOrderCompositionItem: OrderCompositionItem = {
            id: listaReplica[x].id,
            compositionId: listaReplica[x].compositionId,
            produtoId: listaReplica[x].produtoId,
            productId: listaReplica[x].productId,
            description: listaReplica[x].description,
            price: listaReplica[x].price,
            quantityFree: listaReplica[x].quantityFree,
            compositionItemId: listaReplica[x].compositionItemId,
            quantity: 0,
            quantityFreeSelected: 0,
            itemType: listaReplica[x].itemType
          };
          listaGratis.push(newFreeOrderCompositionItem);
        }
      }
    }
    return listaGratis;
  }

  const onChangeQuantity = (compositionItem: CompositionItem, quantidadeDoItem: number, quantityFree: number) => {
    if (detectType() === "quantity") {
      const compositionItemIndex = selectedItemsReplica.findIndex(item => {
        return item.compositionItemId === compositionItem.id;
      });
      if (compositionItemIndex === -1) {
        const newOrderCompositionItem: OrderCompositionItem = {
          id: v4(),
          compositionId: compositionItem.compositionId,
          produtoId: compositionItem.produtoId,
          productId: compositionItem.productId,
          description: compositionItem.description,
          price: compositionItem.price,
          quantityFree: compositionItem.quantityFree,
          compositionItemId: compositionItem.id,
          quantity: quantidadeDoItem,
          quantityFreeSelected: 0,
          itemType: compositionItem.type
        };

        const newOrderCompositionItemList = [...selectedItemsReplica, newOrderCompositionItem]; //aqui eu acesso o ultimo estado da variavel
        setSelectedItemsReplica(newOrderCompositionItemList);

        const listaComposicaoAtual: OrderCompositionItem[] = [];

        if (newOrderCompositionItemList.length > 0) {
          for (const noci of newOrderCompositionItemList) {
            if (noci.compositionId === composicao.id) {
              listaComposicaoAtual.push(noci);
            }
          }
        }

        setSelectedItems(listaComposicaoAtual);
        setSelectedItemsFree(bindFreeList(newOrderCompositionItemList));

      } else {
        const newOrderCompositionItemList: OrderCompositionItem[] = [];
        if (selectedItemsReplica.length > 0) {
          for (const replica of selectedItemsReplica) {
            if (replica.compositionItemId === compositionItem.id) {
              replica.quantity = quantidadeDoItem;
            }

            newOrderCompositionItemList.push(replica);
          }
        }

        setSelectedItemsReplica(newOrderCompositionItemList);

        const listaComposicaoAtual: OrderCompositionItem[] = [];

        if (newOrderCompositionItemList.length > 0) {
          for (const noci of newOrderCompositionItemList) {
            if (noci.compositionId === composicao.id) {
              listaComposicaoAtual.push(noci);
            }
          }
        }

        setSelectedItems(listaComposicaoAtual);

        if (quantidadeDoItem <= 0) {
          const listaGratisAtual = bindFreeList(newOrderCompositionItemList);
          setSelectedItemsFree(listaGratisAtual);
        } else {
          const listaGratisAtual = [...selectedItemsFree];

          let index = listaGratisAtual.findIndex(lig => {
            return lig.compositionItemId === compositionItem.id;
          });

          let totalItemsListaGratis = getTotalFreeFromList(listaGratisAtual);

          if (compositionItem.type !== EnumTypes.Fixed && isFree(compositionItem.quantityFree, totalItemsListaGratis, quantidadeDoItem)) {
            listaGratisAtual[index].quantity = quantidadeDoItem;
            setSelectedItemsFree(listaGratisAtual);
          } else {
            if (index !== -1) {
              if (listaGratisAtual[index].quantity >= quantidadeDoItem) {
                listaGratisAtual[index].quantity = quantidadeDoItem;
                listaGratisAtual[index].quantityFreeSelected = listaGratisAtual[index].quantity;

                let itemsZerados = listaGratisAtual.filter(lga => {
                  return lga.quantity === 0;
                });

                let proximoItemGratis: OrderCompositionItem | null = null;

                if(itemsZerados !== null && itemsZerados !== undefined && itemsZerados.length > 0){
                  for(const iz of itemsZerados){
                    let proximoItemViraGratisIndex = newOrderCompositionItemList.findIndex(nocil => {
                      return nocil.compositionItemId === iz.compositionItemId &&
                      nocil.quantity > 0 && nocil.quantityFree > 0 && nocil.quantityFree > nocil.quantity &&
                      nocil.itemType === EnumTypes.Variable;
                    });

                    if(proximoItemViraGratisIndex === -1){
                      continue;
                    } else {
                      if(iz.quantity !== 0){
                        continue;
                      } else {
                        proximoItemGratis = iz;
                        break;
                      }
                    }
                  }
                }

                if(proximoItemGratis !== null){
                  let lgaIndex = listaGratisAtual.findIndex(lga => {
                    return lga.compositionItemId === proximoItemGratis?.compositionItemId;
                  });

                  if(lgaIndex !== -1){
                    listaGratisAtual[lgaIndex].quantity += 1;
                    listaGratisAtual[lgaIndex].quantityFreeSelected = listaGratisAtual[lgaIndex].quantity;
                  }
                }
              }
              //listaGratisAtual[index].quantity = 0;
              setSelectedItemsFree(bindFreeList(listaGratisAtual));
            } else {
              const nOrderCompositionItem: OrderCompositionItem = {
                id: v4(),
                compositionId: compositionItem.compositionId,
                produtoId: compositionItem.produtoId,
                productId: compositionItem.productId,
                description: compositionItem.description,
                price: compositionItem.price,
                quantityFree: compositionItem.quantityFree,
                compositionItemId: compositionItem.id,
                quantity: 0,
                quantityFreeSelected: 0,
                itemType: compositionItem.type
              };
              listaGratisAtual.push(nOrderCompositionItem);
              setSelectedItemsFree(listaGratisAtual);
            }
          }
        }
      }
    } else {
      let newOrderCompositionItemList = [...selectedItemsReplica];
      let list: OrderCompositionItem[] = [];

      for(const noc of newOrderCompositionItemList){
        if(noc.compositionId !== compositionItem.compositionId){
          list.push(noc);
        }
      }

      unselectOtherItems(compositionItem.id);

      const newValueItem: OrderCompositionItem = {
        id: v4(),
        compositionId: compositionItem.compositionId,
        produtoId: compositionItem.produtoId,
        productId: compositionItem.productId,
        description: compositionItem.description,
        price: compositionItem.price,
        quantityFree: compositionItem.quantityFree,
        compositionItemId: compositionItem.id,
        quantity: quantidadeDoItem,
        quantityFreeSelected: 0,
        itemType: compositionItem.type
      };

      list.push(newValueItem);

      setSelectedItemsReplica(list);

      const listaComposicaoAtual: OrderCompositionItem[] = [];

      if (list.length > 0) {
        for (const noci of list) {
          if (noci.compositionId === composicao.id) {
            listaComposicaoAtual.push(noci);
          }
        }
      }

      setSelectedItems(listaComposicaoAtual);
      setSelectedItemsFree(bindFreeList(list));
    }

    // console.log("REPLICA :", selectedItemsReplica);
    // console.log("LISTA GRATIS :", selectedItemsFree);
    // console.log("LISTA COMPOSICAO ATUAL :", selectedItems);
  };

  const getInitialValuesByComposition = (compositionItemId: string, initValues: OrderComposition | undefined, startedCurrentInitialValue: OrderCompositionItem[]) => {
    if(initValues && initValues.orderCompositionItems.length > 0){
      for(const ioc of initValues.orderCompositionItems){
        if(ioc.compositionItemId === compositionItemId){
          return ioc.quantity;
        } else {
          if(startedCurrentInitialValue.length > 0){
            for(const sciv of startedCurrentInitialValue){
              if(sciv.compositionItemId === compositionItemId){
                return sciv.quantity;
              }
            }
          }
        }
      }
    } else {
      if(startedCurrentInitialValue.length > 0){
        for(const sciv of startedCurrentInitialValue){
          if(sciv.compositionItemId === compositionItemId){
            return sciv.quantity;
          }
        }
      } else {
        return 0;
      }
    }
  }

  return (
    <div className="composicao">
      {!hideDescription && (
        <div className="composicao-descricao">{composicao.description}</div>
      )}
      <div style={{ height: 25, fontSize: 18 }}> {minimo ? " " : "Obrigatorio"} {quantidade}/{composicao.max}</div>
      <div className="composicao-item-conainer">
        {composicao.compositionItems.map((compositionItem, index) => {
          return (
            <ComposicaoItem
              ref={refComposicaoItems[index]}
              key={index}
              compostionFreeMax={compostionFreeMax}
              id={compositionItem.id}
              imageUrl={compositionItem.imageUrl}
              description={compositionItem.description}
              value={compositionItem.price}
              min={compositionItem.min}
              max={compositionItem.max}
              quantityfree={compositionItem.quantityFree}
              onQuantityChanged={(quantity, quantityFree) => {
                onChangeQuantity(compositionItem, quantity, quantityFree ?? 0);
              }}
              getTotalItemsFreeDesc={getTotalItemsFreeDesc}
              getTotalItemsFree={getTotalItemsFree}
              selectedItemsFree={selectedItemsFree}
              selectedItemsReplica={selectedItemsReplica}
              adicionarUmTotal={adicionarUmTotal}
              removeUmTotal={removeUmTotal}
              getTotal={getTotal}
              setAddtionalCount={setAddtionalCount}
              addtionalCount={addtionalCount}
              disabledAdd={disableAdd}
              type={detectType()}
              quantidadeInicial={getInitialValuesByComposition(compositionItem.id, initalValues, selectedItems)}
              itemType={compositionItem.type}
            />
          );
        })}
      </div>
    </div>
  );
}

