import { IPrintSector } from "Infrastructure/repositories/api/ConfigTotemMeep";
import { PrintType } from "./PrintType";

export enum SystemType {
  prepayTicket = 1,
  prepayCashless,
  pospayOrderPad = 4,
}
export enum SystemPrintType {
  ticket = 1,
  resume,
}

export enum MovementType {
  inSell = 1,
  inTicketValidation
}

export interface Kiosk {
  id: string,
  generateEletronicInvoice: boolean;
  mustPrintEletronicInvoice: boolean;
  hasSplitInvoice: boolean;
  barCodeMode?: boolean;
  enableBarCode?: boolean;
  localName: string;
  cashlessCode?: string; // Validar front
  token: string;
  mustEnableManagementPassword: boolean;
  localId: string;
  enableVariablePricing: boolean;
  printType: PrintType;
  addressLocalServer?: string;
  printCategoryInProductName: boolean;
  wallpaperImageUrl: string;
  wallpaperImageId: string;
  customerField: string[];
  customerFieldEnable: boolean;
  customerFieldDisabled?: boolean;
  enableInstallment: boolean;
  paymentVoucher: boolean;
  paymentDebit: boolean;
  paymentCredit: boolean;
  paymentPix: boolean;
  kioskName: string;
  prism: boolean;
  serviceRate: boolean;
  serviceRatePercent: number;
  orderPad: { id: string; number: string }[];
  configSymstem: {
    systemType: SystemType;
    printType: PrintType;
    enableRemotePrint?: boolean;
  }[];
  messageOnPayment: string;
  printReceipt: boolean;
  newPrinter: boolean;
  oldPrinter: boolean;
  weighingMachineMode: boolean;
  paymentCashless: boolean;
  paymentNominalCashless: boolean;
  enableSchedule?: boolean;
  onlyNextTime?: boolean;
  enableCpfRegister?: boolean
  hideZeroPrice?: boolean;
  orderToGo: boolean;
  verifyInventory: boolean;
  inventoryMovementType: MovementType;
  blockOrderIfEmptyInvetory: boolean;
  requestDocumentOnConsumeCashless: boolean;
  newPosPaymentApi: boolean;
  minimumConsumption: boolean;
  expirationTime?: number;
  onlineCashlessControl?: boolean;
  printingSectors?: IPrintSector[],
  duplicateRemotePrint?: boolean,
  searchMeepCardByName?: string,
  useStrongPassword?: boolean;
  enableRecharge?: boolean;
  doubleValidationOnlineRecharge?: boolean;
  profilerNamePDV?: string;
  generateEletronicInvoiceForRecharge?: boolean;
}
