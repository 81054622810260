import React, { useState, RefObject, createRef, useEffect, useImperativeHandle, forwardRef, memo, } from "react";
import { ProdutoLista } from "../produtoLista/ProdutoLista";
import "./ProdutoListaSeccionado.css";
import { SeccionedProduct } from "../../../../../../domains/catalog/aggregates/SeccionedProduct";
import { debounce } from 'throttle-debounce';
import animationData from '../../../../../../assets/lottie/loading_red.json'
import { ProdutoTipo } from "Infrastructure/repositories/api/PedidoMeep";


interface ProdutoListaSeccionadoProps {
  produtosSeccionado: SeccionedProduct[];
  onChangeCategory?: (index: number) => void;
  isClosed?: boolean;
}

export interface ProdutoListaSeccionadoInterface {
  scrollToCategoria: (index: number) => void
}

export const ProdutoListaSeccionado = memo(forwardRef<ProdutoListaSeccionadoInterface, ProdutoListaSeccionadoProps>((
  props, ref
) => {
  const [refDiv, setRefDiv] = useState<RefObject<HTMLDivElement>[]>([]);
  const { onChangeCategory } = props


  const [load, setload] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setload(true)
    }, 800);

  }, [])

  useImperativeHandle(ref, () => ({
    scrollToCategoria: (index: number) => {
      scrollToCategoria(index);
    }
  }));


  function scrollToCategoria(index: number) {
    if (refDiv.length > 0) {
      let _ref = refDiv[index].current
      if (_ref) {
        window.scrollTo({
          top: _ref.offsetTop + 210,
          behavior: 'smooth',
        }
        )
      }
    }
  }

useEffect(() => {
  const handleScroll = debounce(200, () => {
    const scrollPosition = window.scrollY;
    if (!Array.isArray(refDiv) || refDiv.length === 0) return;

    let activeIndex = -1;
    for (let i = 0; i < refDiv.length; i++) {
      const element = refDiv[i].current;
      if (!element) continue;
      const { offsetTop, offsetHeight } = element;
      if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
        activeIndex = i;
        break;
      }
    }

    if (onChangeCategory && activeIndex !== -1) {
      onChangeCategory(activeIndex);
    }
  });

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, [refDiv, onChangeCategory]);

  useEffect(() => {
    if (props.produtosSeccionado.length > 0) {
      let refs = props.produtosSeccionado.map(() => (createRef<HTMLDivElement>()))
      setRefDiv(refs)
    }
  }, [props.produtosSeccionado])

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  return (
    <div className="produto-lista-seccionado" style={{opacity: props?.isClosed ? 0.5 : undefined}}>
      {load ? props.produtosSeccionado.map((SeccionedProducts, index) => {
        // Verifica se algum produto na seção tem type igual a 3
        const hideProductCatalog = SeccionedProducts.products.some(item => item.hideCatalog !== true);

        // Renderiza a div somente se não houver produtos com type igual a 3
        return hideProductCatalog && (
          <div ref={refDiv[index]} key={index} className={"sessao"}>
            <div className={"titulo"}>{SeccionedProducts.title}</div>
            <div className={"container-produto"}>
              <ProdutoLista
                produtos={SeccionedProducts.products.filter(item => item.type !== ProdutoTipo.Kilo)}
              ></ProdutoLista>
            </div>
          </div>
        );
      })
        :
        <div style={{ margin: "64px auto", }} >
          {/* <Lottie options={defaultOptions}
          height={150}
          width={150} /> */}

        </div>
      }
    </div>
  );
}));

