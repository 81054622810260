import { IOrderComposition } from "../../domain/models/IOrderComposition";
import { v4 } from "uuid";
import { ICartItem } from "../../domain/models/ICart";
import { IProductWithComposition as IProductWithCompositionCart } from "../../domain/models/IProductWithCompositionCart";

export const AddCartItemByProductUseCase = (
  prevCartItems: ICartItem[],
  product: IProductWithCompositionCart,
  quantity: number,
  orderCompositions: IOrderComposition[],
  notes?: string
): ICartItem[] => {
  const compareComposition = (
    composition?: IOrderComposition[] | null,
    compositionToCopare?: IOrderComposition[]
  ) => {
    try {
      const compositionString = JSON.stringify(composition);
      const compositionToCopareString = JSON.stringify(compositionToCopare);
      return compositionString === compositionToCopareString;
    } catch (error) {
      return false;
    }
  };

  const calcTotalPriceItem = (
    _product: IProductWithCompositionCart,
    _orderComposition?: IOrderComposition[]
  ) => {
    const totalComposition =
      _orderComposition?.reduce((a, b) => a + b.totalPrice, 0) ?? 0;

    const price = _product.discount
      ? _product.price - _product.price * (_product.discount / 100)
      : _product.price;

    return price + totalComposition;
  };

  const productToCartItem = (
    _product: IProductWithCompositionCart,
    _quantity: number,
    _orderCompositions: IOrderComposition[],
    notes?: string
  ) => {
    const newCartItem: ICartItem = {
      id: v4(),
      name: _product.name,
      category: _product.category,
      price: _product.discount
        ? _product.price - _product.price * (_product.discount / 100)
        : _product.price,
      description: _product.description,
      productPrice: _product.discount
        ? _product.price - _product.price * (_product.discount / 100)
        : _product.price,
      productId: _product.id,
      productType: _product.type,
      quantity: _quantity,
      imageUrl: _product.imageUrl,
      thumbnailUrl: _product.thumbnailUrl,
      notes: notes,
      barcode: _product.barcode,
      orderComposition: _orderCompositions,
      printerName: _product.printerName,
      totalPrice: calcTotalPriceItem(product, _orderCompositions),
      hideCatalog: _product.hideCatalog,
      compositions: _product.compositions ?? [],
      realPrice: _product.realPrice,
      isWeight: _product.isWeight,
      discount: _product.discount,
      isBarcodeUnity: _product.isBarcode,
    };
    return newCartItem;
  };

  const addQuantityInExitCartItem = (
    _prevCartItems: ICartItem[],
    _id: string,
    _quantity: number
  ) => {
    const newCartItems = _prevCartItems.map((cartItem) => {
      if (cartItem.id === _id) {
        return { ...cartItem, quantity: cartItem.quantity + _quantity };
      } else {
        return cartItem;
      }
    });
    return newCartItems;
  };

  const addNewCartItem = (
    _prevCartItems: ICartItem[],
    _product: IProductWithCompositionCart,
    _quantity: number,
    _orderComposition: IOrderComposition[],
    _notes?: string
  ) => {
    const newCartItems = [
      ..._prevCartItems,
      productToCartItem(_product, _quantity, _orderComposition, _notes),
    ];
    return newCartItems;
  };

  const existProductInCart = (
    _prevCartItems: ICartItem[],
    _product: IProductWithCompositionCart,
    _quantity: number,
    _orderCompositions?: IOrderComposition[]
  ) => {
    const existCartItem = _prevCartItems.find(
      (cartItem) =>
        cartItem.productId === _product.id &&
        !cartItem?.isWeight &&
        compareComposition(cartItem.orderComposition, _orderCompositions)
    );
    return existCartItem;
  };

  const addCartItem = (
    _prevCartItems: ICartItem[],
    _product: IProductWithCompositionCart,
    _quantity: number,
    _orderCompositions: IOrderComposition[],
    _notes?: string
  ) => {
    const existCartItem = existProductInCart(
      _prevCartItems,
      _product,
      _quantity,
      _orderCompositions
    );
    const dontHaveComposition = !_orderCompositions?.length;

    if (
      existCartItem &&
      dontHaveComposition &&
      !_notes &&
      !_product.isWeight &&
      !existCartItem?.isWeight
    ) {
      const newCartItems = addQuantityInExitCartItem(
        _prevCartItems,
        existCartItem.id,
        _quantity
      );
      return newCartItems;
    } else {
      const newCartItems = addNewCartItem(
        _prevCartItems,
        _product,
        _quantity,
        _orderCompositions,
        notes
      );
      return newCartItems;
    }
  };

  const execute = () => {
    return addCartItem(
      prevCartItems,
      product,
      quantity,
      orderCompositions,
      notes
    );
  };

  return execute();
};
