import { TourRepository } from 'Infrastructure/repositories/api/TourRepository'
import { UserDataByCpfResponse } from 'modules/tour/domain/dtos/UserDataByCpfResponse'

const tourRepository = TourRepository()

const GetUserDataByCpfUseCase = async (cpf: string, localId: string): Promise<UserDataByCpfResponse> => {

    const response = await tourRepository.getUserDataByCpf(cpf, localId)

    return (
        {
            ...response,
            birthday: `${new Date(response.birthday).getUTCDate()}/${new Date(response.birthday).getUTCMonth() + 1}/${new Date(response.birthday).getUTCFullYear()}`
        }
    )
}

export default GetUserDataByCpfUseCase
