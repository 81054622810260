import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import PickerService from './components/pickerService/PickerService'
import PickerColaborator from './components/pickerColaborator/PickerColaborator'
import PickerDate from './components/pickerDate/PickerDate'
import PickerTime from './components/pickerTime/PickerTime'
import { Toolbar } from '../catalog/components/toolbar/Toolbar'
import useSchedulePage from './UseSchedulePage'
import { BotaoGrande } from '../../components/botaoGrande/BotaoGrande'
import ScheduleCartProvider, { useScheduleCart } from './components/scheduleCart/ScheduleCart'
import styles from './SchedulePage.module.scss'
import PickerCategoryService, { IPIckerCategoryServiceRef } from './components/pickerCategoryServivce/PickerCategoryService'
import SchedulCartToolbar from './components/scheduleCart/schedulCartToolbar/SchedulCartToolbar'
import { Drawer } from '@material-ui/core'
import ListScheduleCart from './components/scheduleCart/listScheduleCart/ListScheduleCart'
import { IOrderProviderRef } from 'modules/order/presentation/OrderProvider'

interface ISchedulePageProps {
    orderProvider: React.RefObject<IOrderProviderRef>
}

const SchedulePage: FC<ISchedulePageProps> = ({ orderProvider }) => {
    const {
        services,
        selectedService,
        colaborators,
        selectedColaborator,
        selectedDate,
        selectedTime,
        demandedServices,
        collaboratorSchedule,
        now,
        setSelectedService,
        setSelectedColaborator,
        setSelectedDate,
        setSelectedTime,
        autoSelectSchedule,
        selectNextAbailableCollaborator,
        onClickConfirmarAgendamento,
        categoryServices,
        selectedCategoryService,
        setSelectedCategoryService,
        onClickBack,
    } = useSchedulePage();

    const { scheduleCartItems, total, remover, onClickContinuar } = useScheduleCart()

    const pickerCategoryRef = useRef<IPIckerCategoryServiceRef>(null)

    const order = (a: { name: string }, b: { name: string }) => {

        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        // a must be equal to b
        return 0;
    }

    
    
    const [openCart, setopenCart] = useState(false);
    
    const onClickSelectOther = useCallback(() => {
        setopenCart(false);
        pickerCategoryRef.current?.openDrawer();
    }, [])


    useEffect(() => {
        if (!!scheduleCartItems.length) {
            setopenCart(true)
        } else {
            setopenCart(false)
        }
    }, [scheduleCartItems])

    return (
        <ScheduleCartProvider orderProvider={orderProvider}>
            <div id={styles.ScheudulePage}>
                <Toolbar onPressVoltar={onClickBack}></Toolbar>
                {/* <h1>Agendamento</h1> */}
                {
                    !!categoryServices.length &&
                    <PickerCategoryService ref={pickerCategoryRef} categoryServices={categoryServices} value={selectedCategoryService} onChange={setSelectedCategoryService} />
                }
                {
                    !!services.length && selectedCategoryService &&
                    <PickerService services={services.sort(order)} value={selectedService} onChange={setSelectedService} />
                }
                {
                    selectedService && !!colaborators.length &&
                    <PickerColaborator
                        autoSelectSchedule={autoSelectSchedule}
                        colaborators={colaborators}
                        value={selectedColaborator}
                        onChange={setSelectedColaborator}
                        onClickSelectNext={selectNextAbailableCollaborator}
                    />
                }

                {
                    selectedColaborator &&
                    (<PickerDate
                        disabledClick={autoSelectSchedule}
                        value={selectedDate}
                        availableDays={collaboratorSchedule?.availableDays}
                        onChange={setSelectedDate}
                    />)
                }
                {
                    selectedDate &&
                    (<PickerTime
                        disabledClick={autoSelectSchedule}
                        collaboratoSchedule={collaboratorSchedule}
                        date={selectedDate}
                        demmandedServices={demandedServices}
                        value={selectedTime}
                        onChange={setSelectedTime}
                        now={now}
                    />)
                }

                {
                    selectedDate && selectedTime &&
                    <div className={styles.botaoContainer}>
                        <BotaoGrande onClick={onClickConfirmarAgendamento}>
                            Adicionar no Carrinho
                        </BotaoGrande>
                    </div>
                }


                <SchedulCartToolbar total={total} quantidade={scheduleCartItems.length} onClick={() => setopenCart(true)} />

                <Drawer anchor={'bottom'} open={openCart} onClose={() => { setopenCart(false) }}>
                    <ListScheduleCart onClickSelecionarMais={onClickSelectOther} onClickClose={() => { setopenCart(false) }} onClickRemove={remover} onClickContinuar={onClickContinuar} scheduleCartItems={scheduleCartItems}></ListScheduleCart>
                </Drawer>
            </div>
        </ScheduleCartProvider>
    )
}



export default SchedulePage;
