import { TourDetail } from 'modules/tour/domain/models/CatalogTour'
import React, { FC, useContext, createContext, useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import { ReserveTour } from '../reserverTourResume/ReserveTourResume.type'
import { ICartTourItem } from './ICartTourItem'

interface ITourCartContext {
    cartTourItems: ICartTourItem[]
    totals: { price: number, quantity: number }
    addTourCart: (reserveTour: ReserveTour, tour: TourDetail) => void
    removeTourCart: (cartTourId: string) => void
    clearTourCart: () => void,
}


const TourCartContext = createContext<ITourCartContext>({} as ITourCartContext)

const TourCartProvider: FC = ({ children }) => {


    const [cartTourItems, setCartTourItems] = useState<ICartTourItem[]>([])
    const [totals, setTotals] = useState({ price: 0, quantity: 0 })

    const addTourCart = useCallback(
        (reserveTour: ReserveTour, tour: TourDetail) => {
            setCartTourItems(prev => ([...prev, { id: v4(), reserveTour, tour }]))
        },
        [],
    )
    const removeTourCart = useCallback(
        (cartTourId: string) => {
            setCartTourItems(prev => (prev.filter(item => item.id !== cartTourId)))
        },
        [],
    )

    const clearTourCart = useCallback(
        () => {
            setCartTourItems([])
        },
        [],
    )


    useEffect(() => {
        const totalPrice = cartTourItems.flatMap(item => item.reserveTour.totalPrice).reduce((prev, current) => (prev + current), 0);
        const totalQuantity = cartTourItems.flatMap(item => item.reserveTour.customers.length).reduce((prev, current) => (prev + current), 0);
        setTotals({ price: totalPrice, quantity: totalQuantity });
        return () => {

        }
    }, [cartTourItems])

    return (
        <TourCartContext.Provider
            value={
                {
                    cartTourItems,
                    totals,
                    addTourCart,
                    removeTourCart,
                    clearTourCart
                }
            }
        >
            {children}
        </TourCartContext.Provider>
    )
}



export default TourCartProvider


export const useTourCart = () => {
    const context = useContext(TourCartContext);
    return context;
};