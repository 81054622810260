import React, { memo, useState, useCallback, useEffect, useMemo } from "react";
import { Store } from "../../../../domains/catalog/aggregates/Store";
import { ItemStore } from "./Components/itemStore/ItemStore";
import "./StoresListPage.css";
import { Toolbar } from "../../components/toolbar/Toolbar";
import { useRouteMatch, useHistory } from "react-router-dom";
import { ListaCategorias } from "../listaCategorias/ListaCategorias";
import { useCatalog } from "../../../../application/contexts/catalog/CatalogContext";
import { useUi } from "../../../../application/contexts/ui/UIContext";
import { useKiosk } from "application/contexts/kiosk/KioskContext";

export const StoresListPage = memo(() => {
  const [filtroStore, setfiltroStore] = useState<string>("");
  const { path } = useRouteMatch();
  const { catalog } = useCatalog();
  const history = useHistory();
  const { kiosk } = useKiosk();

  const wallpaper = useMemo(() => (kiosk?.profilerNamePDV && kiosk?.wallpaperImageId) ? `${process.env.REACT_APP_URL_IMAGEM}wallpapers/${kiosk.wallpaperImageId}.png` : kiosk?.wallpaperImageUrl, [kiosk])

  const [stores, setstores] = useState<Store[]>();
  const { transitionPageAnimation } = useUi();

  const getStore = useCallback(() => {
    if (catalog?.stores) {
      setstores(catalog?.stores);
    }
  }, [catalog])

  const obterStores = useCallback(() => {
    getStore();
    return () => { };
  }, [getStore]);

  useEffect(() => {
    obterStores();
  }, [obterStores]);

  useEffect(() => {
    if (catalog) {
      setfiltroStore("all");
    }
  }, [catalog]);

  const onClickStore = useCallback(
    (index: number) => {
      transitionPageAnimation(() => {
        history.push(path + "/store/" + index);
      })
    },
    [history, path, transitionPageAnimation]
  );



  const onPressVoltarHandle = useCallback(
    () => {

      history.replace(`/`);
    },
    [history],
  )

  return (
    <div className="container-store">
      <Toolbar
        imageUrl={wallpaper ?? catalog?.imageUrl}
        title={catalog?.name}
        onPressVoltar={onPressVoltarHandle}
      ></Toolbar>
      {!filtroStore && catalog ? (
        <>
          <ListaCategorias
            catalog={catalog}
            onClickCategoria={(id) => {
              setfiltroStore(id);
            }}
            onClickTodos={() => setfiltroStore("all")}
          />
        </>
      ) :
        (
          <>
            <div className="container-item-store">
            {stores?.sort((a, b) => a.order - b.order).filter(
                (store) =>
                  store.tags.findIndex((tag) => tag.id === filtroStore) !== -1 || filtroStore === "all"
              ).map((store, index) => (
                <>
                  <ItemStore
                    isClosed={store.isClosed}
                    key={store.id}
                    nome={store.name}
                    imagemUrl={store.thumbnailUrl}
                    id={store.id}
                    onClick={() => onClickStore(index)} />
                </>
              ))}
            </div>
          </>
        )}
    </div>
  );
});
