import { IKioskRepository } from "../../../domains/kiosk/interfaces/repositories/IKioskRepository";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { ILocalStorageRepository } from "../../interfaces/ILocalStorageRepository";
import { IConfigRepository } from "../../interfaces/IConfigRepository";
import { ConfigTotemMeep, configTotemMeepToKiosk } from "./ConfigTotemMeep";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import { HttpRepository } from "./_HttpRepository";
import { LocalStorageRepository } from "./_LocalStorageRepository";
import { CefSharpConfigRepository } from "../../services/cefSharpService/local/CefSharpConfigRepository";

const keyStorage = "@kiosk";

export const KioskRepository = (
): IKioskRepository => {

  const httpRepository: IHttpRepository = HttpRepository();
  const localStorageRepository: ILocalStorageRepository = LocalStorageRepository();
  const config: IConfigRepository = CefSharpConfigRepository();

  const syncAsync = async () => {
    const configs = await config.getConfigAsync()
    const reponseConfigMeep = await httpRepository.postAsync<any, ConfigTotemMeep>
      ("/UsuarioMeepPOS/AtualizarDadosEquipamento",
        {
          IMEI: configs.macAddress,
          // IMEI: '00215D299378',
          UsuarioMeepPOSId: configs.equipamentId,
        }
      )
    localStorageRepository.post(keyStorage, configTotemMeepToKiosk(reponseConfigMeep));
    console.log("sync configKiosk", configs);
    return {
      ...configTotemMeepToKiosk(reponseConfigMeep),
      token: configs.token,
    };
  };


  const getAsync = async (): Promise<Kiosk | null> => {
    let kioskValues: Kiosk | null = await localStorageRepository.get<Kiosk>(
      keyStorage
    );
    return kioskValues ?? null;
  };



  return { getAsync, syncAsync };
};

