import { useUi } from 'application/contexts/ui/UIContext';
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Tour } from '../../../domain/models/CatalogTour'
import { useCatalogTour } from '../../contexts/TourCatalogContext'

const UseTourDetail = () => {
    const [tourDetail, settourDetail] = useState<Tour>();
    const { replace } = useHistory();
    const { storeTourId, tourId } = useParams<{ storeTourId: string, tourId: string }>();


    // const { tourId } = useParams<{ tourId: string }>();

    const { getTourById } = useCatalogTour();
    const { showLoading, hideLoading } = useUi()
    useEffect(() => {
        showLoading();
        console.log('TOUR',tourId)
        getTourById(tourId).then((tour) => {
            settourDetail(tour);

        }).finally(() => {
            hideLoading();
        });
    }, [tourId, getTourById])

    const onPressBack = useCallback(
        () => {
            replace(`/tour/storeTour/${storeTourId}`)
        },
        [],
    )
    const onClickReserveHandle = useCallback(
        (id: string) => {
            replace(`/tour/storeTour/${storeTourId}/reserve/${id}`)
        },
        [storeTourId],
    )
        
    return (
        {
            tourDetail,
            tourId,
            onPressBack,
            onClickReserveHandle
        }
    )
}

export default UseTourDetail
