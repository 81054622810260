
import {
  OfflineRechargeStatus,
  Order,
  OrderLog,
  PedidoPosOrderLog,
  ProdutoPedidoPosOrderLog,
  RePrintOrderSyncObject,
} from "../../../domains/order/agreggates/order/Order";
import { IOrderRepositoryDB } from "../../../domains/order/interfaces/repositories/IOrderRepositoryDB";
import moment from "moment";
import { IHttpRepository } from "../../interfaces/IHttpRepository";

import localforage from "localforage"
import { HttpRepository } from "../api/_HttpRepository";
const KEY = "@meep/Orders";

export interface IIndexedOrderRepository {
  add(order: OrderLog, log: string): Promise<void>;
  findById(id: string): Promise<Order | null>;
  all(): Promise<Array<Order>>;
}

export const OrderRepositoryLocalStorage = (
): IOrderRepositoryDB => {
  const httpRepository: IHttpRepository = HttpRepository()

  const add = async (order: OrderLog, log: string, pix?: string): Promise<void> => {
    try {
      let orders = await localforage.getItem<OrderLog[]>(KEY) ?? []
      const index = orders.findIndex(it => it?.id === order.id);
      if (index >= 0) {
        let pixLogs = [...orders[index]?.pixLog ?? []];
        let logs = [...orders[index]?.log ?? []];
        if (pix) {
          pixLogs.push({
            time: moment().utc().format(),
            message: log,
            pix: pix
          })
        } else {
          logs.push({
            time: moment().utc().format(),
            message: log,
          })
        }
        orders[index] = {
          ...orders[index],
          isDataRecorded: ("isDataRecorded" in order) ? order.isDataRecorded : ("isDataRecorded" in orders[index]) ? orders[index].isDataRecorded : null,
          isOfflineRecharge: order?.isOfflineRecharge ?? orders[index]?.isOfflineRecharge,
          offlineRechargeStatus: order?.offlineRechargeStatus ?? orders[index].offlineRechargeStatus,
          payment: order?.payment ?? orders[index]?.payment, 
          paymentPix: order?.paymentPix ?? orders[index]?.paymentPix,
          pedidoPOS: order?.pedidoPOS ?? orders[index]?.pedidoPOS, 
          paymentApproved: order?.paymentApproved ?? orders[index]?.paymentApproved,
          nfce: order?.nfce ?? orders[index]?.nfce,
          orderItems: order?.orderItems ?? orders[index]?.orderItems,
          totalValue: order?.totalValue ?? orders[index]?.totalValue,
          log: logs,
          pixLog: pixLogs
        }
      } else {
        const orderToAddLog = {
          ...order,
          log: [
            {
              time: moment().utc().format(),
              message: log,
            }
          ]
        }
        orders.push(orderToAddLog)
      }
      if (orders.length > 200) {
        // Os logs ficam salvos na pasta %TEMP%
        // ${GUID}.txt
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(orders.filter((_, index) => (index <= 100)))], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${new Date().getTime()}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
        await localforage.setItem(KEY, orders.filter((_, index) => (index > 100)))
      } else {
        await localforage.setItem(KEY, orders.filter(it => it))
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAllPendingRecharge = async (): Promise<OrderLog[]> => {
    try {
      const orders = await localforage.getItem<OrderLog[]>(KEY) ?? []
      return orders.filter(order => (order.offlineRechargeStatus === OfflineRechargeStatus.RECHARGE)) ?? []
    } catch (error) {
      return []
    }
  };

  const findPendingRecharge = async (associationId: string): Promise<OrderLog | null> => {
    try {
      const orders = await localforage.getItem<OrderLog[]>(KEY) ?? []
      return orders.find(order => (order.customer?.associationId === associationId && order.offlineRechargeStatus === OfflineRechargeStatus.RECHARGE)) ?? null
    } catch (error) {
      return null
    }
  };

  const findById = async (id: string): Promise<OrderLog | null> => {
    try {
      const orders = await localforage.getItem<OrderLog[]>(KEY) ?? []
      return orders.find(order => order.id === id) ?? null
    } catch (error) {
      return null
    }
  };

  const all = async (): Promise<OrderLog[]> => {
    try {
      const orders = await localforage.getItem<OrderLog[]>(KEY) ?? []
      return orders.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
    } catch (error) {
      return []
    }
  };

  const syncRePrint = async (
    rePrintRequest: RePrintOrderSyncObject
  ): Promise<void> => {
    try {
      const operatorsMeep = await httpRepository.postAsync(
        `/Ticket/Reimpressao/`,
        rePrintRequest
      );
    } catch (error) {
      //logar erro
    }
  };


  const updateRePrintTicket = async (ticketId: string, rePrintRequest: RePrintOrderSyncObject) => {

    const orders: OrderLog[] = await localforage.getItem<OrderLog[]>(KEY) ?? [];


    const newOrders = orders.map((orderLog: OrderLog) => {
      
      if (orderLog?.pedidoPOS) {
        const newProdutosObject = orderLog.pedidoPOS?.produtosObject?.map((produtoPOSOrderLog) => {
          if (produtoPOSOrderLog.ticketId === ticketId) {
            const newProdutoPos: ProdutoPedidoPosOrderLog = {
              ...produtoPOSOrderLog,
              reprinted: produtoPOSOrderLog.reprinted ? [...produtoPOSOrderLog.reprinted, rePrintRequest] : [rePrintRequest]
            }
            return newProdutoPos
          }
          return produtoPOSOrderLog
        })

        const newPedidoPos: PedidoPosOrderLog = {
          ...orderLog.pedidoPOS,
          produtosObject: newProdutosObject,
        }
        return { ...orderLog, pedidoPOS: newPedidoPos }
      }
    })

    await localforage.setItem(KEY, newOrders)
  }


  return {
    add,
    findById,
    all,
    syncRePrint,
    updateRePrintTicket,
    findPendingRecharge,
    getAllPendingRecharge
  };
};
