import InventoryRepository from 'Infrastructure/repositories/api/InventoryRepository'
import { useKiosk } from 'application/contexts/kiosk/KioskContext'
import { useUi } from 'application/contexts/ui/UIContext'
import { useCallback } from 'react'
import { Order } from 'domains/order/agreggates/order/Order'

const invetoryService = InventoryRepository();

const UseInventory = () => {
    const { toast } = useUi();
    const { kiosk } = useKiosk();

    const verifyInventory = useCallback(async (order: Order) => {
        let verified = true;
        console.log({verifyInventory: kiosk?.verifyInventory})
        if (kiosk?.verifyInventory) {
            const response = await invetoryService.veryInventory(order, kiosk?.localId)
            verified = response.every(it => it.availability);
            response.forEach(item => {
                if (!item.availability) {
                    const cartProduct = order.orderItems.find((cartItem) => cartItem.productId === item.productId);
                    if(item.quantityRemain){
                        toast("Desculpe, mas só temos " + item.quantityRemain + " x " + cartProduct?.name + " em estoque!", "error");
                    } else if (item.quantityRemain === 0) {
                        toast("Desculpe, mas estamos sem " + cartProduct?.name + " em estoque!", "error");
                    }
                }
            });
        }
        return verified;
    }, [kiosk, toast])


    return ({
        verifyInventory
    })
}

export default UseInventory