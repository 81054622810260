import React, { FC, memo, useRef } from "react";
import "./App.css";
import "./cores.css";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import { Inicio } from "./areas/inicio/Inicio";
import { Admin } from "./areas/admin/Admin";
import { Catalog } from "./areas/catalog/Catalog";
import { BarCode } from "./areas/barCode/BarCode";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { CatalogProvider } from "./application/contexts/catalog/CatalogContext";
import { UIProvider } from "./application/contexts/ui/UIContext";
import { AuthProvider } from "./application/contexts/auth/AuthContext";
import { CardReaderProvider } from "./application/contexts/cardReader/CardReaderContext";
import {
  KioskConsumer,
  KioskProvider,
} from "./application/contexts/kiosk/KioskContext";
import { SyncTimer } from "./components/syncTimer/SyncTimer";
import { AdminProvider } from "./application/contexts/admin/adminContext";
import { SessionProvider } from "./application/contexts/session/SessionContext";
import { Cashless } from "./areas/cashless/Cashless";
import { StoresListPage } from "./areas/catalog/pages/storesListPageChurch/StoresListPage";
import StorePage from "./areas/catalog/pages/storePage/StorePage";
import { PrinterProvider } from "./application/contexts/printer/PrinterContext";
import WeighingMachineProvider from "application/contexts/weighingMachine/WeighingMachine";
import SchedulePage from "./areas/schedule/SchedulePage"
import WeighingMachinePage from "./areas/weighingMachine/WeighingMachinePage";
import StoreTourPage from "./modules/tour/presentation/pages/storeTour/StoreTourPage";
import TourPage from "./modules/tour/presentation/pages/TourPage";
import ReserveTourPage from "./modules/tour/presentation/pages/reserveTour/ReserveTourPage";
import { CatalogTourProvider } from "./modules/tour/presentation/contexts/TourCatalogContext";
import TourCartProvider from "modules/tour/presentation/components/tourCart/TourCartContext";
import { AboutProject } from "modules/tour/presentation/pages/aboutProject/AboutProject";
import TourDetail from "modules/tour/presentation/pages/tourDetail/TourDetail";
import { ThemeProvider } from "application/contexts/theme/ThemeContext";
import { OfflineRecharge } from "areas/cashless/offlineRecharge/OfflineRecharge";
import RequestDocument from "components/requestDocument/RequestDocument";
import OrderProvider, { IOrderProviderRef } from "modules/order/presentation/OrderProvider";
import { AppInsightsProvider } from "Infrastructure/repositories/appInsights/AppInsights";

// const storePromise = import('./areas/catalog/pages/storePage/StorePage');

// const LazyStorePage = React.lazy(() => storePromise);

const App: FC = memo(() => {
  const orderProvider = useRef<IOrderProviderRef>(null);

  return (
    <div className="App">
      {/* <ErrorBoundary> */}
      <UIProvider>
        <BrowserRouter>
          <AuthProvider>
            <KioskProvider>
              <KioskConsumer>{({ kiosk }) =>
                <AppInsightsProvider kiosk={kiosk}>
                  <ThemeProvider>
                    <SessionProvider>
                      <CardReaderProvider>
                        {/* <PaymentProvider> */}
                          <CatalogProvider>
                            <PrinterProvider>
                              {/* <CartProvider> */}
                                <OrderProvider ref={orderProvider} />
                                <WeighingMachineProvider>
                                    <SyncTimer />
                                    {
                                      <Switch>
                                        <>
                                          <Route exact path="/" component={Inicio} />
                                          <Route path="/barcode" component={BarCode} />
                                          <Route path="/weighing" >
                                            <WeighingMachinePage />
                                          </Route>
                                          <Route path="/catalog"  >
                                            <Catalog>
                                              <Switch>
                                                <Route exact path={"/catalog"}>
                                                  {
                                                    StoresListPage &&
                                                    // <StoresListPage />
                                                    <StoresListPage />
                                                  }
                                                </Route>
                                                <Route path={"/catalog/store/:storeId"}>
                                                  <StorePage />
                                                </Route>
                                              </Switch>
                                            </Catalog>
                                          </Route>
                                          <Route path="/cashless/:type" component={() => <Cashless orderProvider={orderProvider}/>} />
                                            <Route path="/offlineRecharge" component={() => <OfflineRecharge orderProvider={orderProvider}/>} />
                                          <Route path="/validateDocument" component={RequestDocument} />
                                          <Route path="/admin" >
                                            <AdminProvider>
                                              <Admin></Admin>
                                            </AdminProvider>
                                          </Route>
                                          <Route path="/schedule" >
                                            <SchedulePage orderProvider={orderProvider}/>
                                          </Route>
                                          <Route path="/tour" >
                                            <CatalogTourProvider>
                                              <TourCartProvider>
                                                <Switch>
                                                  <Route exact path="/tour/storeTour/:storeTourId/reserve/:tourId" >
                                                    <ReserveTourPage />
                                                  </Route>
                                                  <Route exact path="/tour/storeTour/:storeTourId" >
                                                    <StoreTourPage />
                                                  </Route>
                                                  <Route exact path="/tour/" >
                                                    <TourPage />
                                                  </Route>
                                                  <Route exact path={`/tour/storeTour/:storeTourId/moreDetails/:tourId`}>
                                                    <TourDetail />
                                                  </Route>
                                                  <Route exact path="/tour/aboutProject" >
                                                    <AboutProject />
                                                  </Route>
                                                </Switch>
                                              </TourCartProvider>
                                            </CatalogTourProvider>
                                          </Route>
                                        </>
                                      </Switch>
                                    }
                                </WeighingMachineProvider>
                              {/* </CartProvider> */}
                            </PrinterProvider>
                          </CatalogProvider>
                        {/* </PaymentProvider> */}
                      </CardReaderProvider>
                    </SessionProvider>
                  </ThemeProvider>
                </AppInsightsProvider>
              }</KioskConsumer>
            </KioskProvider>
          </AuthProvider>
        </BrowserRouter>
      </UIProvider>
      {/* </ErrorBoundary> */}

    </div >
  );
});

export default App;

const SwitchAnimation: FC = (props) => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        mountOnEnter
        unmountOnExit
        classNames="transition-router"
        timeout={300}
      >
        <Switch location={location}>{props.children}</Switch>
      </CSSTransition>
    </TransitionGroup>
  );
};
