import { IconButton } from '@material-ui/core'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import { IMoreDescription } from 'modules/tour/domain/models/CatalogTour'
import React, { FC, useState } from 'react'
import styles from './CardMoreDescription.module.scss'


interface ICardMoreDescriptionProps {
    value: IMoreDescription
}

export const CardMoreDescription: FC<ICardMoreDescriptionProps> = ({ value }) => {


    const [openDescription, setOpenDescription] = useState(false)

    return (
        <div id={styles.ContainerDescription}>
            <div className={styles.title} onClick={() => setOpenDescription((prev) => !prev)}>
                <p>{value.title}</p>
                <IconButton>
                    {!openDescription ? (
                        <KeyboardArrowDown />
                    ) : (
                        <KeyboardArrowUp />
                    )}
                </IconButton>
            </div>
            <div
                className={
                    openDescription ? styles.showCard : styles.hideCard
                }
            >
                <div className={styles.moreDescription}>
                    {/* <p> {value.description.split('\n').map((str: any) => <p>{str}</p>)}</p> */}
                    <p dangerouslySetInnerHTML={{ __html: value.description.replace(/<script>/g, '') }}></p>
                </div>
            </div>

        </div>
    )
}
