import { IAdminRepository } from "../../../domains/admin/interfaces/repositories/IAdminRepository";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { HttpRepository } from "./_HttpRepository";

export const AdminRepository = (
): IAdminRepository => {

    const httpRepository: IHttpRepository = HttpRepository();

    const syncCancelPayment = async (orderId: string, operatorId: string, password: string) => {
        const response = await httpRepository.postAsync("/Proprietario/EstornarPedidoPOS", {
            pedidoPOSId: orderId,
            operadorId: operatorId,
            senha: password

        })
        // return (response)
    }

    return ({ syncCancelPayment })
}