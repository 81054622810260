import React, { FC } from 'react'
import { INFCeResult } from '../../../domains/order/agreggates/EletronicInvoiceTicket/IEletronicInvoice'
import QRCode from 'qrcode.react'
import { Kiosk } from '../../../domains/kiosk/aggregates/Kiosk'
import { EletronicInvoiceTicket } from '../../../domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket'
import "./InvoiceLayoutPrinter.css"
interface InvoiceLayoutPrinterProps {
    invoice: EletronicInvoiceTicket
    kiosk: Kiosk
}

export const InvoiceLayoutPrinter: FC<InvoiceLayoutPrinterProps> = ({ invoice, kiosk }) => {
    return (
        <div id="invoiceLayoutPrinter">
            <div className="container">
                <div className="title">
                    NFCe
                </div>
                <div className="header">
                    {/* <div className="logo">
                            <img width={150} src={require('./../../../assets/images/logo-meep.png')}></img>
                        </div> */}
                    <div className="localName">
                        {invoice.companyName ? invoice.companyName : kiosk.localName}
                    </div>
                    <div className="localName">
                        CNPJ:{invoice.companyDocument}
                    </div>
                </div>
                <div className="item">
                    <div className="value">
                        Documento:
                        </div>
                    <div className="value">
                        {invoice.clientName}
                    </div>
                </div>
                <div>Pedido:</div>
                {invoice.itens.map((item, key) => (
                    <div key={key} className="product">
                        <div className="productName">
                            {item.quantity}x{item.name}
                        </div>
                        <div className="productPrice">
                            R${item.value.toFixed(2).replace(".", ",")}
                        </div>
                    </div>
                ))}


                <div className="total">
                    <div className="label">
                        Total
                    </div>
                    <div className="productPrice">
                        R${invoice?.totalValue?.toFixed(2).replace(".", ",")}
                    </div>
                </div>

                <div>
                    {invoice.status}
                </div>
                <div >
                    <QRCode
                        className="qrCode" size={200} value={invoice.danfe} />
                </div>
                <div>
                    {invoice.protocol}
                </div>
                <div>
                    {invoice.key}
                </div>
            </div>
        </div>
    )
}
