import { IConfigCart } from "modules/cart/domain/models/IConfigCart";
import { AddCartItemByIdUseCase } from "../../application/useCases/AddCartItemByIdUseCase";
import { AddCartItemByProductUseCase } from "../../application/useCases/AddCartItemByProductUseCase";
import { CalcTotalsCartUseCase } from "../../application/useCases/CalcTotalsCartUseCase";
import { RemoveCartItemByIdUseCase } from "../../application/useCases/RemoveCartItemByIdUseCase";
import { RemoveCartItemByProductIdUseCase } from "../../application/useCases/RemoveCartItemByProductIdUseCase";
import { ICartItem } from "../../domain/models/ICart";
import { IOrderComposition } from "../../domain/models/IOrderComposition";
import { IProductCart } from "../../domain/models/IProductCart";
import { v4 } from "uuid";

/**
 * Adicionar produto no carrinho caso ja exista adiciona na quantidade
 */
export const addProductCart = (
  prevCartItems: ICartItem[],
  product: IProductCart,
  quantity: number,
  orderCompositions: IOrderComposition[],
  showVerifyMajorityModal?: boolean,
  notes?: string
) => {
  if (showVerifyMajorityModal) {
    return {
      showVerifyMajorityModal: true,
      tempMajorityProduct: {
        product,
        quantity,
        orderCompositions,
      },
    };
  } else {
    const cartItems = AddCartItemByProductUseCase(
      prevCartItems,
      product,
      quantity,
      orderCompositions,
      notes
    );
    const lastProductAdded = { ...product, v: new Date().getTime() };
    const totals = CalcTotalsCartUseCase(cartItems);

    const isWeightOrBarcode = product?.isWeight || product?.isBarcodeUnity;

    if (isWeightOrBarcode) {
      return {
        cartItems,
        lastProductAdded,
        totals,
        openLastProductToast: true,
        showCart: isWeightOrBarcode,
      };
    } else {
      return {
        cartItems,
        lastProductAdded,
        totals,
        openLastProductToast: true,
      };
    }
  }
};

export const removeCartItemByProductId = (
  prevCartItems: ICartItem[],
  productId: string,
  quantity: number
) => {
  const cartItems = RemoveCartItemByProductIdUseCase(
    prevCartItems,
    productId,
    quantity
  );
  const totals = CalcTotalsCartUseCase(cartItems);
  return { cartItems, totals };
};

/**
 * Remove quantidade de items do carrinho
 */
export const removeCartItem = (
  prevCartItem: ICartItem[],
  cartItemId: string,
  quantity: number
) => {
  const cartItems = RemoveCartItemByIdUseCase(
    prevCartItem,
    cartItemId,
    quantity
  );
  const totals = CalcTotalsCartUseCase(cartItems);
  return { cartItems, totals };
};
/**
 * Adiciona quantidade de items do carrinho
 */
export const addCartItem = (
  prevCartItem: ICartItem[],
  cartItemId: string,
  quantity: number
) => {
  const cartItems = AddCartItemByIdUseCase(prevCartItem, cartItemId, quantity);
  const totals = CalcTotalsCartUseCase(cartItems);
  return { cartItems, totals };
};

/**
 * Limpar carrinho
 */
export const clearCart = () => {
  return {
    cartItems: [],
    totals: { totalQuantity: 0, totalPrice: 0 },
    cart: { id: "", orderItems: [], date: new Date().toISOString() },
    showVerifyMajorityModal: false,
    tempMajorityProduct: null,
    adultHood: false,
    showCart: false,
  };
};

/**
 * Fechar modal de ultimo produto
 */
export const clearLastProduct = () => {
  return { openLastProductToast: false };
};

/**
 * setConfigCart
 */
export const setConfigCart = (configCart: IConfigCart) => {
  return { configCart };
};

/**
 * setNewCart
 */
export const setNewCart = (id: string) => {
  return { cart: { id, date: new Date().toISOString() } };
};

/**
 * setAdultHood
 */
export const setAdultHood = (adultHood: boolean) => {
  return { adultHood };
};

/**
 * setShowVerifyMajorityModal
 */
export const setShowVerifyMajorityModal = (
  showVerifyMajorityModal: boolean
) => {
  return { showVerifyMajorityModal };
};

/**
 * clearTempMajorityProduct
 */
export const clearTempMajorityProduct = () => {
  return { tempMajorityProduct: null };
};

/**
 * setShowCart
 */
export const setShowCart = (showCart: boolean) => {
  return { showCart };
};
