import React, { FC } from 'react'
import { IExtract } from '../../../domains/cashless/aggregates/IExtract';
import { ProdutoTipo } from 'modules/order/domain/models/IOrder';
import "./ExtractView.css"
import { roundValue } from 'utils/utils';
interface IExtractView {
    extract: IExtract,
    onClickAdd: () => void
    onClickPayment: () => void
    type: "cashless" | "orderPad" | "school"
    serviceRate: number | null,
    serviceRateObrigatory?: boolean
}

export const ExtractView: FC<IExtractView> = ({ extract, onClickAdd, onClickPayment, type, serviceRate, serviceRateObrigatory }) => {
    return (
        <div id="extract-view">
            <div className="name">{extract.Nome}</div>
            <div className="moviment">
                {
                    extract?.Movimentacoes?.map((moviment, key) => {
                        const date = new Date(moviment.Data);
                        const adjustedDate = new Date(date.getTime() - (3 * 60 * 60 * 1000)).toLocaleString("pt-BR");
                        return (
                            <div key={key} className="movimentItem">
                                <div className="top">
                                    <div className="date">{adjustedDate}</div>
                                    {/* {moviment.Credito && <div className="credit">+R${moviment.Type === ProdutoTipo.Kilo ? (moviment.Credito * moviment.Quantity).toFixed(2) : moviment.Credito.toFixed(2)}</div>}
                                    {moviment.Debito && <div className="debit">-RS{moviment.Type === ProdutoTipo.Kilo ? (moviment.Debito * moviment.Quantity).toFixed(2) : moviment.Debito.toFixed(2)}</div>} */}
                                    {moviment.Credito && <div className="credit">+R${moviment.Credito.toFixed(2)}</div>}
                                    {moviment.Debito && <div className="debit">-RS{moviment.Debito.toFixed(2)}</div>}
                                </div>
                                <div className="description">{moviment.Descricao}</div>
                            </div>
                        )
                    })
                }
            </div>
            {type === "orderPad" && extract.Saldo < 0 ?
                <>
                    {
                        /* {
                            type === "orderPad" && extract.Saldo > 0 &&
                            <div onClick={() => { }} className="remover-taxa-button">Não desejo pagar taxa de serviço</div>
                        } */
                    }
                    {
                        !!extract.MinimoConsumo && <div>
                            <h3>ATENCÃO</h3>
                            <div>Existe um valor minimo de consumo nesta conta</div>
                            <div>o valor é R${extract.MinimoConsumo.toFixed(2)}</div>
                        </div>
                    }
                    {
                        serviceRate &&
                        <>
                            <div className="balance ">
                                <div>Subtotal: </div>
                                <div>R${Math.abs(extract.Saldo).toFixed(2)}</div>
                            </div>
                            <div className="balance ">
                                <div>Taxa de serviço ({serviceRate * 100}%):</div>
                                <div>R${extract.TaxaServico.toFixed(2)}</div>
                            </div>
                        </>
                    }
                    <div className="balance ">{
                        <div>Total a pagar: </div>
                    }
                        <div>R${roundValue((extract.Saldo * -1) + extract.TaxaServico).toFixed(2)}</div>
                    </div>
                </>
                :
                <div className="balance ">
                    <div>Saldo: </div>
                    <div>R${(extract.Saldo).toFixed(2)}</div>
                </div>
            }
            <div className="footer">

                {
                    type === "cashless" &&
                    <div onClick={onClickAdd} style={{ backgroundColor: "#1af" }} className="payment-button">Adicionar Credito</div>
                }
                {
                    type === "orderPad" && extract.Saldo < 0 &&
                    <div onClick={onClickPayment} style={{ backgroundColor: "#1a5" }} className="payment-button">Pagar conta</div>
                }
            </div>
        </div>
    )
}
