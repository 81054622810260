import React, { FC } from 'react'
import styles from './SchedulCartToolbar.module.scss'
export interface ISchedulCartToolbarProps {
    //propertys
    total: number,
    quantidade: number,
    onClick: () => void
}
const SchedulCartToolbar: FC<ISchedulCartToolbarProps> = ({ onClick, total, quantidade }) => {
    return (
        <div onClick={onClick} id={styles.SchedulCartToolbar} className={!!quantidade ? styles.open : styles.close} >
            Carrinho - {quantidade} Serviços - R${total.toFixed(2).replace(".", ",")}
        </div>
    )
}
export default SchedulCartToolbar