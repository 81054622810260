import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Inicio.css";
import { useHistory } from "react-router-dom";
import { PapelDeParedePlayer } from "./components/papelDeParedePlayer/PapelDeParedePlayer";
import { useKiosk } from "../../application/contexts/kiosk/KioskContext";
import { useCatalog } from "../../application/contexts/catalog/CatalogContext";

import { useSession } from "../../application/contexts/session/SessionContext";
import { SystemType } from "../../domains/kiosk/aggregates/Kiosk";
import { useUi } from "../../application/contexts/ui/UIContext";
import BotaoInicio from "./components/botaoInicio/BotaoInicio";
import { Dialog } from "@material-ui/core";
import { useCardReader } from "../../application/contexts/cardReader/CardReaderContext";
import { MeepOrderRepository } from "../../Infrastructure/repositories/api/OrderRepository";
import { useWeighingMachine } from "application/contexts/weighingMachine/WeighingMachine";
import CpfRegisterPage from "modules/cpfRegister/CpfRegisterPage";
import { UseBarCode } from "areas/barCode/hooks/UseBarCode";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";

//container

export const Inicio: FC = () => {
  let history = useHistory();
  const [clearCart] = useCartStore((state: ICartContext) => [
    state.clearCart,
  ]);
  const { clearCardData, isSchoolData, isOnlineRecharge } = useCardReader();
  const { kiosk } = useKiosk();
  const { transitionPageAnimation, toast, showLoading, hideLoading } = useUi();
  const { verifySessionIsOpen } = useSession();
  const { addLog } = useAppInsights();
  const orderRepository = MeepOrderRepository(addLog);

  const firstLoad = useRef(true);

  const [codeBarMode, setCodeBarMode] = useState(false);
  const { sync, catalog, handleRestrictions, handleDiscounts } = useCatalog();

  const { weighingMachineEnable } = useWeighingMachine()

  const [cpfRegisterOpen, setCpfRegisterOpen] = useState(false)

  const { refDiv, focus } = UseBarCode();

  useEffect(() => {
    handleRestrictions([]);
    handleDiscounts([]);
    clearCart();
    clearCardData();
  }, [clearCardData, clearCart, handleDiscounts, handleRestrictions]);

  useEffect(() => {
    if (kiosk && firstLoad.current) {
      firstLoad.current = false;
      orderRepository.syncOrdersAsync(kiosk?.localId)
    }
  }, [kiosk, orderRepository]);

  useEffect(() => {
    if (kiosk?.enableBarCode) {
      history.replace("/barcode");
      setCodeBarMode(true);
    }
  }, [history, kiosk]);

  useEffect(() => {
    sync();
  }, [sync]);

  const verifySession = useCallback(
    async () => {
      showLoading();
      const result = await verifySessionIsOpen();
      hideLoading();
      return result;
    },
    [hideLoading, showLoading, verifySessionIsOpen],
  )
  

  const onclickFazerPedido = useCallback(async () => {
    sync();
    clearCardData();
    const hasOpenSession = await verifySession();
    if (hasOpenSession) {
      transitionPageAnimation(() => {
        if (catalog?.stores) {
          if (catalog.stores.length === 1) {
            history.push("/catalog/store/" + 0);
          } else {
            history.push("/catalog/");
          }
        }
      });
    }
  },[catalog, clearCardData, history, sync, transitionPageAnimation, verifySession]);

  const onClickSchool = useCallback(async () => {
    clearCardData();
    const hasOpenSession = await verifySession();
    if(hasOpenSession) {
      isSchoolData.current = true;
      transitionPageAnimation(() => {
        history.push("/cashless/school");
      });
    }
  },[clearCardData, history, isSchoolData, transitionPageAnimation, verifySession]);
  
  const onclickAdicionarCredito = useCallback(async () => {
    clearCardData();
    const hasOpenSession = await verifySession();
    if (hasOpenSession) {
      isOnlineRecharge.current = true;
      transitionPageAnimation(() => history.push("/cashless/cashless"));
    }
  },[clearCardData, verifySession, isOnlineRecharge, transitionPageAnimation, history]);
  
  const onclickAdicionarCreditoOffline = useCallback(() => {
    if (!kiosk?.cashlessCode) {
      toast('Falha na ativação cashless', 'error');
    } else {
      clearCardData();
      history.push("/offlineRecharge");
    }
  },[clearCardData, history, kiosk, toast]);

  const onclickAgendamento = useCallback(async () => {
    const hasOpenSession = await verifySession();
    if (hasOpenSession) {
      // transitionPageAnimation(() =>
      history.push("/schedule")
      // )
    }
  },[history, verifySession]);

  const validateDocument = useCallback(() => {
    //verifySession();
    // transitionPageAnimation(() =>
    history.push("/validateDocument")
    // )
  },[history]);

  const onClickPasseio = useCallback(() => {
    // transitionPageAnimation(() =>
    history.push("/tour")
    // )
  },[history]);

  const onclickEfetuarPagamento = useCallback(async () => {
    const hasOpenSession = await verifySession();
    if (hasOpenSession) {
      transitionPageAnimation(() => history.push("/cashless/orderPad"));
    }
  },[history, transitionPageAnimation, verifySession]);

  const onClickPesar = useCallback(async () => {
    const hasOpenSession = await verifySession();
    if (hasOpenSession) {
      transitionPageAnimation(() => history.push("/weighing"));
    }
  },[history, transitionPageAnimation, verifySession]);

  const onClickCpfRegister = useCallback(() => {
    // transitionPageAnimation(() => history.push("/cpfRegister"));
    setCpfRegisterOpen(true)
  },[]);

  const isSystemType = useCallback((systemType: SystemType) => {
    return kiosk?.configSymstem?.find(
      (configSymstem) => configSymstem.systemType === systemType
    )
      ? true
      : false;
  },[kiosk]);

  // Esconder botão para o cliente Minas Tenis Clube - MTC - Restaurante - 17217951000110
  // Esconder botão para o cliente Minas Tenis Clube - MTC - Unidade II Restaurante - 17217951000110
  // Esconder botão para o cliente Minas Tenis Clube - MTC - Unidade Country - 17217951000110
  const mostrarBotaoPagarConta = useMemo(() => {
    const MTCids = [
      "03dcdd76-1450-c832-0913-518cef45cdab",
      "d643e88f-5457-6024-483c-d25b048ee927",
      "24f3749d-5a4f-5d6f-f909-b79981298e61",
    ]

    return MTCids.findIndex(it => it.toUpperCase() === (kiosk?.localId ?? '').toUpperCase()) === -1;
  }, [kiosk]);

  return (
    <div className="inicio" ref={refDiv} onClick={focus}>
      <PapelDeParedePlayer>
        {
          !kiosk?.enableVariablePricing &&
          <div className="botaocontainer">
            {isSystemType(SystemType.prepayTicket) && (
              <div className={"botao"}>
                <BotaoInicio
                  onClick={onclickFazerPedido}
                  title="Fazer pedido"
                  icon="shopping_cart"
                  data-custom-id="order-button"
                  data-custom-description="Fazer pedido"
                />
              </div>
            )}

            {kiosk?.paymentNominalCashless && (
              <div className={"botao"}>
                <BotaoInicio
                  onClick={onClickSchool}
                  title="Cartão do aluno"
                  icon="shopping_cart"
                  data-custom-id="nominal-button"
                  data-custom-description="Cartão do aluno"
                />
              </div>
            )}


            {/* {(TextTrackCueList) && ( */}
            {isSystemType(SystemType.pospayOrderPad) && mostrarBotaoPagarConta && !weighingMachineEnable && !kiosk?.paymentNominalCashless && (
              <div className={"botao"}>
                <BotaoInicio
                  title="Pagar Conta"
                  icon="payment"
                  onClick={onclickEfetuarPagamento}
                  data-custom-id="payment-button"
                  data-custom-description="Pagar conta"
                ></BotaoInicio>
              </div>
            )}

            {/* {(true) && ( */}
            {((isSystemType(SystemType.prepayCashless) && !isSystemType(SystemType.pospayOrderPad) && !weighingMachineEnable && kiosk?.onlineCashlessControl) || kiosk?.paymentNominalCashless) && kiosk?.enableRecharge && (
              <div className={"botao"}>
                <BotaoInicio
                  title="Adicionar Crédito"
                  icon="payment"
                  onClick={onclickAdicionarCredito}
                  data-custom-id="add-credit-online-button"
                  data-custom-description="Adicionar crédito online"
                ></BotaoInicio>
              </div>
            )}
            {isSystemType(SystemType.prepayCashless) && !kiosk?.onlineCashlessControl && !kiosk?.paymentNominalCashless && kiosk?.enableRecharge && (
              <div className={"botao"}>
                <BotaoInicio
                  title="Adicionar Crédito"
                  icon="payment"
                  onClick={onclickAdicionarCreditoOffline}
                  data-custom-id="add-credit-offline-button"
                  data-custom-description="Adicionar crédito offline"
                ></BotaoInicio>
              </div>
            )}
            {/* {kiosk?.id === "12e94352-e33c-49ce-9626-85995b1b76c2" && (
            <div className={"botao"}>
              <BotaoInicio
                title="Fazer pedido"
                icon="payment"
                onClick={validateDocument}
              ></BotaoInicio>
            </div>
          )} */}
            {kiosk?.enableSchedule && (
              <div className={"botao"}>
                <BotaoInicio 
                  title="Iniciar" 
                  icon="payment" 
                  onClick={onclickAgendamento}
                  data-custom-id="init-button"
                  data-custom-description="Iniciar agendamento"
                >
                </BotaoInicio>
              </div>
            )}
            {(
              kiosk?.localId === "5650de25-2e2a-0eab-ac51-b5f554bc2908" //gramado
              || kiosk?.localId === "24c93427-8517-44fb-866d-d4f13662feb7"//demonstracao
              || kiosk?.localId === "1f3d32dd-9f1e-042a-dbb4-09ecb64a1427"//comercial
              || kiosk?.localId === "a863671b-bfd8-e987-7866-54f32c71be2a"//meep-dev
              // || kiosk?.localId === "1f3d32dd-9f1e-042a-dbb4-09ecb64a1427"//comercial
            ) && (
                <div className={"botao"}>
                  <BotaoInicio 
                    title="Reservar" 
                    icon="schedule" 
                    onClick={onClickPasseio}
                    data-custom-id="schedule-button"
                    data-custom-description="Reservar agendamento"
                  >
                  </BotaoInicio>
                </div>
              )}
            {weighingMachineEnable && (
              <div className={"botao"}>
                <BotaoInicio 
                  title="Clique para começar" 
                  icon="payment" 
                  onClick={onClickPesar}
                  data-custom-id="weight-button"
                  data-custom-description="Clique para começar"
                >

                </BotaoInicio>
              </div>
            )}
            {kiosk?.enableCpfRegister && (
              <div className={"botao"}>
                <BotaoInicio 
                  color={"#db0"} 
                  title="PARTICIPAR DO SORTEIO" 
                  icon={"star"} 
                  onClick={onClickCpfRegister}
                  data-custom-id="draw-button"
                  data-custom-description="Participar do sorteio"
                >

                </BotaoInicio>
              </div>
            )}
            {/* <BotaoGrande onClick={onclickBarcode}>Codigo de Barras</BotaoGrande> */}
          </div>

        }
      </PapelDeParedePlayer>
      <Dialog open={cpfRegisterOpen} onClose={() => setCpfRegisterOpen(false)} >
        <CpfRegisterPage onClose={() => setCpfRegisterOpen(false)} />
      </Dialog>
    </div>
  );
};
