import { BotaoGrande } from 'components/botaoGrande/BotaoGrande'
import React, { FC, useEffect } from 'react'
import styles from './KeyboarInstalment.module.scss'
export interface IKeyboardInstallmentSelectorProps {
    //propertys
    total: number
    onSubmit: (value: number) => void
    onCancel?: () => void,
    min: number
    max: number
    parcelaMin: number
}
const KeyboardInstallmentSelector: FC<IKeyboardInstallmentSelectorProps> = ({
    onSubmit,
    onCancel,
    total,
    min,
    max,
    parcelaMin,
}) => {
    useEffect(() => {

        let parcelas: number[] = []

        for (var i = max; i >= min; i--) {
            const valorDaParcela = total / i;
            if (valorDaParcela >= parcelaMin) {
                parcelas.push(i)
            }
        }
        if (parcelas.length > 1) {
            // onSubmit(2)
        } else {
            onSubmit(1)
        }

    }, [max, min, onSubmit, parcelaMin, total])
    return (
        <div id={styles.KeyboarInstalment} >
            <div className={styles.botao} onClick={() => onSubmit(1)} >
                A vista
            </div>
            <div className={styles.botao} onClick={() => onSubmit(2)} >
                Parcelado
            </div>
            {onCancel && <div className={styles.botaoCancelar} onClick={() => onCancel()} >
                Cancelar
            </div>}
        </div>
    )
}
export default KeyboardInstallmentSelector