import { ILocalStorageRepository } from "../../interfaces/ILocalStorageRepository";

export const LocalStorageRepository = (): ILocalStorageRepository => {
  const post = async (key: string, data: object): Promise<void> => {
    const stringData = JSON.stringify(data);
    await localStorage.setItem(key, stringData);
  };

  const postString = async (key: string, data: string): Promise<void> => {
    await localStorage.setItem(key, data);
  };

  const get = async <TResponse extends unknown>(
    key: string
  ): Promise<TResponse | null> => {
    const value = await localStorage.getItem(key);
    if (value) {
      return JSON.parse(value) as TResponse;
    }
    return null;
  };

  const getString = async (key: string): Promise<string | null> => {
    const value = await localStorage.getItem(key);
    return value;
  };

  const remove = async (key: string): Promise<void> => {
    await localStorage.removeItem(key);
  };

  const removeAll = async (): Promise<void> => {
    await localStorage.clear();
  };

  return { post, postString, get, getString, remove, removeAll };
};
