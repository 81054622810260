import React, { FC, useState, useEffect, memo } from 'react'
import { SeccionedProduct } from '../../../../../../domains/catalog/aggregates/SeccionedProduct'
import { Tabs, Tab } from '@material-ui/core';
import styles from './TabCategoriaCardapio.module.scss'
interface TabCategoriaCardapioProps {
    produtoSeccionado: SeccionedProduct[],
    onClick: (index: number) => void
    position?: number
}

export const TabCategoriaCardapio: FC<TabCategoriaCardapioProps> = memo(({ position, produtoSeccionado, onClick }) => {
    const [categoriaIndex, setcategoriaIndex] = useState(position);

    useEffect(() => {
        // console.log('produtoSeccionado', produtoSeccionado)
        setcategoriaIndex(position)
    }, [position, produtoSeccionado])
    const onImgError = (ev: React.ChangeEvent<HTMLImageElement>) => {

        console.log("imageError")
        ev.target.hidden = true

    }

    return (
        produtoSeccionado ?
            <Tabs
                className={styles.Tabs}
                value={categoriaIndex}
                indicatorColor="primary"
                textColor="primary"
                onChange={(e, newValue) => { onClick(newValue); setcategoriaIndex(newValue) }}
                aria-label="simple tabs example"
                variant="scrollable"
                scrollButtons="off"
            >
                {produtoSeccionado.map((produtoSeccionado, index) =>
                    <Tab
                        key={index}
                        id={`simple-tab-${index}`}
                        aria-controls={`simple-tabpanel-${index}`}
                        classes={{
                            selected: styles.customTabSelected,
                            root: categoriaIndex === index ? styles.customTab : undefined
                        }}
                        value={index}
                        label={
                            <div className={styles.Tab} >
                                <img onErrorCapture={onImgError} className={styles.TabImage} src={produtoSeccionado.imageUrl} alt="category" />
                                <div style={{ color: categoriaIndex !== index ? "#32008E" : undefined, fontSize: 18 }}>{produtoSeccionado.title}</div>
                            </div>
                        }
                        // classes={{ root: "TabRoot", }}
                        data-custom-id="tab-category-menu"
                        data-custom-description="Abas categorias"
                        data-custom-info={JSON.stringify({
                            position,
                            catId: produtoSeccionado.id,
                            catTitle: produtoSeccionado.title,
                            catProducts: produtoSeccionado.products,
                            catImg: produtoSeccionado.imageUrl
                        })}
                    >
                    </Tab>
                )}
            </Tabs>
            :
            null
    )
})