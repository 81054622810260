import { ITourRepository } from '../../interfaces/ITourRespository'
import { GetServiceDetailByIdResponseDTO } from '../../../domain/dtos/GetServiceDetailByIdResponseDTO'
const GetTourDetailByIdUseCase = async(tourRepository: () => ITourRepository, serviceId: string): Promise<GetServiceDetailByIdResponseDTO> => {

    const response = await tourRepository().getServiceDetailById(serviceId);

    return (
        response
    )
}

export default GetTourDetailByIdUseCase
