import React, { FC, memo, useMemo } from "react";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import "./OfflineRechargeLayout.css";
import { hideCpf } from "utils/utils";

export interface IOfflineRechargePrint {
  orderId: string;
  tag: string;
  previousBalance: number;
  newBalance: number;
  name?: string;
  document?: string;
}

interface OfflineRechargeLayoutProps {
  offlineRechargePrint: IOfflineRechargePrint;
  kiosk: Kiosk;
}

export const OfflineRechargeLayout: FC<OfflineRechargeLayoutProps> = memo(
  ({ kiosk, offlineRechargePrint: { tag, newBalance, previousBalance, document, name, orderId } }) => {

    const cpf = useMemo(() => {
      let cpf;

      if (document?.length === 11) {
        cpf = document;
      }

      return cpf

    }, [document])

    return (
      <div id="OfflineRechargeLayout">
        <div className="container">
          <div className="container">
            <div className="logo">
              <img width={120} src={require("./../../../assets/images/logo-meep.png")} alt="logo" />
            </div>
            <div className="productName">GRAVAÇÃO CONCLUIDA</div>
          </div>
          <div className="header">
            <div className="offlineRechargeItem">LOCAL: {kiosk.localName}</div>
            <div className="offlineRechargeItem">TERMINAL: {kiosk.kioskName}</div>
            <div className="offlineRechargeItem">SALDO ANTERIOR: R$ {(previousBalance).toFixed(2).replace(".", ",")}</div>
            <div className="offlineRechargeItem">SALDO ATUAL: R$ {(newBalance).toFixed(2).replace(".", ",")}</div>
            {!!cpf && (
              <div className="offlineRechargeItem">CPF:{hideCpf(cpf)}</div>
            )}
            {name && (
              <div className="offlineRechargeItem">
                  {`NOME: ${name}`}
              </div>
            )}
            {tag && (
              <div className="offlineRechargeItem">TAG: {tag}</div>
            )}
            {orderId && (
              <div className="offlineRechargeItem">ID: {orderId}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
