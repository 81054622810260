import React, { createContext, useState, FC, useContext, useEffect, useCallback } from 'react'
import { Operator } from '../../../domains/admin/aggregates/operator/Operator';
import { useKiosk } from '../kiosk/KioskContext';
import { Order, OrderLog } from '../../../domains/order/agreggates/order/Order';
import { OrderItem } from '../../../domains/order/agreggates/order/OrderItem';
import { AdminPrintService } from '../../../domains/admin/services/AdminPrintService';
import moment from 'moment';
import { OrderRepositoryLocalStorage } from '../../../Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage';
import { OperatorRepositoryDB } from '../../../Infrastructure/repositories/localStorage/OperatorRepositoryDB';

export interface IAdminProvider {
    operators: Operator[],
    orderLog: OrderLog[],
    isAuthOperator: boolean

    selectedOperator: Operator | null
    validateOperator: (operatorId: string, password: string) => void
    printOrder: (orderLog: OrderLog, orderItem: OrderItem) => void
    verifyRoles: (role: string) => boolean
    setOperator: (operatorId: string) => void
    onOrderLogReprinted: () => void
}

const AdminContext = createContext({} as IAdminProvider)

const orderRepositoryLocalStorage = OrderRepositoryLocalStorage();
const operatorRepositoryDB = OperatorRepositoryDB()
const adminPrintService = AdminPrintService();

export const AdminProvider: FC = ({ children }) => {



    const { kiosk } = useKiosk();

    const [operators, setoperators] = useState<Operator[]>([]);
    const [selectedOperator, setSelectedOperator] = useState<Operator | null>(null);
    const [orderLog, setorderLog] = useState<OrderLog[]>([]);

    const [isAuthOperator, setisAuthOperator] = useState(false);

    const onOrderLogReprinted = () => {
        orderRepositoryLocalStorage?.all().then((orders) => {
            // console.log("order:", orders.map((order) => (order.id + "  R$" + order.totalValue)))
            // console.log("order full:", orders)
            setorderLog(orders)
        })
    }


    useEffect(() => {
        // console.log("Admin", orderRepositoryDB)
        onOrderLogReprinted();
        if (kiosk) {
            operatorRepositoryDB.getAll(kiosk.localId).then((operators) => {
                setoperators(operators.filter(operator => operator.roles.includes("ConfiguracaoPOS")))
                console.log("operators full:", operators.map((operator) => operator))
                console.log("operators:", operators.map((operator) => operator.name + "-" + operator.password))
            })
        }
    }, [kiosk])


    const setOperator = (operatorId: string) => {
        const operador = operators.find(operator => operator.id === operatorId);
        if (operador) {
            setSelectedOperator(operador);
        }
    }

    const verifyRoles = (role: string) => {
        if (selectedOperator?.roles.includes(role)) {
            return true;
        } else {
            return false;
        }
    }


    const printOrder = useCallback(
        (orderLog: OrderLog, orderItem: OrderItem) => {
            console.log("print")
            if (selectedOperator) {
                adminPrintService.printTicket(orderLog, orderItem.id, selectedOperator).then(async () => {
                    console.log("printed");
                    orderRepositoryLocalStorage?.syncRePrint({
                        reimpressaoId: "",
                        dataRealizacao: moment().utc().toDate(),
                        ticketId: orderItem.id,
                        operadorId: selectedOperator.id,
                        localClienteId: kiosk?.localId ?? "",
                        equipamentoId: kiosk?.id ?? ""
                    })

                });
            }
        },
        [adminPrintService, kiosk, orderRepositoryLocalStorage, selectedOperator]
    )


    const validateOperator = useCallback(
        (operatorId: string, password: string) => {
            const operador = operators.find(operator => operator.id === operatorId)
            console.log(operador)
            if (operador?.password === password) {
                setSelectedOperator(operador);
            } else {
                console.log("Senha Incorreta")
            }
        },
        [operators],
    )



    useEffect(() => {
        if (selectedOperator) {
            setisAuthOperator(true)
        } else {
            setisAuthOperator(false)

        }
        return () => {

        }
    }, [selectedOperator])

    return (
        <AdminContext.Provider value={{
            operators,
            orderLog,
            isAuthOperator,
            selectedOperator,
            validateOperator,
            printOrder,
            verifyRoles,
            setOperator,
            onOrderLogReprinted
        }}>
            {children}
        </AdminContext.Provider>
    )
}

export const useAdmin = () => {
    const context = useContext(AdminContext)
    return context
}