import React, { FC, useState, useEffect, useCallback } from "react";
import "./ProdutoDetalhamentoSimple.css";
import { Button, Icon } from "@material-ui/core";
import { BotaoVoltar } from "../../../../../../components/botaoVoltar/BotaoVoltar";
import { Product } from "../../../../../../domains/catalog/aggregates/Product";
import { OrderComposition } from "../../../../../../domains/order/agreggates/order/OrderComposition";
import InputText from "components/inputText/InputText";
import { v4 } from "uuid";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";
import { roundValue } from "utils/utils";


interface ProdutoDetalhamentoProps {
  produto: Product;
  onClose: () => void;
}

export const ProdutoDetalhamentoSimple: FC<ProdutoDetalhamentoProps> = ({
  produto,
  onClose,
}) => {
  const [quantidade, setquantidade] = useState(1);
  // const cart = useCart();
  const [addProductCart] = useCartStore((state: ICartContext) => [
    state.addProductCart
  ]);
  const [orderCompositions, setorderCompositions] = useState<
    { orderComposition: OrderComposition[] }
  >();
  const [minimo, setMinimo] = useState<boolean>(true);
  const [disableButtomAdicionar, setdisableButtomAdicionar] = useState(false);

  const onClickAdicionar = () => {

    if (produto) {
      const hasObservation = produto.compositions?.length || produto?.productGeneralObservation;
      const _composition: OrderComposition[] = [...(orderCompositions?.orderComposition ? orderCompositions.orderComposition : [])]


      const _observationField = observationField ? {
        id: v4(),
        description: 'Observação',
        compositionId: '',
        totalPrice: 0,
        orderCompositionItems: [
          {
            id: v4(),
            description: observationField.observation,
            price: 0,
            quantity: 1,
            compositionId: '',
            produtoId: '',
            compositionItemId: v4(),
            quantityFree: 0,
            quantityFreeSelected: 0,
            itemType: 0
          }
        ],
      } : null

      addProductCart(produto, quantidade, hasObservation ? _observationField ? [..._composition, _observationField] : _composition : [], produto.adultHood);
      
      onClose();
    }


    onClose();
  };
  const [observationField, setObservationField] = useState({ observation: '' })


  const onClickAdd = () => {
    setquantidade((prev) => prev + 1);
  };
  const onClickRemove = () => {
    if (quantidade > 1) {
      setquantidade((prev) => prev - 1);
    }
  };

  const limitChars = 200;

  const handleChangeObservation = useCallback((observation: string) => {
    if (observation.length <= limitChars) {
      setObservationField({ observation });
    }
  }, [])

  useEffect(() => {
    if (
      produto.compositions?.length &&
      produto.compositions?.length > 0 &&
      orderCompositions?.orderComposition.length === 0 &&
      !minimo
    ) {
      setdisableButtomAdicionar(true);
    } else {
      setdisableButtomAdicionar(false);
    }
  }, [orderCompositions, produto, setdisableButtomAdicionar, minimo]);

  const onFinishCompostions = useCallback(
    (_orderCompositions: OrderComposition[], _minimo: boolean) => {
      setorderCompositions({orderComposition: _orderCompositions});
      setMinimo(_minimo);
    },
    []
  );

  return (
    <>
      <div className={"produto-detalhamento-simple"}>
        {/* <div className={"container-produto-detalhamento-simple"}> */}
        
        <div
          className={!!produto.productGeneralObservation ? '' : "image-detalhamento-simple"}
          style={{  backgroundImage: !!produto.productGeneralObservation ? '' : "url(" + produto.imageUrl + ")"}}
        >
          <BotaoVoltar 
            onClick={onClose}
            data-custom-id="simple-detail-back-button"
            data-custom-description="Botão voltar"
          ></BotaoVoltar>
          {produto?.discount && (
            <div className="produto-detalhamento-desconto">
              -{produto.discount}%
            </div>
          )}
        </div>
        <div className={"conteudo-detalhamento-simple"}>
          <div className={"titulo-detalhamento-simple"}>{produto.name}</div>
          <div className={"descricao-detalhamento-simple"}>
            {produto.description}
          </div>
          {/* {produto.compositions?.length && (
            <div className={"composicao-detalhamento-simple"}>
              <ComposicaoLista
                onFinish={onFinishCompostions}
                composicoes={produto.compositions}
              ></ComposicaoLista>
            </div>
          )} */}
        </div>
        <div className={"observation"}>
          {
            !!produto.productGeneralObservation &&
            <>
              <label className="observation-label">Observações</label>
              <InputText
                label="Observações"
                className="input"
                placeHolder="Digite sua observação"
                value={observationField.observation}
                type={'text'}
                multiline
                onChange={(value) => handleChangeObservation(value)}
              />
            </>
          }
        </div>
      </div>

      <div className={"rodape-detalhamento-simple"}>
        <div className={"container-quantidade-detalhamento-simple"}>
          <Button
            onClick={onClickRemove}
            className={"remove-quantidade-detalhamento-simple"}
            data-custom-id="simple-detail-remove-button-icon"
            data-custom-description="Remover produto"
            data-custom-info={JSON.stringify({
              productId: produto.id,
              productName: produto.name
            })}
          >
            <Icon>remove</Icon>
          </Button>
          <div className={"quantidade-detalhamento-simple"}>{quantidade}</div>
          <Button
            onClick={onClickAdd}
            className={"add-quantidade-detalhamento-simple"}
            data-custom-id="simple-detail-add-button-icon"
            data-custom-description="Adicionar produto"
            data-custom-info={JSON.stringify({
              productId: produto.id,
              productName: produto.name
            })}
          >
            <Icon>add</Icon>
          </Button>
        </div>
        <Button
          fullWidth
          onClick={onClickAdicionar}
          disabled={disableButtomAdicionar}
          style={{ opacity: disableButtomAdicionar ? 0.2 : 1 }}
          className={"botao-continuar-detalhamento-simple"}
          data-custom-id="simple-detail-add-button"
          data-custom-description="Adicionar produto"
          data-custom-info={JSON.stringify({
            productId: produto.id,
            productName: produto.name
          })}
        >
          ADICIONAR&nbsp;
          <span 
            style={{
              textDecoration: produto.discount ? 'line-through' : undefined,
              fontSize: produto.discount ? '16px' : undefined,
              color: produto.discount ? 'lightgray' : undefined,
            }}
          >
            R$ {roundValue(quantidade * produto.price).toFixed(2).replace(".", ",")}
          </span>
          {produto.discount && (
            <span>
              &nbsp;R$ {roundValue(quantidade * (produto.price - (produto.price * (produto.discount / 100)))).toFixed(2).replace(".", ",")}
            </span>
          )}
        </Button>
      </div>
    </>
  );
};
