import localforage from "localforage"


const key = "@LOG"

interface ILocalLog<T = any> {
    ocurred?: string,
    tag?: string,
    message: string,
    object?: T,
    url?: string,
    code?: string
}

const LogService = () => {

    const add = async <T = any>(data: ILocalLog<T>) => {
        // try {
        // const prev = await localforage.getItem<ILocalLog<T>[]>(key) ?? [];
        // localforage.setItem<ILocalLog<T>[]>(key, [...prev, { ...data, ocurred: new Date().toISOString() }]);
        // } catch (error) {
        // console.log("log Local Erro", error);
        // }
    }
    const download = async <T = any>() => {
        // try {
        //     const prev = await localforage.getItem<ILocalLog<T>[]>(key) ?? [];
        //     const element = document.createElement("a");
        //     const file = new Blob([JSON.stringify(prev)], { type: 'text/plain' });
        //     element.href = URL.createObjectURL(file);
        //     element.download = "myFile.txt";
        //     document.body.appendChild(element); // Required for this to work in FireFox
        //     element.click();

        // } catch (error) {
        //     console.log("log Local Erro", error);
        // }
    }

    const clear = async () => {
        localforage.removeItem("@LOG");
        // try {
        //     const prev = await localforage.getItem<ILocalLog[]>(key) ?? [];
        // localforage.clear();
        //     if (prev.length > 1000) {
        //         localforage.setItem<ILocalLog[]>(key, prev.filter((item, index) => (index > 500)));
        //     }
        // } catch (error) {
        //     console.log("log Local Erro", error);
        // }
    }

    return {
        add,
        download,
        clear
    }


}

export default LogService