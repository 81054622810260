
import { IOperatorRepositoryDB } from "../../../domains/admin/interfaces/repositories/IOperatorRepositoryDB";
import { Operator } from "../../../domains/admin/aggregates/operator/Operator";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import LocalForage from 'localforage'
import { HttpRepository } from "../api/_HttpRepository";



const KEY = "@meep/operators"

export const OperatorRepositoryDB = (
): IOperatorRepositoryDB => {
  const httpRepository: IHttpRepository = HttpRepository()

  const getAll = async (local: string): Promise<Operator[]> => {
    try {
      await sync(local)
      return await LocalForage.getItem<Operator[]>(KEY) ?? [] as Operator[]
    } catch {
      return [] as Operator[]
    }
  };

  const get = async (id: string, password: string): Promise<Operator | null> => {
    console.log("getOperator")
    return {} as Operator
  };

  const sync = async (local: string): Promise<void> => {
    try {
      const operatorsMeep = await httpRepository.getAsync<Array<any>>(
        `Operador/BuscarOperadoresPorLocalId/${local}`
      );
      const operators = operatorsMeep?.map<Operator>((operatorMeep) => {
        return {
          id: operatorMeep.id,
          name: operatorMeep.nome,
          password: operatorMeep.senha,
          roles: operatorMeep.papeis,
        };
      });

      await LocalForage.setItem(KEY, operators);
      // collection.bulkInsert(operators);
    } catch (error) {
      //logar erro
      console.log(error)
    }
  };
  return { getAll, get };
};
