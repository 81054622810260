import { Drawer, Icon } from '@material-ui/core'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import styles from './PickerTime.module.scss'
import ButtonPickerSchedule from '../buttonPicker/ButtonPickerSchedule'
import { AvailableDay, ICollaboratorSchedule } from '../../../../domains/schedule/agreggates/ICollaboratorSchedule'
import { IDemandedService } from '../../../../domains/schedule/agreggates/IDemandedService'
import { addMinutes, differenceInMinutes, getDate } from 'date-fns'
import { add } from 'date-fns/esm'
import getDay from 'date-fns/fp/getDay'

import ScheduleUseCase from '../../ScheduleUseCase'
import { ITimeItem } from '../../interfaces/ITimeItem'
import { TimeStatus } from '../../interfaces/TimeStatus'

export interface IPickerTimeProps {
    //propertys
    // demamandedTime: Date[];
    disabledClick?: boolean;
    date: Date;
    demmandedServices?: IDemandedService[];
    collaboratoSchedule?: ICollaboratorSchedule;
    value: Date | null;
    onChange: (Time: Date) => void;
    now: Date
}
const PickerTime: FC<IPickerTimeProps> = ({
    // demamandedTime,
    disabledClick,
    value,
    onChange,
    collaboratoSchedule,
    date,
    demmandedServices,
    now
}) => {

    const scheduleUseCase = useMemo(ScheduleUseCase, [])
    const generateTimes = useCallback(scheduleUseCase.generateTimes, []);
    const [openList, setopenList] = useState(false)
    const [times, setTimes] = useState<ITimeItem[]>([])

    const closeListHandle = () => {
        setopenList(false);
    }

    const openListHandle = () => {
        !disabledClick && setopenList(true);
    }

    const onClickItem = useCallback((Time: Date) => {
        onChange(Time);
        closeListHandle();
    }, [onChange])


    useEffect(() => {
        if (!disabledClick && collaboratoSchedule && demmandedServices) {
            setopenList(true);
            setTimes(generateTimes(date, demmandedServices, collaboratoSchedule, now))
        }
        return () => {

        }
    }, [date, demmandedServices, collaboratoSchedule, generateTimes, now])

    return (
        <div id={styles.PickerTime} >
            <div className={styles.botao} >
                <ButtonPickerSchedule onClick={openListHandle} selected={!!value}>
                    <Icon fontSize={"large"}>schedule</Icon>{value ? value.toLocaleTimeString('pt-BR') :
                        "Selecionar Horário"}
                </ButtonPickerSchedule>
            </div>
            <Drawer anchor={"bottom"} open={openList} onClose={closeListHandle}>
                <div id={styles.drawerTime}>
                    <h1>Selecione um Horário</h1>
                    <div className={styles.lista}>
                        {
                            times.map((time, index) =>
                                <div key={index} onClick={() => time.status === TimeStatus.available && onClickItem(time.time)} className={time.status === TimeStatus.available ? styles.availableItem : styles.busyItem}>{time.time.toLocaleTimeString("pt-BR")}</div>
                            )
                        }
                    </div>
                </div>
            </Drawer>
        </div>
    )
}
export default PickerTime