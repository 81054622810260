
import { TransactionType } from "../../../../domains/order/agreggates/payment/transactionType";
import { ICefSharp } from "./Interfaces/_ICefSharp";
import { ICefSharpPaymentService } from "./Interfaces/_ICefSharpPaymentService";
import { Payment } from "../../../../domains/order/agreggates/payment/payment";
import { IPaymentRepository } from "../../../../domains/order/interfaces/repositories/_IPaymentRepository";

declare const CefSharp: ICefSharp;
declare const paymentService: ICefSharpPaymentService;

declare global {
  interface Window {
    showMessageFromClient: (
      message: string,
      comando: number,
      severity: string
    ) => void;
    getKeyBoardValue: () => string;
    keyboardValue: string;
    showKeyboard: (min: number, max: number, message: string) => void;
  }
}

export const CefSharpPaymentRepository = (): IPaymentRepository => {
  const payAsync = async (
    value: number,
    transactionType: TransactionType
  ): Promise<Payment> => {
    await CefSharp.BindObjectAsync("paymentService");
    const paymentReturn = await paymentService.pinPadPayment(
      value,
      transactionType
    );
    console.log('paymentResult', paymentReturn)
    if(!paymentReturn.success) {
      throw new Error(paymentReturn?.errorMessage ?? "Falha no pagamento");
    }
    return paymentReturn;
  };

  const cancelPaymentAsync = async (value: number, typePayment: TransactionType, couponNumber: string, paymentDate: string) => {
    await CefSharp.BindObjectAsync("paymentService");
    const paymentReturn: boolean = await paymentService.cancelPayment(value, typePayment, couponNumber, paymentDate);
    return paymentReturn
  }

  return { payAsync, cancelPaymentAsync };
};
