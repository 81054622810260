import { TransactionType } from "../../../domains/order/agreggates/payment/transactionType";
import { FinancialTransactionModel } from "../../../domains/order/agreggates/payment/FinancialTransactionModel";

export enum ProdutoTipo {
  Produto = 1,
  Recarga = 2,
  Variavel = 3,
  Promocao = 4,

  AtivacaoCashless = 5,
  DesativacaoCashless = 6,

  AtivacaoCashlessPosPago = 7,
  DesativacaoCashlessPosPago = 8,

  AtivacaoCashlessComanda = 9,
  DesativacaoCashlessComanda = 10,
  TaxaServico = 11,
  Kilo = 12,
  Ajuste = 13,
  TaxaEntrega = 14,
  Agendamento = 50
}

export interface DadosConsumoPOS {
  localId: string;
  accountId: string;
  equipamentId: string;
  order: ConsumoPedidoPos;
}

export interface ConsumoPedidoPos {
  orderId?: string;
  // operatorId: string;
  createdAt?: Date;
  descriptionPersonalizedDelivery?: string;
  items: ConsumoItemPedido[];
}

export interface ConsumoItemPedido {
  ticketsIds: string[];
  productId: string | null;
  productName: string;
  unitValue: number;
  discountValue: number;
  quantity: number;
  annotation: string;
  additionals: ConsumoAdicionais[];
  observations: ConsumoObservacoes[];
  optionsOldFormat: string;
}

export interface ConsumoAdicionais {
  productId: string;
  selectedQuantity: number;
  unitValue: number;
  discountValue: number;
}

export interface ConsumoObservacoes {
  id: string;
  question: string;
  answer: string;
}

export interface DadosPedidosPOS {
  localClienteId: string;
  tipoDispositivo: 2;
  sincronizarPedidoPOSInsertModel: string; //PedidoPos String
  sincronizarPedidoPOSInsertModelObject?: PedidoPos[]; //PedidoPos String
}

export enum StatusPedido {
  Pendente = 1,
  Falha = 2,
  Aprovado = 3,
  Cancelado = 4,
  ParcialmenteCancelado = 5,
  AguardandoPagamento = 6,
}

export interface PedidoPos {
  valorTotal: number;
  codigoPedido: string;
  clienteEstabelecimento: string,
  clienteEstabelecimentoObject: ClienteEstabelecimento,
  tipoPagamento?: TransactionType; //Enum tipo de pagamento 1=credito 2=debito
  pedidoPOSId?: string; //Guid Gerado no totem
  pedidoPOSPaiId?: string; //Guid Gerado no totem
  statusPedido?: StatusPedido; // 3 pagamento realizado
  dataPedido?: Date; //UTC
  produtos: string;
  produtosObject: ProdutoPedidoPos[]; //string 
  tipoAdquirente: number;
  dadosTransacao: string; //rever
  dadosTransacaoObject?: FinancialTransactionModel; //rever
  tagCashless: string
  observacaoPedido?: string;
  codigoPainelSenha?: string;
  isDataRecorded?: boolean | null;
  isOfflineRecharge?: boolean;
  isOfflineRechargeRecordConfirmed?: boolean;
}

export interface TransacoesDinheiro {
  IdTransacao?: string;
  DataCriacao?: Date;
  Valor?: number;
  TipoPagamento: number;
  DadosTransacao: string;
  DadosTransacaoObject?: FinancialTransactionModel;
  Status?: number;
  TipoAdquirente?: number;
}

export interface ProdutoPedidoPos {
  id?: string;
  nome: string;
  nomeProduto: string;
  ticketId: string; //guid gerado
  produtoId: string;
  storeName?: string;
  category?: string;
  quantidade?: number | null;
  tipoProduto?: ProdutoTipo;
  valor: number; //total
  opcoesDoConsumidor: string;
  opcoesDoConsumidorObject: OpcoesDoConsumidor[];
}

export interface OpcoesDoConsumidor {
  id: string;
  descricao: string;
  opcoes: OpcaoDoConsumidor[];
  // opcoesObject?: OpcaoDoConsumidor[]; //string
}

export interface OpcaoDoConsumidor {
  id: string;
  produtoId?: string;
  descricao: string;
  isChecked: boolean;
  quantidade: number;
  produtoValor: number;
  quantidadeGratuito: number;
  quantidadeGratuitoSelecionado: number;
}

export interface responseSyncPedidoPos {
  pedidoPOSId: string;
}

export interface ClienteEstabelecimento {
  nome?: string,
  identificador?: string,
  cpf?: string,
  telefone?: string,
  email?: string,
  comanda?: string,
  mesa?: string,
  pedidoPosPaiId?: string,
  pedidoPosId?: string,
  tag?: string,
}