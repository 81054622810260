
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { ILocalStorageRepository } from "../../interfaces/ILocalStorageRepository";
import { ICatalogRepository } from "../../../domains/catalog/interfaces/repositories/ICatalogRepository";
import { Catalog, lastSyncCatalogResponse } from "../../../domains/catalog/aggregates/Catalog";
import { LocalStorageRepository } from "./_LocalStorageRepository";
import { HttpRepository } from "./_HttpRepository";

const key = "CATALOG";
const keyLastSync = "lastSync";
export const CatalogRepository = (
): ICatalogRepository => {
  const localStorageRepository: ILocalStorageRepository = LocalStorageRepository();
  const httpRepository: IHttpRepository = HttpRepository();
  // const get = () => {
  //   const catalog = localStorageRepository.get<Catalog>(key);
  //   return from(catalog);
  // };
  const getAsync = async () => {
    try {
      const catalog = await localStorageRepository.get<Catalog>(key);
      if (catalog) {
        return catalog;
      } else {
        return null;
      }
    } catch {
      return null;
    }
  };

  // const syncObservable = () => {
  //   let response = httpRepository
  //     .getObservable<Catalog>("/equipment/productcatalog")
  //     .pipe(
  //       tap((catalog) => {
  //         localStorageRepository.remove(key);
  //         console.log(catalog);
  //         localStorageRepository.post(key, catalog);
  //       })
  //     );
  //   return response;
  // };

  const syncAsync = async () => {
    let response = await httpRepository.getAsync<Catalog>("/equipment/productcatalog")

    console.log(response);

    localStorageRepository.remove(key);
    localStorageRepository.post(key, response);

    return response;
  };

  // const _lastSyncObservable = (localId: string) => {
  //   let response = httpRepository
  //     .getObservable<{ ultimaAtualizacaoCardapio: string }>(
  //       "/Produto/BuscarUltimaAlteracaoProdutos/" + localId
  //     )
  //     .pipe(
  //       map((response) => {
  //         const newlastSyncCatalog: lastSyncCatalogResponse = {
  //           lastSyncCatalog: response?.ultimaAtualizacaoCardapio ?? "",
  //         };

  //         return newlastSyncCatalog;
  //       })
  //     );
  //   return response;
  // };

  const _lastSyncAsync = async (localId: string) => {
    let response = await httpRepository.getAsync<{ ultimaAtualizacaoCardapio: string }>(
      "/Produto/BuscarUltimaAlteracaoProdutos/" + localId,
      { timeout: 15000 }
    )


    const newlastSyncCatalog: lastSyncCatalogResponse = {
      lastSyncCatalog: response?.ultimaAtualizacaoCardapio ?? "",
    };

    return newlastSyncCatalog;


  };




  const syncIfDiffAsync = async (localId: string) => {
    const lastSyncCatalogResponse = await _lastSyncAsync(localId);   

    const lastSyncStorage = await localStorageRepository.get<lastSyncCatalogResponse>(keyLastSync)


    console.log(lastSyncCatalogResponse, lastSyncStorage);

    if (lastSyncCatalogResponse.lastSyncCatalog !== lastSyncStorage?.lastSyncCatalog) {

      if (lastSyncCatalogResponse) {
        localStorageRepository.remove(keyLastSync);
        localStorageRepository.post(
          keyLastSync,
          lastSyncCatalogResponse
        );
      }

      return true;
    } else {
      return false;
    }
  }


  // const deprecated_syncIfDiffObservable = (localId: string) => {
  //   return _lastSyncObservable(localId).pipe(
  //     flatMap((lastSyncCatalogResponse) => {
  //       return from(
  //         localStorageRepository.get<lastSyncCatalogResponse>(keyLastSync)
  //       ).pipe(
  //         map((lastSyncStorage) => {
  //           console.log(lastSyncCatalogResponse, lastSyncStorage);

  //           if (lastSyncCatalogResponse.lastSyncCatalog !== lastSyncStorage?.lastSyncCatalog) {
  //             console.log(
  //               lastSyncCatalogResponse.lastSyncCatalog +
  //               "==" +
  //               lastSyncStorage?.lastSyncCatalog
  //             );

  //             if (lastSyncCatalogResponse) {
  //               localStorageRepository.remove(keyLastSync);
  //               localStorageRepository.post(
  //                 keyLastSync,
  //                 lastSyncCatalogResponse
  //               );
  //             }
  //             return true;
  //           } else {
  //             return false;
  //           }
  //         })
  //       );
  //     })
  //   );
  // };




  return {   getAsync, syncAsync, syncIfDiffAsync };
};
