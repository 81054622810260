import { Drawer } from '@material-ui/core'
import React, { FC } from 'react'
import TourLayout from '../../components/layout/TourLayout'
import ReserverTourForm from '../../components/reserveTourForm/ReserverTourForm'
import styles from './ReserveTourPage.module.scss'
import UseReserveTourPage from './UseReserveTourPage'
import ReservertourResume from '../../components/reserverTourResume/ReserveTourResume'
import { Carousel } from 'components/carousel/Carousel'
import { useTourCart } from '../../components/tourCart/TourCartContext'
export interface IReserveTourPageProps {
    //propertys
}
const ReserveTourPage: FC<IReserveTourPageProps> = () => {
    const {
        tour,
        onPressBack,
        setResumeValues,
        resumeValues,
        onClickPaymentHandle,
        dateInfo,
        onClickAddReserveToCartHandle
    } = UseReserveTourPage();
    const { cartTourItems } = useTourCart()

    return (
        <TourLayout isTopLayout title={"Reservar"} onPressBack={onPressBack}>
            <div id={styles.ReserveTourPage} >
                {
                    tour && !!tour.showcaseImages?.length &&

                    <Carousel values={tour.showcaseImages.map(item => item.url)} />
                }
                <div className={styles.reserveTourPage}>

                    <h2>{tour?.name}</h2>
                    {
                        tour &&
                        <ReserverTourForm
                            dateInfo={dateInfo}
                            onPressContinuar={(value) => { setResumeValues(value); console.log(value) }}
                            tourDetail={tour}
                            cartTourItems={cartTourItems}
                        />
                    }
                    <Drawer
                        open={!!resumeValues}
                        // open={true}
                        onClose={() => setResumeValues(undefined)}
                        anchor={"bottom"}
                    >
                        {
                            tour && resumeValues &&
                            <ReservertourResume reserve={resumeValues} tour={tour} onPressAdicionar={onClickAddReserveToCartHandle}> </ReservertourResume>
                        }
                    </Drawer>
                </div>
            </div>
        </TourLayout>
    )
}
export default ReserveTourPage

