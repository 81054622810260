import { IPasswordPainel } from "Infrastructure/repositories/api/PasswordPainel";
import { ErrorCodeType } from "application/models/IError";
import { IOrderRepository } from "domains/order/interfaces/repositories/IOrderRepository";
import { GetError } from "utils/GetError";

export const GetPasswordUseCase = async (
  orderRepository: IOrderRepository,
  localId: string,
  PosSectionId: string
): Promise<IPasswordPainel | undefined> => {
  try {
    const result = await orderRepository.getPasswordPainel(
      PosSectionId ?? "",
      localId
    );
    return result;
  } catch (error) {
    GetError(
      error,
      "GetPasswordUseCase",
      "GetPasswordUseCase",
      "Falha ao obter senha",
      ErrorCodeType.GENERAL
    );
  }
};
