import { IAdminPrintService } from "../interfaces/services/IPrintService";
import { Operator } from "../aggregates/operator/Operator";

import { Order } from "../../order/agreggates/order/Order";
import { Ticket } from "../../order/agreggates/ticket/ticket";
import { TransactionTypeLabel } from "../../order/agreggates/payment/transactionType";
import { OrderItem } from "../../order/agreggates/order/OrderItem";
import { IPrintRepository } from "../../order/interfaces/repositories/iprintRespository";
import { LocalPrintRepository } from "../../../Infrastructure/services/cefSharpService/local/_LocalPrintRepository";

export const AdminPrintService = (
): IAdminPrintService => {

  const printRepository: IPrintRepository = LocalPrintRepository();

  const orderItemToTicket = (
    order: Order,
    orderItem: OrderItem,
    index: number
  ): Ticket => {
    return {
      card: order.payment?.cardNumber ?? "",
      categoria: orderItem.name,
      codigoBarras: orderItem.id,
      dataVenda: new Date(),
      documentName: orderItem.id,
      fichaAtual: index + 1,
      id: orderItem.id,
      orderFriendlyId: order.friendlyId,
      paymentType:
        TransactionTypeLabel.get(order.payment?.paymentMethod ?? 1) ?? "",
      produto: orderItem.name,
      totalFichas: order?.orderItems.length,
      valor: orderItem.price,
      terminal: "",
      notes: orderItem.orderComposition?.map((orderComposition) => ({
        description: orderComposition.description,
        options: orderComposition.orderCompositionItems.map(
          (orderCompositionItem) => (orderCompositionItem.description)
        ),
      })),
    };
  };



  const printTicket = async (
    order: Order,
    orderItemId: string,
    operator: Operator
  ): Promise<string> => {
    if (operator.roles.includes("ReimpressaoTicketTotem")) {
      const orderToPrint = order.orderItems.find((item) => item.id === orderItemId)
      if (orderToPrint && await printRepository.printOrderAsync(orderItemToTicket(order, orderToPrint, order.orderItems.findIndex(item => item.id === orderToPrint.id)))) {
        return "Impresso"
      } else {
        return "Falha ao imprimir"
      }
    } else {
      return "Voce não possui permissão"
    }
  };

  return { printTicket };
};
