import { Kiosk } from 'domains/kiosk/aggregates/Kiosk'
import { Order } from 'domains/order/agreggates/order/Order'
import React, { Fragment, useCallback, useEffect, useMemo } from 'react'
import "./RemotePrinter.css"
import { OpcoesDoConsumidor, ProdutoPedidoPos } from 'Infrastructure/repositories/api/PedidoMeep'
import { useCatalog } from 'application/contexts/catalog/CatalogContext'
import { OrderItem } from 'domains/order/agreggates/order/OrderItem'
import { OrderCompositionItem } from 'domains/order/agreggates/order/OrderCompositionItem'
import { OrderComposition } from 'domains/order/agreggates/order/OrderComposition'
import { toInputDateString } from 'utils/utils'


interface IRemotePrinterProps {
    kiosk: Kiosk,
    remoteToPrint: Order
}

interface ICategoryProducts {
    category: string;
    products: OrderItem[];
}


export const RemotePrinter: React.FC<IRemotePrinterProps> = ({ kiosk, remoteToPrint }) => {


    let previousStoreName: string = '';

    //TODO: Remover dependência do catálogo
    const { catalog } = useCatalog();

    const password = useMemo(() => remoteToPrint.passwordPanelCode, [remoteToPrint.passwordPanelCode]);

    const removerZerosEsquerda = (password: string | undefined) => {
        return password?.replace(/^0+/, '');
    }  

    const getOptional = useCallback(
        (produto: OrderItem) => {
            let _adicionais: OrderComposition[] = [];
            let _observacoes: OrderComposition[] = [];

            if (produto.orderComposition) {
                produto.orderComposition.forEach(it => {
                    if (it.orderCompositionItems.filter(f => f.description.length > 0).some(op => !op.productId)) {
                        const obs = {
                            ...it,
                            orderCompositionItems : it.orderCompositionItems.filter(i => i.description.length)
                        }
                        if (obs?.orderCompositionItems?.length) {
                            _observacoes.push(obs);
                        }
                    } else {
                        let nOpcoes: OrderCompositionItem[] = [];
                        for (const ito of it.orderCompositionItems) {
                            if (it.orderCompositionItems.length > 0 && ito.description !== "Observação" && !!ito?.productId) {
                                nOpcoes.push(ito);
                            }
                        }
                        if (nOpcoes.length) {
                            _adicionais.push({
                                ...it,
                                orderCompositionItems: nOpcoes
                            })
                        }
                    }
                });
            }           

            return {
                adicionais: _adicionais,
                observacoes: _observacoes,
            }
        },
        [],
    );   


    const categories = useMemo(() => {
        const record = remoteToPrint.orderItems.reduce((agrupado: Record<string, OrderItem[]>, produto) => {
            const { category } = produto;
            if (agrupado[category ?? 'Padrão']) {
                agrupado[category ?? 'Padrão'].push(produto);
            } else {
                agrupado[category ?? 'Padrão'] = [produto];
            }
            return agrupado;
        }, {});

        const _categories: ICategoryProducts[] = [];

        Object.keys(record).forEach(key => {
            _categories.push({
                category: key,
                products: record[key]
            })
        })

        return _categories;

    }, [remoteToPrint.orderItems])


    return (
        <div id="remoteLayoutPrinter">
            <div className="container-RemoteLayoutPrinter">
                <div className="localNameRemote">
                    {

                        <span className="textLocal">{kiosk.localName} - <span className="textPedido">{remoteToPrint.friendlyId}</span></span>

                    }
                </div>
                {
                    remoteToPrint.observation && <div className="observation-RemoteLayoutPrintern">
                        {remoteToPrint.observation}
                    </div>
                }

                {
                    kiosk.mustEnableManagementPassword &&
                    <div className="productPassWordOrderRemote">
                        <span className="textLabelSenha">{password === null || password === undefined || password === '' ? 'SEM SENHA' : 'SENHA'}</span> <span className="textSenha"> {password === null || password === undefined || password === '' ? 'Dirija-se ao balcão' : removerZerosEsquerda(password)}</span>
                    </div>
                }

                <div className="ordersItems-remoteLayoutPrinter">
                    {


                        categories.map((category, key) => (
                            <div className="container-RemoteOrderItem" key={key}>
                                {kiosk.printCategoryInProductName && (
                                    <div className="title-RemoteLayoutPrinter">
                                        {category.category}
                                    </div>
                                )}
                                {category.products.map((product, index) => {
                                    const { adicionais, observacoes } = getOptional(product);

                                    const storeNameToDisplay = (previousStoreName !== product.storeName && (catalog && catalog?.stores?.length > 1)) ? product.storeName : null;
                                    previousStoreName = product.storeName ?? '';

                                    return (
                                        <div className="content-OrderItem" key={index}>
                                            {storeNameToDisplay && (
                                                <div className="name-storeName-RemoteLayoutPrinter">
                                                    {storeNameToDisplay}:
                                                </div>
                                            )}
                                            <div className="ordersItem-RemoteLayoutPrinter">
                                                <div className="text-ordersItem-RemoteLayoutPrinter">
                                                    <div className="name-ordersItem-RemoteLayoutPrinter">
                                                        {product.quantity > 0 && <span className='name-remote-product'>{product.quantity}x</span>}
                                                        <span className='name-remote-product'>{product.name}</span>
                                                    </div>
                                                    <div className="price-ordersItem-RemoteLayoutPrinter">
                                                        <span className='name-remote-product-price'>R${product.productPrice.toFixed(2).replace(".", ",")}</span>
                                                    </div>
                                                </div>
                                                {(adicionais.length > 0 || observacoes.length > 0) && (
                                                    <div className="composition-ordersItem-RemoteLayoutPrinter">
                                                        {adicionais.length > 0 && (
                                                            <div className="options-ordersItem-RemoteLayoutPrinter">
                                                                {adicionais.map(opcaoDoConsumidor => (
                                                                    <div className="optionConsumer-ordersItem-RemoteLayoutPrinter">
                                                                        <div className="descriptionOptionConsumer-ordersItem-RemoteLayoutPrinter">
                                                                            {opcaoDoConsumidor.description}:
                                                                        </div>
                                                                        <div className="optionsItemsOptionConsumer-ordersItem-RemoteLayoutPrinter">
                                                                            {opcaoDoConsumidor.orderCompositionItems.map(option => (
                                                                                <div className="optionItemOptionConsumer-ordersItem-RemoteLayoutPrinter">
                                                                                    <div>
                                                                                        {option.quantity}x {option.description}
                                                                                    </div>
                                                                                    <div>
                                                                                        R${option.price.toFixed(2).replace(".", ",")}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        <>
                                                            {observacoes.map((op) => (
                                                                <div>
                                                                    <div className="descricao">{op.description}:</div>
                                                                    <div className="optionsItemsOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                        {
                                                                            op.orderCompositionItems.map((opcao) => (
                                                                                <div className="option">
                                                                                    <div>{opcao.description}</div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </>

                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    );

                                })}
                            </div>
                        ))}
                    {
                        remoteToPrint.observation &&
                        <div className="date-RemoteLayoutPrinter">
                            {remoteToPrint.observation}
                        </div>
                    }
                    <div className="amount-RemoteLayoutPrinter">
                        <div>Total:</div> <div>R${remoteToPrint.totalValue.toFixed(2).replace(".", ",")}</div>
                    </div>
                    <div className='remote-layout-dotted-separator' />
                    {remoteToPrint?.customer?.prism &&
                        <div className="date-RemoteLayoutPrinter">
                            Mesa: {remoteToPrint?.customer?.prism}
                        </div>}
                    {remoteToPrint?.customer?.name &&
                        <div className="date-RemoteLayoutPrinter">
                            Cliente: {remoteToPrint?.customer?.name}
                        </div>}

                    <div className="date-RemoteLayoutPrinter">
                        {toInputDateString(remoteToPrint.createdAt)}
                    </div>
                    <div className="date-RemoteLayoutPrinter">
                        {kiosk.kioskName}
                    </div>
                </div>

            </div>
        </div>
    )
}
