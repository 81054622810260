import { useCatalog } from "application/contexts/catalog/CatalogContext";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useUi } from "application/contexts/ui/UIContext";
import { ErrorCodeType } from "application/models/IError";
import { randomIntFromInterval } from "corss-cutting/numberFunctions";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";
import { CalcServiceFeeUseCase } from "modules/cart/application/useCases/CalcServiceFeeUseCase";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";
import { ICartItem } from "modules/cart/domain/models/ICart";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import { IOrder, ProdutoTipo } from "modules/order/domain/models/IOrder";
import { IPaymentResult } from "modules/payment/presentation/PaymentProvider";
import { useCallback, useMemo, useState } from "react";
import { GetError } from "utils/GetError";
import { roundValue } from "utils/utils";
import { v4 } from "uuid";

const UseCartView = (onSendOrder: (order: IOrder) => Promise<IPaymentResult>) => {
  const { kiosk } = useKiosk();
  const { getConfigurationProduct } = useCatalog();
  const { addLog } = useAppInsights();
  const { toast } = useUi();

  const [
    cartItems,
    clearCart,
    lastProductAdded,
    openLastProductToast,
    clearLastProduct,
    totals,
    configCart,
    setNewCart,
    showVerifyMajorityModal,
    tempMajorityProduct,
    addProductCart,
    setAdultHood,
    setShowVerifyMajorityModal,
    clearTempMajorityProduct,
    showCart,
    setShowCart,
  ] = useCartStore((state: ICartContext) => [
    state.cartItems,
    state.clearCart,
    state.lastProductAdded,
    state.openLastProductToast,
    state.clearLastProduct,
    state.totals,
    state.configCart,
    state.setNewCart,
    state.showVerifyMajorityModal,
    state.tempMajorityProduct,
    state.addProductCart,
    state.setAdultHood,
    state.setShowVerifyMajorityModal,
    state.clearTempMajorityProduct,
    state.showCart,
    state.setShowCart,
  ]);

  const [openOrderToGoModal, setOpenOrderToGoModal] = useState(false);

  const cartBarHandle = useCallback(() => {
    setShowCart(!showCart);
  }, [setShowCart, showCart]);

  const totalServiceFeeValue = CalcServiceFeeUseCase(cartItems, kiosk, totals);

  const serviceFeeProduct = useMemo(() => {
    if (totalServiceFeeValue > 0 && kiosk) {
      return {
        id: v4(),
        name: "Taxa de serviço de " + kiosk.serviceRatePercent * 100 + "%",
        category: "",
        price: totalServiceFeeValue,
        realPrice: totalServiceFeeValue,
        totalPrice: totalServiceFeeValue,
        description: "",
        productPrice: totalServiceFeeValue,
        productId: getConfigurationProduct(ProdutoTipo.TaxaServico)?.id ?? "",
        productType: ProdutoTipo.TaxaServico,
        quantity: 1,
        imageUrl: "",
        barcode: "",
        hideCatalog: false,
        orderComposition: [],
        compositions: [],
        printerName: null,
      } as ICartItem;
    } else {
      return null;
    }
  }, [getConfigurationProduct, kiosk, totalServiceFeeValue]);

  const totalWithServiceFee = useMemo(
    () => roundValue((totals.totalPrice) + totalServiceFeeValue),
    [totalServiceFeeValue, totals.totalPrice]
  );

  const sendOder = useCallback(
    async (observation?: string) => {
      const friendlyId = randomIntFromInterval(1, 9999);
      const id = v4();
      setNewCart(id);
      const order = {
        createdAt: new Date().toISOString(),
        id: id,
        orderItems: serviceFeeProduct
          ? [...cartItems, serviceFeeProduct]
          : cartItems,
        observation: observation,
        totalValue: totalWithServiceFee,
        friendlyId: kiosk?.kioskName.replace(/\D/gm, "") + " " + friendlyId,
      } as IOrder;
      console.log("SEND ORDER", order);
      addLog('Cart Confirm', {order})
      try {
        await onSendOrder(order);
      } catch (error) {
        GetError(
          error,
          "sendOder",
          "UseCartView",
          undefined,
          ErrorCodeType.PAYMENT
        )
      }
    },
    [
      setNewCart,
      serviceFeeProduct,
      cartItems,
      totalWithServiceFee,
      kiosk,
      onSendOrder,
      addLog
    ]
  );

  const onConfirmOrder = useCallback(() => {
    if (cartItems.length === 0) {
      toast('Carrinho vazio', "error");
    }else if (kiosk?.orderToGo) {
      setOpenOrderToGoModal(true);
    } else {
      sendOder();
    }
  }, [cartItems.length, kiosk, sendOder, toast]);

  const onPressSimMaioridade = () => {
    if (tempMajorityProduct) {
      setAdultHood(true);
      addProductCart(
        tempMajorityProduct.product,
        tempMajorityProduct.quantity,
        tempMajorityProduct.orderCompositions
      );
    }
  };

  const onPressNaoMaioridade = () => {
    setAdultHood(false);
    clearTempMajorityProduct();
    setShowVerifyMajorityModal(false);
  };

  return {
    lastProductAdded,
    openLastProductToast,
    clearLastProduct,
    cartBarHandle,
    cartItems,
    showCart,
    configCart,
    kiosk,
    totalWithServiceFee,
    clearCart,
    totalServiceFeeValue,
    onConfirmOrder,
    openOrderToGoModal,
    showVerifyMajorityModal,
    onPressSimMaioridade,
    onPressNaoMaioridade,
    sendOder,
    setOpenOrderToGoModal,
    setShowVerifyMajorityModal,
    serviceFeeProduct
  };
};

export default UseCartView;
