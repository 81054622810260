import { useKiosk } from 'application/contexts/kiosk/KioskContext';
import { usePrinter } from 'application/contexts/printer/PrinterContext';
import { MeepOrderRepository } from 'Infrastructure/repositories/api/OrderRepository';
import { TourRepository } from 'Infrastructure/repositories/api/TourRepository';
import PaymentTourReserveWithPinpad from 'modules/tour/application/useCases/payment/PaymentReserveTourWithPinpad';
import SyncReserveOrderUseCase from 'modules/tour/application/useCases/payment/SyncReserveOrderUseCase';
import { TourDetail } from 'modules/tour/domain/models/CatalogTour';
import { Tour } from 'modules/tour/domain/models/ReserveTour';
import React, { useCallback } from 'react'

// import { usePayment } from "../../../../application/contexts/payment/PaymentContext";
import { useUi } from '../../../../application/contexts/ui/UIContext';
import { Payment } from "../../../../domains/order/agreggates/payment/payment";
import { TransactionType } from '../../../../domains/order/agreggates/payment/transactionType';
import { CefSharpPaymentRepository } from '../../../../Infrastructure/services/cefSharpService/local/CefSharpPaymentRepository';
import { ReserveTour } from '../components/reserverTourResume/ReserveTourResume.type';
import { useHistory } from 'react-router';
import AddOrderOnDemandUseCase from 'modules/tour/application/useCases/payment/AddOrderOnDemandUseCase';
import ReserveTourLogRepository from 'Infrastructure/repositories/localStorage/ReserveTourLogRepository';
import AddLogTourUseCase from 'modules/tour/application/useCases/tour/AddLogTourUseCase';
import CancelReserveUseCase from 'modules/tour/application/useCases/payment/CancelReserveUseCase';
import { ICartTourItem } from '../components/tourCart/ICartTourItem';
import PostScheduleReservetWithoutPaymentUseCase from 'modules/tour/application/useCases/payment/PostScheduleReservetWithoutPaymentUseCase';
import PostScheduleCartReservetWithoutPaymentUseCase from 'modules/tour/application/useCases/payment/PostScheduleCartReservetWithoutPaymentUseCase';
import { ICartTour } from '../components/tourCart/ICartTour';
import SyncReserveCartOrderUseCase from 'modules/tour/application/useCases/payment/SyncReserveCartOrderUseCase';


const UsePaymentTour = (onSucessPayment?: () => void) => {

    const { toastFullscreen } = useUi();
    //TODO: IMplementar novo meio de pagamento
    // const { setinProgress, setMessage, setValuesWithoutOrder } = usePayment();
    const { printReserveTour } = usePrinter();
    const { kiosk } = useKiosk()
    const { replace } = useHistory()

    const paymentReserveTour = useCallback(
        async (reserveTour: ReserveTour, tour: TourDetail, transactionType: TransactionType = TransactionType.credit) => {
            try {
                // setValuesWithoutOrder({ totalValue: reserveTour.totalPrice, instalment: 1 })
                // setinProgress(true);
                // setMessage("Verificando Disponibilidade");
                const responseReserve = await PostScheduleReservetWithoutPaymentUseCase(TourRepository, reserveTour, tour);
                let response: Payment | undefined = undefined;
                if (reserveTour.totalPrice > 0) {
                    response = await PaymentTourReserveWithPinpad(reserveTour.totalPrice, transactionType)
                }
                if (response?.success || reserveTour.totalPrice === 0) {
                    console.log(response)
                    if (kiosk) {
                        try {
                            // setMessage("Sincronizando Pedido");
                            const syncOrderResponse = await SyncReserveOrderUseCase(MeepOrderRepository, tour, reserveTour, responseReserve, kiosk?.localId, transactionType, response?.FinancialTransactionModel);

                            if (syncOrderResponse.pedidoPOSId) {
                                // setMessage("Vinculando agendamento ao pedido");
                                const responseAddOrderDemand = await AddOrderOnDemandUseCase(TourRepository, syncOrderResponse.pedidoPOSId, responseReserve.demandScheduleId)
                            }
                        } catch (error) {
                            CancelReserveUseCase(TourRepository(), responseReserve.demandScheduleId)
                            throw (error)
                        }
                    }
                    AddLogTourUseCase(ReserveTourLogRepository(), { ...reserveTour, tour, transactionType }, responseReserve, response?.FinancialTransactionModel)

                    // setMessage("Aguarde a impressão do comprovante");

                    printReserveTour(reserveTour.customers.map((item) => {
                        return ({
                            id: item.id,
                            tourName: tour.name,
                            date: reserveTour.date,
                            customerName: item.name,
                            customerDocument: item.cpf,
                            customerBirthday: item.birthday,
                            price: item.price.price,
                        });
                    }))
                    toastFullscreen("Pagamento realizado com sucesso", "success", {onClose: () => {
                        replace("/")
                    }});
                    onSucessPayment && onSucessPayment();

                } else {
                    // toastFullscreen(response.errorMessage ?? "Pagamento Cancelado", "warning");
                    toastFullscreen("Transação não autorizada", "warning");
                    CancelReserveUseCase(TourRepository(), responseReserve.demandScheduleId)
                }
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.message) {
                    toastFullscreen(error?.response?.data?.message?.message ?? error?.response?.data?.message ?? "falha ao realizar agendamento, verifique a disponibilidade", "warning");
                } else {
                    toastFullscreen("Falha ao realizar agendamento verifique a disponibilidade", "warning");
                }
            } finally {
                // setinProgress(false)
            }

        },
        [kiosk, printReserveTour, toastFullscreen, onSucessPayment, replace],
    )


    const paymentCartTour = useCallback(
        async (cartTour: ICartTour, transactionType: TransactionType = TransactionType.credit) => {
            try {
                // setinProgress(true);
                // setMessage("Verificando Disponibilidade");
                const responseReserve = await PostScheduleCartReservetWithoutPaymentUseCase(TourRepository, cartTour);
                let response: Payment | undefined = undefined;
                if (cartTour.totalPrice > 0) {
                    response = await PaymentTourReserveWithPinpad(cartTour.totalPrice, transactionType)
                }
                if (response?.success || cartTour.totalPrice === 0) {
                    console.log(response)
                    if (kiosk) {
                        try {
                            // setMessage("Sincronizando Pedido");
                            const syncOrderResponse = await SyncReserveCartOrderUseCase(MeepOrderRepository, cartTour, responseReserve, kiosk?.localId, transactionType, response?.FinancialTransactionModel);

                            if (syncOrderResponse.pedidoPOSId) {
                                // setMessage("Vinculando agendamento ao pedido");
                                const responseAddOrderDemand = await AddOrderOnDemandUseCase(TourRepository, syncOrderResponse.pedidoPOSId, responseReserve.demandScheduleId)
                            }
                        } catch (error) {
                            CancelReserveUseCase(TourRepository(), responseReserve.demandScheduleId)
                            throw (error)
                        }
                    }
                    cartTour.cartTourItems.map((cartTourItem) => {
                        AddLogTourUseCase(ReserveTourLogRepository(), { ...cartTourItem.reserveTour, tour: cartTourItem.tour, transactionType }, responseReserve, response?.FinancialTransactionModel)
                    })

                    // setMessage("Aguarde a impressão do comprovante");

                    printReserveTour(cartTour.cartTourItems.map(cartTour => cartTour.reserveTour.customers.map((item) => {
                        return ({
                            id: item.id,
                            tourName: cartTour.tour.name,
                            date: cartTour.reserveTour.date,
                            customerName: item.name,
                            customerDocument: item.cpf,
                            customerBirthday: item.birthday,
                            price: item.price.price,
                        })
                    })).flatMap(item => item));
                    toastFullscreen("Pagamento realizado com sucesso", "success", {onClose: () => {
                        replace("/")
                    }});
                    onSucessPayment && onSucessPayment();

                } else {
                    // toastFullscreen(response.errorMessage ?? "Pagamento Cancelado", "warning");
                    toastFullscreen("Transação não autorizada", "warning");
                    CancelReserveUseCase(TourRepository(), responseReserve.demandScheduleId)
                }
            } catch (error) {
                console.log(error);
                if (error?.response?.data?.message) {
                    toastFullscreen(error?.response?.data?.message?.message ?? error?.response?.data?.message ?? "falha ao realizar agendamento, verifique a disponibilidade", "warning");
                } else {
                    toastFullscreen("Falha ao realizar agendamento verifique a disponibilidade", "warning");
                }
            } finally {
                // setinProgress(false)
            }

        },
        [kiosk, printReserveTour, toastFullscreen, onSucessPayment, replace],
    )

    return (
        { paymentReserveTour, paymentCartTour }
    )
}

export default UsePaymentTour

