export interface ICreateDefferedPromise<T> {
  promise: Promise<T>;
  resolver: (value: T) => void;
  rejecter: (error: any) => void;
}

export function createDefferedPromise<T = any>(): ICreateDefferedPromise<T> {
  let resolve: (value: T) => void = () => {};
  let reject: (error: any) => void = () => {};

  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  });

  return {
    promise,
    resolver: resolve,
    rejecter: reject,
  };
}
