
import { ICefSharp } from "./Interfaces/_ICefSharp";
import { IConfigRepository } from "../../../interfaces/IConfigRepository";
import { AppConfigurationData } from "../../../models/AppConfigurationData";
import { ICefSharpAppConfigurationService } from "./Interfaces/ICefSharpAppConfigurationService";

declare const CefSharp: ICefSharp;
declare const appConfigurationService: ICefSharpAppConfigurationService;

export const CefSharpConfigRepository = (): IConfigRepository => {

  const getConfigAsync = async (): Promise<AppConfigurationData> => {
    await CefSharp.BindObjectAsync("appConfigurationService");
    const appConfigData = await appConfigurationService.get();
    console.log("appConfigData: ", appConfigData);

    return {
      printType: appConfigData.PrintType,
      ticketNote: appConfigData.TicketNote,
      tickectName: appConfigData.TickectName,
      printQRCodeTicket: appConfigData.PrintQRCodeTicket,
      cnpj: appConfigData.CNPJ,
      userAgent: appConfigData.UserAgent,
      urlApp: appConfigData.UrlApp,
      equipamentId: appConfigData.EquipamentId,
      token: appConfigData.Token,
      macAddress: appConfigData.MacAddress,
    };
  };

  return { getConfigAsync };
};
