import React, { FC, Fragment, useCallback, useEffect, useMemo, useRef } from 'react'
import { Kiosk } from '../../../domains/kiosk/aggregates/Kiosk'
import "./ResumoLayoutPrinter.css"
import { OpcoesDoConsumidor, PedidoPos, ProdutoPedidoPos } from '../../../Infrastructure/repositories/api/PedidoMeep'
import QRCode from 'qrcode.react'
import { useCatalog } from 'application/contexts/catalog/CatalogContext'
import { hideCnpj, hideCpf } from 'utils/utils'
interface ResumoLayoutPrinterProps {
    pedidoPos: PedidoPos,
    kiosk: Kiosk
}

interface ICategoryProducts {
    category: string;
    products: ProdutoPedidoPos[];
}


export const ResumoLayoutPrinter: FC<ResumoLayoutPrinterProps> = ({ pedidoPos, kiosk }) => {
    let previousStoreName: string = '';

    //TODO: Remover dependência do catálogo
    const { catalog } = useCatalog();

    const getOptonal = useCallback(
        (produto: ProdutoPedidoPos) => {
            let _adicionais: OpcoesDoConsumidor[] = [];
            let _observacoes: OpcoesDoConsumidor[] = [];

            produto.opcoesDoConsumidorObject.forEach(it => {
                if (it.opcoes.filter(f => f.descricao.length > 0).some(op => !op.produtoId)) {
                    _observacoes.push(it);
                } else {
                    if (it.opcoes.length > 0 && it.descricao !== "Observação") {
                        _adicionais.push(it)
                    }
                }
            })

            return {
                adicionais: _adicionais,
                observacoes: _observacoes,
            }
        },
        [],
    )


    const categories = useMemo(() => {
        const record = pedidoPos.produtosObject.reduce((agrupado: Record<string, ProdutoPedidoPos[]>, produto) => {
            const { category } = produto;
            if (agrupado[category ?? 'Padrão']) {
                agrupado[category ?? 'Padrão'].push(produto);
            } else {
                agrupado[category ?? 'Padrão'] = [produto];
            }
            return agrupado;
        }, {});

        const _categories: ICategoryProducts[] = [];

        Object.keys(record).forEach(key => {
            _categories.push({
                category: key,
                products: record[key]
            })
        })

        return _categories;

    }, [pedidoPos.produtosObject])

    const password = useMemo(() => pedidoPos?.codigoPainelSenha, [pedidoPos]);

    const removerZerosEsquerda = (password: string) => {
        return password.replace(/^0+/, '');
    }

    return (
        <div id="resumoLayoutPrinter">
            <div className="container-ResumoLayoutPrinter">

                <div className="logo-ResumoLayoutPrinter">
                    <img width={150} src={require('./../../../assets/images/logo-meep.png')} alt="logo" />
                </div>
                <div className="localName-ResumoLayoutPrinter">
                    {

                        kiosk.localName

                    }
                </div>

                <div className="friendlyIdResume">
                    <span className="textLabelPedido">Pedido:</span> <span className="textPedido">{pedidoPos?.codigoPedido}</span>
                </div>

                {
                    kiosk.mustEnableManagementPassword &&
                    <div className="productPassWordOrderResume">
                        <span className="textLabelSenha">{password === null || password === undefined || password === '' ? 'SEM SENHA' : 'SENHA'}</span> <span className="textSenha"> {password === null || password === undefined || password === '' ? 'Dirija-se ao balcão' : removerZerosEsquerda(password)}</span>
                    </div>
                }


                {pedidoPos.pedidoPOSId && <div>
                    <QRCode width={500} value={pedidoPos.pedidoPOSId.replace("-", "").replace("-", "").replace("-", "").replace("-", "").replace("-", "")} />
                </div>
                }
                <div className="ordersItems-ResumoLayoutPrinter">
                    {


                        categories.map((category, key) => (
                            <div key={key}>
                                {kiosk.printCategoryInProductName && (
                                    <div className="title-ResumoLayoutPrinter">
                                        {category.category}
                                    </div>
                                )}
                                {category.products.map((product, index) => {
                                    const { adicionais, observacoes } = getOptonal(product);
                                   
                                    const storeNameToDisplay = (previousStoreName !== product.storeName && (catalog && catalog?.stores?.length > 1)) ? product.storeName : null;
                                    previousStoreName = product.storeName ?? '';

                                    return (
                                        <Fragment key={index}>
                                            {storeNameToDisplay && (
                                                <div className="name-storeName-resumoLayoutPrinter">
                                                    {storeNameToDisplay}:
                                                </div>
                                            )}
                                            <div className="ordersItem-ResumoLayoutPrinter">
                                                <div className="text-ordersItem-ResumoLayoutPrinter">
                                                    <div className="name-ordersItem-ResumoLayoutPrinter">
                                                        {product.nomeProduto}
                                                    </div>
                                                    <div className="price-ordersItem-ResumoLayoutPrinter">
                                                        R${(product.valor * (product.quantidade ?? 1)).toFixed(2).replace(".", ",")}
                                                    </div>
                                                </div>
                                                {(adicionais.length > 0 || observacoes.length > 0) && (
                                                    <div className="composition-ordersItem-ResumoLayoutPrinter">
                                                        {adicionais.length > 0 && (
                                                            <div className="options-ordersItem-ResumoLayoutPrinter">
                                                                {adicionais.map(opcaoDoConsumidor => (
                                                                    <div className="optionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                        <div className="descriptionOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                            {opcaoDoConsumidor.descricao}:
                                                                        </div>
                                                                        <div className="optionsItemsOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                            {opcaoDoConsumidor.opcoes.map(option => (
                                                                                <div className="optionItemOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                                    <div>
                                                                                        {option.quantidade}x {option.descricao}
                                                                                    </div>
                                                                                    <div>
                                                                                        R${option.produtoValor.toFixed(2).replace(".", ",")}
                                                                                    </div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        {observacoes.length > 0 && (
                                                            <div className="options-ordersItem-ResumoLayoutPrinter">
                                                                {observacoes.map(opcaoDoConsumidor => (
                                                                    <div className="optionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                        <div className="descriptionOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                            {opcaoDoConsumidor.descricao}:
                                                                        </div>
                                                                        <div className="optionsItemsOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                            {opcaoDoConsumidor.opcoes.map(option => (
                                                                                <div className="optionItemOptionConsumer-ordersItem-ResumoLayoutPrinter">
                                                                                    <div>{option.descricao}</div>
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </Fragment>
                                    );

                                })}
                            </div>
                        ))}

                    {
                        pedidoPos.observacaoPedido && <div className="observation-ResumoLayoutPrintern">
                            {pedidoPos?.observacaoPedido}
                        </div>
                    }

                    <div className="amount-ResumoLayoutPrinter">
                        <div>Total:</div> <div>R${pedidoPos.valorTotal.toFixed(2).replace(".", ",")}</div>
                    </div>
                    <div className='resume-layout-dotted-separator' />
                    {pedidoPos?.clienteEstabelecimentoObject.nome &&
                    <div className="kioskName">
                        {pedidoPos?.clienteEstabelecimentoObject.nome === kiosk.kioskName ? '' : 'Nome: ' + pedidoPos?.clienteEstabelecimentoObject.nome}
                    </div>}
                    {pedidoPos?.clienteEstabelecimentoObject.cpf && pedidoPos.clienteEstabelecimentoObject.cpf.length === 11 &&
                    <div className="kioskName">
                        CPF:{hideCpf(pedidoPos?.clienteEstabelecimentoObject.cpf)}
                    </div>}
                    {pedidoPos?.clienteEstabelecimentoObject.cpf && pedidoPos.clienteEstabelecimentoObject.cpf.length === 14 &&
                    <div className="kioskName">
                        CNPJ:{hideCnpj(pedidoPos?.clienteEstabelecimentoObject.cpf)}
                    </div>}
                    {pedidoPos?.clienteEstabelecimentoObject.mesa &&
                    <div className="kioskName">
                        Mesa: {pedidoPos?.clienteEstabelecimentoObject.mesa}
                    </div>}
                    <div className="date-ResumoLayoutPrinter">
                        {pedidoPos.dataPedido?.toLocaleString("pt-BR")}
                    </div>
                    <div className="date-ResumoLayoutPrinter">
                        {kiosk.kioskName}
                    </div>
                </div>
            </div>
        </div>
    )
}
