import React, { FC, useCallback, useMemo } from 'react'
import { OrderLog, ProdutoPedidoPosOrderLog } from '../../../../domains/order/agreggates/order/Order'
import { Grid, Container } from '@material-ui/core'
import moment from 'moment'
import "./OrderLogItemPreview.css"
import { OrderItem } from '../../../../domains/order/agreggates/order/OrderItem'
import { OrderItemPreview } from "./orderItemPreview/OrderItemPreview"
import { useAdmin } from '../../../../application/contexts/admin/adminContext'
import { TransactionTypeLabel } from '../../../../domains/order/agreggates/payment/transactionType'
import { PedidoPos, ProdutoPedidoPos } from 'Infrastructure/repositories/api/PedidoMeep'
import { ProdutoPedidoPosPreview } from './produtoPedidoPosPreview/ProdutoPedidoPosPreview'
import { useKiosk } from 'application/contexts/kiosk/KioskContext'
import { PrintType } from 'domains/kiosk/aggregates/PrintType'
import { PedidoPosPreview } from './pedidoPosPreview/PedidoPosPreview'
import { EletronicInvoiceTicket } from 'domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket'
import { usePrinter } from 'application/contexts/printer/PrinterContext'
import { PrintService } from 'domains/order/services/printService'

const printService = PrintService();

interface OrderLogItemPreviewProps {
    orderLog: OrderLog,
    onClose: () => void,
    isTicketPrinter: boolean
    onClickPrintProdutoPosTicket: (produtoPos: ProdutoPedidoPos) => void
    onClickPrintPedidoPos: (pedidoPos: PedidoPos) => void | undefined
}
export const OrderLogItemPreview: FC<OrderLogItemPreviewProps> = ({ orderLog, isTicketPrinter, onClickPrintProdutoPosTicket, onClickPrintPedidoPos }) => {

    const { printOrder, verifyRoles, operators, selectedOperator } = useAdmin();
    const { printInvoice } = usePrinter();
    const { kiosk } = useKiosk();

    const DEPRECATED_onClickPrintItem = useCallback(
        (orderItem: OrderItem) => {
            printOrder(orderLog, orderItem);
        },
        [orderLog, printOrder]
    );

    const onClickPrint = useCallback((nf: EletronicInvoiceTicket[]) => {
        if (kiosk?.newPrinter) {
            printInvoice(nf);
        } else {
            printService.printEletronicInvoice(nf);
        }
    }, [kiosk, printInvoice]);

    const nfs = useMemo(() => {
        return orderLog.nfce?.filter(it => it.danfe)
    }, [orderLog.nfce])

    console.log({orderLog});
    
    return (
        <Container className={"container-orderLogItemPreview"}>
            <Grid sm={12} item className="content-orderLogItemPreview">
                <Grid sm={12} item className="horario-orderLogItemPreview">
                    Pedido: {orderLog.friendlyId}
                </Grid>
                <Grid sm={12} item className="horario-orderLogItemPreview">
                    Status: {orderLog.paymentApproved ? 'Pagamento aprovado' : 'Pagamento pendente'}
                </Grid>
                <Grid sm={12} item className="horario-orderLogItemPreview">
                    Id: {orderLog.id}
                </Grid>
                <Grid sm={12} item className="horario-orderLogItemPreview">
                    Horario: {moment(orderLog.createdAt).format("DD/MM/YYYY [as] HH:mm:ss")}
                </Grid>
                <Grid sm={12} item className="quantidade-orderLogItemPreview">
                    Quantidade: {orderLog.orderItems.length}
                </Grid>
                <Grid sm={12} item className="total-orderLogItemPreview">
                    Total: R${orderLog.totalValue.toFixed(2).replace(".", ",")}
                </Grid>
                {orderLog?.payment && (
                    <Grid sm={12} item className="total-orderLogItemPreview">
                        Forma de pagamento:  {TransactionTypeLabel.get(orderLog.payment?.paymentMethod)}
                    </Grid>
                )}
                {orderLog?.payment?.cardNumber && (
                    <Grid sm={12} item className="total-orderLogItemPreview">
                        Cartão: {orderLog.payment.cardNumber}
                    </Grid>
                )}
                <Grid sm={12} item className="total-orderLogItemPreview">
                    Produtos:
                </Grid>
                <Grid sm={12} item className="orders-orderLogItemPreview">

                    
                    {
                        !(kiosk?.printType === PrintType.OrderResume) ?
                            orderLog.pedidoPOS ?
                                <>
                                    <ProdutoPedidoPosPreview indice />
                                    {orderLog.pedidoPOS.produtosObject.map((produto: ProdutoPedidoPosOrderLog, key) => {
                                        return (
                                            <ProdutoPedidoPosPreview
                                                key={key}
                                                isPaid={orderLog.paymentApproved}
                                                isTicketPrinter={isTicketPrinter}
                                                onClickPrint={() => onClickPrintProdutoPosTicket(produto)}
                                                permissionPrint={verifyRoles("ReimpressaoTicketTotem")}
                                                produtoPedidoPosPreview={produto}
                                                operators={operators}
                                                currentOperator={selectedOperator}
                                            />
                                        )
                                    })}
                                </>
                                :
                                <>
                                    <OrderItemPreview indice />
                                    {orderLog.orderItems.map((orderItem: OrderItem, key) => {
                                        
                                        return (
                                                <OrderItemPreview isPayd={orderLog.paymentApproved} key={key} isTicketPrinter={isTicketPrinter} onClickPrint={() => DEPRECATED_onClickPrintItem(orderItem)} permissionPrint={verifyRoles("ReimpressaoTicketTotem")} orderItem={orderItem} />
                                            )
                                    })
                                    }
                                </>
                            :

                            <>
                                <PedidoPosPreview indice />
                             
                                <PedidoPosPreview
                                    isPaid={orderLog.paymentApproved}
                                    isTicketPrinter={isTicketPrinter}
                                    permissionPrint={verifyRoles("ReimpressaoTicketTotem")}
                                    pedidoPosPreview={orderLog.pedidoPOS}
                                    orderLogItems={orderLog.orderItems}
                                    operators={operators}
                                    currentOperator={selectedOperator}
                                    onClickPrintPedidoPos={() => orderLog.pedidoPOS && onClickPrintPedidoPos(orderLog.pedidoPOS)}
                                />
                                        
                            </>




                    }
                </Grid>
                <Grid sm={12} item className="total-orderLogItemPreview">
                    Logs:
                </Grid>
                <Grid sm={12} item className="orders-orderLogItemPreviewList">
                    {orderLog?.log?.map((it, key) => (
                         <div key={key}>{`${moment(it.time).format("DD/MM/YYYY [as] HH:mm:ss")} - ${it.message}`}</div>
                    ))}
                </Grid>
                {verifyRoles("ReimpressaoTicketTotem") && nfs?.length && (
                    <Grid sm={12} item className="total-orderLogItemPreview-nf" onClick={() => onClickPrint(orderLog.nfce!)}>
                        Reimprimir Nota Fiscal
                    </Grid>
                )}
            </Grid>
        </Container>

    )
}
