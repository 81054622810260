import { Drawer } from "@material-ui/core";
import { MeepOrderRepository } from "Infrastructure/repositories/api/OrderRepository";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { useUi } from "application/contexts/ui/UIContext";
import { PassKeyboard } from "components/passKeyboard/PassKeyboard";
import { TecladoVirtual } from "components/tecladoVirtual/TecladoVirtual";
import {
  ISyncSchoolOrderResult,
  SyncSchoolOrderUseCase,
} from "modules/order/application/useCases/SyncSchoolOrderUseCase";
import { ICustomer } from "modules/order/domain/models/ICustomer";
import { IOrder } from "modules/order/domain/models/IOrder";
import { ICardCashless } from "modules/payment/domain/models/ICashless";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { createDefferedPromise } from "utils/DefferedPromise";


export interface ISchoolCashlessHandler {
  open(order: IOrder, cardData: ICardCashless): Promise<ISyncSchoolOrderResult>;
}

interface ICashlessProps {}

const UseSchoolCashless: React.ForwardRefRenderFunction<
ISchoolCashlessHandler,
ICashlessProps
> = ({}, ref) => {
  const deffered = useRef(createDefferedPromise<ISyncSchoolOrderResult>());
  const { toast, showLoading, hideLoading } = useUi();
  
  const [visible, setVisible] = useState(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [password, setPassword] = useState("");
  const [cardData, setCardData] = useState<ICardCashless>();
  const { kiosk } = useKiosk();
  const { addLog } = useAppInsights();
  const orderRepository = MeepOrderRepository(addLog);

  const open = useCallback((_order: IOrder, _cardData: ICardCashless) => {
    setOrder(_order);
    setVisible(true);
    setCardData(_cardData);
    deffered.current = createDefferedPromise<ISyncSchoolOrderResult>();
    return deffered.current.promise;
  }, []);

  useImperativeHandle(ref, () => ({ open }));

  const onKeyPress = useCallback(
    (key: string) => {
      if (password.length < 4) {
        setPassword(password + key);
      }
    },
    [password]
  );
  const onClearPress = useCallback(() => {
    setPassword("");
  }, []);

  const onRemovePress = useCallback(() => {
    setPassword((prev) => prev.substring(0, prev.length - 1));
  }, []);

  useEffect(() => {
    if (!visible) {
      setPassword("");
    }
  }, [visible]);

  const onConfirmPassword = useCallback(async (validated?: boolean) => {
    if (cardData?.password === password || validated) {
      if (!kiosk?.localId) {
        toast("Falha ao efetuar cobrança no cartão", "error");
        setVisible(false);
        deffered.current.rejecter("Falha no local corrente");
      } else {
        const customer: ICustomer = {
          ...order.customer,
          document: cardData?.holderDocument,
          name: cardData?.holderName,
          associationId: cardData?.associationId,
          tag: cardData?.number,
        };

        const syncSchoolOrder: IOrder = {
          ...order,
          customer,
        };

        showLoading();
        const result = await SyncSchoolOrderUseCase(
          orderRepository,
          syncSchoolOrder,
          kiosk.localId
        );
        hideLoading();

        if (!result.success) {
          setVisible(false);
          deffered.current.rejecter(new Error(result.message));
        }

        setVisible(false);
        deffered.current.resolver({ ...result, order: syncSchoolOrder });
      }
    } else {
      toast("Digitos não correspondem", "error");
      setPassword("");
    }
  }, [cardData, hideLoading, kiosk, order, orderRepository, password, showLoading, toast]);

  const onCancel = useCallback(() => {
    setVisible(false);
    setPassword("");
    deffered.current?.rejecter(new Error("Fechado pelo usuário ou tempo esgotado"));
  }, []);

  return (
    <Drawer anchor="bottom" open={visible} onClose={onCancel}>
      <div className={"drawer-paymentCashless"}>
        <h2>Digite sua senha</h2>
        {!kiosk?.useStrongPassword && (
          <div className={"password-paymentCashless"}>
            {password.replace(/[0-9]/g, "*")}
          </div>
        )}
        {kiosk?.useStrongPassword ? (
          <PassKeyboard onValidate={onConfirmPassword} password={cardData?.password ?? 'xxx'}/>
        ) : (
          <TecladoVirtual
            onKeyPress={onKeyPress}
            onPressClear={onClearPress}
            onPressBack={onRemovePress}
            onPressEnter={onConfirmPassword}
            type="numeric"
          />
        )}
      </div>
    </Drawer>
  );
};

export default forwardRef(UseSchoolCashless);
