import { Icon } from "@material-ui/core";
import React, { FC } from "react";
import "./BotaoInicio.css";
interface IBotaoInicio {
  onClick: () => void;
  icon?: string;
  title?: string;
  color?: string;
  'data-custom-id'?: string;
  'data-custom-description'?: string;
}
const BotaoInicio: FC<IBotaoInicio> = ({ onClick, children, icon, title, color, "data-custom-description" : desc, "data-custom-id": id }) => {
  return (
    <div 
      id="botaoInicio" 
      onClick={onClick} 
      style={color ? { backgroundColor: color } : {}}
      data-custom-id={id}
      data-custom-description={desc}
    >
      {children ?? (
        <div id="container" onClick={onClick} >
          {icon && (
            <div className="iconContainer">
              <Icon className="icon">{icon ?? "fiber_manual_record"}</Icon>
            </div>
          )}
          <div className="title">{title}</div>
        </div>
      )}
    </div>
  );
};

export default BotaoInicio;
