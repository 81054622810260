import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import styles from './InputText.module.scss'
import { TecladoVirtual } from '../tecladoVirtual/TecladoVirtual'
import { TextField } from '@material-ui/core';

export interface IInputTextProps {
    label?: string,
    placeHolder?: string,
    value?: string;
    onChange: (value: string) => void,
    onPressEnter?: () => void,
    type?: "numeric" | "text",
    className?: string,
    valueClassName?: string,
    onFocus?: () => void,
    onBlur?: () => void
    autoFocus?: boolean
    multiline?: boolean
}
export interface IInputTextRef {
    focus: () => void,
    blur: () => void
}
const InputText = forwardRef<IInputTextRef, IInputTextProps>(({ label,
    placeHolder,
    value,
    onChange,
    type,
    onPressEnter,
    className,
    valueClassName,
    onFocus,
    onBlur,
    autoFocus,
    multiline
}, ref) => {

    const [_focus, setFocus] = useState(autoFocus ?? false)

    const focus = useCallback(
        () => {
            setFocus(true);
        },
        [],
    )
    const blur = useCallback(
        () => {
            setFocus(false);
        },
        [],
    )

    useImperativeHandle(
        ref,
        () => ({ focus, blur }),
        [blur, focus],
    )

    useEffect(() => {
        if (_focus) {
            onFocus && onFocus()
        }

    }, [_focus, onFocus])

    useEffect(() => {
        if (_focus) {
            onBlur && onBlur()
        }

    }, [_focus, onBlur])


    return (
        <div id={styles.InputText} >
            <div className={`${styles.inputContainer}${className ? (" " + className) : ""}`} onClick={() => setFocus(true)} >
                {value !== "" ? 
                    <TextField 
                        className={valueClassName}
                        InputProps={{ disableUnderline: true }} 
                        inputProps={{style: { textAlign: 'center', fontSize: 28 }}} 
                        fullWidth 
                        multiline={multiline} 
                        value={value} 
                    /> 
                    : 
                    <div className={styles.placeHolder}>{placeHolder}</div>
                }
            </div>

            {/* <Modal open={focus} anchor={"bottom"} onClose={() => setFocus(false)} > */}
            {_focus &&
                <div className={styles.keyboard} >
                    <TecladoVirtual
                        label={label}
                        type={type === 'numeric' ? "numeric" : 'upperCaseCheck'}
                        onPressEnter={() => { onPressEnter && onPressEnter(); setFocus(false) }}
                        value={value}
                        onChange={onChange}
                        onClose={() => setFocus(false)}
                    />
                </div >
            }
            {/* </Drawer> */}
        </div >
    )
})
export default InputText