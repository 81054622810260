
import { Drawer } from '@material-ui/core'
import React, { createContext, FC, useContext, useEffect, useState } from 'react'
import { IScheduleItemCart } from '../../interfaces/IScheduleItemCart'
import styles from './ScheduleCart.module.scss'
import UseScheduleCart, { IUseScheduleCart } from './UseScheduleCart'
import ListScheduleCart from './listScheduleCart/ListScheduleCart'
import SchedulCartToolbar from './schedulCartToolbar/SchedulCartToolbar'
import { IOrderProviderRef } from 'modules/order/presentation/OrderProvider'

export interface IServiceCartProps {
    //propertys
    orderProvider: React.RefObject<IOrderProviderRef>
}


const ScheduleCartContext = createContext({} as IUseScheduleCart)
const ScheduleCartProvider: FC<IServiceCartProps> = ({ children, orderProvider }) => {
    const {
        add,
        clear,
        remover,
        scheduleCartItems,
        onClickContinuar,
        total,
        quantity,
        cancelSchedules
    } = UseScheduleCart(orderProvider);





    return (
        <div id={styles.ServiceCart} >
            <ScheduleCartContext.Provider value={{
                add,
                clear,
                remover,
                onClickContinuar,
                scheduleCartItems,
                total,
                quantity,
                cancelSchedules
            }} >
                {children}

            </ScheduleCartContext.Provider>
        </div>
    )
}

export const useScheduleCart = () => {
    const context = useContext(ScheduleCartContext)
    return context
}
export default ScheduleCartProvider