import React, { FC, useRef } from 'react'
import styles from './StoreTourPage.module.scss'
import TourLayout from '../../components/layout/TourLayout'
import UseStoreTourPage from './UseStoreTourPage'
import { useParams } from 'react-router-dom'
import TourCategoryList, { RefTourCategoryList } from '../../components/tourCategoryList/TourCategoryList'
import TourItem from '../../components/tourItem/TourItem'
import { TabCategoria } from '../../../../../components/tabCategoria/TabCategoria'
import { title } from 'process'
import { Dialog, Drawer } from '@material-ui/core'
import TourCart from '../../components/tourCart/TourCart'
import TourDetail from '../tourDetail/TourDetail'

export interface IStoreTourPageProps {
    //propertys
}
const StoreTourPage: FC<IStoreTourPageProps> = () => {
    const { storeTour, onPressBack, onClickTourDetails, onClickGoToPaymentHandle } = UseStoreTourPage();

    const tourCategoryListRef = useRef<RefTourCategoryList>(null)

    return (
        <div id={styles.StoreTourPage} >
            {
                <TourLayout
                    onPressBack={onPressBack}
                    title={storeTour?.name}
                    backgroundUrl={storeTour?.imageUrl}
                >

                    {storeTour ?
                        <>
                            <div className={styles.categoryMenu}>
                                {storeTour?.categoryTours.length < 1 && <TabCategoria
                                    hideImage
                                    list={storeTour?.categoryTours.map(item => ({ title: item.name, imageUrl: item.imageUrl }))}
                                    onClick={function (index: number): void {
                                        tourCategoryListRef.current?.scrollToCategoria(index)
                                    }}
                                />}
                            </div>
                            <TourCategoryList
                                ref={tourCategoryListRef}
                                categoryList={storeTour?.categoryTours ?? []}
                                renderTourItem={
                                    (_item) => (
                                        <TourItem onClick={() => onClickTourDetails(_item.id)} tourItem={_item} />
                                    )
                                }
                            />
                        </>
                        :
                        <div>''</div>
                    }
                </TourLayout>
            }

            <TourCart
                onClickPayment={onClickGoToPaymentHandle}
            />
        </div>
    )
}
export default StoreTourPage