import { IFullCardData } from "domains/cashless/aggregates/ICardCashless";
import { ICefSharpCardReader } from "./Interfaces/ICefSharpCardReader";

import { ICefSharp } from "./Interfaces/_ICefSharp";

declare const CefSharp: ICefSharp;
declare const rfidService: ICefSharpCardReader;
declare global {
  interface Window {
    setCardValue: (tag: string) => void;
    setFullCardData: (cardData: IFullCardData) => void;
    setCardBalance: (value: number) => void;
    setReadError: (errorMessage: string) => void;
    onRemovedCard: () => void;
    onDetectedCard: () => void;
  }
}

export const CefSharpCardReader = (): ICefSharpCardReader => {
  const enableCardReader = async (key?: string) => {
    await CefSharp.BindObjectAsync("rfidService");
    try {
      console.log("startCardReader", { key });
      await rfidService.startCardReader(key);
    } catch (error) {
      console.log("startCardReaderWithoutKey", error);
      await rfidService.startCardReader();
      throw new Error("CefSharp desatualizado");
    }
  };

  const writeBalance = async (tag: string, rechargeValue: number): Promise<void> => {
    await CefSharp.BindObjectAsync("rfidService");
    try {
      await rfidService.writeBalance(tag, rechargeValue);
    } catch (error) {
      console.log("writeBalanceError", error);
      throw new Error("Falha na gravação");
    }
  };

  const setBalance = async (rechargeValue: number) => {
    await rfidService.setCardBalance(rechargeValue);
  };

  const setReadError = async (errorMessage: string) => {
    try {
      await rfidService.setReadError(errorMessage);
    } catch (error) {
      console.log("ReadError", error);
    }
  };

  const onDetectedCard = () => {
    rfidService.onDetectedCard();
  };

  const onRemovedCard = () => {
    rfidService.onRemovedCard();
  };

  return {
    startCardReader: enableCardReader,
    writeBalance,
    setCardBalance: setBalance,
    setReadError,
    onDetectedCard,
    onRemovedCard,
  };
};
