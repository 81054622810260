import { useUi } from 'application/contexts/ui/UIContext';
import { DateInfo } from 'components/pickerDate/PickerDate.type';
import { TransactionType } from 'domains/order/agreggates/payment/transactionType';
import { TourRepository } from 'Infrastructure/repositories/api/TourRepository';
import GetDiponibilityAndVancanciesUseCase from 'modules/tour/application/useCases/tour/GetDiponibilityAndVancanciesUseCase';
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { Tour, TourDetail } from '../../../domain/models/CatalogTour';
import { ReserveTourFormValue } from '../../components/reserveTourForm/UseReserverTourForm.type';
import { useTourCart } from '../../components/tourCart/TourCartContext';
import { useCatalogTour } from '../../contexts/TourCatalogContext';
import UsePaymentTour from '../../hooks/UsePaymentTour'
const UseReserveTourPage = () => {
    const [tour, setTour] = useState<TourDetail>()
    const { tourId, storeTourId } = useParams<{ tourId: string, storeTourId: string }>();
    const { getTourById, } = useCatalogTour();
    const { replace } = useHistory();
    const [resumeValues, setResumeValues] = useState<ReserveTourFormValue>()
    const { paymentReserveTour: onClickPayment } = UsePaymentTour();
    const { showLoading, hideLoading, toast } = useUi();
    const [dateInfo, setDateInfo] = useState<DateInfo[]>([])
    const { addTourCart } = useTourCart()


    useEffect(() => {
        showLoading();
        getTourById(tourId).then((tour) => {
            //update disponibilidade
            if (tour?.scheduledId) {
                GetDiponibilityAndVancanciesUseCase(TourRepository, tour.scheduledId,).then(response => {
                    setDateInfo(response);
                })
            }

            setTour(tour);
        }).finally(() => {
            hideLoading();
        });
    }, [tourId, getTourById])

    const onPressBack = useCallback(() => {
        replace(`/tour/storeTour/${storeTourId}/moreDetails/${tourId}`)
    }, [replace, storeTourId])


    const onClickPaymentHandle = useCallback(
        (transactionType: TransactionType) => {
            if (resumeValues && tour) {
                onClickPayment(resumeValues, tour, transactionType)

                // addTourCart(resumeValues, tour);
            }
        },
        [onClickPayment, resumeValues, tour],
    )
    const onClickAddReserveToCartHandle = useCallback(
        () => {
            if (resumeValues && tour) {
                // onClickPayment(resumeValues, tour, transactionType)
                replace(`/tour`)
                toast("Adicionado ao carrinho", 'success')
                addTourCart(resumeValues, tour);
            }
        },
        [addTourCart, replace, resumeValues, toast, tour],
    )


    return (
        {
            tour,
            onPressBack,
            resumeValues,
            setResumeValues,
            onClickPaymentHandle,
            dateInfo,
            onClickAddReserveToCartHandle
        }
    )
}

export default UseReserveTourPage

