import { IOrderRepository } from 'domains/order/interfaces/repositories/IOrderRepository'
import { Order } from 'domains/order/agreggates/order/Order'
import { PedidoPos, ProdutoTipo } from 'Infrastructure/repositories/api/PedidoMeep'
import { ReserveTourFormRequestDTO } from 'modules/tour/domain/dtos/ReserveFormRequest'
import { TourDetail } from 'modules/tour/domain/models/CatalogTour'
import { v4 } from 'uuid'
import { TransactionType } from 'domains/order/agreggates/payment/transactionType'
import { FinancialTransactionModel } from 'domains/order/agreggates/payment/FinancialTransactionModel'
import { ReserveTour } from 'modules/tour/presentation/components/reserverTourResume/ReserveTourResume.type'
import { IAddReserveScheduleWithoutPaymentResponse } from 'modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentResponse'



const SyncReserveOrderUseCase = (
    orderRepository: () => IOrderRepository,
    tourDetail: TourDetail,
    reserve: ReserveTour,
    productSync: IAddReserveScheduleWithoutPaymentResponse,
    localId: string,
    transactionType?: TransactionType,
    transaction?: FinancialTransactionModel
): Promise<PedidoPos> => {
    const response = orderRepository().sendNowOrdersAsync(TourProductToOrder(tourDetail, reserve, productSync), localId, transactionType, transaction)
    return response;
}

//private
const TourToOrder = (tourDetail: TourDetail, reserve: ReserveTour, productSync: IAddReserveScheduleWithoutPaymentResponse) => {
    const newOrder: Order = {
        id: v4(),
        cartDate: new Date().toISOString(),
        orderItems: reserve.customers.map((item) => ({
            id: item.id,
            name: tourDetail.name + " - " + item.name + " - " + item.cpf,
            category: "",
            price: item.price.price,
            realPrice: item.price.price,
            totalPrice: item.price.price,
            description: "",
            productPrice: item.price.price,
            productId: tourDetail.id,
            productType: ProdutoTipo.Agendamento,
            quantity: 1,
            imageUrl: "",
            hideCatalog: false,
            barcode: "",
            orderComposition: [
                {
                    id: "",
                    compositionId: "Serviço",
                    description: "",
                    imageUrl: "",
                    totalPrice: 0,
                    orderCompositionItems: [
                        {
                            id: "",
                            produtoId: "",
                            compositionId: "",
                            description: tourDetail.name,
                            price: 0,
                            quantityFree: 0,
                            compositionItemId: "",
                            quantity: 0,
                            quantityFreeSelected: 0,
                            itemType: 0
                        }

                    ]
                }

            ],
            compositions: [],
            printerName: "",
        })),
        totalValue: reserve.totalPrice,
        friendlyId: "",
        createdAt: new Date().toISOString(),

    }

    return newOrder

}


const TourProductToOrder = (tourDetail: TourDetail, reserve: ReserveTour, productSync: IAddReserveScheduleWithoutPaymentResponse) => {


    console.log(reserve);


    const newOrder: Order = {
        id: v4(),
        cartDate: new Date().toISOString(),
        orderItems: productSync.productsDemanded.map((item, index) => {
            return ({
                id: v4(),
                name: item.productName,
                category: "",
                price: reserve.customers[index].price.price,
                realPrice: reserve.customers[index].price.price,
                totalPrice: reserve.customers[index].price.price,
                description: reserve.customers[index].name + " " + reserve.customers[index].cpf,
                productPrice: reserve.customers[index].price.price,
                productId: item.productId,
                productType: ProdutoTipo.Agendamento,
                quantity: 1,
                imageUrl: "",
                hideCatalog: false,
                barcode: "",
                orderComposition: [],
                compositions: [],
                printerName: "",
            })
        }),
        totalValue: reserve.totalPrice,
        friendlyId: "",
        createdAt: new Date().toISOString(),

    }

    return newOrder

}


export default SyncReserveOrderUseCase





