import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { useAdmin } from '../../../application/contexts/admin/adminContext'
import { OrderLogItem } from './orderLogItem/OrderLogItem'
import { Container, Grid, Modal, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Drawer, Backdrop } from '@material-ui/core';
import { OrderLogItemPreview } from './orderLogItemPreview/OrderLogItemPreview';
import { OrderLog } from '../../../domains/order/agreggates/order/Order';
import { AuthKeyboard } from '../authKeyboard/AuthKeyboard';
import { InputField } from '../../payment/inputField/InputField';
import { useUi } from '../../../application/contexts/ui/UIContext';
import "./OrderLogView.css"
import { usePrinter } from '../../../application/contexts/printer/PrinterContext';
import { orderToPedidoPOS } from '../../../Infrastructure/repositories/api/OrderRepository';
import { AdminRepository } from '../../../Infrastructure/repositories/api/AdminRepository';
import { PaymentService } from '../../../domains/order/services/paymentService';
import { useKiosk } from 'application/contexts/kiosk/KioskContext';
import { PrintType } from 'domains/kiosk/aggregates/PrintType';
import { PedidoPos, ProdutoPedidoPos } from 'Infrastructure/repositories/api/PedidoMeep';


//container
const paymentService = PaymentService();
const adminRepository = AdminRepository();

export const OrderLogView = () => {
    const { orderLog, onOrderLogReprinted, selectedOperator, verifyRoles } = useAdmin();
    const { kiosk } = useKiosk();

    const { printOrder, rePrintTicket } = usePrinter();


    const [modalPreviewOpen, setmodalPreviewOpen] = useState(false);
    const [itemSelectedView, setitemSelectedView] = useState<OrderLog | null>(null);
    const [autenticated, setautenticated] = useState(false);
    const [confirmCancelOpen, setconfirmCancelOpen] = useState(false);
    const [itemSelectedCancel, setitemselectedCancel] = useState<OrderLog | null>(null);

    const [keyboardValues, setkeyboardValues] = useState<{ min: number, max: number, message: string } | null>(null)

    // const { message, setMessage } = usePayment()
    const { toastFullscreen, showLoading, hideLoading } = useUi()

    const [inProgress, setinProgress] = useState(false);


    const onCloseModal = useCallback(
        () => {
            setmodalPreviewOpen(false);
            setitemSelectedView(null)
            onOrderLogReprinted();
        },
        [onOrderLogReprinted],
    )

    const onClickPreview = useCallback(
        (orderItem: OrderLog) => {
            setmodalPreviewOpen(true);
            setitemSelectedView(orderItem)
        },
        [],
    )

    const onClickCancelOrder = useCallback((orderItem: OrderLog) => {
        setconfirmCancelOpen(true);
        setitemselectedCancel(orderItem);
    }, [])

    const onClickReprintOrder = useCallback((orderItem: OrderLog) => {
        const newPedidoPos = orderToPedidoPOS(orderItem);
        debugger
        printOrder(newPedidoPos);

    }, [printOrder])


    const onClickPrintTicket = useCallback((pedidoPos: PedidoPos, produtoPedidoPos?: ProdutoPedidoPos) => {
        if (selectedOperator) {
             rePrintTicket(pedidoPos, selectedOperator, produtoPedidoPos);
        }
    }, [rePrintTicket, selectedOperator])

    const onCloseConfirmCancelModal = () => {
        setconfirmCancelOpen(false)
        setitemselectedCancel(null)
    }

    const onConfirmCancel = useCallback(async () => {
        if (autenticated && itemSelectedCancel) {
            if (itemSelectedCancel.payment?.paymentMethod) {
                // setMessage("");
                try {
                    showLoading();
                    setinProgress(true)

                    const value = await paymentService.cancelPaymentAsync(
                        itemSelectedCancel.totalValue,
                        itemSelectedCancel.payment?.paymentMethod,
                        itemSelectedCancel.payment?.FinancialTransactionModel.NumeroCupom,
                        itemSelectedCancel.createdAt)
                    if (value) {
                        if (itemSelectedCancel && selectedOperator) {
                            adminRepository.syncCancelPayment(itemSelectedCancel.id, selectedOperator?.id, selectedOperator?.password)
                        }
                        toastFullscreen("Estorno realizado com sucesso!", "success")
                    } else {
                        console.log("ERRO FALSE ON TRY");
                    }
                } catch (error) {
                    console.log("ERROR FALSE ON CATCH");
                } finally {
                    setinProgress(false)
                    // setMessage("");
                    setkeyboardValues(null);
                    hideLoading();
                }

            }
            onCloseConfirmCancelModal()
        }
    }, [autenticated, hideLoading, itemSelectedCancel, selectedOperator, showLoading, toastFullscreen])


    useEffect(() => {
        window.showKeyboard = (min: number, max: number, message: string) => {
            console.log("min: ", min);
            console.log("max: ", max);
            console.log("message: ", message);
            hideLoading();
            setkeyboardValues({ min, max, message })
        };

    }, []);


    const isResumePrinter = useMemo(() => (!!kiosk?.configSymstem.find(item => item.printType === PrintType.OrderResume)), [kiosk])


    const isTicketPrinter = true; // Permitir reimpressão para qualquer tipo de ficha
    // const isTicketPrinter = useMemo(() => (!!kiosk?.configSymstem.find(item => item.printType === PrintType.Ticket)), [kiosk])

    // Os logs ficam salvos na pasta %TEMP%
    // ${GUID}.txt
    const saveLogs = useCallback(
      () => {
        const element = document.createElement("a");
        const file = new Blob([JSON.stringify(orderLog)], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = `${new Date().getTime()}.txt`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      },
      [orderLog],
    )
    

    return (
        <Container id="container-orderlog">
            {/* {
                <Modal open={!!message && inProgress} BackdropComponent={Backdrop}>
                    <div className="message-container-orderlog" >
                        {message}
                    </div>
                </Modal>
            } */}
            <Drawer anchor="bottom" open={!!keyboardValues}>
                <div style={{ padding: 16 }}>
                    <InputField
                        keyboardType="numeric"
                        minInputLength={keyboardValues?.min}
                        maxInputLength={keyboardValues?.max}
                        description={keyboardValues?.message ?? ""}
                        onPressContinuar={(value) => {
                            window.keyboardValue = value ?? "";
                            setkeyboardValues(null)
                            showLoading();

                        }}
                        onPressCancel={() => {
                            setkeyboardValues(null);
                            setinProgress(false)
                        }}

                        buttonField={{
                            confirm: "Confirmar",
                            cancel: "Confirmar",
                        }}

                    ></InputField>
                </div>
            </Drawer>

            <Grid sm={12} container className="content-order-log-item">
                <Grid sm={2} item className="horario-order-log-item" onClick={saveLogs}>
                    Id
                </Grid>
                <Grid sm={4} item className="horario-order-log-item">
                    Horário
                </Grid>
                <Grid sm={1} item className="quantidade-order-log-item">
                    Itens
                </Grid>
                <Grid sm={2} item className="total-order-log-item">
                    Preço
                </Grid>
                <Grid sm={3} item className="total-order-log-item">
                    Ações
                </Grid>
            </Grid>
            {kiosk && orderLog.map((orderLogItem, index) => (
                orderLogItem && <OrderLogItem
                    key={index}
                    permissionCancelOrder={verifyRoles("CancelarPedidoPOS")}
                    permissionReprintOrder={verifyRoles("ReimpressaoTicketTotem")}
                    onClickCancelOrder={onClickCancelOrder}
                    onClickReprintOrder={onClickReprintOrder}
                    onClickPreview={onClickPreview}
                    orderLog={orderLogItem}
                    isResume={isResumePrinter}
                />))
            }


            <Dialog onClose={onCloseModal} open={modalPreviewOpen}>
                <>
                    {
                        itemSelectedView &&
                        <OrderLogItemPreview
                            orderLog={itemSelectedView}
                            onClose={onCloseModal}
                            isTicketPrinter={isTicketPrinter}
                            onClickPrintProdutoPosTicket={(produtoPedidoPos) => itemSelectedView?.pedidoPOS && onClickPrintTicket(itemSelectedView.pedidoPOS, produtoPedidoPos)}
                            onClickPrintPedidoPos={(pedidopos) => itemSelectedView?.pedidoPOS && onClickPrintTicket(itemSelectedView.pedidoPOS)}
                        />
                    }
                </>
            </Dialog>
            <Dialog onClose={onCloseModal} open={confirmCancelOpen}>
                <>
                    <DialogTitle> Digite sua senha novamente </DialogTitle>
                    <DialogContent>
                        <DialogContentText>Atenção o pedido {itemSelectedCancel?.friendlyId ?? ""} será cancelado, e o pagamento R${itemSelectedCancel?.totalValue.toFixed(2).replace(".", ",")} será estornada ao cliente</DialogContentText>
                        {
                            selectedOperator &&
                            <AuthKeyboard password={selectedOperator.password} onValidate={(autenticated) => { setautenticated(autenticated) }} />
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onCloseConfirmCancelModal}>Cancelar</Button>
                        <Button onClick={onConfirmCancel}>Confirmar</Button>
                    </DialogActions>
                </>
            </Dialog>
        </Container>
    )
}
