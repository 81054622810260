import { Drawer, Icon } from '@material-ui/core'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { AvailableDay } from '../../../../domains/schedule/agreggates/ICollaboratorSchedule'
import './PickerDate.scss'
import ButtonPickerSchedule from '../buttonPicker/ButtonPickerSchedule'
import Calendar from 'react-calendar';
import { format, getDay } from 'date-fns'
import { IDemandedService } from '../../../../domains/schedule/agreggates/IDemandedService'
import { finished } from 'stream'
// import 'react-calendar/dist/Calendar.css'
export interface IPickerDateProps {
    //propertys
    disabledClick?: boolean
    availableDays?: AvailableDay[];
    value: Date | null;
    onChange: (Date: Date) => void;
}
const PickerDate: FC<IPickerDateProps> = ({
    availableDays,
    disabledClick,
    value,
    onChange
}) => {
    const [openList, setopenList] = useState(true)


    const closeListHandle = () => {
        setopenList(false)
    }

    const openListHandle = useCallback(() => {
        !disabledClick && setopenList(true)
    }, [disabledClick])

    const onClickItem = (Date: Date) => {
        onChange(Date);
        closeListHandle();
    }

    const disabledDayOfWeek = useCallback(
        ((availableDays: AvailableDay[], date: Date) => {
            return !availableDays.find((availableDay) => availableDay.dayOfWeek === getDay(date))
        }),
        [],
    )

    useEffect(() => {
        if (!disabledClick) {
            setopenList(true)
        }
        return () => {

        }
    }, [disabledClick])


    return (
        <div id={"PickerDateOld"} >
            {
                <div className={"botao"} style={{ opacity: disabledClick ? 0.4 : 1 }}>
                    <ButtonPickerSchedule onClick={openListHandle} selected={!!value}>
                        <Icon fontSize={"large"}>event</Icon>  {value ? value.toLocaleDateString('pt-BR') :
                            "Selecionar Data"}
                    </ButtonPickerSchedule>
                </div>
            }
            {

                <Drawer anchor={"bottom"} open={!disabledClick && availableDays && openList} onClose={closeListHandle}>
                    <div id={"drawerDateOld"}>
                        <h1>Selecione uma Data</h1>
                        <div className={"calendar"}>
                            {
                                <Calendar
                                    minDate={new Date()}
                                    locale="pt-BR"
                                    className={"react-calendar"}
                                    onChange={(date: Date) => onClickItem(date as Date)}
                                    tileDisabled={({ activeStartDate, date, view }) => !!availableDays ? disabledDayOfWeek(availableDays, date) : false}
                                // tileContent={
                                //     ({ view, activeStartDate, date }) => <div><Icon>close</Icon></div>
                                // }
                                // tileClassName={
                                //     ({ view, activeStartDate, date }) => "busyDay"
                                // }

                                />
                            }
                        </div>
                    </div>
                </Drawer>
            }
        </div>
    )
}
export default PickerDate