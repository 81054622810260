import React, { FC, useCallback, useRef } from 'react'
import styles from './ReserverTourForm.module.scss'
import UseReserverTourForm, { cpfMaks } from './UseReserverTourForm'
import PickerDate from 'components/pickerDate/PickerDate'
import { TecladoVirtual } from 'components/tecladoVirtual/TecladoVirtual'
import InputText, { IInputTextRef } from 'components/inputText/InputText'
import { Button, Drawer, Icon, MenuItem, TextField } from '@material-ui/core'
import { TourReserveForm } from './ReserveTour.type'
import { ReserveTourFormValue } from './UseReserverTourForm.type'
import { DateInfo } from 'components/pickerDate/PickerDate.type'
import { differenceInCalendarYears, differenceInYears, format } from 'date-fns'
import TimeSelect from './timeSelect/TimeSelect'
import { AddCircleOutline } from '@material-ui/icons'
import { ICartTourItem } from '../tourCart/ICartTourItem'
export interface IReserverTourFormProps {
    //propertys
    tourDetail: TourReserveForm,
    onPressContinuar: (values: ReserveTourFormValue) => void,
    dateInfo: DateInfo[],
    cartTourItems: ICartTourItem[]
}
const ReserverTourForm: FC<IReserverTourFormProps> = ({ tourDetail: tour, onPressContinuar, dateInfo, cartTourItems }) => {

    const {
        date,
        customers,
        changeDateHandle,
        onChangeKeyboard,
        onPressNewCustomer,
        onPressDeleteCustomerByIndex,
        changePriceHandle,
        onPressContinuarHandle,
        time,
        setTime,
        totalValue,
        localVacancies
    } = UseReserverTourForm(tour, onPressContinuar, cartTourItems);


    const refInputCpf = useRef<IInputTextRef>(null);
    const refInputName = useRef<IInputTextRef>(null);
    const refInputBirthday = useRef<IInputTextRef>(null);
    const refInputPhone = useRef<IInputTextRef>(null);
    const refInputNote = useRef<IInputTextRef>(null);
    const refInputRg = useRef<IInputTextRef>(null);

    const removePipe = (value: string) => {
        if (value.includes("|")) {
            const [newValue1, newValue2] = value.split("|");
            return newValue2
        } else {
            return value
        }
    }

    const birthDayToDate = (birthDay: string) => {
        const [day, mounth, year] = birthDay.split("/");
        return new Date(Number(year), Number(mounth), Number(day), 0, 0, 0, 0)
    }

    const calcuDisabledValueByAge = (birthday: string, minAge?: number, maxAge?: number) => {
        if (maxAge && maxAge !== 0 && differenceInYears(new Date(), birthDayToDate(birthday)) > maxAge) {
            return { disabled: true, message: `(Idade máxima é ${maxAge} anos)` }
        }
        if (minAge && minAge !== 0 && differenceInYears(new Date(), birthDayToDate(birthday)) < minAge) {
            return { disabled: true, message: `(Idade mínima é ${minAge} anos)` }
        }
        return { disabled: false }
    }

    return (
        <div id={styles.ReserverTourForm} >
            <div className={styles.container} >
                <div className={styles.item}>
                    <div className={styles.label}>Data:</div>
                    <PickerDate
                        dateInfo={dateInfo}
                        value={date}
                        onChange={changeDateHandle}
                    />
                </div>
                {
                    date &&
                    <div className={styles.item}>
                        <div className={styles.label}>Hora:</div>
                        {/* {format(date, "dd/MM/yyyy")} */}
                        <TimeSelect
                            times={dateInfo.find(item => item.day === format(date, "dd/MM/yyyy"))?.times ?? []}
                            value={time}
                            onChange={setTime}
                            localVacancies={localVacancies}
                        />
                    </div>
                }
                {
                    customers.map((item, index) => (
                        <div className={styles.customerItem}>

                            <h2 onClick={() => onPressDeleteCustomerByIndex(index)} >Cliente {index + 1}</h2>
                            {customers.length > 1 &&
                                <div onClick={() => onPressDeleteCustomerByIndex(index)} className={styles.closeIconItem}>
                                    <Icon fontSize="large">delete</Icon>
                                </div>
                            }
                            <div className={styles.item}>
                                <div className={styles.label}> CPF: </div>
                                <InputText
                                    ref={refInputCpf}
                                    className={styles.input}
                                    label="Digite o CPF"
                                    placeHolder="Digite o CPF"
                                    value={item.cpf}
                                    onChange={(value) => onChangeKeyboard(index, "cpf", cpfMask(value))}
                                    type={'numeric'}
                                    onFocus={() => {
                                        refInputName.current?.blur();
                                        refInputBirthday.current?.blur();
                                        refInputNote.current?.blur();
                                        refInputPhone.current?.blur();
                                        refInputRg.current?.blur();
                                    }}
                                />

                            </div>
                            <div className={styles.item}>
                                <div className={styles.label}> RG ou Certidão de nascimento: </div>
                                <InputText
                                    ref={refInputRg}
                                    className={styles.input}
                                    label="RG / Certidão"
                                    placeHolder="RG / Certidão"
                                    value={item.rg}
                                    onChange={(value) => onChangeKeyboard(index, "rg", value)}
                                    type={'numeric'}
                                    onFocus={() => {
                                        refInputName.current?.blur();
                                        refInputBirthday.current?.blur();
                                        refInputNote.current?.blur();
                                        refInputPhone.current?.blur();
                                        refInputCpf.current?.blur();
                                    }}
                                />

                            </div>
                            <div className={styles.item}>
                                <div className={styles.label}>Nome:</div>
                                <InputText
                                    ref={refInputName}
                                    className={styles.input}
                                    label="Digite o Nome"
                                    placeHolder="Digite o Nome"
                                    value={item.name}
                                    onChange={(value) => onChangeKeyboard(index, "name", value)}
                                    type={'text'}
                                    onFocus={() => {
                                        refInputCpf.current?.blur();
                                        refInputRg.current?.blur();
                                        refInputBirthday.current?.blur();
                                        refInputNote.current?.blur();
                                        refInputPhone.current?.blur();

                                    }}
                                />
                            </div>
                            <div className={styles.item}>
                                <div className={styles.label}>Data de nascimento:</div>
                                <InputText
                                    ref={refInputBirthday}
                                    className={styles.input}
                                    label="Digite a data de nascimento"
                                    placeHolder="Digite a data de nascimento"
                                    value={item.birthday}
                                    onChange={(value) => onChangeKeyboard(index, "birthday", dateMask(value))}
                                    type={'numeric'}
                                    onFocus={() => {
                                        refInputRg.current?.blur();
                                        refInputName.current?.blur();
                                        refInputCpf.current?.blur();
                                        refInputNote.current?.blur();
                                        refInputPhone.current?.blur();

                                    }}
                                />
                            </div>

                            <div className={styles.item}>
                                <div className={styles.label}>Telefone:</div>
                                <InputText
                                    ref={refInputPhone}
                                    className={styles.input}
                                    label="Telefone de contato"
                                    placeHolder="Telefone de contato"
                                    value={item.phone}
                                    onChange={(value) => onChangeKeyboard(index, "phone", telefoneMask(value))}
                                    type={'numeric'}
                                    onFocus={() => {
                                        refInputName.current?.blur();
                                        refInputRg.current?.blur();
                                        refInputBirthday.current?.blur();
                                        refInputCpf.current?.blur();
                                        refInputNote.current?.blur();
                                    }}
                                />
                            </div>
                            <div className={styles.item}>
                                <div className={styles.label}>Hotel:</div>
                                <InputText
                                    ref={refInputNote}
                                    className={styles.input}
                                    label="Digite o nome do hotel que você se encontra"
                                    placeHolder="Nome do Hotel"
                                    value={item.note}
                                    onChange={(value) => onChangeKeyboard(index, "note", value)}
                                    onFocus={() => {
                                        refInputName.current?.blur();
                                        refInputRg.current?.blur();
                                        refInputBirthday.current?.blur();
                                        refInputCpf.current?.blur();
                                        refInputPhone.current?.blur();
                                    }}
                                />
                            </div>

                            <div className={styles.item}>
                                <div className={styles.label}>Valor:</div>
                                <select
                                    // label="Digite a data de nascimento"
                                    placeholder="Valor"
                                    value={item.price?.description}
                                    onChange={(ev) => { changePriceHandle(index, ev.target.value) }}
                                    className={styles.select}
                                >
                                    <option value={""}>Selecionar</option>
                                    {tour?.prices.map((itemValue) => (
                                        <option
                                            value={itemValue.description}
                                            style={
                                                calcuDisabledValueByAge(item.birthday, itemValue.minAge, itemValue.maxAge).disabled ?
                                                    { color: '#BDBDBD' }
                                                    :
                                                    { color: '#333333' }
                                            }
                                            disabled={
                                                calcuDisabledValueByAge(item.birthday, itemValue.minAge, itemValue.maxAge).disabled
                                            }
                                        >
                                            {removePipe(itemValue.description)} - {new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL", }).format(itemValue.price)} {
                                                calcuDisabledValueByAge(item.birthday, itemValue.minAge, itemValue.maxAge).message
                                            }
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                    ))
                }

                {
                    localVacancies && ((localVacancies - customers.length) > 0) && <Button fullWidth className={styles.botaoNovoCliente} variant="outlined" onClick={onPressNewCustomer}>Adicionar nova pessoa <AddCircleOutline /></Button>
                }


                {/* <div className={styles.botaoNovoCliente}>Adicionar nova pessoa<AddCircleOutline /></div> */}
            </div>
            <div className={styles.botaoContinuar}>
                <div className={styles.infoSchedule}>

                    <p>
                        Pessoas: <b>{customers.length}</b>
                    </p>
                    <p> Total: <b>{new Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                    }).format(totalValue)}</b>
                    </p>

                </div>
                <Button fullWidth onClick={onPressContinuarHandle}>Reservar</Button>


            </div>
        </div >
    )
}
export default ReserverTourForm

function cpfMask(cpf: string): string {
    cpf = cpf.substr(0, 14).replace(/\D/g, "")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d)/, "$1.$2")
    cpf = cpf.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return cpf;
}

function dateMask(date: string): string {
    date = date.substr(0, 10).replace(/\D/g, "")
    date = date.replace(/(\d{2})(\d)/, "$1/$2")
    date = date.replace(/(\d{2})(\d)/, "$1/$2")

    return date;
}
function telefoneMask(telefone: string): string {
    telefone = telefone.substr(0, 15).replace(/\D/g, "")
    telefone = telefone.replace(/(\d{2})(\d)/, "($1) $2")
    telefone = telefone.replace(/(\d{5})(\d)/, "$1-$2")

    return telefone;
}
