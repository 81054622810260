import { add, addMinutes, differenceInMinutes, formatISO9075, getDay, isBefore, isWithinInterval } from "date-fns";
import { ICollaborator } from "../../domains/schedule/agreggates/ICollaborator";
import { ICollaboratorSchedule } from "../../domains/schedule/agreggates/ICollaboratorSchedule";
import { IDemandedService } from "../../domains/schedule/agreggates/IDemandedService";
import { IService } from "../../domains/schedule/agreggates/IServices";
import ScheduleRepository from "../../Infrastructure/repositories/api/ScheduleRepository";
import { ITimeItem } from "./interfaces/ITimeItem";
import { TimeStatus } from "./interfaces/TimeStatus";
import shuffleArray from '../../corss-cutting/shuffleArray'
import { Product } from "../../domains/catalog/aggregates/Product";
import { ProdutoTipo } from "../../Infrastructure/repositories/api/PedidoMeep";
import { OrderComposition } from "../../domains/order/agreggates/order/OrderComposition";
import { IScheduleItemCart } from "./interfaces/IScheduleItemCart";
import { Order } from "../../domains/order/agreggates/order/Order";
import { v4 } from "uuid";
import { IServiceCategory } from "../../domains/schedule/agreggates/IServiceCategory";

export const ScheduleUseCase = () => {
    /**
     * Gerar os horários disponivels e ocupados para listagem.
     * @param date Dia selecionado
     * @param demmandedServices Horarios ocupados
     * @param collaboratoSchedule Dados da agenda do Colaborador
     * @param now Hora Atual
     * @returns ITimeItem[] Array de horarios disponiveis e ocupados a partir do horario atual
     */
    const generateTimes = (date: Date, demmandedServices: IDemandedService[], collaboratoSchedule: ICollaboratorSchedule, now: Date): ITimeItem[] => {
        const dayOfWeek = getDay(date)
        const availableDay = collaboratoSchedule.availableDays.find((_availableDay) => _availableDay.dayOfWeek === dayOfWeek);
        const demandedService = demmandedServices.filter((_demandedService) => _demandedService.date === date.toLocaleDateString("pt-BR")).flatMap(demmandedService => demmandedService.items);
        const duration = collaboratoSchedule.serviceDuration;
        const startHour = availableDay && new Date(availableDay?.startDateTime);
        const endHour = availableDay && new Date(availableDay?.endDateTime);

        const durationBase = 10


        console.log("===GENERATE TIMES===")
        console.log(collaboratoSchedule.availableDays)
        console.log(dayOfWeek)
        console.log(availableDay)
        console.log(demandedService)
        console.log(duration)
        console.log(startHour)
        console.log(endHour)

        const timeItems: ITimeItem[] = [];

        // Gerar Horarios
        if (availableDay && startHour && endHour) {
            const totalMinutes = differenceInMinutes(endHour, startHour);
            const timeItemsLength = totalMinutes / durationBase;

            for (let i = 0; i < timeItemsLength; i++) {
                const newtimeHour = addMinutes(concatDateTime(date, startHour), i * durationBase);
                // console.log("newtimeHour", newtimeHour)

                // const newTime: ITimeItem = {
                //     time: newtimeHour,
                //     status: !!demandedService?.find((_demmandedServiceItem) => (new Date(_demmandedServiceItem.dateTime)).toISOString() === newtimeHour.toISOString()) ? TimeStatus.busy : TimeStatus.available
                // }



                //Verificar se o horario atual está dentro de algum intervalo.
                const ocupation = demandedService.find(demanded => {
                    return isWithinInterval(newtimeHour, { start: new Date(demanded.dateTime), end: addMinutes(new Date(demanded.dateTime), demanded.duration - 1) })
                })

                const newTime: ITimeItem = {
                    time: newtimeHour,
                    status: ocupation ? TimeStatus.busy : TimeStatus.available
                }


                if (isBefore(now, concatDateTime(date, newTime.time))) {
                    timeItems.push(newTime)
                }
            }

        }
        // console.log(timeItems)
        return calcIfInterval(timeItems, duration, durationBase)
    }

    /**
     * Calcula dentre os tempo quais cabem o serviço escolhido
     */
    const calcIfInterval = (horarios: ITimeItem[], durationInMinutes: number, durationBase: number) => {
        const horariosOndeCabemOsServico = horarios.map((horario, index, horarios) => {
            if (horario.status === TimeStatus.available) {
                let intervalo = 0;
                for (let i = index; i < horarios.length; i++) {
                    if (horarios[i].status === TimeStatus.available) {
                        intervalo = intervalo + durationBase;
                    } else {
                        break;
                    }
                }
                if (intervalo < durationInMinutes) {
                    return { ...horario, status: TimeStatus.busy };
                }
            }
            return horario;
        });
        return horariosOndeCabemOsServico;
    }





    /**
     * Concatena data e hora formando um Date completo
     * @param date 
     * @param time 
     * @returns 
     */
    const concatDateTime = (date: Date, time: Date) => {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds(), time.getMilliseconds())
    }

    /**
     * Retorna o primeiro horario disponivel na data informada
     * @param date 
     * @param demmandedServices 
     * @param collaboratoSchedule 
     * @returns ITimeItem | null
     */

    const calcFirstTimeCollaborator = (date: Date, demmandedServices: IDemandedService[], collaboratoSchedule: ICollaboratorSchedule, now: Date): ITimeItem | null => {

        const times = generateTimes(date, demmandedServices, collaboratoSchedule, now);
        return times.find(time => time.status === TimeStatus.available) ?? null;
    }



    /**
     * Retorna 3 meses a frente da data de hoje 
     * @returns 
     */
    const getFinalDate = (now: Date, months: number = 3) => {
        // console.log(formatISO9075(add(now, { months }), { representation: 'date' }))
        return (formatISO9075(add(now, { months }), { representation: 'date' }))
    }

    /**
     * Retornar dados do colaborador e as servicos agendados
     * @param collaborator 
     * @returns Promise<{ collaboratorSchedule: ICollaboratorSchedule, demandedServices: IDemandedService[] }>
     */
    const getColaboratorScheduleAndDemandedServices = async (collaborator: ICollaborator, now: Date): Promise<{ collaboratorSchedule: ICollaboratorSchedule, demandedServices: IDemandedService[] }> => {
        const scheduleRepository = ScheduleRepository();
        const colaboratorSchedulePromise = scheduleRepository.getColaboratorScheduleByServiceScheduledId(collaborator.serviceScheduledId)
        const demandedPromise = scheduleRepository.getAllDemandedServicesByScheduleId(collaborator.scheduleId, getFinalDate(now))
        const [responseColaboratorSchedule, responseDemanded] = await Promise.all([colaboratorSchedulePromise, demandedPromise])

        return { collaboratorSchedule: responseColaboratorSchedule, demandedServices: responseDemanded }
    }

    /**
     * Obtem o proximo horário livre, considerando todos os colaboradores
     * @param serviceId 
     */
    const getNextAvailableScheduleOfAllColaborators = async (serviceId: string, now: Date) => {

        const scheduleRepository = ScheduleRepository();
        const collaborators = shuffleArray<ICollaborator>(await scheduleRepository.getCollaboratorsByServiceId(serviceId));

        const timesCollaboratorPromises = collaborators.map(async (colaborator) => {
            const { collaboratorSchedule, demandedServices } = await getColaboratorScheduleAndDemandedServices(colaborator, now);
            const firsTime = calcFirstTimeCollaborator(now, demandedServices, collaboratorSchedule, now)
            console.log("firstTime", firsTime);

            return firsTime && { colaborator, time: firsTime }
        })

        const timesCollaboratorArray = await Promise.all(timesCollaboratorPromises)
        const timesCollaborator = timesCollaboratorArray.filter(item => (!!item)) as { colaborator: ICollaborator, time: ITimeItem }[]
        const timesCollaboratorSort = timesCollaborator.sort((a, b) => (differenceInMinutes(a.time.time, b.time.time)))
        console.log("timesCollaboratorSort", timesCollaboratorSort);
        return !!timesCollaborator.length ? timesCollaboratorSort[0] : null
    }

    /**
    * Obtem o proximo horário livre, considerando todos os colaboradores
    * @param serviceId 
    */
    const getNextAvailableScheduleColaborator = async (colaborator: ICollaborator, now: Date) => {
        const { collaboratorSchedule, demandedServices } = await getColaboratorScheduleAndDemandedServices(colaborator, now);


        const firstTime = calcFirstTimeCollaborator(now, demandedServices, collaboratorSchedule, now)
        console.log("firstTime", firstTime);
        return firstTime ? { colaborator, time: firstTime } : null
    }

    /**
     * @param service 
     * @param colaborator 
     * @param colaboratorSchedule 
     * @param dateTime 
     * @returns 
     */
    const serviceToProduct = (service: IService, colaborator: ICollaborator, colaboratorSchedule: ICollaboratorSchedule, dateTime: Date) => {
        const newProduct: Product = {
            id: service.productId,
            name: service.name,
            price: colaboratorSchedule.serviceValue,
            realPrice: colaboratorSchedule.serviceValue,
            description: "",
            type: ProdutoTipo.Agendamento,
            imageUrl: "",
            thumbnailUrl: "",
            compositions: [],
            printerName: "",
            category: "",
            categoryId: "",
            hideCatalog: false
        }

        const newOrderCompostitions: OrderComposition[] = [
            {
                id: "",
                compositionId: "Horario",
                description: "",
                imageUrl: "",
                totalPrice: 0,
                orderCompositionItems: [
                    {
                        id: "",
                        produtoId: "",
                        compositionId: "",
                        description: dateTime.toLocaleString('pt-BR'),
                        price: 0,
                        quantityFree: 0,
                        compositionItemId: "",
                        quantity: 0,
                        itemType: 0,
                        quantityFreeSelected: 0,
                    }

                ]
            },
            {
                id: "",
                compositionId: "Colaborador",
                description: "",
                imageUrl: "",
                totalPrice: 0,
                orderCompositionItems: [
                    {
                        id: "",
                        produtoId: "",
                        compositionId: "",
                        description: colaborator.name,
                        price: 0,
                        quantityFree: 0,
                        compositionItemId: "",
                        quantity: 0,
                        itemType: 0,
                        quantityFreeSelected: 0,
                    }

                ]
            },
            {
                id: "",
                compositionId: "Serviço",
                description: "",
                imageUrl: "",
                totalPrice: 0,
                orderCompositionItems: [
                    {
                        id: "",
                        produtoId: "",
                        compositionId: "",
                        description: service.name,
                        price: 0,
                        quantityFree: 0,
                        compositionItemId: "",
                        quantity: 0,
                        itemType: 0,
                        quantityFreeSelected: 0
                    }

                ]
            }
        ]
        return { product: newProduct, orderCompositions: newOrderCompostitions }
    }

    const scheduleCartToOrder = (scheduleCardItem: IScheduleItemCart[]) => {
        const newOrder: Order = {
            id: v4(),
            cartDate: new Date().toISOString(),
            orderItems: scheduleCardItem.map((item) => ({
                id: item.id,
                name: item.service.name + " - " + item.colaborator.name,
                category: "",
                price: item.colaboratorSchedule.serviceValue,
                realPrice: item.colaboratorSchedule.serviceValue,
                totalPrice: item.colaboratorSchedule.serviceValue,
                description: "",
                productPrice: item.colaboratorSchedule.serviceValue,
                productId: item.service.productId,
                productType: ProdutoTipo.Agendamento,
                quantity: 1,

                hideCatalog: false,
                imageUrl: "",
                barcode: "",
                orderComposition: [

                    {
                        id: "",
                        compositionId: v4(),
                        description: "Horário",
                        imageUrl: "",
                        totalPrice: 0,
                        orderCompositionItems: [
                            {
                                id: "",
                                produtoId: "",
                                compositionId: "",
                                description: item.dateTime.toLocaleString('pt-BR'),
                                price: 0,
                                quantityFree: 0,
                                compositionItemId: "",
                                quantityFreeSelected: 0,
                                quantity: 0,
                                itemType: 0
                            }

                        ]
                    },
                    {
                        id: "",
                        compositionId: v4(),
                        description: "Colaborador",
                        imageUrl: "",
                        totalPrice: 0,
                        orderCompositionItems: [
                            {
                                id: "",
                                produtoId: "",
                                compositionId: "",
                                description: item.colaborator.name,
                                price: 0,
                                quantityFree: 0,
                                quantityFreeSelected: 0,
                                compositionItemId: "",
                                quantity: 0,
                                itemType: 0
                            }

                        ]
                    },
                    {
                        id: "",
                        compositionId: v4(),
                        description: "Serviço",
                        imageUrl: "",
                        totalPrice: 0,
                        orderCompositionItems: [
                            {
                                id: "",
                                produtoId: "",
                                compositionId: "",
                                description: item.service.name,
                                price: 0,
                                quantityFree: 0,
                                quantityFreeSelected: 0,
                                compositionItemId: "",
                                quantity: 0,
                                itemType: 0
                            }

                        ]
                    }

                ],
                compositions: [],
                printerName: item.colaboratorSchedule.servicePrinterName
            })),
            totalValue: scheduleCardItem.map(item => item.colaboratorSchedule.serviceValue).reduce((a, b) => (a + b)),
            friendlyId: "",
            createdAt: new Date().toISOString(),

        }

        return newOrder
    }


    return {
        scheduleCartToOrder,
        serviceToProduct,
        generateTimes,
        getNextAvailableScheduleOfAllColaborators,
        getColaboratorScheduleAndDemandedServices,
        getNextAvailableScheduleColaborator,
        calcFirstTimeCollaborator
    }

}

export default ScheduleUseCase;