import { ErrorCodeType } from "application/models/IError";
import { IOrderRepository } from "domains/order/interfaces/repositories/IOrderRepository";
import { IOrder } from "modules/order/domain/models/IOrder";
import { IPedidoPosDTO } from "modules/order/domain/models/IPedidoPosDTO";
import { GetError } from "utils/GetError";

export interface ISyncSchoolOrderResult {
  message?: string;
  success?: boolean;
  pedidoPOS?: IPedidoPosDTO;
  order?: IOrder;
}

export const SyncSchoolOrderUseCase = async (
  orderRepository: IOrderRepository,
  order: IOrder,
  localId: string
): Promise<ISyncSchoolOrderResult> => {
  let result: ISyncSchoolOrderResult = {};

  try {
    const pedidoPOS = await orderRepository.sendSchoolOrderAsync(
      order,
      localId
    );
    if (pedidoPOS) {
      result = {
        message: `Pedido sincronizado tag: ${
          pedidoPOS.clienteEstabelecimentoObject?.tag ?? "-"
        }`,
        success: true,
        pedidoPOS,
      };
      return result;
    } else {
      GetError(
        new Error("Falha ao sincronizar pedido"),
        "SyncSchoolOrderUseCase",
        "SyncSchoolOrderUseCase",
        "Falha ao sincronizar pedido",
        ErrorCodeType.ORDER
      );
      result = {
        message: `Falha ao sincronizar o pedido`,
        success: false,
      };
      return result;
    }
  } catch (error) {
    GetError(
      error,
      "SyncSchoolOrderUseCase",
      "SyncSchoolOrderUseCase",
      "Falha ao sincronizar pedido",
      ErrorCodeType.ORDER
    );
    result = {
      message: `Falha ao sincronizar o pedido - ${
        (error as any)?.response?.data?.message &&
        (error as any)?.response?.data?.message
      }`,
      success: false,
    };
    return result;
  }
};
