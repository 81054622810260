import React, { FC, useCallback, useEffect, useState } from "react";
import { InputField } from "../inputField/InputField";
import { useUi } from "../../../application/contexts/ui/UIContext";
import "./KeyboardPimpad.css";
import KeyboarInstalment from "./keyboardInstalment/KeyboarInstalment";
import KeyboardInstallmentSelector from "./keyboardInstalment/KeyboardInstallmentSelector";

export interface IKeyboardPimpadProps {
  total?: number;
  enableInstallment?: boolean;
  keyboardValues: { min: number; max: number; message: string } | null;
  onSend?: (values?: { installment?: number }) => void;
  onSendInstallment?: (installment: number) => void;
  onCancel?: () => void;
}
const KeyboardPimpad: FC<IKeyboardPimpadProps> = ({ keyboardValues, onCancel, onSend, total, enableInstallment, onSendInstallment }) => {

  const [keyboarType, setKeyboarType] = useState<'numeric' | 'installment' | 'select'>("numeric");


  useEffect(() => {
    if (
      keyboardValues?.message === "1:A Vista;2:Parcelado pelo Estabelecimento;3:Parcelado pela Administradora;4:Consulta parcelamento;" ||
      keyboardValues?.message === "1:A Vista;2:Parcelado pelo Estabelecimento;" ||
      keyboardValues?.message.includes("1:A Vista;")
    ) {
      // window.keyboardValue = "1";
      // onSend?.();
      if (enableInstallment && total) {
        setKeyboarType("select")
      } else {
        window.keyboardValue = "1";
        onSend?.();
      }
    } else if (
      keyboardValues?.message === 'Forneca o numero de parcelas'
    ) {
      setKeyboarType('installment')
    }
    else {
      setKeyboarType("numeric")
      // onCancelHandle();
    }
    return () => { };
  }, [enableInstallment, keyboardValues, onSend, total]);

  const onCancelHandle = useCallback(
    () => {
      window.keyboardValue = "-1";
      onCancel && onCancel();
    },
    [onCancel],
  )
  const onCancelInstallment = useCallback(
    () => {
      window.keyboardValue = "1";
      onCancel && onCancel();
    },
    [onCancel],
  )

  const onPressKeyboard = useCallback((value?: string) => {
    window.keyboardValue = value ?? "";
    onSend?.();
  }, [onSend])

  const onPressKeyboardInstallment = useCallback((parcela: number) => {
    window.keyboardValue = parcela.toString() ?? "";
    onSendInstallment?.(parcela);
    onSend?.({ installment: Number(parcela) });
  }, [onSend, onSendInstallment])

  return (
    <div id={"keyboardPimpad"}>
      {
        keyboarType === 'numeric' &&
        <div className={"keyboardFade"}><InputField
          keyboardType="numeric"
          minInputLength={keyboardValues?.min}
          maxInputLength={keyboardValues?.max}
          description={keyboardValues?.message ?? ""}
          onPressContinuar={onPressKeyboard}
          onPressCancel={onCancelHandle}
          buttonField={{
            confirm: "Confirmar",
            cancel: "Confirmar",
          }}
        ></InputField>
        </div>
      }
      {
        keyboarType === 'select' && total &&
        <div className={"keyboardFade"}> <KeyboardInstallmentSelector
          total={total}
          min={2}
          parcelaMin={100}
          max={6}
          onSubmit={value => onPressKeyboard(value.toString())}
        />
        </div >
      }
      {
        keyboarType === 'installment' && total &&
        <div className={"keyboardFade"}> <KeyboarInstalment
          total={total}
          min={2}
          parcelaMinima={100}
          max={6}
          onSubmit={(value) => onPressKeyboardInstallment(value.parcela)}
          description={keyboardValues?.message ?? ""}
        />
        </div >
      }
    </div >
  );
};
export default KeyboardPimpad;
