import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
} from "@material-ui/core";
import { Operator } from "domains/admin/aggregates/operator/Operator";
import {
  OrderLogItems,
  PedidoPosOrderLog,
} from "domains/order/agreggates/order/Order";
import React, { FC, useCallback, useMemo, useState } from "react";
import "./PedidoPosPreview.css";
interface PedidoPosPreviewProps {
  pedidoPosPreview?: PedidoPosOrderLog;
  orderLogItems?: OrderLogItems[];
  indice?: boolean;
  operators?: Operator[];
  currentOperator?: Operator | null;
  onClickPrint?: () => void;
  permissionPrint?: boolean;
  isTicketPrinter?: boolean;
  onClickPrintPedidoPos?: () => void;
  isPaid?: boolean;
}
export const PedidoPosPreview: FC<PedidoPosPreviewProps> = ({
  pedidoPosPreview,
  orderLogItems,
  operators,
  currentOperator,
  indice,
  isPaid,
  onClickPrintPedidoPos: onClickPrint,
  permissionPrint,
  isTicketPrinter,
}) => {
  const [openAlert, setOpenAlert] = useState(false);

  const onClickReprintHandle = useCallback(() => {
    setOpenAlert(false);
    onClickPrint?.();
  }, [onClickPrint]);

  const { nome, preco } = useMemo(() => {
    if (pedidoPosPreview) {
      return {
        nome: pedidoPosPreview?.produtosObject.map(
          (item, index) =>
            item.nome +
            (index === pedidoPosPreview?.produtosObject.length - 1 ? "" : ", ")
        ),
        preco: "R$" + pedidoPosPreview?.valorTotal.toFixed(2).replace(".", ",")
      };
    } else if (orderLogItems) {
      return {
        nome: orderLogItems.map(
          (item, index) =>
            item.quantity + 'x ' + item.name + (index === orderLogItems.length - 1 ? "" : ", ")
        ),
        preco: "R$" + orderLogItems.reduce((acc, it) => acc += (it.totalPrice * it.quantity), 0).toFixed(2).replace(".", ",")
      };
    } else {
      return {
        nome: '-',
        preco: '-'
      }
    }
  }, [orderLogItems, pedidoPosPreview]);

  return (
    <>
      <Grid container lg={12} item className="content-ProdutoPedidoPosPreview">
        <Grid sm={4} item className="nome-ProdutoPedidoPosPreview">
          {indice
            ? "Nome"
            : nome}
        </Grid>
        <Grid sm={4} item className="total-ProdutoPedidoPosPreview">
          {indice
            ? "Preço"
            : preco}
        </Grid>

        <Grid sm={1} item className="actions-ProdutoPedidoPosPreview">
          {indice
            ? "Ações"
            : isPaid &&
              permissionPrint &&
              isTicketPrinter && (
                <>
                  {" "}
                  <IconButton onClick={() => setOpenAlert(true)}>
                    <Icon>print</Icon>
                  </IconButton>
                </>
              )}
        </Grid>
      </Grid>
      {/* <div>{produtoPedidoPosPreview?.ticketId}</div> */}
      <Dialog open={openAlert}>
        <DialogTitle id="alert-dialog-title">Atenção!!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <b>Tem certeza que deseja reimprimir esta ficha?</b>

            <div>
              <b>operador: {currentOperator?.name}</b>
            </div>
            <div>
              produto:{" "}
              {nome}
            </div>
            <div>valor: {preco}</div>
            {!!pedidoPosPreview?.reprinted?.length && (
              <div>
                <b>
                  Esta ficha já foi reimpressa{" "}
                  {pedidoPosPreview?.reprinted?.length} vezes por:
                </b>
                <div>
                  {pedidoPosPreview?.reprinted?.map((reprintedLog) => (
                    <div>
                      {
                        operators?.find(
                          (_operator) =>
                            _operator.id === reprintedLog.operadorId
                        )?.name
                      }{" "}
                      - {reprintedLog.dataRealizacao.toLocaleString()}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant={"outlined"}
            color={"secondary"}
            onClick={() => setOpenAlert(false)}
          >
            Cancelar
          </Button>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={onClickReprintHandle}
          >
            Reimprimir
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
