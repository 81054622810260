import React, { FC, useState } from 'react'
import { BotaoVoltar } from '../../../../../components/botaoVoltar/BotaoVoltar'
import styles from './TourLayout.module.scss'
import SerraGaucha from 'assets/images/serraGaucha.png'
import HeaderLayout from './headerLayout/HeaderLayout'
import { Close } from '@material-ui/icons'
import QrcodeImageGramado from 'assets/images/qrcode-gramado.png';
export interface ITourLayoutProps {
    //propertys
    title?: string,
    backgroundUrl?: string,
    onPressBack?: () => void,
    headerContent?: Element,
    isTopLayout?: boolean
}
const TourLayout: FC<ITourLayoutProps> = ({ children, title, backgroundUrl, onPressBack, isTopLayout }) => {


    const [help, setHelp] = useState(false)


    const handleHelpModal = () => {
        setHelp(!help)
    }


    return (
        <div id={styles.TourLayout} >
            {/* <div className={styles.top}  > */}
            {/* <div className={styles.top} style={{ backgroundImage: `url(${backgroundUrl})` }} >
                <img className={styles.top} src={backgroundUrl} />

                {title && <h2>{title}</h2>}
                {onPressBack &&
                    <BotaoVoltar onClick={onPressBack}>VOLTAR</BotaoVoltar>
                }
            </div> */}

            <HeaderLayout name={title} onClickBack={onPressBack} handleHelpModal={handleHelpModal} />
            {
                help &&
                <div className={styles.containerHelp}>
                    <div className={styles.titleHeader}>
                        <p>Ajuda</p>
                        <Close onClick={handleHelpModal} />
                    </div>
                    <p>Leia o QR Code e entre em contato com a gente através do Whatsapp!</p>
                    <div className={styles.containerQrcode}>
                        <img src={QrcodeImageGramado} alt="QRCODE para suporte de gramado" />
                    </div>
                </div>
            }
            {
                !isTopLayout ?

                    <div className={styles.imageGramado} ><img src={SerraGaucha} alt="Experimente Serra Gaucha" /></div>
                    :
                    ''
            }
            {/* {onPressBack &&
                    <BotaoVoltar onClick={onPressBack}>VOLTAR</BotaoVoltar>
                }
                   */}
            <div className={styles.content} >
                {children}
            </div>
        </div>
    )
}
export default TourLayout