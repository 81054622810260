import React, { createRef, FC, forwardRef, memo, ReactElement, RefObject, useCallback, useEffect, useImperativeHandle, useState } from 'react'
import { debounce } from 'throttle-debounce';
import styles from './TourCategoryList.module.scss'
import { CategoryTour, Tour } from './TourCategoryList.type'
export interface ITourCategoryListProps {
    //propertys
    categoryList: CategoryTour[],
    renderTourItem: (tours: Tour) => ReactElement
    onChangeCategory?: (index: number) => void
    offsetScroll?: number
}


export interface RefTourCategoryList {
    scrollToCategoria: (index: number) => void
}

const TourCategoryList = memo(forwardRef<RefTourCategoryList, ITourCategoryListProps>(({ categoryList, renderTourItem, onChangeCategory, offsetScroll = 0 }, ref) => {



    const [refDiv, setRefDiv] = useState<RefObject<HTMLDivElement>[]>([]);
    useImperativeHandle(ref, () => ({
        scrollToCategoria: (index: number) => {
            scrollToCategoria(index);
        }
    }));

    const scrollToCategoria = useCallback((index: number) => {
        if (refDiv.length > 0) {
            let _ref = refDiv[index].current
            if (_ref) {
                window.scrollTo({
                    top: _ref.offsetTop + offsetScroll,
                    behavior: "auto"
                })
            }
        }
    }, [offsetScroll, refDiv])
    useEffect(
        () => {
            const listener = debounce(200, () => {
                console.log("scrol " + refDiv.length, window.pageYOffset)
                // console.log("refs", refDiv)
                let last = false;
                refDiv.map((_ref, index) => {
                    let before = refDiv[index]?.current?.offsetTop ?? 0
                    let next = refDiv[index + 1]?.current?.offsetTop ?? window.pageYOffset + 1
                    if ((window.pageYOffset >= before && window.pageYOffset < next)) {
                        console.log("valores:", before + "<" + window.pageYOffset + " <" + next)
                        if (onChangeCategory) {
                            console.log(index)
                            onChangeCategory(index)
                        }
                    }
                })
            })
            window.addEventListener("scroll", listener)
            return () => {
                window.removeEventListener("scroll", listener)
            }
        },
        [refDiv, onChangeCategory]
    )

    useEffect(() => {
        if (categoryList.length > 0) {
            let refs = categoryList.map(() => (createRef<HTMLDivElement>()))
            setRefDiv(refs)
        }
    }, [categoryList])



    return (
        <div id={styles.TourCategoryList} >
            {
                categoryList.map((categoryItem: CategoryTour, index: number) => (
                    <div id={styles.TourCategoryItem} ref={refDiv[index]} key={index}>
                        {/* <div className={styles.title} >
                            <h2>{categoryItem.name.toUpperCase()}</h2>
                        </div> */}
                        <div className={styles.content} >
                            {
                                categoryItem.tours.map(tourItem => renderTourItem(tourItem))
                            }
                        </div>
                    </div>
                ))
            }
        </div>
    )
}))
export default TourCategoryList

