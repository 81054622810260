import { BotaoGrande } from 'components/botaoGrande/BotaoGrande'
import React, { FC, useCallback, useState } from 'react'
import styles from './KeyboarInstalment.module.scss'
interface Parcela {
    label: string
    value: number
    parcela: number
}

export interface IKeyboarInstalmentProps {
    //propertys
    total: number,
    parcelaMinima: number
    max: number,
    min: number,
    description: string,
    onSubmit: (value: Parcela) => void
    onCancel?: () => void
}
const KeyboarInstalment: FC<IKeyboarInstalmentProps> = ({
    total,
    onSubmit,
    parcelaMinima,
    max,
    min,
    onCancel,
    description
}) => {




    const gerarParcelas = useCallback((total: number, parcelaMin: number, max: number, min: number,) => {
        var parcelas: Parcela[] = []
        for (var i = max; i >= min; i--) {
            const valorDaParcela = total / i;
            if (valorDaParcela >= parcelaMin) {
                parcelas.push({ label: `${i} vezes de R$ ${valorDaParcela.toFixed(2)}`, parcela: i, value: valorDaParcela })
            }
        }
        console.log(parcelas)
        // parcelas.push({ label: `A vista por R$ ${total.toFixed(2)}`, parcela: 1, value: total })
        return parcelas
    }, [])


    return (
        <div id={styles.KeyboarInstalment} >
            {description}
            {gerarParcelas(total, parcelaMinima, max, min).map((parcela) => (
                <div className={styles.botao} onClick={() => onSubmit(parcela)} >
                    {parcela.label}
                </div>
            ))}
            {onCancel && <div className={styles.botaoCancelar} onClick={onCancel} >
                Cancelar
            </div>}
        </div>
    )
}
export default KeyboarInstalment