import { ICardReaderStore } from "modules/cardReader/domain/interfaces/IUseCardReaderStore";
import { IOfflineCardData } from "modules/cardReader/domain/models/IOfflineCardData";
import { create } from "zustand";
import { enableSensor } from "./CardReaderActions";

export const useCardReaderStore = create<ICardReaderStore>((set) => {
  const onReadTag = (tag: string) => {
    set({ tag });
  };

  const onReadError = (readError: string) => {
    set({ readError });
  };

  const onOfflineCardData = (offlineCardData: IOfflineCardData) => {
    if (offlineCardData) {
      set({ offlineCardData });
    }
  };

  const onRemovedCard = () => {
    set({ cardDetected: false, tag: null });
  };

  const onDetectedCard = () => {
    set({ cardDetected: true });
  };

  if (!window.setCardValue) {
    window.setCardValue = onReadTag;
  }
  if (!window.setReadError) {
    window.setReadError = onReadError;
  }
  if (!window.onRemovedCard) {
    window.onRemovedCard = onRemovedCard;
  }
  if (!window.onDetectedCard) {
    window.onDetectedCard = onDetectedCard;
  }
  if (!window.setFullCardData) {
    window.setFullCardData = onOfflineCardData;
  }

  return {
    tag: null,
    cardDetected: false,
    offlineCardData: null,
    readError: null,

    enableSensor: async (cashlessCode?: string) => {
      return enableSensor(cashlessCode);
    },

    clearCardReader: () => {
      set(() => ({
        tag: null,
        cardDetected: false,
        offlineCardData: null,
        readError: null,
      }))
    }
  };
});
