import React, { FC } from "react";
import "./ItemCategoria.css";

interface ItemListaCategoriasProps {
  nome: string;
  imagemUrl: string;
  id: string;
  onClick?: () => void;
}

export const ItemCategoria: FC<ItemListaCategoriasProps> = (props) => {
  return (
    <div onClick={props.onClick} className="itemListaCategorias">
      <div className="imagem" style={{ backgroundImage: "url(" + props.imagemUrl + ")" }}></div>
      <div className="nome">{props.nome}</div>
    </div>
  );
};
