import { ICollaborator } from "../../../domains/schedule/agreggates/ICollaborator"
import { ICollaboratorSchedule } from "../../../domains/schedule/agreggates/ICollaboratorSchedule"
import { IService } from "../../../domains/schedule/agreggates/IServices"
import { HttpRepository } from "./_HttpRepository"
import { IScheduleRepository } from '../../interfaces/IScheduleRepository'
import { AddOrderInDemandScheduleRequest, IDemmandScheduleRequest, IDemmandScheduleResponse } from "../../../domains/schedule/agreggates/IDemmandSchedule"
import { IDemandedService } from "../../../domains/schedule/agreggates/IDemandedService"
import { IServiceCategory } from "../../../domains/schedule/agreggates/IServiceCategory"
import { AddPlaceDemandScheduleRequestDTO } from "modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentRequest"

export const ScheduleRepository = (): IScheduleRepository => {
    const httpRepository = HttpRepository();

    const getServices = async (localId: string) => {
        return await httpRepository.getAsync<IService[]>("/ServiceSchedule/ByLocalId/" + localId)
    }

    const getCollaboratorsByServiceId = async (serviceId: string) => {
        return await httpRepository.getAsync<ICollaborator[]>("/Collaborator/GetCollaboratorScheduleByServiceId/" + serviceId);
    }

    /**
     * Api para obter os horários
     * @param serviceScheduleId 
     * @param finalDate 
     * @returns 
     */
    const getAllDemandedServicesByScheduleId = async (scheduleId: string, finalDate: string): Promise<IDemandedService[]> => {
        return await httpRepository.getAsync<IDemandedService[]>
            (`/DemandSchedule/GetAllDemandedServicesByScheduleId/${scheduleId}`, { params: { finalDate } })
    }

    /**
     * Api para obter os dados do colaborador
     * @param serviceId 
     * @returns 
     */
    const getColaboratorScheduleByServiceScheduledId = async (serviceScheduledId: string): Promise<ICollaboratorSchedule> => {
        return await httpRepository.getAsync<ICollaboratorSchedule>
            (`/collaboratorSchedule/GetColaboratorScheduleByServiceScheduledId/${serviceScheduledId}`)
    }
    /**
     * Fazer reserva do serviço
     * @param request 
     * @returns 
     */
    const demmandSchedule = async (request: IDemmandScheduleRequest): Promise<IDemmandScheduleResponse> => {
        return await httpRepository.postAsync<IDemmandScheduleRequest, IDemmandScheduleResponse>("/DemandSchedule/WithoutPayment", request)
    }

    const addOrderInDemmandSchedule = async (request: AddOrderInDemandScheduleRequest): Promise<unknown> => {
        return await httpRepository.postAsync<AddOrderInDemandScheduleRequest, unknown>("/DemandSchedule/AddOrderInDemandSchedule", request)
    }

    const getCategoryServices = async (localId: string): Promise<{ items: IServiceCategory[] }> => {
        return await httpRepository.getAsync<{ items: IServiceCategory[] }>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: false, removePrincipalCategories: false } })
    }

    const getServicesByCategory = async (serviceCategoryId: string) => {
        return await httpRepository.getAsync<{ items: IService[] }>("/ServiceSchedule/ByCategoryId/" + serviceCategoryId)
    }


    const cancelSchedule = async (scheduleId: string) => {
        await httpRepository.postAsync(`/DemandSchedule/Cancel`, { id: scheduleId })
    }
    return {
        getServices,
        getCollaboratorsByServiceId,
        getAllDemandedServicesByScheduleId,
        demmandSchedule,
        getColaboratorScheduleByServiceScheduledId,
        addOrderInDemmandSchedule,
        getCategoryServices,
        getServicesByCategory,
        cancelSchedule
    }
}

export default ScheduleRepository