export enum TransactionType {
  credit = 1,
  debit = 2,
  money = 3,
  cashless = 4,
  pospaid = 5,
  misto = 6,
  voucher = 14,
  pix = 22,
}

export const TransactionTypeSefaz = new Map<number, string>([
  [TransactionType.credit, "03"],
  [TransactionType.debit, "04"],
  [TransactionType.money, "01"],
  [TransactionType.cashless, "05"],
  [TransactionType.voucher, "11"],
  [TransactionType.pix, "22"],
  //TODO: Ajustar codigo PIX
]);

export const TransactionTypeLabel = new Map<number, string>([
  [TransactionType.credit, "Crédito"],
  [TransactionType.debit, "Débito"],
  [TransactionType.money, "Dinheiro"],
  [TransactionType.cashless, "Cartão Meep"],
  [TransactionType.voucher, "Voucher"],
  [TransactionType.pix, "PIX"],
]);
