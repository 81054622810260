import React, { createContext, FC, useContext } from 'react';
import { CatalogTour, StoreTour, Tour, TourDetail } from '../../domain/models/CatalogTour';
import UseCatalogTour from '../hooks/UseCatalogTour';

interface ICatalogTourContext {
    catalogTour: CatalogTour | undefined;
    getStoreTourById: (id: string) => StoreTour | undefined;
    getTourById: (id: string) => Promise<TourDetail | undefined>;
}

const CatalogTourContext = createContext<ICatalogTourContext>({} as ICatalogTourContext);

export const CatalogTourProvider: FC = ({ children }) => {
    const { catalogTour, getStoreTourById, getTourById } = UseCatalogTour()
    return (
        <CatalogTourContext.Provider value={{
            catalogTour,
            getStoreTourById,
            getTourById
        }}>
            <>{children}</>
        </CatalogTourContext.Provider>
    );
};


export const useCatalogTour = () => {
    const context = useContext(CatalogTourContext);
    return context;
};