import { Product } from "domains/catalog/aggregates/Product";
import React, { FC } from "react";
import "./WeighingMachineProduct.scss";
export interface IWeighingMachineProductProps {
  //propertys
  product: Product;
  onClick: () => void;
  selected?: boolean;
}
const WeighingMachineProduct: FC<IWeighingMachineProductProps> = ({
  product,
  onClick,
  selected,
}) => {
  return (
    <div id={"weighingMachineProduct"}>
      <div
        onClick={onClick}
        className={selected ? "containerSelected" : "container"}
      >
        <div className={"productImage"}>
          <img src={product.thumbnailUrl}></img>
        </div>
        <div className={"name"}>
          <h4>{product.name}</h4>
        </div>
        <div className={"price"}>
          {product.tare && <h3>Tara: {product.tare?.toFixed(2)}g</h3>}
          <h3>R${product.price.toFixed(2)}/kg</h3>
        </div>
      </div>
    </div>
  );
};
export default WeighingMachineProduct;
