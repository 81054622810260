import React, { useCallback } from 'react'
import { useHistory } from 'react-router';

export const UseAboutProject = () => {

    const { replace } = useHistory();

    const onPressBack = useCallback(
        () => {
            replace('/tour')
        },
        [],
    )
    return (
        {
            onPressBack
        }
    )
}
