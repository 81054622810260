import React from 'react'
import { ITourRepository } from '../../../modules/tour/application/interfaces/ITourRespository'

import { HttpRepository } from "./_HttpRepository";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { IGetMainServicesCategoryByLocalIdResponse, IGetServicesCategoryByLocalIdResponse } from '../../../modules/tour/domain/dtos/GetServicesByLocalIdResponseDTO';
import { UserDataByCpfResponse } from "../../../modules/tour/domain/dtos/UserDataByCpfResponse"
import { GetServiceDetailByIdResponseDTO } from 'modules/tour/domain/dtos/GetServiceDetailByIdResponseDTO';
import { GetPlaceScheduleByServiceIdResponseDTO } from 'modules/tour/domain/dtos/GetPlaceScheduleByServiceIdResponseDTO';
import { AddPlaceDemandScheduleRequestDTO } from 'modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentRequest';
import { GetVancaciesAndDiponibilityResponseDto } from 'modules/tour/domain/dtos/GetVacanciesAndDisponibilityByServiceIdResponseDTO';
import { IAddReserveScheduleWithoutPaymentResponse } from 'modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentResponse';
import { AddOrderInDemandScheduleRequest } from 'modules/tour/domain/dtos/AddOrderOnDemandRequestDTO';
export const TourRepository = (): ITourRepository => {
    const httpRepository: IHttpRepository = HttpRepository();

    const getServiceCategoryByLocalId = async (localId: string): Promise<IGetServicesCategoryByLocalIdResponse> => {
        const response = await httpRepository.getAsync<IGetServicesCategoryByLocalIdResponse>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: false, removePrincipalCategories: true } });
        return (response);
    }
    const getMainServiceCategoryByLocalId = async (localId: string): Promise<IGetMainServicesCategoryByLocalIdResponse> => {
        const response = await httpRepository.getAsync<IGetMainServicesCategoryByLocalIdResponse>(`/Schedule/ServiceCategory/ByLocalId/${localId}`, { params: { removeSubcategories: true, removePrincipalCategories: false } });
        return (response);
    }

    const getServiceCategoryByCategoryId = async (categoryId: string): Promise<IGetServicesCategoryByLocalIdResponse> => {
        const response = await httpRepository.getAsync<IGetServicesCategoryByLocalIdResponse>(`/Schedule/ServiceCategory/ByCategoryId/${categoryId}`);
        return (response);
    }

    const getUserDataByCpf = async (cpf: string, localId: string): Promise<UserDataByCpfResponse> => {
        const response = await httpRepository.getAsync<UserDataByCpfResponse>(`Schedule/${localId}/Client/Cpf/${cpf}`);
        return (response);

    }

    const getServiceDetailById = async (tourId: string): Promise<GetServiceDetailByIdResponseDTO> => {
        const response = await httpRepository.getAsync<GetServiceDetailByIdResponseDTO>(`/ServiceSchedule/GetServiceDetails/${tourId}`);
        return (response);

    }

    const getPlaceScheduleByServiceId = async (serviceId: string): Promise<GetPlaceScheduleByServiceIdResponseDTO> => {
        const response = await httpRepository.getAsync<GetPlaceScheduleByServiceIdResponseDTO>(`/PlaceSchedule/GetPlacesScheduleByServiceId/${serviceId}`);
        return (response);
    }

    const getVacanciesAndDisponibilityService = async (serviceScheduledId: string, start: string, end: string): Promise<GetVancaciesAndDiponibilityResponseDto[]> => {
        const response = await httpRepository.getAsync<GetVancaciesAndDiponibilityResponseDto[]>(`/PlaceSchedule/Vacancies`,
            {
                params: {
                    serviceScheduledId,
                    start,
                    end,
                }
            });
        return (response);
    }

    const postAddResevePlaceSchedule = async (request: AddPlaceDemandScheduleRequestDTO): Promise<IAddReserveScheduleWithoutPaymentResponse> => {
        const response = await httpRepository.postAsync<AddPlaceDemandScheduleRequestDTO, IAddReserveScheduleWithoutPaymentResponse>(`/DemandSchedule/Place/WithoutPayment`, request);
        return response
    }


    const addOrderInDemmandSchedule = async (request: AddOrderInDemandScheduleRequest): Promise<unknown> => {
        return await httpRepository.postAsync<AddOrderInDemandScheduleRequest, unknown>("/DemandSchedule/AddOrderInDemandSchedule", request)
    }


    const cancelReserveDraft = async (scheduleId: string) => {
        await httpRepository.postAsync(`/DemandSchedule/Cancel`, { id: scheduleId })
    }


    return ({
        getServiceCategoryByLocalId,
        getMainServiceCategoryByLocalId,
        getServiceCategoryByCategoryId,
        getUserDataByCpf,
        getServiceDetailById,
        getPlaceScheduleByServiceId,
        getVacanciesAndDisponibilityService,
        postAddResevePlaceSchedule,
        addOrderInDemmandSchedule,
        cancelReserveDraft
    })

}