import React, { useCallback, useEffect, useState } from 'react'
import GetCatalogToursStoresByLocalIdUseCase from '../../application/useCases/tour/GetCatalogToursByLocalIdUseCase'
import { CatalogTour, Tour, TourDetail } from '../../domain/models/CatalogTour'
import { useKiosk } from '../../../../application/contexts/kiosk/KioskContext'
import { useUi } from '../../../../application/contexts/ui/UIContext'
import { TourRepository } from 'Infrastructure/repositories/api/TourRepository'
import GetTourDetailByIdUseCase from 'modules/tour/application/useCases/tour/GetTourDetailByIdUseCase'
import GetPlaceScheduleByServiceId from 'modules/tour/application/useCases/tour/GetPlaceScheduleByServiceIdUseCase'



export const UseCatalogTour = () => {

    const { showLoading, hideLoading } = useUi();
    const { kiosk } = useKiosk();
    const [catalogTour, setCatalogTour] = useState<CatalogTour>();

    useEffect(() => {
        console.log("KIOSK", kiosk?.localId);
        const tourRepository = TourRepository();
        if (kiosk) {
            showLoading();
            GetCatalogToursStoresByLocalIdUseCase(kiosk.localId, tourRepository).then(catalogTourResoponse => {
                setCatalogTour({
                    name: kiosk.localName,
                    localId: kiosk.localId,
                    imageUrl: (kiosk?.profilerNamePDV && kiosk?.wallpaperImageId) ? `${process.env.REACT_APP_URL_IMAGEM}wallpapers/${kiosk.wallpaperImageId}.png` : kiosk?.wallpaperImageUrl,
                    storeTours: catalogTourResoponse,
                });
            }).finally(() => {
                hideLoading();
            })
        }
    }, [kiosk])

    const getStoreTourById = useCallback(
        (id: string) => {
            console.log(id)
            const value = catalogTour?.storeTours.find(item => item.id === id);
            return value
        },
        [catalogTour],
    )


    const getTourById = useCallback(
        async (id: string): Promise<TourDetail | undefined> => {

            const tour: Tour | undefined =
                catalogTour?.storeTours
                    .flatMap(item => item.categoryTours)
                    .flatMap(item => item.tours)
                    .find(item => item.id === id);
            if (tour) {

                const responseTour = await GetTourDetailByIdUseCase(TourRepository, id);
                const responsePlace = await GetPlaceScheduleByServiceId(TourRepository, id);

                console.log("PLACE", responsePlace);
                console.log("TOUR", responseTour);

                return {
                    ...tour,
                    prices: responseTour.otherValues.map(item => item),
                    description: responseTour.description,
                    availableDays: responseTour.availableDays,
                    showcaseImages: responseTour.showcaseImages,
                    scheduledId: !!responsePlace.items.length ? responsePlace.items[0].serviceScheduledId : "",
                    moreDescriptions: responseTour.moreDescriptions,
                    otherValues: responseTour.otherValues
                }
            } else {
                return undefined
            }
        },
        [catalogTour],
    )


    return (
        {
            catalogTour,
            getStoreTourById,
            getTourById
        }
    )
}

export default UseCatalogTour
