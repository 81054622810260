import { IFinancialTransactionModel, TransactionType } from './Payment';
import { ProdutoPedidoPosDTO } from './ProdutoPedidoPosDTO';


export interface IPedidoPosDTO {
  valorTotal: number;
  codigoPedido: string;
  clienteEstabelecimento: string;
  clienteEstabelecimentoObject: ICustomerDTO;
  tipoPagamento?: TransactionType; //Enum tipo de pagamento 1=credito 2=debito
  pedidoPOSId?: string; //Guid Gerado no totem
  pedidoPOSPaiId?: string; //Guid Gerado no totem
  statusPedido?: StatusPedido; // 3 pagamento realizado
  dataPedido?: Date; //UTC
  produtos: string;
  produtosObject: ProdutoPedidoPosDTO[]; //string 
  tipoAdquirente: number;
  dadosTransacao: string; //rever
  dadosTransacaoObject?: IFinancialTransactionModel; //rever
  tagCashless: string;
  observacaoPedido?: string;
  codigoPainelSenha?: string;
}

export enum StatusPedido {
  Pendente = 1,
  Falha = 2,
  Aprovado = 3,
  Cancelado = 4,
  ParcialmenteCancelado = 5,
  AguardandoPagamento = 6,
}

export interface ICustomerDTO {
  nome?: string;
  identificador?: string;
  cpf?: string;
  telefone?: string;
  email?: string;
  comanda?: string;
  mesa?: string;
  pedidoPosPaiId?: string;
  pedidoPosId?: string;
  tag?: string;
}
