import { Icon } from '@material-ui/core';
import React, { FC, useCallback } from 'react'
import styles from './ButtonPickerSchedule.module.scss'

import ButtonPickerScheduleUseCase from "./ButtonPickerScheduleUseCase";

export interface IButtonPickerProps {
    //propertys
    onClick: () => void
    selected?: boolean
}
const ButtonPickerSchedule: FC<IButtonPickerProps> = ({ onClick, children, selected }) => {


    const onClickHandle = useCallback(
        () => {
            ButtonPickerScheduleUseCase().click();
            onClick()
        },
        [],
    )
    return (
        <div id={styles.ButtonPicker} >
            <div onClick={onClickHandle} className={selected ? styles.containerSelected : styles.containerUnselected} >
                {children}
            </div>
        </div>
    )
}
export default ButtonPickerSchedule