import React, { FC, useCallback, useEffect, useState } from 'react'
import { Drawer, Icon, IconButton } from '@material-ui/core'

import styles from './TourCart.module.scss'
import { useTourCart } from './TourCartContext'
import { ICartTourItem } from './ICartTourItem'
import { TransactionType } from '../reserverTourResume/ReserveTourResume.type'
import { ICartTour } from './ICartTour'
import DialogSelectPaymentType from '../dialogSelectPaymentType/DialogSelectPaymentType'
import { usePayment } from 'application/contexts/payment/PaymentContext'

export interface ITourCartProps {
    onClickPayment: (cartTour: ICartTour, transactionType?: TransactionType) => void,
    disableSelectPaymentDialog?: boolean
}

const TourCart: FC<ITourCartProps> = ({ onClickPayment, disableSelectPaymentDialog }) => {

    const { cartTourItems, totals, removeTourCart, clearTourCart } = useTourCart()

    const [openCart, setOpenCart] = useState(false);

    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

    const { setinProgress, setMessage, setValuesWithoutOrder } = usePayment();


    useEffect(() => {
        setValuesWithoutOrder({ instalment: 1, totalValue: totals.price })
        return () => {

        }
    }, [setValuesWithoutOrder, totals.price])


    const sendTourCartToPayment = useCallback((transactionType?: TransactionType) => {
        onClickPayment({ cartTourItems, totalPrice: totals.price }, transactionType)
    }, [cartTourItems, onClickPayment, totals.price])

    const onSelectTrasactionTypeHandle = useCallback((transactionType: TransactionType) => {
        sendTourCartToPayment(transactionType)
    }, [sendTourCartToPayment])

    const onClickPaymentHandle = useCallback(() => {
        if (!!totals.price && !disableSelectPaymentDialog) {
            setOpenPaymentDialog(true)
        } else {
            sendTourCartToPayment();
        }
    }, [disableSelectPaymentDialog, sendTourCartToPayment, totals.price])

    return (
        <div id={styles.cart}>
            <div onClick={() => setOpenCart(true)} className={!!totals.quantity ? styles.barVisible : styles.barHide}>
                <div className={styles.quantity}>
                    {totals.quantity} Reservas
                </div>
                <div className={styles.title}>
                    Carrinho
                </div>
                <div className={styles.price}>
                    R${totals.price.toFixed(2)}
                </div>
            </div>
            <Drawer anchor="bottom" open={openCart && !!totals.quantity} onClose={() => setOpenCart(false)}>
                <div className={styles.drawerContainer}>
                    <div className={styles.header}>
                        <IconButton onClick={() => setOpenCart(false)}><Icon fontSize={"large"}>keyboard_arrow_down</Icon></IconButton> <h2>Carrinho</h2> <div onClick={clearTourCart}>Limpar</div>
                    </div>
                    <div className={styles.content}>
                        {cartTourItems.map((cartItem) => (
                            <div className={styles.cartTourItem}>
                                <div className={styles.removeButton}>
                                    <IconButton onClick={() => removeTourCart(cartItem.id)}><Icon fontSize={"large"}>close</Icon></IconButton>
                                </div>
                                <div className={styles.image}>
                                    <img src={cartItem.tour.imageUrl} alt="Imagem do item selecionado" />
                                </div>
                                <div className={styles.info}>
                                    <div className={styles.tourName}>
                                        {cartItem.tour.name}
                                    </div>
                                    <div className={styles.dateTime}>
                                        {cartItem.reserveTour.date} - {cartItem.reserveTour.time}

                                    </div>
                                    <div className={styles.persons}>
                                        {cartItem.reserveTour.customers.length} pessoas
                                    </div>
                                    <div className={styles.price}>
                                        R${cartItem.reserveTour.totalPrice.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                        )
                        )}
                    </div>
                    <div className={styles.footer}>
                        <div className={styles.totals}>
                            <div>Subtotal:</div><div>R${totals.price.toFixed(2)}</div>
                        </div>
                        <div className={styles.buttons} onClick={onClickPaymentHandle}>
                            <div className={styles.sendButton} >Realizar Pagamento</div>
                        </div>
                    </div>
                </div>
            </Drawer>
            <DialogSelectPaymentType open={openPaymentDialog} onClose={() => setOpenPaymentDialog(false)} onPressIrParaPagamento={onSelectTrasactionTypeHandle}></DialogSelectPaymentType>
        </div>
    )
}

export default TourCart
