import { Order } from "domains/order/agreggates/order/Order"
import { FinancialTransactionModel } from "domains/order/agreggates/payment/FinancialTransactionModel"
import localforage from "localforage"
import { IReserveTourLogRepository } from "modules/tour/application/interfaces/IReserveTourLogRepository"
import { IReserveLog } from "modules/tour/domain/models/IReserveLog"
import { IAddReserveScheduleWithoutPaymentResponse } from "modules/tour/domain/dtos/IAddReseveScheduleWithoutPaymentResponse"
import { ReserveTour } from "modules/tour/domain/models/ReserveTour"
const key = "@tour"


const ReserveTourLogRepository = (): IReserveTourLogRepository => {
    const add = async (
        request: IReserveLog
    ) => {

        const prev = await localforage.getItem<IReserveLog[]>(key) ?? [];

        let newPrevArray = prev;

        console.log("ADD TOUR LOCALSTORAGE 1")
        debugger;

        if (prev.length > 20) {
            newPrevArray = prev.filter((_, index) => index < 10)
        }
        const newArray = [request, ...newPrevArray]

        localforage.setItem(key, newArray);

        return request
    }

    const getAll = async () => {
        const values = await localforage.getItem<IReserveLog[]>(key) ?? [];
        return values
    }
    return (
        {
            add,
            getAll
        }
    )
}

export default ReserveTourLogRepository
