import React, { FC } from 'react'
import styles from './CategoryCardItem.module.scss'
export interface ICategoryCardItemProps {
    //propertys
    imageUrl?: string,
    title: string,
    onPress?: () => void
}
const CategoryCardItem: FC<ICategoryCardItemProps> = ({ title, imageUrl, onPress }) => {

    return (
        <div onClick={onPress} id={styles.CategoryCardItem} style={{ backgroundImage: `url(${imageUrl})` }} >
            <div className={styles.titulo} >
                {title}
            </div>
        </div>
    )
}
export default CategoryCardItem