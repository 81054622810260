export interface IError extends Error {
  code: ErrorCodeType;
  message: string;
  caller: string;
  file: string;
  stack?: string;
}

export type IErrorType = IError[];

export enum ErrorCodeType {
  GENERAL = 1,
  PAYMENT,
  NFCE,
  ORDER
}

