import { IPaymentService } from "../interfaces/services/IPaymentService";
import { IPaymentRepository } from "../interfaces/repositories/_IPaymentRepository";
import { TransactionType } from "../agreggates/payment/transactionType";
import { Payment, PixPaymentRequest, PixPaymentResponse } from "../agreggates/payment/payment";
import { CefSharpPaymentRepository } from "../../../Infrastructure/services/cefSharpService/local/CefSharpPaymentRepository";
import { HttpRepository } from "Infrastructure/repositories/api/_HttpRepository";
import { IHttpRepository } from "Infrastructure/interfaces/IHttpRepository";
import { pixApprovedResponseMock, pixPendingResponseMock } from "./pixMock";

export const PaymentService = (
): IPaymentService => {
  const httpRepository: IHttpRepository = HttpRepository();
  const paymentRepository: IPaymentRepository = CefSharpPaymentRepository()
  const payAsync = async (orderValue: number, transactionType: TransactionType): Promise<Payment> => {
    try {
      const pay = await paymentRepository.payAsync(orderValue, transactionType);
      if (!pay.success) {
        throw new Error(pay.errorMessage ?? "");
      }
      return pay
    } catch (error) {
      throw error;
    }
  };
  const cancelPaymentAsync = async (value: number, typePayment: TransactionType, couponNumber: string, paymentDate: string) => {
    const cashback = await paymentRepository.cancelPaymentAsync(value, typePayment, couponNumber, paymentDate)
    console.log("cashback", cashback);
    return cashback
  }

  const pixPayment = async (payRequest: PixPaymentRequest): Promise<PixPaymentResponse> => {
    // const result = await httpRepository.postAsync<PixPaymentRequest, PixPaymentResponse>('https://b3c4-200-166-48-200.ngrok-free.app/Promptus.Meep.Server/api/Pix/', payRequest);
    const result = await httpRepository.postAsync<PixPaymentRequest, PixPaymentResponse>('https://portal-api.meep.cloud/api/Pix/', payRequest);
    return result;
    // return Promise.resolve(pixPendingResponseMock)
  }

  const pixPaymentStatus = async (paymentId: string): Promise<PixPaymentResponse> => {
    // const result = await httpRepository.getAsync<PixPaymentResponse>(`https://b3c4-200-166-48-200.ngrok-free.app/Promptus.Meep.Server/api/Pix/${paymentId}`);
    const result = await httpRepository.getAsync<PixPaymentResponse>(`https://portal-api.meep.cloud/api/Pix/${paymentId}`);
    return result;
    // return Promise.resolve(pixApprovedResponseMock)
  }  
  return { payAsync, cancelPaymentAsync, pixPayment, pixPaymentStatus };
};
