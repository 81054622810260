import { Ticket } from "../../../../domains/order/agreggates/ticket/ticket";
import { ICefSharp } from "./Interfaces/_ICefSharp";
import { ICefSharpPrintService } from "./Interfaces/_ICefSharpPrintService";
import { EletronicInvoiceTicket } from "../../../../domains/order/agreggates/EletronicInvoiceTicket/EletronicInvoiceTicket";
import { IPrintRepository } from "../../../../domains/order/interfaces/repositories/iprintRespository";
import { OrderResume } from "../../../../domains/order/agreggates/order/OrderResume";
import { v4 } from "uuid";

declare const CefSharp: ICefSharp;
declare const printService: ICefSharpPrintService;

export const LocalPrintRepository = (): IPrintRepository => {
  const printOrderAsync = async (ticket: Ticket): Promise<boolean> => {
    await CefSharp.BindObjectAsync("printService");

    return printService.print(ticket);
  };



  const printEletronicInvoiceAsync = async (
    eletronicInvoiceTicket: EletronicInvoiceTicket
  ): Promise<boolean> => {
    console.log("NFCE", JSON.stringify(eletronicInvoiceTicket));
    await CefSharp.BindObjectAsync("printService");
    return printService.printEletronicInvoice(eletronicInvoiceTicket);
  };


  const printImage = async (ImageToPrint: string): Promise<boolean> => {
    await CefSharp.BindObjectAsync("printService");
    return printService.printImage({ DocumentName: v4(), ImageToPrint });
  };



  const printResumeAsync = async (
    orderResume: OrderResume
  ): Promise<boolean> => {
    await CefSharp.BindObjectAsync("printService");

    return printService.printResume(orderResume);
  };



  const printStringAsync = async (
    text: string
  ): Promise<boolean> => {
    await CefSharp.BindObjectAsync("printService");

    return printService.printString(text);
  };



  const remotePrintAsync = async (
    uri: string,
    orderResume: OrderResume
  ): Promise<boolean> => {
    await CefSharp.BindObjectAsync("printService");

    return printService.remotePrint(uri, orderResume);
  };


  return {
    printOrderAsync,
    printEletronicInvoiceAsync,
    printResumeAsync,
    remotePrintAsync,
    printStringAsync,
    printImage,
  };
};
