import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { useKiosk } from '../../../../application/contexts/kiosk/KioskContext';
import { useUi } from '../../../../application/contexts/ui/UIContext';
import { Order } from '../../../../domains/order/agreggates/order/Order';
import { Payment } from '../../../../domains/order/agreggates/payment/payment';
import { TransactionType } from '../../../../domains/order/agreggates/payment/transactionType';
import { AddOrderInDemandScheduleRequest } from '../../../../domains/schedule/agreggates/IDemmandSchedule';
import ScheduleRepository from '../../../../Infrastructure/repositories/api/ScheduleRepository';
import { IScheduleItemCart } from '../../interfaces/IScheduleItemCart'
import ScheduleUseCase from '../../ScheduleUseCase';
import { IOrderProviderRef } from 'modules/order/presentation/OrderProvider';
import { GetError } from 'utils/GetError';
import { ErrorCodeType } from 'application/models/IError';
import { IOrderLog } from 'modules/order/domain/models/IOrderLog';
import { IPedidoPosDTO } from 'modules/order/domain/models/IPedidoPosDTO';
import { IEmitedNfce } from 'modules/order/domain/models/INfce';
import { OrderRepositoryLocalStorage } from 'Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage';
import { useAppInsights } from 'Infrastructure/repositories/appInsights/AppInsights';
export interface IUseScheduleCartProps {
    //propertys
}

export interface IUseScheduleCart {
    //propertys
    add: (scheduleItem: IScheduleItemCart) => void,
    remover: (scheduleItem: IScheduleItemCart) => void,
    clear: () => void,
    scheduleCartItems: IScheduleItemCart[]
    onClickContinuar: () => void
    total: number,
    quantity: number,
    cancelSchedules: () => void
}


const locaStorageOrderRepository = OrderRepositoryLocalStorage();


const UseScheduleCart = (orderProvider: React.RefObject<IOrderProviderRef>): IUseScheduleCart => {
    const [scheduleCartItems, setscheduleCartItems] = useState<IScheduleItemCart[]>([]);
    const { kiosk } = useKiosk();
    const { addLog } = useAppInsights();


    const { replace } = useHistory();
    const { toastFullscreen, showLoading, hideLoading, toast } = useUi();

    const add = useCallback(
        (scheduleItem: IScheduleItemCart) => {
            setscheduleCartItems(prev => [...prev, scheduleItem])
        },
        [],
    )
    const remover = useCallback(
        (scheduleItem: IScheduleItemCart) => {
            setscheduleCartItems(prev => prev.filter((item) => item.id !== scheduleItem.id));
            const scheduleRepository = ScheduleRepository();
            scheduleRepository.cancelSchedule(scheduleItem.id)
        },
        [],
    )

    const clear = useCallback(
        () => {
            setscheduleCartItems(prev => [])
        },
        [],
    )

    const addOrderLog = useCallback(
        async (
          order: IOrderLog | null,
          message: string,
          pedidoPOS?: IPedidoPosDTO,
          payment?: Payment,
          nfce?: IEmitedNfce[]
        ) => {
          try {
            if (order) {
              let orderLog = { ...order, payment, pedidoPOS, nfce };
              console.log({ orderLog, message });
              addLog("schedule", {message, orderLog});
              await locaStorageOrderRepository.add(orderLog, message);
            }
          } catch (error) {
            GetError(
              error,
              "addOrderLog",
              "OrderProvider",
              "Falha ao adicionar log",
              ErrorCodeType.GENERAL
            );
          }
        },
        []
      );


    const onSucessPayment = useCallback(
        async (orderWithCustomer: Order, transactionType: TransactionType, payment: Payment) => {

            showLoading();
            if (kiosk) {
                scheduleCartItems.map(async (demmand) => {

                    const request: AddOrderInDemandScheduleRequest = {
                        orderId: orderWithCustomer.id,
                        demandScheduleId: demmand.id
                    }

                    const scheduleRepository = ScheduleRepository();
                    await scheduleRepository.addOrderInDemmandSchedule(request);
                })


                hideLoading();

                toastFullscreen(`Seja bem-vinda!\n\n❤️❤️❤️\n\nEntre e viva uma nova experiência!\n`, "success", {onClose: () => { replace("/") }});

            }
            clear()
        },
        [clear, hideLoading, kiosk, replace, showLoading, toastFullscreen, scheduleCartItems],
    )

    const onClickContinuar = useCallback(
        async () => {
            const scheduleUseCase = ScheduleUseCase();
            const newOrder = scheduleUseCase.scheduleCartToOrder(scheduleCartItems)
            try {
                const result = await orderProvider.current?.startPayment(newOrder);
                if (result) {
                    onSucessPayment(result.order, result.paymentType, result.payment)
                } else {
                addOrderLog(newOrder, `Falha no pagamento`);
                  toast("Falha no pagamento", "error");
                  GetError(
                    new Error("Falha no pagamento"),
                    "paymentCashlessOffline",
                    "useOfflineRecharge",
                    undefined,
                    ErrorCodeType.PAYMENT
                  )
                }
              } catch (error) {
                GetError(
                  error,
                  "paymentCashlessOffline",
                  "useOfflineRecharge",
                  undefined,
                  ErrorCodeType.PAYMENT
                )
              }
        },
        [addOrderLog, onSucessPayment, orderProvider, scheduleCartItems, toast],
    )

    const getTotalsAndQuantity = useCallback(
        () => {
            return ({
                total: scheduleCartItems.map(item => item.colaboratorSchedule.serviceValue).reduce((a, b) => (a + b), 0),
                quantity: scheduleCartItems.length
            })
        },
        [scheduleCartItems],
    )


    const cancelSchedules = useCallback(() => {
        const scheduleRepository = ScheduleRepository()
        showLoading()
        clear()
        Promise.all(scheduleCartItems.map(async (item) => {
            scheduleRepository.cancelSchedule(item.id)
        })).finally(() => {
            hideLoading()
        })

    }, [clear, hideLoading, scheduleCartItems, showLoading])

    return (
        {
            add,
            remover,
            clear,
            scheduleCartItems,
            onClickContinuar,
            total: getTotalsAndQuantity().total,
            quantity: getTotalsAndQuantity().quantity,
            cancelSchedules
        }
    )
}
export default UseScheduleCart