import {
  Payment,
} from "modules/payment/domain/models/IPayment";
import { TransactionType } from "modules/payment/domain/models/TransactionType";
import { IPinpadService } from "../interfaces/IPinpadService";
import { GetError } from "utils/GetError";
import { ErrorCodeType } from "application/models/IError";

export const DoPinpadPaymentUseCase = async (
  service: IPinpadService,
  value: number,
  type: TransactionType
): Promise<Payment> => {
  try {
    const result = await service.payAsync(value, type);
    return result;
  } catch (error) {
    return Promise.reject(
      GetError(
        error,
        'DoPinpadPaymentUseCase',
        'DoPinpadPaymentUseCase',
        '[Cefsharp] - Falha no pagamento',
        ErrorCodeType.PAYMENT
      )
    )
  }
    // __DEV__ && console.log(result);
};
