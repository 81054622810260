import { Dialog, Icon, IconButton } from '@material-ui/core';
import React, { FC } from 'react'
import styles from './ModalRetirada.module.scss'


export enum EnumRetirada {
    COMER_AQUI = 0,
    LEVAR_PARA_VIAGEM = 1,

}

export interface IModalRetiradaProps {
    open: boolean;
    onClick: (value: EnumRetirada) => void;
    onClose: () => void;
}
//TODO: Refazer modal Retirada usar ActionMOdal
const ModalRetirada: FC<IModalRetiradaProps> = ({ open, onClick, onClose }) => {
    return (
        <Dialog className={styles.dialog} open={open} id={styles.ModalRetirada} >
            <div className={styles.header}>
                <h2>Pedido para viagem</h2>
                <IconButton
                    onClick={onClose}
                    style={{
                        position: "absolute",
                        top: 12,
                        right: 12,
                        color: "#fff",
                    }}
                    data-custom-id="withdrawal-close-button"
                    data-custom-description="Fechar modal retirada"
                >
                    <Icon fontSize="large">close</Icon>
                </IconButton>
            </div>
            <div className={styles.container} >
                Onde gostaria de consumir?
                <div 
                    className={styles.button} 
                    onClick={() => { 
                        onClose();
                        onClick(EnumRetirada.COMER_AQUI) 
                    }}
                    data-custom-id="withdrawal-stay-button"
                    data-custom-description="Para comer aqui"
                >Para comer aqui</div>
                <div 
                    className={styles.button} 
                    onClick={() => { 
                        onClose(); 
                        onClick(EnumRetirada.LEVAR_PARA_VIAGEM) 
                    }}
                    data-custom-id="withdrawal-leave-button"
                    data-custom-description="Levar para viagem"
                >Levar para viagem</div>
            </div>
        </Dialog>
    )
}
export default ModalRetirada