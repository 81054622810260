import Axios from "axios"
import { IHttpRepository } from "Infrastructure/interfaces/IHttpRepository"
import { IUserRegiterResponseDTO } from "../interfaces/IUserRegiter"



const API_USER_DATA = `https://meepserver2.azurewebsites.net/api/dispositivo/dadoscliente/901DDA04-285D-D764-A779-5D9BAF472E4A/`
const TOKEN = "-eW-nCIknyHLY3XoFRoVlz3awO888h9ft--9IRqpQ7OTDwK4BTN_Li2NLc3TIeHmHzvmvUC8N0xhHlJwZdK_zpgxrAbyvo3oLayqZx4IY2w9DIkiAW4G0GDwZno-5k951xN4O9s4DUDYAcwrSjUmfbJQTXbjgY1tdaXkVCAnDvv5WyzuGYuRKRYXc_ZZFtPeIKMxAqERV9nN-vv9lIMHindzF-qvDzVK6pPYNgxKJ4fDgBBs06_C3mI37rDS8FF-YkZ8spN28ucjIE85fPpw_kBL7FBEew_N1OB8XWF1IigOQ_OqYSRl4xOp6Kc-EhAUdr07YqvyaBqxnbTP7fleuXjDfNqa99gxgrLGXqTuXYESGWDiPnTywGmbMofTZc3y5hs3aWmSv2eZDoPwyXkQQcY1UtFbMeFC4rXUaQPdXyRZCnotHoE8jTpY21x_Q3bqtqTDiP4NwLv3F04U4nx1Ym2fTjbRn-o60OHFMJQxyg2pwjuCpzfnXrDgei7UVRqtN2GX_IZmQsAYT9_DsZPOnF0LYp9AW1ofViCmzdpwCjySgxs3GX_74syfJU9_NtnN"



const UserRegisterService = (httpRepository: IHttpRepository) => {


    const getUserDataForced = (cpf: string) => {
        const response = Axios.get(API_USER_DATA + cpf, {
            headers: {
                Authorization: `Bearer ${TOKEN}`
            }
        });
        return response
    }
    const getUserData = async (localId: string, cpf: string) => {
        const response: IUserRegiterResponseDTO = await httpRepository.getAsync<IUserRegiterResponseDTO>(`/dispositivo/dadoscliente/${localId}/${cpf}`);
        return response
    }


    return ({ getUserData })
}
export default UserRegisterService